<div class="container-fluid">
    <div class="row filters">
        <div class="col company-filter">
            <label>Bemanningsbolag</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filterStaffingCompany" placeholder="Välj bolag">
                    <mat-option *ngFor="let company of staffingCompanies" [value]="company"
                    >{{ company.companyName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col punch-date-filter">
            <label>Stämpeldatum</label>
            <div class="date-range">
                <mat-form-field class="from" (click)="pickerFrom.open()">
                    <input matInput [matDatepicker]="pickerFrom" [(ngModel)]="filterPunchDateFrom" readonly/>
                    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFrom></mat-datepicker>
                </mat-form-field>
                <span>-</span>
                <mat-form-field class="to" (click)="pickerTo.open()">
                    <input matInput [matDatepicker]="pickerTo" [(ngModel)]="filterPunchDateTo" readonly/>
                    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                    <mat-datepicker #pickerTo></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="col button-filter">
            <button mat-raised-button (click)="getReport()" color="primary">Ladda ner rapport</button>
        </div>
    </div>
</div>
<div class="loading-wrapper" *ngIf="loading">
    <mat-spinner diameter="40"></mat-spinner>
</div>
