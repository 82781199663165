import { Component, OnInit } from '@angular/core';

// Services
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'app-new-trip-driving-log-page',
    templateUrl: './new-trip.component.html',
    styleUrls: ['./new-trip.component.scss']
})

export class NewTripDrivingLogComponent implements OnInit {
    employeeId: number;

    constructor(private authService: AuthService) { }

    ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
    }
}
