import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// Services
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'app-edit-car-driving-log-page',
    templateUrl: './edit-car.component.html',
    styleUrls: ['./edit-car.component.scss']
})

export class EditCarDrivingLogComponent implements OnInit {
    public employeeId: number;
    public carId: number;

    constructor(private authService: AuthService,
        private activatedRoute: ActivatedRoute) { }

    public ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
        this.activatedRoute.params.subscribe(params => {
            this.carId = +params['id'];
        });
    }
}
