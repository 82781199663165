<div class="container-fluid" [formGroup]="employeeForm">
    <div class="row">
        <div class="col-md">
            <div class="row">
                <div class="col">
                    <mat-card class="compact">
                        <mat-card-title> Personuppgifter</mat-card-title>
                        <mat-card-content>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-md">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Förnamn</mat-label>
                                            <input matInput autocomplete="off" formControlName="firstName" required/>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md">
                                        <mat-form-field>
                                            <mat-label>Efternamn</mat-label>
                                            <input matInput autocomplete="off" formControlName="lastName" required/>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <mat-form-field>
                                            <mat-label>Personnummer</mat-label>
                                            <input
                                                matInput
                                                autocomplete="off"
                                                formControlName="identityNumber"
                                                (keyup)="updateLoginCode()"
                                                required/>
                                            <mat-hint>I formatet ÅÅÅÅMMDD-XXXX</mat-hint>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md">
                                        <mat-form-field>
                                            <mat-label>E-post</mat-label>
                                            <input matInput autocomplete="off" formControlName="email" required/>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md">
                                        <mat-form-field>
                                            <mat-label>E-post arbete</mat-label>
                                            <input matInput autocomplete="off" formControlName="workEmail" required/>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md">
                                        <mat-form-field>
                                            <mat-label>Mobilnummer</mat-label>
                                            <input matInput autocomplete="off" formControlName="mobile" required/>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md">
                                        <mat-form-field>
                                            <mat-label>Telefonnummer</mat-label>
                                            <input matInput autocomplete="off" formControlName="phone"/>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <mat-form-field>
                                            <mat-label>Adress</mat-label>
                                            <input matInput autocomplete="off" formControlName="address" required/>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md">
                                        <mat-form-field>
                                            <mat-label>Postnummer</mat-label>
                                            <input matInput autocomplete="off" formControlName="zip" required/>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md">
                                        <mat-form-field>
                                            <mat-label>Postort</mat-label>
                                            <input matInput autocomplete="off" formControlName="city" required/>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row login-code" *ngIf="!adminMode">
                                    <div class="col-md">
                                        Ditt login är:
                                        <span>{{ login }}</span>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <div class="row">
                <div class="col" *ngIf="!adminMode">
                    <mat-card class="compact">
                        <mat-card-title> Anställning</mat-card-title>
                        <mat-card-content>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col">
                                        <mat-form-field>
                                            <mat-label>Bolag</mat-label>
                                            <mat-select formControlName="companyId">
                                                <mat-option *ngFor="let company of companies"
                                                            [value]="company.companyId">
                                                    {{ company.companyName }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <mat-form-field>
                                            <mat-label>Anställningstyp</mat-label>
                                            <mat-select formControlName="employmentTypeId">
                                                <mat-option *ngFor="let type of employmentTypes"
                                                            [value]="type.employmentTypeId">
                                                    {{ type.employmentTypeName }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="compact">
                        <mat-card-title>Bemanning</mat-card-title>
                        <mat-card-content>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-md">
                                        <mat-form-field>
                                            <mat-label>Bemanningsbolag</mat-label>
                                            <mat-select formControlName="staffingCompanyId" placeholder="0">
                                                <mat-option *ngFor="let staffingCompany of staffingCompanies"
                                                            [value]="staffingCompany.staffingCompanyId">
                                                    {{ staffingCompany.companyName }}
                                                </mat-option>
                                                <mat-option [value]="0">Inget valt</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div class="col" *ngIf="adminMode">
                    <mat-card class="compact">
                        <mat-card-title> Anställning</mat-card-title>
                        <mat-card-content>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-md">
                                        <mat-form-field>
                                            <mat-label>Bolag</mat-label>
                                            <mat-select formControlName="companyId">
                                                <mat-option *ngFor="let company of companies"
                                                            [value]="company.companyId">
                                                    {{ company.companyName }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md">
                                        <mat-form-field>
                                            <mat-label>Avdelning</mat-label>
                                            <mat-select formControlName="departmentId">
                                                <mat-option [value]="0"> (Ej fast)</mat-option>
                                                <mat-option
                                                    *ngFor="let department of departments | companyDepartmentFilter: f.companyId.value"
                                                    [value]="department.departmentId">
                                                    {{ department.departmentName }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md">
                                        <mat-form-field>
                                            <mat-label>Kategori</mat-label>
                                            <mat-select formControlName="categoryId">
                                                <mat-option
                                                    *ngFor="let category of employeeCategories"
                                                    [value]="category.categoryId">
                                                    {{ category.categoryName }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md">
                                        <mat-form-field>
                                            <mat-label>Anställningstyp</mat-label>
                                            <mat-select formControlName="employmentTypeId">
                                                <mat-option *ngFor="let type of employmentTypes"
                                                            [value]="type.employmentTypeId">
                                                    {{ type.employmentTypeName }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md">
                                        <mat-form-field>
                                            <mat-label>Lönetyp</mat-label>
                                            <mat-select formControlName="paymentTypeId"
                                                        (selectionChange)="paymentTypeChanged()">
                                                <mat-option
                                                    *ngFor="let paymentType of paymentTypes"
                                                    [value]="paymentType.paymentTypeId">
                                                    {{ paymentType.paymentTypeName }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md">
                                        <mat-form-field>
                                            <mat-label>Inloggningskod</mat-label>
                                            <input type="number" matInput autocomplete="off" formControlName="login"
                                                   required/>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md">
                                        <mat-form-field>
                                            <mat-label>Anställningsnummer</mat-label>
                                            <input
                                                type="number"
                                                matInput
                                                autocomplete="off"
                                                formControlName="fortnoxEmployeeId"
                                                required/>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md">
                                        <mat-form-field>
                                            <mat-label>Timmar/dag</mat-label>
                                            <input matInput autocomplete="off" formControlName="hoursPerDay"/>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md">
                                        <mat-form-field>
                                            <mat-label>Dagar/vecka</mat-label>
                                            <input matInput autocomplete="off" formControlName="daysPerWeek"/>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md">
                                        <mat-form-field>
                                            <mat-label>Timlön</mat-label>
                                            <input matInput autocomplete="off" formControlName="hourlyWage"/>
                                            <span matTextSuffix>kronor</span>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md">
                                        <mat-form-field>
                                            <mat-label>Kollektivavtal</mat-label>
                                            <mat-select formControlName="unionContractId">
                                                <mat-option [value]="0"> (Inget kollektivavtal)</mat-option>
                                                <mat-option
                                                    *ngFor="let unionContract of unionContracts"
                                                    [value]="unionContract.contractId">
                                                    {{ unionContract.contractName }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md">
                                        <mat-form-field>
                                            <mat-label>Titel</mat-label>
                                            <input matInput autocomplete="off" formControlName="title"/>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md">
                                        <mat-form-field (click)="periodsStartPicker.open()">
                                            <mat-label>Startdatum för perioder</mat-label>
                                            <input
                                                matInput
                                                autocomplete="off"
                                                [matDatepicker]="periodsStartPicker"
                                                formControlName="periodsStart"
                                                readonly/>
                                            <mat-datepicker-toggle matSuffix
                                                                   [for]="periodsStartPicker"></mat-datepicker-toggle>
                                            <mat-datepicker #periodsStartPicker [disabled]="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row checkboxes">
                                    <div class="col-md">
                                        <mat-checkbox formControlName="reportMilage">Kan rapportera resor</mat-checkbox>
                                    </div>
                                    <div class="col-md">
                                        <mat-checkbox formControlName="reportCostPool">Rapportera kostnadsställe
                                        </mat-checkbox>
                                    </div>
                                </div>
                                <div class="row checkboxes">
                                    <div class="col-md">
                                        <mat-checkbox formControlName="employmentContract"
                                        >Generera anställningsavtal
                                        </mat-checkbox>
                                    </div>
                                    <div class="col-md">
                                        <mat-checkbox formControlName="inactive">Inaktiverad</mat-checkbox>
                                    </div>
                                </div>
                                <div class="row checkboxes">
                                    <div class="col-md">
                                        <mat-checkbox formControlName="isPunching">Stämplar</mat-checkbox>
                                    </div>
                                    <div class="col-md">
                                        <mat-checkbox formControlName="punchWarningsAndPeriodsActive"
                                        >Stämpelvarningar &amp; perioder
                                        </mat-checkbox
                                        >
                                    </div>
                                </div>
                                <div class="row checkboxes">
                                    <div class="col-md">
                                        <mat-checkbox formControlName="canOrderOvertime">Kan beordra övertid
                                        </mat-checkbox>
                                    </div>
                                    <div class="col-md">
                                        <mat-checkbox formControlName="hasUnsocialHoursPayment"
                                        >Kan få OB-ersättning
                                        </mat-checkbox>
                                    </div>
                                </div>
                                <div class="row checkboxes">
                                    <div class="col-md">
                                        <mat-checkbox formControlName="allowRemoteAccess"
                                        >Kan logga in varifrån som helst
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="compact">
                        <mat-card-title>Bemanning</mat-card-title>
                        <mat-card-content>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-md">
                                        <mat-form-field>
                                            <mat-label>Kopplat bemanningsbolag</mat-label>
                                            <mat-select formControlName="staffingCompanyId">
                                                <mat-option *ngFor="let staffingCompany of staffingCompanies"
                                                            [value]="staffingCompany.staffingCompanyId">
                                                    {{ staffingCompany.companyName }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-card class="compact">
                        <mat-card-title> Bankuppgifter</mat-card-title>
                        <mat-card-content>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-md">
                                        <mat-form-field>
                                            <mat-label>IBAN</mat-label>
                                            <input matInput autocomplete="off" formControlName="bankName"/>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md">
                                        <mat-form-field>
                                            <mat-label>Clearing nr.</mat-label>
                                            <input matInput autocomplete="off" formControlName="clearingNumber"/>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <mat-form-field>
                                            <mat-label>Kontonummer</mat-label>
                                            <input matInput autocomplete="off" formControlName="bankAccount"/>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <div class="row" *ngIf="adminMode && hasListPunchesClaim">
                <div class="col">
                    <mat-card class="compact">
                        <mat-card-title> Senaste stämplingarna</mat-card-title>
                        <mat-card-content>
                            <app-employee-punches [employeeId]="employeeId"></app-employee-punches>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <!--<div class="row" *ngIf="adminMode">
                <div class="col">
                    <mat-card class="compact">
                        <mat-card-title>
                            Lägg in frånvaro
                        </mat-card-title>
                        <mat-card-content>
                            <app-report-absence [employeeId]="employeeId" (absenceAdded)="absencePartial.update()"></app-report-absence>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>-->
        </div>
        <div class="col-md">
            <div class="row next-of-kins-wrapper">
                <div class="col">
                    <mat-card
                        *ngFor="let kin of (employeeForm | nextOfKins); let i = index"
                        formArrayName="nextOfKins"
                        class="compact">
                        <div [formGroupName]="i">
                            <mat-card-title>
                                Närmast anhörig {{ i == 0 ? '' : i + 1 }}
                                <button *ngIf="i > 0" mat-mini-fab class="remove-kin" (click)="removeKin(i)">
                                    <mat-icon aria-label="Ta bort anhörig">clear</mat-icon>
                                </button>
                            </mat-card-title>
                            <mat-card-content>
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-md">
                                            <mat-form-field>
                                                <mat-label>Förnamn</mat-label>
                                                <input
                                                    matInput
                                                    autocomplete="off"
                                                    formControlName="firstName"
                                                    [required]="i === 0"/>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md">
                                            <mat-form-field>
                                                <mat-label>Efternamn</mat-label>
                                                <input
                                                    matInput
                                                    autocomplete="off"
                                                    formControlName="lastName"
                                                    [required]="i === 0"/>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <mat-form-field>
                                                <mat-label>Adress</mat-label>
                                                <input matInput autocomplete="off" formControlName="address"/>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md">
                                            <mat-form-field>
                                                <mat-label>Postnummer</mat-label>
                                                <input matInput autocomplete="off" formControlName="zip"/>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md">
                                            <mat-form-field>
                                                <mat-label>Postort</mat-label>
                                                <input matInput autocomplete="off" formControlName="city"/>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md">
                                            <mat-form-field>
                                                <mat-label>Mobilnummer</mat-label>
                                                <input matInput autocomplete="off" formControlName="mobile"/>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md">
                                            <mat-form-field>
                                                <mat-label>Telefonnummer</mat-label>
                                                <input matInput autocomplete="off" formControlName="phone"/>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <mat-form-field>
                                                <mat-label>Kommentar</mat-label>
                                                <textarea matInput autocomplete="off"
                                                          formControlName="comment"></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </div>
                    </mat-card>
                    <div align="right">
                        <button mat-button class="add-next-of-kin" (click)="addKin()">
                            <mat-icon aria-label="Lägg till anhörig">add</mat-icon>
                            Lägg till anhörig
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="adminMode && f.employmentContract.value && hasListEmploymentContractsClaim">
                <div class="col">
                    <mat-card class="compact">
                        <mat-card-title>Anställningsavtal</mat-card-title>
                        <mat-card-content>
                            <app-employee-contracts [employeeId]="employeeId"></app-employee-contracts>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <div class="row"
                 *ngIf="adminMode && f.punchWarningsAndPeriodsActive.value && hasViewOpenWorkPeriodClaim">
                <div class="col">
                    <mat-card class="compact">
                        <mat-card-title>Öppna tidrapporter</mat-card-title>
                        <mat-card-content>
                            <app-employee-work-periods [employeeId]="employeeId"></app-employee-work-periods>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <div class="row" *ngIf="adminMode && hasViewActiveScheduleClaim">
                <div class="col">
                    <mat-card class="compact">
                        <mat-card-title> Aktivt schema</mat-card-title>
                        <mat-card-content>
                            <app-employee-schedule [employeeId]="employeeId"></app-employee-schedule>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <div class="row" *ngIf="adminMode && hasListAbsenceClaim">
                <div class="col">
                    <mat-card class="compact">
                        <mat-card-title> Frånvaro</mat-card-title>
                        <mat-card-content>
                            <app-employee-absence #absencePartial [employeeId]="employeeId"></app-employee-absence>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <div class="row" *ngIf="adminMode && hasListTravelCompensationsClaim">
                <div class="col">
                    <mat-card class="compact">
                        <mat-card-title> Milrapportering</mat-card-title>
                        <mat-card-content>
                            <app-employee-travel-compensations #travelCompensationsPartial
                                                               [employeeId]="employeeId">
                            </app-employee-travel-compensations>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <!--<div class="row" *ngIf="adminMode">
                <div class="col">
                    <mat-card class="compact">
                        <mat-card-title>
                            Lägg in resa
                        </mat-card-title>
                        <mat-card-content>
                            <app-report-milage (compensationAdded)="travelCompensationsPartial.update()" [employeeId]="employeeId"></app-report-milage>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>-->
        </div>
    </div>
    <div class="row actions">
        <div class="col" align="end">
            <button mat-raised-button *ngIf="cancelRoute" (click)="cancel()">Avbryt</button>
            <button *ngIf="adminMode" mat-raised-button (click)="resetPassword()">Återställ lösenord</button>
            <button *ngIf="adminMode" mat-raised-button (click)="sendToDreamPack()">Skicka till DreamPack</button>
            <button mat-raised-button color="primary" (click)="save()">Spara</button>
        </div>
    </div>
</div>

<div class="loading-wrapper" *ngIf="loading">
    <mat-spinner diameter="40"></mat-spinner>
</div>
