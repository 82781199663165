import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from "@angular/router";

// Services
import { AuthService } from '@app/services/auth.service';
import { Helpers } from '@app/helpers/helpers.class';

@Component({
    selector: 'app-employee-cost-pool-handled-by-admin-page',
    templateUrl: './employee-cost-pool-handled-by.component.html',
    styleUrls: ['./employee-cost-pool-handled-by.component.scss']
})

export class EmployeeCostPoolHandledByAdminComponent implements OnInit {
    employeeId: number;
    page: number;
    companyId: number;
    status: number;

    constructor(private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private router: Router) { }

    ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();

        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);

        if (Helpers.isDefined(params['page'])) {
            this.page = parseInt(params['page']);
        }

        if (Helpers.isDefined(params['companyId'])) {
            this.companyId = parseInt(params['companyId']);
        }

        if (Helpers.isDefined(params['status'])) {
            this.status = parseInt(params['status']);
        }
    }

    updateQueryParameters(filter: any): void {
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);
        params['companyId'] = filter.companyId;
        params['status'] = filter.status;
        params['page'] = filter.page;
        this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params });
    }
}
