import { Pipe, PipeTransform } from '@angular/core';
import { Helpers } from '@app/helpers/helpers.class';

@Pipe({name: 'dayOfWeekShort'})
export class DayOfWeekShortPipe implements PipeTransform {
    transform(dateTime: string): string {
        let date = new Date(dateTime);
        return Helpers.getDayName(date.getDay()).substr(0, 3);
    }
}
