<header>
    <mat-toolbar>
        <button mat-button>
            <mat-icon class="spaced">directions_car</mat-icon>
            <span>Körjournal</span>
        </button>

        <ng-container *ngIf="!mobile && authenticated; else mobileTemplate">
            <mat-divider [vertical]="true"></mat-divider>
            <button mat-button>
                Scheman
            </button>
        </ng-container>

        <ng-template #mobileTemplate>
            <ng-container *ngIf="authenticated">
                <div class="spacer"></div>
                <button mat-button [matMenuTriggerFor]="mobileMenu">
                    <mat-icon>menu</mat-icon>
                </button>
                <mat-menu #mobileMenu="matMenu" class="driving-log-main-mobile-menu">
                    <button mat-menu-item routerLink="/driving-log/trip">
                        <mat-icon>add</mat-icon>
                        Ny resa
                    </button>
                    <button mat-menu-item routerLink="/driving-log/trip-history">
                        <mat-icon>history</mat-icon>
                        Tidigare resor
                    </button>
                    <button mat-menu-item routerLink="/driving-log/cars">
                        <mat-icon>directions_car</mat-icon>
                        Bilar
                    </button>
                    <mat-divider></mat-divider>
                    <button mat-menu-item (click)="logout()">
                        <mat-icon>exit_to_app</mat-icon>
                        Logga ut
                    </button>
                </mat-menu>
            </ng-container>
        </ng-template>
    </mat-toolbar>
</header>
