import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ApplicationClientService } from '@app/services/application-client.service';

@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor {
    constructor(private router: Router, private applicationClientService: ApplicationClientService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const clientId = this.applicationClientService.getClientId();

        if (this.router.url.indexOf('/admin/') > -1) {
            currentUser = JSON.parse(localStorage.getItem('currentAdmin'));
        }

        if (this.router.url.indexOf('/driving-log/') > -1) {
            currentUser = JSON.parse(localStorage.getItem('currentDrivingLogUser'));
        }

        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`,
                    'X-Client-Id': clientId,
                },
            });
        }

        return next.handle(request);
    }
}
