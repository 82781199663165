<app-employee-menu></app-employee-menu>
<main role="main">
    <div class="container page-container">
        <div class="row messages" *ngIf="showBankDetailsWarning || showSoonDuePeriodWarning || showPastDuePeriodWarning">
            <div class="col">
                <div class="warning" *ngIf="showBankDetailsWarning">
                    <div class="icon">
                        <mat-icon>warning</mat-icon>
                    </div>
                    <div class="message">
                        Du har inte angett alla dina bankuppgifter för att kunna få lön utbetald.
                        <a routerLink="/edit-my-details">Klicka här för att komplettera dina uppgifter</a>.
                    </div>
                </div>
                <div class="info" *ngIf="showSoonDuePeriodWarning">
                    <div class="icon">
                        <mat-icon>error_outline</mat-icon>
                    </div>
                    <div class="message">
                        Det finns tidrapporter som snart ska vara klarmarkerade, vänligen gör detta så snart som möjligt!
                    </div>
                </div>
                <div class="error" *ngIf="showPastDuePeriodWarning">
                    <div class="icon">
                        <mat-icon>warning</mat-icon>
                    </div>
                    <div class="message">
                        Det finns tidrapporter som har passerat sitt inlämningsdatum. Klarmarkera dessa snarast!
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="row">
                    <div class="col">
                        <div class="employee-card">
                            <mat-icon>account_circle</mat-icon>
                            <div *ngIf="employee; else namePlaceholder">
                                <span>{{ employee.firstName }} {{ employee.lastName }}</span>
                                <span>{{ employee.company.companyName }}</span>
                            </div>
                            <ng-template #namePlaceholder>
                                <div>
                                    <span class="ph ph-name"></span>
                                    <span class="ph ph-company"></span>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md">
                        <div
                            class="punch"
                            *ngIf="costPools && employee; else punchPlaceholder"
                            [matTooltip]="
                                mustSelectCostPool && !selectedPool ? 'Du måste välja en avdelning för att stämpla in' : null
                            ">
                            <mat-form-field *ngIf="mustSelectCostPool">
                                <mat-label>Avdelning</mat-label>
                                <mat-select [(ngModel)]="selectedPool" (selectionChange)="costPoolChanged()">
                                    <mat-option *ngFor="let costPool of costPools" [value]="costPool">
                                        {{ costPool.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button
                                mat-raised-button
                                [color]="punchedIn ? 'warn' : 'primary'"
                                (click)="punch()"
                                [disabled]="mustSelectCostPool && !selectedPool">
                                <span *ngIf="!punchedIn; else punchOut">
                                    <mat-icon> arrow_forward </mat-icon>
                                    Stämpla in
                                    <span *ngIf="selectedPool && selectedPool.poolId > 0">
                                        på
                                        <span class="cost-pool">{{ selectedPool.name }}</span>
                                    </span>
                                </span>
                                <ng-template #punchOut>
                                    <span>
                                        <mat-icon> arrow_back </mat-icon>
                                        Stämpla ut
                                        <span *ngIf="employee.lastPunch.costPool && employee.lastPunch.costPool.poolId > 0">
                                            från
                                            <span class="cost-pool">{{ employee.lastPunch.costPool.name }}</span>
                                        </span>
                                    </span>
                                </ng-template>
                            </button>
                        </div>
                        <ng-template #punchPlaceholder>
                            <div class="punch">
                                <div class="ph ph-cost-pool"></div>
                                <div class="ph ph-punch"></div>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div class="col-md">
                <div class="clock">
                    <h2 class="mat-headline-1">{{ currentTime }}</h2>
                    <h2 class="mat-headline-4">{{ currentDay }}</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-tab-group class="tabs" [(selectedIndex)]="selectedTabIndex">
                    <mat-tab label="Min tid">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md">
                                    <div class="row" [hidden]="!(employee && employee.openWorkPeriods.length > 0) && !loading">
                                        <div class="col">
                                            <div class="tab-content">
                                                <mat-card class="compact">
                                                    <mat-card-title>
                                                        <mat-icon>style</mat-icon>
                                                        Öppna tidrapporter
                                                    </mat-card-title>
                                                    <mat-card-content>
                                                        <app-employee-work-periods
                                                            #workPeriodsPartial
                                                            [useExternalData]="true"
                                                            [employeeId]="employeeId"
                                                            [messagesPartial]="messagesPartial"
                                                            [reportAbsencePartial]="reportAbsence"
                                                            (selectTab)="selectTab($event)"></app-employee-work-periods>
                                                    </mat-card-content>
                                                </mat-card>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md">
                                            <div class="tab-content">
                                                <mat-card class="compact">
                                                    <mat-card-title>
                                                        <mat-icon>playlist_add</mat-icon>
                                                        Tidsummering
                                                    </mat-card-title>
                                                    <mat-card-content>
                                                        <app-employee-punch-summary
                                                            #punchSummaryPartial
                                                            [useExternalData]="true"
                                                            [employeeId]="employeeId"></app-employee-punch-summary>
                                                    </mat-card-content>
                                                </mat-card>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md">
                                    <div class="row">
                                        <div class="col-md">
                                            <div class="tab-content">
                                                <div class="disabled" *ngIf="!authenticatedWithPassword">
                                                    Kräver inloggning med lösenord
                                                    <a [routerLink]="[]" (click)="showLoginWithPassword()"> Logga in </a>
                                                </div>
                                                <mat-card class="compact">
                                                    <mat-card-title>
                                                        <mat-icon> history </mat-icon>
                                                        Senaste stämplingar
                                                    </mat-card-title>
                                                    <mat-card-content>
                                                        <app-employee-recent-punches
                                                            #recentPunchesPartial
                                                            [useExternalData]="true"
                                                            [employeeId]="employeeId"></app-employee-recent-punches>
                                                    </mat-card-content>
                                                </mat-card>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="false">
                                        <div class="col-md">
                                            <div class="tab-content">
                                                <mat-card class="compact">
                                                    <mat-card-title>
                                                        <mat-icon>add_circle_outline</mat-icon>
                                                        Saldon
                                                    </mat-card-title>
                                                    <mat-card-content> </mat-card-content>
                                                </mat-card>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md">
                                            <div class="tab-content">
                                                <div class="disabled" *ngIf="!authenticatedWithPassword">
                                                    Kräver inloggning med lösenord
                                                    <a [routerLink]="[]" (click)="showLoginWithPassword()"> Logga in </a>
                                                </div>
                                                <mat-card class="compact">
                                                    <mat-card-title>
                                                        <mat-icon>event_busy</mat-icon>
                                                        Rapporterad frånvaro
                                                    </mat-card-title>
                                                    <mat-card-content>
                                                        <app-employee-absence
                                                            #absencePartial
                                                            [useExternalData]="true"
                                                            [employeeId]="employeeId"></app-employee-absence>
                                                    </mat-card-content>
                                                </mat-card>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Rapportera">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md">
                                    <div class="tab-content">
                                        <mat-card class="compact">
                                            <mat-card-title>
                                                <mat-icon>event_busy</mat-icon>
                                                Ledighet och frånvaro
                                            </mat-card-title>
                                            <mat-card-content>
                                                <app-report-absence
                                                    #reportAbsence
                                                    [employeeId]="employeeId"
                                                    (absenceAdded)="absenceAdded()"></app-report-absence>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                </div>
                                <div class="col-md" [hidden]="!(employee && employee.reportMilage)">
                                    <div class="row">
                                        <div class="col">
                                            <div class="tab-content">
                                                <mat-card class="compact">
                                                    <mat-card-title>
                                                        <mat-icon> directions_car </mat-icon>
                                                        Milrapportering
                                                    </mat-card-title>
                                                    <mat-card-content>
                                                        <app-report-milage
                                                            [employeeId]="employeeId"
                                                            (compensationAdded)="travelCompensationAdded()"></app-report-milage>
                                                    </mat-card-content>
                                                </mat-card>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="tab-content">
                                                <div class="disabled" *ngIf="!authenticatedWithPassword">
                                                    Kräver inloggning med lösenord
                                                    <a [routerLink]="[]" (click)="showLoginWithPassword()"> Logga in </a>
                                                </div>
                                                <mat-card class="compact">
                                                    <mat-card-title>
                                                        <mat-icon>directions_car</mat-icon>
                                                        Rapporterade sträckor
                                                    </mat-card-title>
                                                    <mat-card-content>
                                                        <app-employee-travel-compensations
                                                            #travelCompensationsPartial
                                                            [useExternalData]="true"
                                                            [employeeId]="employeeId"></app-employee-travel-compensations>
                                                    </mat-card-content>
                                                </mat-card>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Mina uppgifter">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md">
                                    <div class="tab-content my-details">
                                        <div class="disabled" *ngIf="!authenticatedWithPassword">
                                            Kräver inloggning med lösenord
                                            <a [routerLink]="[]" (click)="showLoginWithPassword()"> Logga in </a>
                                        </div>
                                        <mat-card class="compact">
                                            <mat-card-title>
                                                <mat-icon>person</mat-icon>
                                                Mina uppgifter
                                            </mat-card-title>
                                            <mat-card-content>
                                                <app-employee-details
                                                    #detailsPartial
                                                    [useExternalData]="true"
                                                    [employeeId]="employeeId"></app-employee-details>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                </div>
                                <div class="col-md" [hidden]="!(employee && employee.recentContracts.length > 0)">
                                    <div class="tab-content">
                                        <mat-card class="compact">
                                            <mat-card-title>
                                                <mat-icon>assignment</mat-icon>
                                                Mina anställningsavtal
                                            </mat-card-title>
                                            <mat-card-content>
                                                <app-employee-contracts
                                                    #contractsPartial
                                                    [useExternalData]="true"
                                                    [employeeId]="employeeId"></app-employee-contracts>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</main>
<app-employee-footer></app-employee-footer>
<app-loading-overlay *ngIf="loading"></app-loading-overlay>
<app-messages
    #messagesPartial
    [hidden]="!authenticatedWithPassword"
    [employeeId]="employeeId"
    (opened)="messagesOpened()"
    (closed)="messagesClosed()"></app-messages>
