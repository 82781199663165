<div class="container-fluid">
    <div class="row">
        <div class="col">
            <label>Rapportera frånvaro för</label>
            <mat-radio-group [(ngModel)]="mode">
                <mat-radio-button value="1">En dag</mat-radio-button>
                <mat-radio-button value="2">Flera dagar</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="one-day" [formGroup]="absenceFormSingle" *ngIf="mode === '1'">
        <div class="row">
            <div class="col-6">
                <mat-form-field class="day" (click)="dayPicker.open()">
                    <mat-label>Dag</mat-label>
                    <input matInput [matDatepicker]="dayPicker" formControlName="day" class="day-picker" required readonly />
                    <mat-datepicker-toggle matSuffix [for]="dayPicker"></mat-datepicker-toggle>
                    <mat-datepicker #dayPicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field class="time-from">
                    <mat-label>Från tid</mat-label>
                    <input type="text" matInput formControlName="fromTime" autocomplete="off" #fromTime required />
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field class="time-to">
                    <mat-label>Till tid</mat-label>
                    <input type="text" matInput formControlName="toTime" autocomplete="off" required />
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field>
                    <mat-label>Typ</mat-label>
                    <mat-select formControlName="type" required>
                        <mat-option *ngFor="let type of types" [value]="type.typeId"> {{type.absenceName}} </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field>
                    <mat-label>Kommentar</mat-label>
                    <textarea matInput formControlName="comment" autocomplete="off"></textarea>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col" align="end">
                <button mat-raised-button color="primary" (click)="save()">Spara</button>
            </div>
        </div>
    </div>
    <div class="multiple-days" [formGroup]="absenceFormMultiple" *ngIf="mode === '2'">
        <div class="row">
            <div class="col-6">
                <mat-form-field class="from" (click)="fromDatePicker.open()">
                    <mat-label>Från</mat-label>
                    <input
                        matInput
                        [matDatepicker]="fromDatePicker"
                        formControlName="fromDate"
                        class="from-date-picker"
                        required
                        readonly />
                    <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #fromDatePicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="to" (click)="toDatePicker.open()">
                    <mat-label>Till och med</mat-label>
                    <input
                        matInput
                        [matDatepicker]="toDatePicker"
                        formControlName="toDate"
                        class="to-date-picker"
                        autocomplete="off"
                        required
                        readonly />
                    <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #toDatePicker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field>
                    <mat-label>Typ</mat-label>
                    <mat-select formControlName="type" required>
                        <mat-option *ngFor="let type of types" [value]="type.typeId"> {{type.absenceName}} </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field>
                    <mat-label>Kommentar</mat-label>
                    <textarea matInput formControlName="comment" autocomplete="off"></textarea>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col" align="end">
                <button mat-raised-button color="primary" (click)="save()">Spara</button>
            </div>
        </div>
    </div>
</div>

<div class="loading-wrapper" *ngIf="loading">
    <mat-spinner diameter="40"></mat-spinner>
</div>
