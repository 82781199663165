import { Component, OnInit, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Helpers } from '@app/helpers/helpers.class';

// Error handling
import * as Sentry from '@sentry/browser';

// Services
import { ScheduleService } from '@app/services/schedule.service';

// Interfaces
import { IScheduleWithDays } from '@app/interfaces/schedule-with-days.interface';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-employee-schedule',
    templateUrl: './employee-schedule.partial.html',
    styleUrls: ['./employee-schedule.partial.scss'],
    animations: [
        trigger('toggleWarnings', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class EmployeeSchedulePartial implements OnInit {
    @Input() public employeeId: number;
    @Input() public data: IScheduleWithDays;
    @Input() public useExternalData: boolean;
    @Input() public atDate: string;
    @Input() public scheduleId: number;

    public scheduleDayColumns: string[] = ['weekday', 'periods', 'breakLength'];
    public schedule: IScheduleWithDays;
    public loading: boolean = false;

    constructor(private scheduleService: ScheduleService, private router: Router, private dialog: MatDialog) {}

    public ngOnInit(): void {
        if (!this.useExternalData) {
            this.getSchedule();
        } else if (this.data) {
            this.schedule = this.data;
        } else {
            this.loading = true;
        }
    }

    public getSchedule(): void {
        this.loading = true;

        if (Helpers.isDefined(this.scheduleId) && this.scheduleId > 0) {
            this.scheduleService.getScheduleWithDays(this.scheduleId).subscribe({
                next: (data) => {
                    this.schedule = data;
                    this.loading = false;
                },
                error: (error) => {
                    console.error(error);
                    this.loading = false;
                    Helpers.captureSentryError('Could not get schedule');
                },
            });
        } else {
            let atDate = Helpers.isDefined(this.atDate) ? this.atDate : '';
            this.scheduleService.getActiveForEmployee(this.employeeId, atDate).subscribe({
                next: (data) => {
                    this.schedule = data;
                    this.loading = false;
                },
                error: (error) => {
                    console.error(error);
                    this.loading = false;
                    Helpers.captureSentryError('Could not get schedule');
                },
            });
        }
    }

    public editSchedule(): void {
        this.dialog.closeAll();
        this.router.navigate(['/admin/schedules/' + this.schedule.scheduleId]);
    }
}
