<div class="container-fluid">
    <div class="row">
        <div class="col status-filter">
            <mat-radio-group [(ngModel)]="filterStatus" (change)="getEmployeesPage(1)">
                <mat-radio-button [value]="1">Alla</mat-radio-button>
                <mat-radio-button [value]="2">Ej kopplade</mat-radio-button>
                <mat-radio-button [value]="3">Kopplade</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="col company-filter">
            <mat-form-field>
                <mat-label>Bolag</mat-label>
                <mat-select [(value)]="filterCompanyId" (selectionChange)="getEmployeesPage(1)">
                    <mat-option [value]="0">(Alla)</mat-option>
                    <mat-option *ngFor="let company of companies" [value]="company.companyId">
                        {{company.companyName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="divider-wrapper">
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="employeesPage && employeesPage.totalItems > 0; else noResults;">
        <div class="col">
            <mat-paginator
                *ngIf="employeesPage && employeesPage.totalItems > employeesPage.pageSize"
                [length]="employeesPage.totalItems"
                [pageSize]="employeesPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
            <div class="table-wrapper">
                <table mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="checkbox">
                        <th mat-header-cell *matHeaderCellDef class="checkbox">
                            <mat-checkbox
                                (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                            <button mat-icon-button [matMenuTriggerFor]="handledByMenu">
                                <mat-icon>keyboard_arrow_down</mat-icon>
                            </button>
                            <mat-menu #handledByMenu="matMenu">
                                <label class="title">Nedan valda ska hanteras av</label>
                                <button mat-menu-item *ngFor="let employee of optionEmployees" (click)="setHandledBy(employee)">
                                    <mat-icon>person</mat-icon>
                                    <span>
                                        {{employee.lastName}}, {{employee.firstName}} ({{companies |
                                        valueFromObjectArray:'companyId':employee.companyId:'companyShort'}})
                                    </span>
                                </button>
                                <div class="spacer"></div>
                                <button mat-menu-item (click)="setHandledBy(null)">
                                    <mat-icon>remove_circle</mat-icon>
                                    <span>(Ingen)</span>
                                </button>
                            </mat-menu>
                        </th>
                        <td mat-cell *matCellDef="let employee" class="checkbox">
                            <mat-checkbox
                                (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(employee) : null"
                                [checked]="selection.isSelected(employee)">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="name">Namn</th>
                        <td mat-cell *matCellDef="let employee" class="name">{{employee.lastName}}, {{employee.firstName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="company">
                        <th mat-header-cell *matHeaderCellDef class="company">Bolag</th>
                        <td mat-cell *matCellDef="let employee" class="company">{{employee.companyName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="department">
                        <th mat-header-cell *matHeaderCellDef class="department">Avdelning</th>
                        <td mat-cell *matCellDef="let employee" class="department">
                            {{employee.departmentName | emptyReplacement}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="handledBy">
                        <th mat-header-cell *matHeaderCellDef class="handled-by">Hanteras av</th>
                        <td mat-cell *matCellDef="let employee" class="handled-by">
                            {{employee.handledByEmployeeFirstName ? employee.handledByEmployeeLastName + ', ' +
                            employee.handledByEmployeeFirstName : '-'}}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="employeeColumns"></tr>
                    <tr mat-row *matRowDef="let employee; columns: employeeColumns;"></tr>
                </table>
            </div>
            <mat-paginator
                *ngIf="employeesPage && employeesPage.totalItems > employeesPage.pageSize"
                [length]="employeesPage.totalItems"
                [pageSize]="employeesPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
        </div>
    </div>
    <ng-template #noResults>
        <div class="row">
            <div class="col no-results">Inga anställda att visa</div>
        </div>
    </ng-template>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
