import { Component, OnInit, Input } from '@angular/core';
import { Helpers } from '@app/helpers/helpers.class';
import { EmployeeContractService } from '@app/services/employee-contract.service';
import { IEmployeeContract } from '@app/interfaces/employee-contract.interface';

@Component({
    selector: 'app-employee-contracts',
    templateUrl: './employee-contracts.partial.html',
    styleUrls: ['./employee-contracts.partial.scss'],
})
export class EmployeeContractsPartial implements OnInit {
    @Input() employeeId: number;
    @Input() data: IEmployeeContract[];
    @Input() useExternalData: boolean;

    loading: boolean = false;
    contracts: IEmployeeContract[];
    contractColumns: string[] = ['icon', 'date', 'download'];
    from: string;
    to: string;

    constructor(private contractService: EmployeeContractService) {}

    ngOnInit(): void {
        let now = new Date();
        this.to = Helpers.toShortDateString(now);
        now.setDate(now.getDate() - 3650);
        this.from = Helpers.toShortDateString(now);

        if (!this.useExternalData) {
            this.getContracts();
        } else if (this.data) {
            this.contracts = this.data;
        } else {
            this.loading = true;
        }
    }

    externalDataLoaded(externalData: IEmployeeContract[]) {
        this.contracts = externalData;
        this.loading = false;
    }

    downloadContract(contract: IEmployeeContract): void {
        window.open(contract.fileUrl);
    }

    getContracts(): void {
        this.loading = true;
        this.contractService.getForEmployeeBetweenDates(this.from, this.to, this.employeeId).subscribe({
            next: (data) => {
                this.loading = false;
                if (data.length > 0) {
                    this.contracts = data;
                }
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not get contracts');
            },
        });
    }
}
