<div class="container-fluid" [formGroup]="scheduleForm">
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col">
                    <mat-card class="compact">
                        <mat-card-title> Schemadetaljer </mat-card-title>
                        <mat-card-content>
                            <div class="container-fluid">
                                <div class="row allow-flex">
                                    <div class="col">
                                        <mat-checkbox formControlName="allowFlex">Tillåter flex</mat-checkbox>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <mat-form-field>
                                            <mat-label>Namn på schema</mat-label>
                                            <input matInput formControlName="name" autocomplete="off" required />
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <mat-form-field>
                                            <mat-label>Schemalängd</mat-label>
                                            <mat-select formControlName="scheduleLength" (selectionChange)="getScheduleDays()">
                                                <mat-option
                                                    *ngFor="let scheduleLength of scheduleLengths"
                                                    [value]="scheduleLength.length">
                                                    {{scheduleLength.length}} dagar
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field class="start-date" (click)="startDatePicker.open()">
                                            <mat-label>Schemat startar</mat-label>
                                            <input
                                                matInput
                                                [matDatepicker]="startDatePicker"
                                                formControlName="startDate"
                                                [matDatepickerFilter]="dateFilter"
                                                (dateChange)="getScheduleDays()"
                                                readonly />
                                            <mat-datepicker-toggle matSuffix [for]="startDatePicker"> </mat-datepicker-toggle>
                                            <mat-datepicker #startDatePicker></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
        <div class="col" *ngIf="scheduleDays">
            <div class="row">
                <div class="col">
                    <mat-card class="compact">
                        <mat-card-title> Schemats dagar </mat-card-title>
                        <mat-card-content>
                            <div class="container-fluid schedule-days">
                                <ng-container
                                    *ngFor="let scheduleDay of (scheduleForm | scheduleDays); let i = index"
                                    formArrayName="days">
                                    <div class="row" [formGroupName]="i">
                                        <div class="col col-4 day-name">
                                            <mat-checkbox
                                                formControlName="active"
                                                (change)="scheduleDayActiveChange(scheduleDay)">
                                                {{scheduleDay.get('dayName').value}}</mat-checkbox
                                            >
                                        </div>
                                        <!--<div class="col">
                                            <mat-form-field>
                                                <mat-label>Från</mat-label>
                                                <input type="time" matInput formControlName="fromHour" autocomplete="off">
                                            </mat-form-field>
                                        </div>
                                        <div class="col">
                                            <mat-form-field>
                                                <mat-label>Till</mat-label>
                                                <input type="time" matInput formControlName="toHour" autocomplete="off">
                                            </mat-form-field>
                                        </div>-->
                                        <div class="col col-8">
                                            <div class="row">
                                                <div class="col">
                                                    <mat-form-field>
                                                        <mat-label>Rast</mat-label>
                                                        <mat-select formControlName="totalBreakLength">
                                                            <mat-option
                                                                *ngFor="let breakLength of breakLengths"
                                                                [value]="breakLength">
                                                                {{breakLength}} min
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <ng-container
                                                        *ngFor="let scheduleDayPeriod of (scheduleDay | scheduleDayPeriods); let x = index"
                                                        formArrayName="periods">
                                                        <div class="row period-row" [formGroupName]="x">
                                                            <div class="col col-5">
                                                                <mat-form-field>
                                                                    <mat-label>Från</mat-label>
                                                                    <input
                                                                        type="time"
                                                                        matInput
                                                                        formControlName="fromHour"
                                                                        autocomplete="off" />
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col col-5">
                                                                <mat-form-field>
                                                                    <mat-label>Till</mat-label>
                                                                    <input
                                                                        type="time"
                                                                        matInput
                                                                        formControlName="toHour"
                                                                        autocomplete="off" />
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col col-button">
                                                                <button
                                                                    mat-icon-button
                                                                    class="remove-period"
                                                                    [disabled]="!scheduleDay.get('active').value"
                                                                    (click)="removePeriod(scheduleDay, x)">
                                                                    <mat-icon>close</mat-icon>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <div class="row add-period-row">
                                                        <div class="col">
                                                            <button
                                                                mat-stroked-button
                                                                [disabled]="!scheduleDay.get('active').value"
                                                                (click)="addPeriod(scheduleDay)">
                                                                <mat-icon>add</mat-icon>
                                                                Lägg till period
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col" align="end">
            <button mat-raised-button color="primary" (click)="save()">Spara</button>
        </div>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
