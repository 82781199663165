import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

// Services
import { AuthService } from '@app/services/auth.service';

// Enums
import { LoginTypeEnum } from '@app/enums/login-type.enum';

@Component({
    selector: 'app-driving-log-menu',
    templateUrl: './driving-log-menu.component.html',
    styleUrls: ['./driving-log-menu.component.scss']
})
export class DrivingLogMenuComponent implements OnInit {
    authenticated: boolean;
    mobile: boolean = true;

    constructor(private router: Router,
        private authService: AuthService,
        public dialog: MatDialog) { }

    ngOnInit(): void {
        this.authenticated = this.authService.isAuthenticated(LoginTypeEnum.DrivingLog);
    }

    logout(): void {
        this.authService.logoutDrivingLog();
        this.router.navigate(['/driving-log/login']);
    }
}
