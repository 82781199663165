<div class="container-fluid">
    <div class="row">
        <div class="col">
            <mat-accordion *ngIf="openWorkPeriods">
                <mat-expansion-panel *ngFor="let period of openWorkPeriods">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{period.periodStart | shortDate}} -
                            {{period.periodEnd | shortDate}}
                        </mat-panel-title>
                        <mat-panel-description>
                            senast färdig {{period.confirmDue | shortDate}}
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="panel-content">
                        <div class="table-wrapper">
                            <table mat-table [dataSource]="period.companies">
                                <ng-container matColumnDef="companyName">
                                    <th mat-header-cell *matHeaderCellDef class="company-name">Bolag</th>
                                    <td mat-cell *matCellDef="let company" class="company-name">
                                        {{company.companyName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="done">
                                    <th mat-header-cell *matHeaderCellDef class="done">Färdiga</th>
                                    <td mat-cell *matCellDef="let company" class="done">
                                        {{company.done.length}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="total">
                                    <th mat-header-cell *matHeaderCellDef class="total">Totalt</th>
                                    <td mat-cell *matCellDef="let company" class="total">
                                        {{company.all.length}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef class="icon"></th>
                                    <td mat-cell *matCellDef="let company" class="icon"
                                        [ngClass]="{'done': company.done.length === company.all.length, 'not-done': company.done.length !== company.all.length}">
                                        <mat-icon *ngIf="company.done.length === company.all.length">done</mat-icon>
                                        <mat-icon *ngIf="company.done.length !== company.all.length">hourglass_empty</mat-icon>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="openWorkPeriodColumns"></tr>
                                <tr mat-row *matRowDef="let company; columns: openWorkPeriodColumns;" (click)="showCompanyEmployees(company)"></tr>
                            </table>
                        </div>
                        <div class="close" align="end">
                            <button mat-button color="warn" (click)="closePeriodPreCheck(period)"><mat-icon>stop</mat-icon> Stäng perioden</button>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>

    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
