import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// Error handling
import * as Sentry from '@sentry/browser';

// Services
import { CarDrivingLogService } from '@app/services/car-driving-log.service';

// Interfaces
import { IPage } from '@app/interfaces/page.interface';
import { Helpers } from '@app/helpers/helpers.class';

@Component({
    selector: 'app-driving-log-trip-history',
    templateUrl: './driving-log-trip-history.partial.html',
    styleUrls: ['./driving-log-trip-history.partial.scss'],
})
export class DrivingLogTripHistoryPartial implements OnInit {
    @Output() public filterChanged: EventEmitter<any> = new EventEmitter();

    @Input() public employeeId: number;
    @Input('page') public inputPage: number;

    public pageIndex: number;
    public tripsPage: IPage<any>;
    public loading: boolean = false;
    public tripColumns: string[] = ['date', 'car', 'length'];

    constructor(private carDrivingLogService: CarDrivingLogService) {}

    public ngOnInit(): void {
        this.getTripsPage(1);
    }

    public getTripsPage(page: number): void {
        this.loading = true;
        this.carDrivingLogService.getByEmployee(this.employeeId, page).subscribe({
            next: (data) => {
                this.tripsPage = data;
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get trips for employee');
            },
        });

        let filterSettings = {
            page: page,
        };

        this.filterChanged.emit(filterSettings);
    }

    public pageChanged(event: any): void {
        this.getTripsPage(event.pageIndex + 1);
        this.pageIndex = event.pageIndex;
    }
}
