<h2 mat-dialog-title>Koppla schema</h2>
<mat-dialog-content>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <mat-form-field>
                    <mat-select [(ngModel)]="selectedScheduleId" placeholder="Välj schema">
                        <mat-option *ngFor="let schedule of schedules" [value]="schedule.scheduleId">
                            {{schedule.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field class="from" (click)="pickerFrom.open()">
                    <input
                        matInput
                        [matDatepicker]="pickerFrom"
                        [(ngModel)]="selectedStartDate"
                        placeholder="Gäller från datum"
                        readonly />
                    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFrom></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close('cancel')">Avbryt</button>
    <button mat-raised-button color="primary" (click)="connectSchedules()">Koppla</button>
</mat-dialog-actions>
<div class="loading-wrapper" *ngIf="loading">
    <mat-spinner diameter="40"></mat-spinner>
</div>
