import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment'

// Interfaces
import { IEmployeeCategory } from '@app/interfaces/employee-category.interface';

@Injectable({ providedIn: 'root' })
export class EmployeeCategoryService {

    constructor(private http: HttpClient) { }

    getAll(): Observable<IEmployeeCategory[]> {
        return this.http.get<IEmployeeCategory[]>(environment.apiUrl + "/employee-categories");
    }
}
