import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'app-admin-new-admin-page',
    templateUrl: './admin-new.component.html',
    styleUrls: ['./admin-new.component.scss'],
})
export class AdminNewAdminComponent implements OnInit {
    public employeeId: number;

    constructor(private authService: AuthService) {}

    ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
    }
}
