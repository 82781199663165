import { Component, OnInit, Input } from '@angular/core';
import { groupBy } from 'lodash-es';
import { Helpers } from '@app/helpers/helpers.class';
import { PunchService } from '@app/services/punch.service';
import { IPunch } from '@app/interfaces/punch.interface';

@Component({
    selector: 'app-employee-recent-punches',
    templateUrl: './employee-recent-punches.partial.html',
    styleUrls: ['./employee-recent-punches.partial.scss'],
})
export class EmployeeRecentPunchesPartial implements OnInit {
    @Input() employeeId: number;
    @Input() data: IPunch[];
    @Input() useExternalData: boolean;
    @Input('from') inputFrom: string;
    @Input('to') inputTo: string;

    loading: boolean = false;
    recentPunches: any;
    from: string;
    to: string;
    manuallySearched: boolean = false;
    punchColumns: string[] = ['punchTypeId', 'punchTypeName', 'punchTime', 'costPool'];

    constructor(private punchService: PunchService) {}

    ngOnInit(): void {
        let now = new Date();
        this.to = Helpers.toShortDateString(now);
        now.setDate(now.getDate() - 5);
        this.from = Helpers.toShortDateString(now);

        if (!this.useExternalData) {
            this.getPunches();

            if (Helpers.isDefined(this.inputFrom)) {
                this.from = this.inputFrom;
            }

            if (Helpers.isDefined(this.inputTo)) {
                this.to = this.inputTo;
            }
        } else if (this.data) {
            this.recentPunches = this.data;
        } else {
            this.loading = true;
        }
    }

    externalDataLoaded(externalData: IPunch[]) {
        this.recentPunches = groupBy(externalData, function (value: IPunch) {
            return value.punchTime.substring(0, 10);
        });
        this.loading = false;
    }

    getPunches(manual: boolean = false): void {
        this.loading = true;
        if (manual) {
            this.manuallySearched = true;
        }
        this.punchService
            .getForEmployeeBetweenDates(this.employeeId, Helpers.toShortDateString(this.from), Helpers.toShortDateString(this.to))
            .subscribe({
                next: (data) => {
                    if (data.length > 0) {
                        this.recentPunches = groupBy(data, function (value: IPunch) {
                            return value.punchTime.substring(0, 10);
                        });
                    }
                    this.loading = false;
                },
                error: (error) => {
                    console.error(error);
                    this.loading = false;
                    Helpers.captureSentryError('Could not get recent punches');
                },
            });
    }
}
