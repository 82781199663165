<app-admin-menu></app-admin-menu>

<h1 class="mat-headline-3">Stämplingar</h1>

<div class="container">
    <div class="row">
        <div class="col">
            <mat-card class="compact punches-list">
                <mat-card-content>
                    <app-punches-list (filterChanged)="updateQueryParameters($event)" [query]="query"
                        [companyIds]="companyIds" [employmentTypeIds]="employmentTypeIds"
                        [departmentIds]="departmentIds" [staffingCompanyIds]="staffingCompanyIds"
                        [punchDateFrom]="punchDateFrom" [punchDateTo]="punchDateTo" [page]="page">
                    </app-punches-list>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<app-messages #messagesPartial [employeeId]="employeeId"></app-messages>

<app-admin-footer></app-admin-footer>
