<h2 mat-dialog-title>Är detta beordrad övertid?</h2>
<mat-dialog-content>
    <p>
        Du har varit instämplad mer än schemalagd tid idag. Är tiden utöver din schemalagda tid övertid, och i så fall vem
        godkände övertiden?
    </p>
    <mat-form-field>
        <mat-label>Övertid beordrad av</mat-label>
        <mat-select [(value)]="employee">
            <mat-option *ngFor="let employee of employees" [value]="employee">
                [{{employee.title}}] {{employee.firstName}} {{employee.lastName}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close(false)">Nej, inte övertid</button>
    <button mat-raised-button color="primary" (click)="close(true)" [disabled]="!employee">Ja, övertid</button>
</mat-dialog-actions>
<div class="loading-wrapper" *ngIf="loading">
    <mat-spinner diameter="40"></mat-spinner>
</div>
