<app-admin-menu></app-admin-menu>

<h1 class="mat-headline-3">Attestera arbetad tid</h1>

<div class="container-fluid">
    <div class="row">
        <div class="col">
            <mat-card class="compact attest-time">
                <mat-card-content>
                    <app-attest-time (filterChanged)="updateQueryParameters($event)" [day]="day" [page]="page"></app-attest-time>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<app-messages #messagesPartial [employeeId]="employeeId"></app-messages>

<app-admin-footer></app-admin-footer>

