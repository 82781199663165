import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

// Error handling
import * as Sentry from '@sentry/browser';

// Services
import { EmployeeMessageService } from '@app/services/employee-message.service';

// Dialogs
import { EditPunchReplyMessageDialog } from '@app/shared/dialogs/edit-punch-reply-message/edit-punch-reply-message.dialog';

// Interfaces
import { IEmployeeEditPunches } from '@app/interfaces/employee-edit-puches.interface';
import { IEmployeeMessageEditPunch } from '@app/interfaces/employee-message-edit-punch.interface';
import { Helpers } from '@app/helpers/helpers.class';

@Component({
    selector: 'edit-punch-messages-dialog',
    templateUrl: './edit-punch-messages.dialog.html',
    styleUrls: ['./edit-punch-messages.dialog.scss'],
})
export class EditPunchMessagesDialog {
    employee: IEmployeeEditPunches;
    messageMoveDate: string;
    day: string;

    constructor(
        private dialog: MatDialog,
        private employeeMessageService: EmployeeMessageService,
        private dialogRef: MatDialogRef<EditPunchMessagesDialog>,
        @Inject(MAT_DIALOG_DATA) { employee, day }: any
    ) {
        this.employee = employee;
        this.day = day;
    }

    toggleIgnore(message: IEmployeeMessageEditPunch): void {
        message.ignoreInEditPunches = message.ignoreInEditPunches ? false : true;

        if (message.ignoreInEditPunches) {
            this.employeeMessageService.ignoreMessage(message.messageId).subscribe({
                next: (data) => {},
                error: (error) => {
                    console.error(error);
                    Helpers.captureSentryError('Could not set message as ignored');
                },
            });
        } else {
            this.employeeMessageService.unignoreMessage(message.messageId).subscribe({
                next: (data) => {},
                error: (error) => {
                    console.error(error);
                    Helpers.captureSentryError('Could not set message as not ignored');
                },
            });
        }
    }

    moveTo(message: IEmployeeMessageEditPunch) {
        if (Helpers.isDefined(this.messageMoveDate)) {
            message.displayAtDate = Helpers.toShortDateString(this.messageMoveDate);
            this.employeeMessageService.setMessageDisplayAtDate(message).subscribe({
                next: (data) => {
                    for (let i = 0; i < this.employee.messages.length; i++) {
                        if (this.employee.messages[i].messageId === message.messageId) {
                            this.employee.messages.splice(i, 1);
                            break;
                        }
                    }
                    if (this.employee.messages.length === 0) {
                        this.close();
                    }
                },
                error: (error) => {
                    console.error(error);
                    Helpers.captureSentryError('Could not set message as ignored');
                },
            });
        }
    }

    showEditReply(message: IEmployeeMessageEditPunch): void {
        this.setDefaultReply(message);

        let handle = this.dialog.open(EditPunchReplyMessageDialog, {
            width: '400px',
            data: {
                message: message,
            },
        });

        handle.afterClosed().subscribe((result) => {});
    }

    setDefaultReply(message: IEmployeeMessageEditPunch): void {
        if (!Helpers.isDefined(message.replyMessage) || message.replyMessage.length === 0) {
            message.replyMessage = this.getDefaultReplyMessage();
        }
    }

    getDefaultReplyMessage(): string {
        return (
            'Hej ' +
            this.employee.firstName +
            '\n' +
            'Jag har nu justerat dina stämplingar datumet ' +
            Helpers.toShortDateString(this.day) +
            ', kika gärna en extra gång så att det blev rätt!'
        );
    }

    close(): void {
        this.dialogRef.close();
    }
}
