<div class="container-fluid">
    <div class="row filters">
        <div class="col active-filter">
            <div class="col query-filter">
                <label>Sök</label>
                <mat-form-field>
                    <mat-label>Fritextsök...</mat-label>
                    <input
                        matInput
                        [(ngModel)]="filterQuery"
                        autocomplete="off"
                        autocomplete="off"
                        (keydown.enter)="getAdminsPage(1)" />
                </mat-form-field>
            </div>
        </div>
        <div class="col button-filter">
            <button mat-raised-button (click)="getAdminsPage(1)" color="primary">Sök</button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="divider-wrapper">
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
    <div class="row create-account">
        <div class="col">
            <button mat-raised-button routerLink="/admin/settings/accounts/new">
                <mat-icon>add</mat-icon>
                Nytt konto
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="table-wrapper">
                <table mat-table [dataSource]="admins">
                    <ng-container matColumnDef="userName">
                        <th mat-header-cell *matHeaderCellDef class="user-name">Användarnamn</th>
                        <td
                            mat-cell
                            *matCellDef="let admin"
                            class="user-name"
                            [routerLink]="'/admin/settings/accounts/' + admin.adminId">
                            {{admin.userName}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="name">Namn</th>
                        <td
                            mat-cell
                            *matCellDef="let admin"
                            class="name"
                            [routerLink]="'/admin/settings/accounts/' + admin.adminId">
                            {{admin.lastName}}, {{admin.firstName}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="active">
                        <th mat-header-cell *matHeaderCellDef class="active">Aktiv</th>
                        <td mat-cell *matCellDef="let admin" class="active">
                            <mat-slide-toggle
                                [disabled]="!canUpdateActive"
                                (change)="setAdminActive(admin.adminId, $event)"
                                [checked]="admin.active ?? false"></mat-slide-toggle>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columns"></tr>
                    <tr mat-row *matRowDef="let admin; columns: columns;"></tr>
                </table>
            </div>
        </div>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
