import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from "@sentry/browser";
import { environment } from '@env/environment';

Sentry.init({
    dsn: "https://a06a826b986640c184bddab4e0f24279@sentry.io/1336970"
});

@Injectable({ providedIn: 'root' })
export class SentryErrorHandler implements ErrorHandler {
    constructor() { }

    beforeBreadcrumb(crumb) {
        const shrinkedCrumb = { ...crumb };
        if (crumb.data && crumb.data.extra && crumb.data.extra.arguments) {
            shrinkedCrumb.data.extra = {
                ...crumb.data.extra,
                arguments: crumb.data.extra.arguments.map((arg: any) => {
                    const shrinkedArg = { ...arg };
                    delete shrinkedArg.ngDebugContext;
                    delete shrinkedArg.ngErrorLogger;
                    return shrinkedArg;
                })
            };
        }
        return shrinkedCrumb;
    }

    handleError(error) {
        if (environment.production) {
            Sentry.captureException(error.originalError || error);
            throw error;
        }
    }
}
