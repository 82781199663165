<div class="container-fluid" [formGroup]="carForm">
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Registreringsnummer</mat-label>
                <input matInput autocomplete="off" formControlName="registrationNumber" required />
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Beskrivning</mat-label>
                <input matInput autocomplete="off" formControlName="description" required />
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Typ</mat-label>
                <mat-select formControlName="type" required>
                    <mat-option *ngFor="let type of carTypes" [value]="type.typeId"> {{type.typeName}} </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row buttons">
        <ng-container *ngIf="!carId; else updateButtonWrapper">
            <div class="col">
                <button mat-raised-button color="primary" (click)="create()">Skapa bil</button>
            </div>
        </ng-container>
        <ng-template #updateButtonWrapper>
            <div class="col update-cancel-button">
                <button mat-raised-button (click)="cancel()">Avbryt</button>
            </div>
            <div class="col update-button">
                <button mat-raised-button color="primary" (click)="update()">Uppdatera bil</button>
            </div>
        </ng-template>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
