<app-admin-menu></app-admin-menu>

<h1 class="mat-headline-3">Generella inställningar</h1>

<div class="container">
    <div class="row">
        <div class="col">
            <app-general-settings></app-general-settings>
        </div>
    </div>
</div>

<app-messages #messagesPartial [employeeId]="employeeId"></app-messages>

<app-admin-footer></app-admin-footer>
