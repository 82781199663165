<h2 mat-dialog-title>Status för {{company.companyName}}</h2>
<mat-dialog-content>
    <div class="wrapper">
        <div class="table-wrapper">
            <table mat-table [dataSource]="company.all">
                <ng-container matColumnDef="employeeName">
                    <th mat-header-cell *matHeaderCellDef class="employee-name">Namn</th>
                    <td mat-cell *matCellDef="let employee" class="employee-name">
                        {{employee.lastName}}, {{employee.firstName}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef class="status">Status</th>
                    <td mat-cell *matCellDef="let employee" class="status">
                        <mat-icon class="done" *ngIf="company.done | arrayContains: 'employeeId': employee.employeeId; else notDone">done</mat-icon>
                        <ng-template #notDone>
                            <mat-icon class="not-done">hourglass_empty</mat-icon>
                        </ng-template>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="employeeColumns"></tr>
                <tr mat-row *matRowDef="let employee; columns: employeeColumns;"></tr>
            </table>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">Stäng</button>
</mat-dialog-actions>
