import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from "@angular/router";
import { Helpers } from '@app/helpers/helpers.class';

// Services
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'app-travel-compensation-admin-page',
    templateUrl: './travel-compensation.component.html',
    styleUrls: ['./travel-compensation.component.scss']
})

export class TravelCompensationAdminComponent implements OnInit {
    public employeeId: number;
    public query: string;
    public companyIds: number[];
    public employmentTypeIds: number[];
    public departmentIds: number[];
    public travelDateFrom: string;
    public travelDateTo: string;
    public paid: boolean;
    public page: number;

    constructor(private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private router: Router) { }

    ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);

        if (Helpers.isDefined(params['query'])) {
            this.query = params['query'];
        }

        if (Helpers.isDefined(params['companyIds'])) {
            this.companyIds = params['companyIds'];
        }

        if (Helpers.isDefined(params['employmentTypeIds'])) {
            this.employmentTypeIds = params['employmentTypeIds'];
        }

        if (Helpers.isDefined(params['departmentIds'])) {
            this.departmentIds = params['departmentIds'];
        }

        if (Helpers.isDefined(params['travelDateFrom'])) {
            this.travelDateFrom = params['travelDateFrom'];
        }

        if (Helpers.isDefined(params['travelDateTo'])) {
            this.travelDateTo = params['travelDateTo'];
        }

        if (Helpers.isDefined(params['paid'])) {
            this.paid = params['paid'];
        }

        if (Helpers.isDefined(params['page'])) {
            this.page = params['page'];
        }
    }

    updateQueryParameters(filter: any): void {
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);
        params['paid'] = Helpers.isDefined(filter.paid) ? (filter.paid ? 1 : 0) : null;
        params['companyIds'] = filter.companyIds.length > 0 ? filter.companyIds.join(',') : null;
        params['employmentTypeIds'] = filter.employmentTypeIds.length > 0 ? filter.employmentTypeIds.join(',') : null;
        params['departmentIds'] = filter.departmentIds.length > 0 ? filter.departmentIds.join(',') : null;
        params['travelDateFrom'] = filter.travelDateFrom.length > 0 ? filter.travelDateFrom : null;
        params['travelDateTo'] = filter.travelDateTo.length > 0 ? filter.travelDateTo : null;
        params['query'] = Helpers.isDefined(filter.query) && filter.query.length > 0 ?
            filter.query : null;
        params['page'] = filter.page;
        this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params });
    }
}
