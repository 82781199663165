import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// Services
import { AuthService } from '@app/services/auth.service';

// Enums
import { LoginTypeEnum } from '@app/enums/login-type.enum';

@Injectable({ providedIn: 'root' })
export class AdminAuthGuard implements CanActivate {

    constructor(private router: Router,
        private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.isAuthenticated(LoginTypeEnum.Admin)) {
            return true;
        }

        this.router.navigate(['/admin/login']);
        return false;
    }
}
