<div class="container-fluid">
    <div class="row filter">
        <div class="col">
            <label>Dag</label>
            <mat-form-field>
                <input
                    matInput
                    [matDatepicker]="picker"
                    (dateChange)="getEmployeesPage(1)"
                    (click)="picker.open()"
                    [(ngModel)]="filterDay"
                    readonly />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker [dateClass]="dateClass"></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col col-auto bulk-actions">
            <button mat-raised-button color="primary" (click)="approveSelectedPeriods()">
                <mat-icon>check</mat-icon>
                Sätt valda som godkända
            </button>
            <button mat-raised-button color="primary" (click)="approvePeriods()">
                <mat-icon>check</mat-icon>
                Sätt alla som godkända
            </button>
        </div>
    </div>
    <div class="row" *ngIf="costPoolColors">
        <div class="col">
            <div class="cost-pools">
                <div class="cost-pool" *ngIf="anyWithoutCostPool">
                    <div class="dot no-cost-pool"></div>
                    <div>(Inget valt)</div>
                </div>
                <div class="cost-pool" *ngFor="let costPool of costPoolColors | keyvalue">
                    <div class="dot" [class]="costPool.value.className"></div>
                    <div>
                        {{ costPool.value.poolName }}
                        <span class="time" matTooltip="Arbetad / schemalagd tid">
                            ({{ employeesPage.metaData.costPoolTotalWorkedMinutes[costPool.key] | readableTimeSpan }} /
                            {{ employeesPage.metaData.costPoolTotalScheduledMinutes[costPool.key] | readableTimeSpan }})
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col" *ngIf="employeesPage && employeesPage.totalItems; else noResults">
            <mat-paginator
                *ngIf="employeesPage && employeesPage.totalItems > employeesPage.pageSize"
                [length]="employeesPage.totalItems"
                [pageSize]="employeesPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
            <div class="table-wrapper">
                <table mat-table *ngIf="employeesPage" [dataSource]="dataSource">
                    <ng-container matColumnDef="name" sticky>
                        <th mat-header-cell *matHeaderCellDef class="name">Namn</th>
                        <td mat-cell *matCellDef="let employee" class="name">
                            <div class="row">
                                <div class="col col-auto">
                                    <mat-checkbox [(ngModel)]="employee.selected"></mat-checkbox>
                                </div>
                                <div class="col">
                                    <div class="name">
                                        {{ employee.lastName }}, {{ employee.firstName }} ({{ employee.companyShort }})
                                    </div>
                                    <div class="scheduled-time">
                                        <ng-container *ngIf="employee.scheduleDay; else noSchedule">
                                            {{ employee.totalScheduledWorkTime | readableTimeSpan }}
                                        </ng-container>
                                        <ng-template #noSchedule>
                                            <i>
                                                {{ employee.totalScheduledWorkTime | readableTimeSpan }}
                                            </i>
                                        </ng-template>
                                    </div>
                                </div>
                                <div
                                    class="col col-icon"
                                    [class.has-issues]="employee.attestedTimeIssues && employee.attestedTimeIssues.length">
                                    <mat-icon [matMenuTriggerFor]="employeeMenu">warning</mat-icon>
                                    <mat-menu #employeeMenu="matMenu">
                                        <button mat-menu-item (click)="addEmployeeIssue(employee)">
                                            <mat-icon>add</mat-icon>
                                            <span>Nytt ärende</span>
                                        </button>
                                        <button
                                            mat-menu-item
                                            [disabled]="!employee.attestedTimeIssues || !employee.attestedTimeIssues.length"
                                            (click)="showIssuesByEmployee(employee)">
                                            <mat-icon>visibility</mat-icon>
                                            <span>Visa ärenden</span>
                                        </button>
                                    </mat-menu>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="workPeriods">
                        <th mat-header-cell *matHeaderCellDef class="work-periods">
                            <div class="timeline-labels">
                                <div
                                    class="label"
                                    [style.left]="label | attestTimeOffset : timelineStart : timelineLengthMinutes"
                                    *ngFor="let label of employeesPage.metaData.timeline">
                                    {{ label | shortTime }}
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let employee" class="work-periods">
                            <div class="work-periods-wrapper">
                                <div
                                    class="label"
                                    [style.left]="label | attestTimeOffset : timelineStart : timelineLengthMinutes"
                                    *ngFor="let label of employeesPage.metaData.timeline"></div>
                                <div class="periods">
                                    <div
                                        [matMenuTriggerFor]="periodMenu"
                                        (click)="periodMenuOpened(pair)"
                                        class="period"
                                        *ngFor="let pair of employee.punchPairs"
                                        [style.left]="pair.in | attestTimeOffset : timelineStart : timelineLengthMinutes"
                                        [style.width]="(pair.workedMinutes / timelineLengthMinutes) * 100 + '%'"
                                        [class.active]="pair.active"
                                        [class.attested]="pair.attestedTime"
                                        [class.attested-approved]="pair.attestedTime?.statusId == 1"
                                        [class.attested-problem]="pair.attestedTime?.statusId == 2"
                                        [class]="pair.costPool ? 'cost-pool-' + pair.costPool.poolId : 'no-cost-pool'"
                                        [title]="pair | attestTimeDetails">
                                        <div class="in">
                                            {{ pair.in | shortTime }}
                                        </div>
                                        <div class="content">
                                            <mat-icon *ngIf="pair.attestedTime?.statusId === 1">done</mat-icon>
                                            <mat-icon *ngIf="pair.attestedTime?.statusId === 2">warning</mat-icon>
                                            <div class="cost-pool-name">
                                                <ng-container *ngIf="pair.costPool; else noCostPool">
                                                    {{ pair.costPool?.name }}
                                                </ng-container>
                                                <ng-template #noCostPool> (Inget) </ng-template>
                                            </div>
                                        </div>
                                        <div class="out" [class.virtual]="pair.outIsVirtual">
                                            {{ pair.out | shortTime }}
                                        </div>
                                        <mat-menu #periodMenu="matMenu" (closed)="periodMenuClosed(pair)">
                                            <button
                                                mat-menu-item
                                                (click)="undoPeriodAction(pair)"
                                                *ngIf="pair.attestedTime?.statusId">
                                                <mat-icon>undo</mat-icon>
                                                <span>Ångra åtgärd</span>
                                            </button>
                                            <button
                                                mat-menu-item
                                                (click)="approvePeriod(pair)"
                                                [disabled]="pair.attestedTime?.statusId === 1">
                                                <mat-icon>done</mat-icon>
                                                <span>Godkänn</span>
                                            </button>
                                            <button
                                                mat-menu-item
                                                (click)="problemPeriod(pair)"
                                                [disabled]="pair.attestedTime?.statusId === 2">
                                                <mat-icon>warning</mat-icon>
                                                <span>Problem</span>
                                            </button>
                                            <button
                                                mat-menu-item
                                                (click)="movePeriod(pair, employee)"
                                                [disabled]="pair.attestedTime?.statusId">
                                                <mat-icon>exit_to_app</mat-icon>
                                                <span>Flytta</span>
                                            </button>
                                            <button
                                                mat-menu-item
                                                (click)="splitPeriod(pair, employee)"
                                                [disabled]="pair.attestedTime?.statusId">
                                                <mat-icon>content_cut</mat-icon>
                                                <span>Dela</span>
                                            </button>
                                            <button
                                                mat-menu-item
                                                *ngIf="pair.attestedTimeIssues && pair.attestedTimeIssues.length"
                                                (click)="showIssuesByPeriod(pair)">
                                                <mat-icon>visibility</mat-icon>
                                                <span>Visa ärenden</span>
                                            </button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="employeeColumns"></tr>
                    <tr mat-row *matRowDef="let employee; columns: employeeColumns" class="employee-row"></tr>
                </table>
                <mat-form-field class="add-employee">
                    <mat-label>Lägg till anställd</mat-label>
                    <input
                        type="text"
                        aria-label="Employee"
                        matInput
                        [formControl]="addEmployeeControl"
                        [matAutocomplete]="addEmployeeAutoComplete" />
                    <mat-autocomplete
                        autoActiveFirstOption
                        #addEmployeeAutoComplete="matAutocomplete"
                        [displayWith]="displaySelectedEmployee"
                        (optionSelected)="addEmployeeSelected($event)">
                        <mat-option *ngFor="let employee of filteredEmployees | async" [value]="employee">
                            {{ employee.lastName }}, {{ employee.firstName }} ({{ employee.companyShort }})
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <mat-paginator
                *ngIf="employeesPage && employeesPage.totalItems > employeesPage.pageSize"
                [length]="employeesPage.totalItems"
                [pageSize]="employeesPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
            <div class="summary">
                <strong>{{ employeesPage.totalItems }}</strong> anställda |
                <strong>{{ employeesPage.metaData.totalWorkedMinutes | readableTimeSpan }}</strong>
            </div>
        </div>
        <ng-template #noResults>
            <div class="col">
                <p class="no-results">Ingen tid att attestera</p>
            </div>
        </ng-template>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
