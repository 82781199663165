<app-admin-menu></app-admin-menu>

<h1 class="mat-headline-3">Nytt kostnadsställe</h1>

<div class="container">
    <div class="row">
        <div class="col">
            <button mat-button class="back" routerLink="/admin/cost-pools">
                <mat-icon>chevron_left</mat-icon>
                Tillbaka
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <app-cost-pool-form></app-cost-pool-form>
        </div>
    </div>
</div>

<app-messages #messagesPartial [employeeId]="employeeId"></app-messages>

<app-admin-footer></app-admin-footer>
