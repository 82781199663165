<h2 mat-dialog-title>{{title}}</h2>
<mat-dialog-content>
    <p [innerHtml]="message"></p>
    <div *ngIf="list && list.length >0">
        <ul>
            <li *ngFor="let item of list">
                {{item}}
            </li>
        </ul>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">OK</button>
</mat-dialog-actions>
