import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Helpers } from '@app/helpers/helpers.class';
import { IAttestedTimeIssue } from '@app/interfaces/attested-time-issue.interface';
import { IAttestedTimeIssuesSearchFilter } from '@app/interfaces/attested-time-issues-search-filter.interface';
import { ICompany } from '@app/interfaces/company.interface';
import { IEmployeeForSelect } from '@app/interfaces/employee-for-select.interface';
import { IPage } from '@app/interfaces/page.interface';
import { CompanyService } from '@app/services/company.service';
import { EmployeeService } from '@app/services/employee.service';
import { PunchService } from '@app/services/punch.service';
import { map, Observable, startWith } from 'rxjs';

@Component({
    selector: 'app-attest-time-issues-list',
    templateUrl: './attest-time-issues-list.component.html',
    styleUrls: ['./attest-time-issues-list.component.scss'],
})
export class AttestTimeIssuesListPartial implements OnInit {
    @Output() public filterChanged: EventEmitter<any> = new EventEmitter();
    @Input('query') public inputQuery: string;
    @Input('page') public inputPage: number;
    @Input('solved') public inputSolved: boolean | null;
    @Input('companyIds') public inputCompanyIds: number[] | null;
    @Input('employeeId') public inputEmployeeId: number;
    @Input('issueDate') public inputIssueDate: Date;

    protected employeeFilterControl: FormControl = new FormControl<string | null>(null);
    protected filter: IAttestedTimeIssuesSearchFilter;
    protected loading: boolean = false;
    protected issuesPage: IPage<IAttestedTimeIssue>;
    protected companies: ICompany[];
    protected columns: string[] = ['issueId', 'added', 'issueDate', 'employeeName', 'adminName', 'message', 'solved'];
    protected filteredEmployees: Observable<IEmployeeForSelect[]>;
    protected employees: IEmployeeForSelect[];

    constructor(
        private punchService: PunchService,
        private companyService: CompanyService,
        private employeeService: EmployeeService
    ) {}

    ngOnInit(): void {
        this.resetFilter();
        this.loadCompanies();
        this.search(1);
        this.getAllEmployees();

        this.filteredEmployees = this.employeeFilterControl.valueChanges.pipe(
            startWith(''),
            map((value) => (value !== null && value.length > 1 ? this.filterEmployees(value) : []))
        );
    }

    protected resetFilter(): void {
        this.filter = {
            query: this.inputQuery,
            pageNumber: this.inputPage,
            solved: this.inputSolved,
            companyIds: this.inputCompanyIds,
            employeeId: this.inputEmployeeId,
            issueDate: this.inputIssueDate,
        };
    }

    protected search(page: number): void {
        this.filter.pageNumber = page;

        this.loading = true;
        this.punchService.searchAttestedTimeIssues(this.filter).subscribe({
            next: (data) => {
                this.issuesPage = data;
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not get issues page');
            },
        });

        this.filterChanged.emit(this.filter);
    }

    protected toggleSolved(issue: IAttestedTimeIssue): void {
        this.punchService.toggleAttestedTimeIssueSolved(issue.issueId, issue.isSolved).subscribe({
            next: () => {
                if (issue.isSolved) {
                    issue.solved = new Date();
                }
            },
        });
    }

    private loadCompanies(): void {
        this.companyService.getAll().subscribe({
            next: (companies) => {
                this.companies = companies;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not load companies');
            },
        });
    }

    private getAllEmployees(): void {
        this.employeeService.getAllForSelect().subscribe({
            next: (data) => {
                this.employees = data;

                if (this.filter.employeeId) {
                    for (const employee of this.employees) {
                        if (employee.employeeId == this.filter.employeeId) {
                            this.employeeFilterControl.setValue(employee);
                            break;
                        }
                    }
                }
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get employees');
            },
        });
    }

    private filterEmployees(value: string): IEmployeeForSelect[] {
        const queries = value.toLowerCase().replace(/,/g, '').replace(/\(/g, '').replace(/\)/g, '').split(' ');
        return this.employees.filter((e) => {
            let allMatch = true;
            for (let i = 0; i < queries.length; i++) {
                if (queries[i].length === 0) {
                    continue;
                }
                if ((e.firstName + e.lastName + e.companyShort).toLowerCase().indexOf(queries[i]) === -1) {
                    allMatch = false;
                    break;
                }
            }
            return allMatch;
        });
    }

    protected displaySelectedEmployee(employee?: IEmployeeForSelect): string | undefined {
        return employee ? employee.lastName + ', ' + employee.firstName + ' (' + employee.companyShort + ')' : undefined;
    }

    protected setEmployeeFilter(event: MatAutocompleteSelectedEvent): void {
        const selectedEmployee = event.option.value as IEmployeeForSelect;
        this.filter.employeeId = selectedEmployee.employeeId;
    }
}

