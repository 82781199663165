import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Helpers } from '@app/helpers/helpers.class';

// Error handling
import * as Sentry from '@sentry/browser';
import { SettingService } from '@app/services/settings.service';
import { ISetting } from '@app/interfaces/settings.interface';

@Component({
    selector: 'app-general-settings',
    templateUrl: './general-settings.partial.html',
    styleUrls: ['./general-settings.partial.scss'],
})
export class GeneralSettingsPartial implements OnInit {
    public settings: ISetting[];
    public loading: boolean = false;

    constructor(private dialog: MatDialog, private settingsService: SettingService) {}

    public ngOnInit(): void {
        this.getSettings();
    }

    public getSettings(): void {
        this.loading = true;
        this.settingsService.getAll().subscribe({
            next: (data) => {
                this.loading = false;
                this.settings = data;
            },
            error: (error) => {
                this.loading = false;
                console.error(error);
                Helpers.captureSentryError('Could not get settings');
            },
        });
    }

    public save(): void {
        let settings: ISetting[] = [];

        for (let s of this.settings) {
            let value = s.settingValue;

            if (s.dataType === 'datetime') {
                value = Helpers.toShortDateString(s.settingValue);
            }

            settings.push({
                settingId: s.settingId,
                settingValue: value,
                description: s.description,
                dataType: s.dataType,
            });
        }

        this.loading = true;
        this.settingsService.updateAll(settings).subscribe({
            next: (data) => {
                this.loading = false;
            },
            error: (error) => {
                this.loading = false;
                console.error(error);
                Helpers.captureSentryError('Could not update all settings');
            },
        });
    }

    public setCheckedValue(event: any, setting: ISetting) {
        setting.settingValue = event.checked ? 'True' : 'False';
    }
}
