import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment'

// Interfaces
import { IMenuItem } from '@app/interfaces/menu-item.interface';

@Injectable({ providedIn: 'root' })
export class MenuService {

    constructor(private http: HttpClient) { }

    getAdminMenu(): Observable<IMenuItem[]> {
        return this.http.get<IMenuItem[]>(environment.apiUrl + "/menus/admin");
    }
}
