<h2 mat-dialog-title>Flytta till annat kostnadsställe</h2>
<mat-dialog-content>
    <p>Ange vilket kostnadsställe som perioden ska flyttas till nedan.</p>
    <mat-form-field>
        <mat-label>Nytt kostnadsställe</mat-label>
        <mat-select [formControl]="costPoolFormControl">
            <mat-option [value]="noCostPool">
                Inget valt kostnadsställe
            </mat-option>
            <mat-optgroup *ngFor="let group of groups" [label]="group.company?.companyName ?? 'Gemensamma'">
                <mat-option *ngFor="let costPool of group.costPools" [value]="costPool">
                    {{ costPool.name }}
                </mat-option>
            </mat-optgroup>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close(true)">Avbryt</button>
    <button mat-raised-button (click)="close(false)" color="primary">Flytta</button>
</mat-dialog-actions>

