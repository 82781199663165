<div class="container-fluid new-trip" [formGroup]="tripForm" *ngIf="!activeTripLogId || editing; else activeTripTemplate">
    <div class="row trip-type">
        <div class="col">
            <mat-radio-group formControlName="private" (change)="typeChanged()">
                <mat-radio-button [value]="false">Tjänsteresa</mat-radio-button>
                <mat-radio-button [value]="true">Privat resa</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Bil</mat-label>
                <mat-select formControlName="car" required>
                    <mat-option *ngFor="let car of cars" [value]="car.carId">
                        {{car.registrationNumber}} ({{car.description}})
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row" [hidden]="f.private.value">
        <div class="col">
            <mat-form-field>
                <mat-label>Bolag</mat-label>
                <mat-select formControlName="company" required>
                    <mat-option *ngFor="let company of companies" [value]="company.companyId">
                        {{company.companyName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Mätarställning</mat-label>
                <input type="tel" autocomplete="off" matInput formControlName="mileage" required />
                <span matSuffix>km</span>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <mat-form-field class="start-date" (click)="startDate.open()">
                <mat-label>Datum</mat-label>
                <input matInput [matDatepicker]="startDate" formControlName="startDate" required />
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate disabled="false"></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-6">
            <mat-form-field>
                <mat-label>Tidpunkt</mat-label>
                <input type="time" autocomplete="off" matInput formControlName="startTime" required />
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Resans syfte</mat-label>
                <textarea autocomplete="off" matInput formControlName="description" required></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <ng-container *ngIf="!editing; else updateButtonWrapper">
            <div class="col">
                <button mat-raised-button color="primary" (click)="create()">Starta resa</button>
            </div>
        </ng-container>
        <ng-template #updateButtonWrapper>
            <div class="col update-cancel-button">
                <button mat-raised-button (click)="cancelEdit()">Avbryt</button>
            </div>
            <div class="col update-button">
                <button mat-raised-button color="primary" (click)="update()">Uppdatera resa</button>
            </div>
        </ng-template>
    </div>
</div>

<ng-template #activeTripTemplate>
    <div class="active-trip">
        <app-driving-log-show-trip
            [logId]="activeTripLogId"
            (edit)="edit($event)"
            (end)="end($event)"></app-driving-log-show-trip>
    </div>
</ng-template>

<div class="loading-wrapper" *ngIf="loading">
    <mat-spinner diameter="40"></mat-spinner>
</div>
