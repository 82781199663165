import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IEmployeePunchList } from '@app/interfaces/employee-punch-list.interface';

@Component({
    selector: 'work-time-per-cost-pool-dialog',
    templateUrl: './work-time-per-cost-pool.dialog.html',
    styleUrls: ['./work-time-per-cost-pool.dialog.scss'],
})
export class WorkTimePerCostPoolDialog {
    employee: IEmployeePunchList;
    pools: any[];
    costPoolColumns: string[] = ['code', 'scheduled', 'overtime', 'ob', 'extra', 'absence', 'total'];

    constructor(private dialogRef: MatDialogRef<WorkTimePerCostPoolDialog>, @Inject(MAT_DIALOG_DATA) { employee }: any) {
        this.employee = employee;

        this.calculate();
    }

    calculate(): void {
        this.pools = [];

        for (let day of this.employee.days) {
            for (let time of day.workTime) {
                let found = false;
                let pool = {
                    costPoolCode: time.costPoolCode,
                    scheduledMinutes: 0,
                    overtimeMinutes: 0,
                    unsocialHoursMinutes: 0,
                    extraWorkMinutes: 0,
                    absenceMinutes: 0,
                    total: 0,
                };

                for (let p of this.pools) {
                    if (p.costPoolCode === time.costPoolCode) {
                        pool = p;
                        found = true;
                        break;
                    }
                }

                if (time.timeType === 'SCHEDULED') {
                    pool.scheduledMinutes += time.totalMinutes;
                } else if (time.timeType === 'OVERTIME') {
                    pool.overtimeMinutes += time.totalMinutes;
                } else if (time.timeType === 'EXTRA') {
                    pool.extraWorkMinutes += time.totalMinutes;
                } else if (time.timeType === 'ABSENCE') {
                    pool.absenceMinutes += time.totalMinutes;
                } else if (time.timeType === 'UNSOCIALHOURSPAYMENT') {
                    pool.unsocialHoursMinutes += time.totalMinutes;
                }

                if (!found) {
                    this.pools.push(pool);
                }
            }
        }

        for (let pool of this.pools) {
            pool.total =
                pool.scheduledMinutes +
                pool.overtimeMinutes +
                pool.unsocialHoursMinutes +
                pool.extraWorkMinutes +
                pool.absenceMinutes;
        }
    }

    close(): void {
        this.dialogRef.close();
    }
}
