import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

// Services
import { AuthService } from '@app/services/auth.service';
import { EmployeeService } from '@app/services/employee.service';
import { MenuService } from '@app/services/menu.service';

// Enums
import { LoginTypeEnum } from '@app/enums/login-type.enum';

// Interfaces
import { IMenuItem } from '@app/interfaces/menu-item.interface';

@Component({
    selector: 'app-admin-menu',
    templateUrl: './admin-menu.component.html',
    styleUrls: ['./admin-menu.component.scss'],
})
export class AdminMenuComponent implements OnInit {
    @Input('loadMenu') loadMenu: boolean = true;

    authenticated: boolean;
    costPoolHandledByTodoCount: number;
    punchCostPoolTodoCount: number;
    attestOvertimeTodoCount: number;
    menuItems: IMenuItem[];

    constructor(
        private router: Router,
        private authService: AuthService,
        public dialog: MatDialog,
        public employeeService: EmployeeService,
        public menuService: MenuService
    ) {}

    ngOnInit(): void {
        this.authenticated = this.authService.isAuthenticated(LoginTypeEnum.Admin);

        if (this.authenticated && this.loadMenu) {
            this.getMenu();
        }
    }

    public getMenu(): void {
        this.menuService.getAdminMenu().subscribe({
            next: (data) => {
                this.menuItems = data;
                this.getCostPoolHandledByTodoCount();
                this.getPunchCostPoolTodoCount();
                this.getGetAttestOvertimeTodoCount();
            },
            error: (error) => {
                console.error(error);
            },
        });
    }

    public logout(): void {
        this.authService.logoutAdmin();
        this.router.navigate(['/admin/login']);
    }

    public getCostPoolHandledByTodoCount(): void {
        if (this.authService.hasClaim('ListCostPoolHandledByEmployees')) {
            this.employeeService.getCostPoolHandledByTodoCount().subscribe({
                next: (data) => {
                    this.costPoolHandledByTodoCount = data;
                },
                error: (error) => {
                    console.error(error);
                },
            });
        }
    }

    public getPunchCostPoolTodoCount(): void {
        if (this.authService.hasClaim('ListCategorizeToCostPoolPunches')) {
            this.employeeService.getPunchCostPoolTodoCount().subscribe({
                next: (data) => {
                    this.punchCostPoolTodoCount = data;
                },
                error: (error) => {
                    console.error(error);
                },
            });
        }
    }

    public getGetAttestOvertimeTodoCount(): void {
        if (this.authService.hasClaim('ListAttestOvertime')) {
            this.employeeService.getAttestOvertimeTodoCount().subscribe({
                next: (data) => {
                    this.attestOvertimeTodoCount = data;
                },
                error: (error) => {
                    console.error(error);
                },
            });
        }
    }
}
