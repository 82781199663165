import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// Error handling
import * as Sentry from '@sentry/browser';

// Dialogs
import { WorkPeriodEmployeesDialog } from '@app/shared/dialogs/work-period-employees/work-period-employees.dialog';
import { ConfirmDialog } from '@app/shared/dialogs/confirm/confirm.dialog';
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';

// Services
import { WorkPeriodService } from '@app/services/work-period.service';

// Interfaces
import { IWorkPeriodWithEmployeeStatus } from '@app/interfaces/work-period-with-employee-status.interface';
import { ICompanyOpenWorkPeriod } from '@app/interfaces/company-open-work-period.interface';
import { Helpers } from '@app/helpers/helpers.class';

@Component({
    selector: 'app-active-work-periods',
    templateUrl: './active-work-periods.partial.html',
    styleUrls: ['./active-work-periods.partial.scss'],
})
export class ActiveWorkPeriodsPartial implements OnInit {
    loading: boolean = true;
    openWorkPeriods: IWorkPeriodWithEmployeeStatus[];
    openWorkPeriodColumns: string[] = ['companyName', 'done', 'total', 'status'];

    constructor(private workPeriodService: WorkPeriodService, private dialog: MatDialog) {}

    ngOnInit(): void {
        this.getWorkPeriods();
    }

    getWorkPeriods(): void {
        this.workPeriodService.getOpen().subscribe({
            next: (data) => {
                this.openWorkPeriods = data;
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get open work periods');
            },
        });
    }

    showCompanyEmployees(company: ICompanyOpenWorkPeriod): void {
        this.dialog.open(WorkPeriodEmployeesDialog, {
            width: '500px',
            data: {
                company: company,
            },
        });
    }

    closePeriodPreCheck(period: IWorkPeriodWithEmployeeStatus): void {
        let allDone = true;
        let message = 'Är du säker på att du vill stänga perioden?';
        let title = 'Stäng period?';

        for (let company of period.companies) {
            if (company.all.length > company.done.length) {
                allDone = false;
                break;
            }
        }

        if (!allDone) {
            message = 'Perioden du vill stänga har bolag där alla inte ' + 'klarmarkerat perioden. Vill du stänga perioden ändå?';
        }

        let handle = this.dialog.open(ConfirmDialog, {
            width: '500px',
            data: {
                title: title,
                message: message,
            },
        });

        handle.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.closePeriod(period);
            }
        });
    }

    closePeriod(period: IWorkPeriodWithEmployeeStatus) {
        this.loading = true;

        this.workPeriodService.closePeriod(period).subscribe({
            next: (data) => {
                this.getWorkPeriods();
            },
            error: (error) => {
                let title: string = 'Fel';
                let message: string = 'Något gick fel när perioden skulle stängas';
                let errors: string[];

                if (error.status === 400 && error.error.errors) {
                    message = 'Följande är fel och måste korrigeras:';
                    errors = error.error.errors;
                } else {
                    Helpers.captureSentryError('Could not close work period');
                }

                this.dialog.open(AlertDialog, {
                    width: '400px',
                    data: {
                        title: title,
                        message: message,
                        list: errors,
                    },
                });

                this.loading = false;
            },
        });
    }
}
