<app-driving-log-menu></app-driving-log-menu>

<h1 class="mat-headline-3">Bilar</h1>

<div class="container">
    <div class="row">
        <div class="col" align="end">
            <button mat-button class="new" routerLink="/driving-log/cars/new">
                <mat-icon>add</mat-icon> Ny
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-card class="compact">
                <mat-card-content>
                    <app-driving-log-cars [employeeId]="employeeId"></app-driving-log-cars>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<app-driving-log-footer></app-driving-log-footer>
