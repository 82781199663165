import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

// Services
import { AuthService } from '@app/services/auth.service';

// Dialogs
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';

@Component({
    selector: 'app-login-admin-page',
    templateUrl: './admin-login.component.html',
    styleUrls: ['./admin-login.component.scss'],
})
export class LoginAdminComponent implements OnInit {
    submitted: boolean = false;
    loginForm: UntypedFormGroup;
    loading: boolean = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private authService: AuthService,
        public dialog: MatDialog,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
        });
    }

    get f() {
        return this.loginForm.controls;
    }

    login(): void {
        this.submitted = true;

        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;

        this.authService.administratorLogin(this.f.username.value, this.f.password.value).subscribe({
            next: (data) => {
                this.loading = false;
                this.router.navigate(['/admin/start']);
            },
            error: (error) => {
                if (error.status === 401) {
                    this.dialog.open(AlertDialog, {
                        width: '400px',
                        data: {
                            title: 'Felaktiga uppgifter',
                            message:
                                'Du har angett ett felaktigt användarnamn eller lösenord, eller så är ditt konto inte längre aktivt',
                        },
                    });
                }
                console.error(error);
                this.loading = false;
            },
        });
    }
}
