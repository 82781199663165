import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Helpers } from '@app/helpers/helpers.class';
import { IAttestTimeProblemWithoutPeriodRequest } from '@app/interfaces/attest-time-problem-without-period-request.interface';
import { ICostPool } from '@app/interfaces/cost-pool.interface';
import { IEmployeeAttestTime } from '@app/interfaces/employee-attest-time.interface';
import { IEmployeeForSelect } from '@app/interfaces/employee-for-select.interface';
import { IPage } from '@app/interfaces/page.interface';
import { IPunchPair } from '@app/interfaces/punch-pair.interface';
import { AuthService } from '@app/services/auth.service';
import { EmployeeService } from '@app/services/employee.service';
import { PunchService } from '@app/services/punch.service';
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';
import { AttestTimeMoveDialogComponent } from '@app/shared/dialogs/attest-time-move-dialog/attest-time-move-dialog.component';
import { AttestTimeProblemDialogComponent } from '@app/shared/dialogs/attest-time-problem-dialog/attest-time-problem-dialog.component';
import { AttestTimeSplitDialogComponent } from '@app/shared/dialogs/attest-time-split-dialog/attest-time-split-dialog.component';
import { ConfirmDialog } from '@app/shared/dialogs/confirm/confirm.dialog';
import { map, Observable, startWith } from 'rxjs';

@Component({
    selector: 'app-attest-time',
    templateUrl: './attest-time.component.html',
    styleUrls: ['./attest-time.component.scss'],
})
export class AttestTimeComponent implements OnInit {
    @Output() filterChanged: EventEmitter<any> = new EventEmitter();
    @Input('mode') inputMode: number;
    @Input('day') inputDay: string;
    @Input('page') inputPage: number;

    employeeId: number;
    loading: boolean = false;
    filterEmployeeId: number;
    filterDay: string;
    pageIndex: number;
    employeesPage: IPage<any>;
    dataSource: MatTableDataSource<IEmployeeAttestTime>;
    employeeColumns: string[] = ['name', 'workPeriods'];
    timelineStart: Date;
    timelineEnd: Date;
    timelineLengthMinutes: number;
    costPoolColors = new Map<number, any>();
    anyWithoutCostPool: boolean = false;
    attestTimeTodoDates: string[] = [];
    addEmployeeControl: FormControl = new FormControl<string | null>(null);
    filteredEmployees: Observable<IEmployeeForSelect[]>;
    employees: IEmployeeForSelect[];

    constructor(
        private dialog: MatDialog,
        private employeeService: EmployeeService,
        private authService: AuthService,
        private punchService: PunchService
    ) {}

    ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
        this.filterEmployeeId = this.employeeId;
        this.filterDay = Helpers.toShortDateString(new Date());

        if (Helpers.isDefined(this.inputMode)) {
            this.filterEmployeeId = this.inputMode == 0 ? 0 : this.employeeId;
        }

        if (Helpers.isDefined(this.inputDay)) {
            this.filterDay = this.inputDay;
        }

        if (Helpers.isDefined(this.inputPage)) {
            this.pageIndex = this.inputPage - 1;
        }

        this.loadAttestTimeTodoDates();
        this.getEmployeesPage(1);
        this.getAllEmployees();

        this.filteredEmployees = this.addEmployeeControl.valueChanges.pipe(
            startWith(''),
            map((value) => (value !== null && value.length > 1 ? this.filter(value) : []))
        );
    }

    getEmployeesPage(page: number): void {
        this.employeesPage = null;
        this.costPoolColors = new Map<number, any>();
        this.loading = true;
        let day = Helpers.toShortDateString(this.filterDay);
        this.employeeService.getAttestTimeEmployees(this.filterEmployeeId, day, page).subscribe({
            next: (data) => {
                for (let employee of data.items) {
                    if (employee.punchPairs != null) {
                        for (let pair of employee.punchPairs) {
                            if (!pair.costPool) {
                                this.anyWithoutCostPool = true;
                                continue;
                            }

                            if (this.costPoolColors.has(pair.costPool.poolId)) {
                                continue;
                            }

                            this.costPoolColors.set(pair.costPool.poolId, {
                                className: 'cost-pool-' + pair.costPool.poolId,
                                poolName: pair.costPool.name,
                            });
                        }
                    }
                }

                this.employeesPage = data;
                this.timelineStart = new Date(this.employeesPage.metaData.timelineStartDate);
                this.timelineEnd = new Date(this.employeesPage.metaData.timelineEndDate);
                this.timelineLengthMinutes = this.employeesPage.metaData.timelineLengthMinutes;
                this.dataSource = new MatTableDataSource<IEmployeeAttestTime>(this.employeesPage.items);
                this.loading = false;
            },
            error: (error) => {
                this.loading = false;
                console.error(error);
                Helpers.captureSentryError('Could not get employees page');
            },
        });

        let filterSettings = {
            day: day,
            page: page,
        };

        this.filterChanged.emit(filterSettings);
    }

    periodMenuClosed(period: IPunchPair) {
        period.active = false;
    }

    periodMenuOpened(period: IPunchPair) {
        period.active = true;
    }

    approvePeriods() {
        this.dialog
            .open(ConfirmDialog, {
                width: '500px',
                data: {
                    title: 'Godkänn alla',
                    message: 'Är du säker på att du vill godkänna alla perioder som inte har en status vald?',
                },
            })
            .afterClosed()
            .subscribe((result: boolean) => {
                if (!result) {
                    return;
                }
                this.loading = true;
                let day = Helpers.toShortDateString(this.filterDay);
                this.punchService.attestTimeApproveAll({ day, handledByEmployeeId: this.filterEmployeeId }).subscribe({
                    next: () => {
                        window.location.reload();
                    },
                    error: (error) => {
                        this.loading = false;
                        console.error(error);
                        Helpers.captureSentryError('Could not attest time');
                    },
                });
            });
    }

    approveSelectedPeriods() {
        const employeeIds = [];

        for (let employee of this.employeesPage.items) {
            if (!employee.selected) {
                continue;
            }

            employeeIds.push(employee.employeeId);
        }

        if (employeeIds.length === 0) {
            this.dialog.open(AlertDialog, {
                width: '500px',
                data: {
                    title: 'Inga valda',
                    message: 'Du har inte valt några anställda att godkänna perioder för.',
                },
            });
            return;
        }

        this.dialog
            .open(ConfirmDialog, {
                width: '500px',
                data: {
                    title: 'Godkänn alla valda',
                    message: `Är du säker på att du vill godkänna alla perioder som inte har en status vald för de ${employeeIds.length} valda anställda?`,
                },
            })
            .afterClosed()
            .subscribe((result: boolean) => {
                if (!result) {
                    return;
                }
                this.loading = true;
                let day = Helpers.toShortDateString(this.filterDay);
                this.punchService
                    .attestTimeApproveAll({ day, handledByEmployeeId: this.filterEmployeeId, employeeIds: employeeIds })
                    .subscribe({
                        next: () => {
                            window.location.reload();
                        },
                        error: (error) => {
                            this.loading = false;
                            console.error(error);
                            Helpers.captureSentryError('Could not attest time');
                        },
                    });
            });
    }

    approvePeriod(period: IPunchPair) {
        this.loading = true;
        this.punchService.attestTimeApprove({ period }).subscribe({
            next: (data) => {
                this.loading = false;
                period.attestedTime = data;
            },
            error: (error) => {
                this.loading = false;
                console.error(error);
                Helpers.captureSentryError('Could not attest time');
            },
        });
    }

    problemPeriod(period: IPunchPair) {
        let handle = this.dialog.open(AttestTimeProblemDialogComponent, {
            width: '600px',
        });

        handle.afterClosed().subscribe((message: string | null) => {
            if (message) {
                this.loading = true;
                this.punchService.attestTimeProblem({ period, message }).subscribe({
                    next: (data) => {
                        this.loading = false;
                        period.attestedTime = data;
                    },
                    error: (error) => {
                        this.loading = false;
                        console.error(error);
                        Helpers.captureSentryError('Could not attest time');
                    },
                });
            }
        });
    }

    undoPeriodAction(period: IPunchPair) {
        this.loading = true;
        this.punchService.attestTimeUndo({ period }).subscribe({
            next: () => {
                this.loading = false;
                period.attestedTime = null;
            },
            error: (error) => {
                this.loading = false;
                console.error(error);
                Helpers.captureSentryError('Could not attest time');
            },
        });
    }

    movePeriod(period: IPunchPair, employee: IEmployeeAttestTime) {
        let handle = this.dialog.open(AttestTimeMoveDialogComponent, {
            width: '600px',
        });

        handle.afterClosed().subscribe((costPool: ICostPool | null) => {
            if (costPool) {
                this.loading = true;
                this.punchService.attestTimeMove({ period, poolId: costPool.poolId }).subscribe({
                    next: (handledByEmployee) => {
                        this.loading = false;

                        if (!handledByEmployee) {
                            const pairs = [...employee.punchPairs];
                            const index = pairs.indexOf(period);
                            pairs.splice(index, 1);
                            employee.punchPairs = pairs;
                        } else {
                            period.costPool = costPool.poolId === 0 ? null : costPool;
                        }
                    },
                    error: (error) => {
                        this.loading = false;
                        console.error(error);
                        Helpers.captureSentryError('Could not attest time');
                    },
                });
            }
        });
    }

    splitPeriod(period: IPunchPair, employee: IEmployeeAttestTime) {
        let handle = this.dialog.open(AttestTimeSplitDialogComponent, {
            width: '300px',
            data: {
                punchIn: period.in,
                punchOut: period.out,
            },
        });

        handle.afterClosed().subscribe((splitAt: Date | null) => {
            if (splitAt) {
                this.loading = true;
                this.punchService.attestTimeSplit({ period, splitAt }).subscribe({
                    next: (newPairs) => {
                        this.loading = false;
                        const pairs = [...employee.punchPairs];
                        const index = pairs.indexOf(period);
                        pairs.splice(index, 1);
                        pairs.push(...newPairs);
                        employee.punchPairs = pairs;
                    },
                    error: (error) => {
                        this.loading = false;
                        console.error(error);
                        Helpers.captureSentryError('Could not attest time');
                    },
                });
            }
        });
    }

    pageChanged(event: any): void {
        this.getEmployeesPage(event.pageIndex + 1);
        this.pageIndex = event.pageIndex;
    }

    loadAttestTimeTodoDates(): void {
        this.punchService.getAttestTimeTodoDates(this.filterEmployeeId).subscribe({
            next: (result) => {
                this.attestTimeTodoDates = result;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not load attest time todo dates');
            },
        });
    }

    dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
        if (view === 'month') {
            let found = false;

            for (let item of this.attestTimeTodoDates) {
                const todoDate = Helpers.toShortDateString(item);
                const calendarDate = Helpers.toShortDateString(cellDate);

                if (todoDate === calendarDate) {
                    found = true;
                    break;
                }
            }

            if (found) {
                return 'highlighted-date-picker-date';
            }
        }

        return '';
    };

    showIssuesByPeriod(period: IPunchPair): void {
        window.open('/admin/employees/attest-time/issues?query=' + period.attestedTime.periodHash);
    }

    showIssuesByEmployee(employee: IEmployeeAttestTime): void {
        window.open(
            '/admin/employees/attest-time/issues?employeeId=' +
                employee.employeeId +
                '&issueDate=' +
                Helpers.toShortDateString(this.filterDay)
        );
    }

    filter(value: string): IEmployeeForSelect[] {
        const queries = value.toLowerCase().replace(/,/g, '').replace(/\(/g, '').replace(/\)/g, '').split(' ');
        return this.employees.filter((e) => {
            let allMatch = true;
            for (let i = 0; i < queries.length; i++) {
                if (queries[i].length === 0) {
                    continue;
                }
                if ((e.firstName + e.lastName + e.companyShort).toLowerCase().indexOf(queries[i]) === -1) {
                    allMatch = false;
                    break;
                }
            }
            return allMatch;
        });
    }

    displaySelectedEmployee(employee?: IEmployeeForSelect): string | undefined {
        return employee ? employee.lastName + ', ' + employee.firstName + ' (' + employee.companyShort + ')' : undefined;
    }

    getAllEmployees(): void {
        this.employeeService.getAllForSelect().subscribe({
            next: (data) => {
                this.employees = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get employees');
            },
        });
    }

    addEmployeeSelected(event: MatAutocompleteSelectedEvent): void {
        const selectedEmployee = event.option.value as IEmployeeForSelect;
        const items = [...this.employeesPage.items];

        items.push({
            employeeId: selectedEmployee.employeeId,
            firstName: selectedEmployee.firstName,
            lastName: selectedEmployee.lastName,
            companyName: selectedEmployee.companyName,
            companyId: selectedEmployee.companyId,
            companyShort: selectedEmployee.companyShort,
            scheduleDay: null,
            punchPairs: null,
        });

        this.employeesPage.items = items;
        this.dataSource = new MatTableDataSource<IEmployeeAttestTime>(items);
        this.addEmployeeControl.reset();
    }

    addEmployeeIssue(employee: IEmployeeAttestTime): void {
        let handle = this.dialog.open(AttestTimeProblemDialogComponent, {
            width: '600px',
        });

        handle.afterClosed().subscribe((message: string | null) => {
            if (message) {
                this.loading = true;
                const request: IAttestTimeProblemWithoutPeriodRequest = {
                    employeeId: employee.employeeId,
                    issueDate: this.filterDay,
                    message: message,
                };

                this.punchService.attestTimeProblemWithoutPeriod(request).subscribe({
                    next: (data) => {
                        this.loading = false;
                        employee.attestedTimeIssues = data;
                    },
                    error: (error) => {
                        this.loading = false;
                        console.error(error);
                        Helpers.captureSentryError('Could not attest time');
                    },
                });
            }
        });
    }
}
