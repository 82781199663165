import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyService } from '@app/services/company.service';
import { ReportService } from '@app/services/report.service';
import { IReport } from '@app/interfaces/report.interface';
import { ICompany } from '@app/interfaces/company.interface';
import { Helpers } from '@app/helpers/helpers.class';
import { IWorkingEmployeesReportFilter } from '@app/interfaces/workinng-employees-report-filter.interface';

@Component({
    selector: 'app-report-working-employees',
    templateUrl: './report-working-employees.partial.html',
    styleUrls: ['./report-working-employees.partial.scss'],
})
export class ReportWorkingEmployeesPartial implements OnInit {
    @Output() public filterChanged: EventEmitter<any> = new EventEmitter();
    @Input('companyIds') public inputCompanyIds: string;
    @Input('punchDateFrom') public inputPunchDateFrom: string;
    @Input('punchDateTo') public inputPunchDateTo: string;

    public report: IReport;
    public loading: boolean = false;
    public filterCompanyIds: number[];
    public companies: ICompany[];
    public filterPunchDateFrom: string;
    public filterPunchDateTo: string;
    public columns: string[] = ['name', 'company', 'punchedInNow', 'latestPunchTime'];

    constructor(private companyService: CompanyService, private reportService: ReportService) {}

    public ngOnInit(): void {
        this.filterCompanyIds = [];
        this.filterPunchDateFrom = Helpers.toShortDateString(new Date());
        this.filterPunchDateTo = Helpers.toShortDateString(new Date());

        if (Helpers.isDefined(this.inputCompanyIds)) {
            this.filterCompanyIds = this.inputCompanyIds.split(',').map(function (item) {
                return parseInt(item, 10);
            });
        }

        if (Helpers.isDefined(this.inputPunchDateFrom)) {
            this.filterPunchDateFrom = this.inputPunchDateFrom;
        }

        if (Helpers.isDefined(this.inputPunchDateTo)) {
            this.filterPunchDateTo = this.inputPunchDateTo;
        }

        this.getCompanies();
        this.getReport();
    }

    public getReport(): void {
        this.report = null;
        let filter: IWorkingEmployeesReportFilter = {
            companyIds: this.filterCompanyIds,
            punchDateFrom: Helpers.toShortDateString(this.filterPunchDateFrom),
            punchDateTo: Helpers.toShortDateString(this.filterPunchDateTo),
        };

        this.loading = true;
        this.reportService.getWorkingEmployeesReport(filter).subscribe({
            next: (data) => {
                this.report = data;
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not get working employees report');
            },
        });

        let filterSettings = {
            companyIds: this.filterCompanyIds,
            punchDateFrom: Helpers.toShortDateString(this.filterPunchDateFrom),
            punchDateTo: Helpers.toShortDateString(this.filterPunchDateTo),
        };

        this.filterChanged.emit(filterSettings);
    }

    public getCompanies() {
        this.companyService.getAll().subscribe({
            next: (data) => {
                this.companies = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get companies');
            },
        });
    }
}
