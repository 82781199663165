<header>
    <mat-toolbar>
        <button mat-button (click)="logout()">
            <mat-icon class="spaced">access_time</mat-icon>
            <span>Stämpelklockan</span>
        </button>
        <div class="spacer"></div>
        <div class="actions" *ngIf="authenticated">
            <button mat-button (click)="showHelp()">
                <mat-icon>help_outline</mat-icon>
                Hjälp
            </button>
            <button mat-button (click)="logout()">
                <mat-icon>exit_to_app</mat-icon>
                Logga ut
            </button>
        </div>
    </mat-toolbar>
</header>
