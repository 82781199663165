<app-driving-log-menu></app-driving-log-menu>

<div class="container-fluid">
    <form [formGroup]="loginForm" (ngSubmit)="login()">
        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Kod</mat-label>
                    <input type="tel" formControlName="code" matInput required>
                    <mat-error *ngIf="submitted && f.code.hasError('required')">
                        Du måste ange din 4-siffriga kod
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col button-wrapper">
                <button mat-raised-button color="primary" mat-button>Logga in</button>
            </div>
        </div>
    </form>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
