import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Helpers } from '@app/helpers/helpers.class';
import { AdministratorService } from '@app/services/administrator.service';
import { AuthService } from '@app/services/auth.service';
import { AlertDialog } from '../alert/alert.dialog';

@Component({
    selector: 'app-admin-password-expired-dialog',
    templateUrl: './admin-password-expired-dialog.component.html',
    styleUrls: ['./admin-password-expired-dialog.component.scss'],
})
export class AdminPasswordExpiredDialogComponent {
    loading: boolean = false;
    mailSent: boolean = false;

    constructor(
        private dialogRef: MatDialogRef<AdminPasswordExpiredDialogComponent>,
        private authService: AuthService,
        private dialog: MatDialog
    ) {
        dialogRef.disableClose = true;
    }

    resetPassword(): void {
        this.loading = true;
        this.authService.requestResetAdministratorPassword(this.authService.getAdminUsername()).subscribe({
            next: () => {
                this.loading = false;
                this.dialog.open(AlertDialog, {
                    width: '400px',
                    data: {
                        title: 'Länk skickad',
                        message:
                            'En länk har skickats till den e-post du är registrerad med. ' +
                            'Följ instruktionerna för att byta ditt lösenord.',
                    },
                });
                this.mailSent = true;
            },
            error: (error) => {
                this.loading = false;
                console.error(error);
                Helpers.captureSentryError('Could not request reset password');
                this.dialog.open(AlertDialog, {
                    width: '400px',
                    data: {
                        title: 'Kunde inte skickas',
                        message: 'Något gick fel när länken skulle skickas',
                    },
                });
            },
        });
    }
}
