import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirm.dialog.html',
    styleUrls: ['./confirm.dialog.scss']
})

export class ConfirmDialog {
    title: string;
    message: string;

    constructor(
        private dialogRef: MatDialogRef<ConfirmDialog>,
        @Inject(MAT_DIALOG_DATA) {
            title,
            message
        }: any) {

        this.title = title;
        this.message = message;
    }

    close(result: boolean): void {
        this.dialogRef.close(result);
    }
}
