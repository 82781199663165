import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

// Interfaces
import { IPunchWarning } from '@app/interfaces/punch-warning.interface';
import { IPunchWarningDialogResult } from '@app/interfaces/punch-warning-dialog-result.interface';

@Component({
    selector: 'punch-warning-action-dialog',
    templateUrl: './punch-warning-action.dialog.html',
    styleUrls: ['./punch-warning-action.dialog.scss']
})

export class PunchWarningActionDialog implements OnInit {
    warning: IPunchWarning;
    selectedAction: number;

    ngOnInit() {

    }

    constructor(private dialogRef: MatDialogRef<PunchWarningActionDialog>,
        @Inject(MAT_DIALOG_DATA) {
            warning
        }: any) {
        this.warning = warning;
    }

    close() {
        this.dialogRef.close();
    }

    closeWithAction() {
        let result: IPunchWarningDialogResult = {
            action: this.selectedAction,
            warning: this.warning
        };
        this.dialogRef.close(result);
    }
}
