import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment'

// Interfaces
import { IUnionContract } from '@app/interfaces/union-contract.interface';

@Injectable({ providedIn: 'root' })
export class UnionContractService {

    constructor(private http: HttpClient) { }

    getAll(): Observable<IUnionContract[]> {
        return this.http.get<IUnionContract[]>(environment.apiUrl + "/union-contracts");
    }
}
