import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment'
import { Observable } from 'rxjs';

// Interfaces
import { IScheduleWithDays } from '@app/interfaces/schedule-with-days.interface';
import { IScheduleLength } from '@app/interfaces/schedule-length.interface';
import { IScheduleSearch } from '@app/interfaces/schedule-search.interface';
import { IPage } from '@app/interfaces/page.interface';
import { IScheduleDayNew } from '@app/interfaces/schedule-day-new.interface';
import { IEmployeeSchedule } from '@app/interfaces/employee-schedule.interface';

@Injectable({ providedIn: 'root' })
export class ScheduleService {

    constructor(private http: HttpClient) { }

    getScheduleWithDays(scheduleId: number): Observable<IScheduleWithDays> {
        return this.http.get<IScheduleWithDays>(environment.apiUrl + '/schedules/' + scheduleId + '/with-days');
    }

    getSchedules(search: IScheduleSearch): Observable<IPage<any>> {
        return this.http.post<IPage<any>>(environment.apiUrl + '/schedules/search', search);
    }

    getActiveForEmployee(employeeId: number, atDate: string = ''): Observable<IScheduleWithDays> {
        return this.http.get<IScheduleWithDays>(environment.apiUrl + '/schedules/active-for-employees/' + employeeId, {
            params: new HttpParams()
                .set('atDate', atDate)
        });
    }

    getScheduleLengths(): Observable<IScheduleLength[]> {
        return this.http.get<IScheduleLength[]>(environment.apiUrl + '/schedules/lengths');
    }

    getScheduleDaysByLengthAndStartDate(length: number, startDate: string): Observable<IScheduleDayNew[]> {
        return this.http.get<IScheduleDayNew[]>(environment.apiUrl + '/schedules/days', {
            params: new HttpParams()
                .set('length', length.toString())
                .set('startDate', startDate)
        });
    }

    update(schedule: IScheduleWithDays): Observable<any> {
        return this.http.patch<any>(environment.apiUrl + '/schedules/' + schedule.scheduleId, schedule);
    }

    create(schedule: IScheduleWithDays): Observable<IScheduleWithDays> {
        return this.http.post<IScheduleWithDays>(environment.apiUrl + '/schedules/' + schedule.scheduleId, schedule);
    }

    getAll(): Observable<IScheduleWithDays[]> {
        return this.http.get<IScheduleWithDays[]>(environment.apiUrl + '/schedules');
    }

    deleteEmployeeSchedule(employeeSchedule: IEmployeeSchedule): Observable<any> {
        return this.http.delete<any>(environment.apiUrl + '/schedules/employee-schedule', {
            params: new HttpParams()
                .set('employeeId', employeeSchedule.employeeId.toString())
                .set('scheduleId', employeeSchedule.scheduleId.toString())
                .set('fromDate', employeeSchedule.fromDate)
        });
    }

    connectEmployeeSchedules(employeeSchedules: IEmployeeSchedule[]): Observable<IEmployeeSchedule[]> {
        return this.http.post<IEmployeeSchedule[]>(environment.apiUrl + '/schedules/employee-schedules', employeeSchedules);
    }
}
