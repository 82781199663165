<div class="container-fluid">
    <div class="row">
        <div class="col">
            <mat-card class="compact">
                <mat-card-title> Administratörens information </mat-card-title>
                <mat-card-content>
                    <div class="container-fluid" [formGroup]="adminForm">
                        <div class="row" *ngIf="adminId">
                            <div class="col">
                                <mat-checkbox formControlName="active"> Aktiv </mat-checkbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <mat-form-field>
                                    <mat-label>Användarnamn</mat-label>
                                    <input matInput autocomplete="off" formControlName="userName" required />
                                    <mat-error *ngIf="f.userName.hasError('required')"> Obligatoriskt fält </mat-error>
                                    <mat-error *ngIf="f.userName.hasError('email')">
                                        Måste vara en giltig e-postadress
                                    </mat-error>
                                    <mat-error *ngIf="!f.userName.hasError('email') && f.userName.hasError('pattern')">
                                        Måste vara en @dreamlogistics.se/@bring.com-mail
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <mat-label>Anställd</mat-label>
                                    <input
                                        matInput
                                        type="text"
                                        formControlName="employeeSearch"
                                        [matAutocomplete]="employeeAutoComplete"
                                        required />
                                    <mat-autocomplete
                                        autoActiveFirstOption
                                        #employeeAutoComplete="matAutocomplete"
                                        [displayWith]="displaySelectedEmployee"
                                        (optionSelected)="employeeSelected($event)">
                                        <mat-option *ngFor="let employee of filteredEmployees | async" [value]="employee">
                                            {{employee.lastName}}, {{employee.firstName}} ({{employee.companyShort}})
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error *ngIf="f.employeeSearch.hasError('required')"> Obligatoriskt fält </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="row buttons">
        <ng-container *ngIf="!adminId; else editMode">
            <div class="col" align="end">
                <button mat-raised-button color="primary" (click)="save()">Skapa konto</button>
            </div>
        </ng-container>
        <ng-template #editMode>
            <div class="col" align="end">
                <button mat-raised-button color="default" (click)="resetPassword()">Återställ lösenord</button>
                <button mat-raised-button color="primary" (click)="save()">Uppdatera konto</button>
            </div>
        </ng-template>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
