import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment'

// Interfaces
import { ICarExtended } from '@app/interfaces/car-extended.interface';
import { ICar } from '@app/interfaces/car.interface';

@Injectable({ providedIn: 'root' })
export class CarService {

    constructor(private http: HttpClient) { }

    get(carId: number): Observable<ICarExtended> {
        return this.http.get<ICarExtended>(environment.apiUrl + "/cars/" + carId);
    }

    create(car: ICar): Observable<ICar> {
        return this.http.post<ICar>(environment.apiUrl + "/cars", car);
    }

    update(car: ICar): Observable<any> {
        return this.http.patch<any>(environment.apiUrl + "/cars/" + car.carId, car);
    }

    getByEmployee(employeeId: number): Observable<ICarExtended[]> {
        return this.http.get<ICarExtended[]>(environment.apiUrl + "/cars/by-employee/" + employeeId);
    }

    getAll(): Observable<ICarExtended[]> {
        return this.http.get<ICarExtended[]>(environment.apiUrl + "/cars");
    }
}
