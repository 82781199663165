<app-admin-menu></app-admin-menu>

<h1 class="mat-headline-3">Administratörsrättigheter</h1>

<div class="container">
    <div class="row">
        <div class="col">
            <mat-card class="compact">
                <mat-card-content>
                    <app-admin-claim-settings></app-admin-claim-settings>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<app-messages #messagesPartial [employeeId]="employeeId"></app-messages>

<app-admin-footer></app-admin-footer>
