import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from "@angular/router";
import { Helpers } from '@app/helpers/helpers.class';

// Services
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'app-report-working-employees-admin-page',
    templateUrl: './report-working-employees.component.html',
    styleUrls: ['./report-working-employees.component.scss']
})

export class ReportWorkingEmployeesAdminComponent implements OnInit {
    employeeId: number;
    companyIds: number[];
    punchDateFrom: string;
    punchDateTo: string;

    constructor(private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private router: Router) { }

    ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);

        if (Helpers.isDefined(params['companyIds'])) {
            this.companyIds = params['companyIds'];
        }

        if (Helpers.isDefined(params['punchDateFrom'])) {
            this.punchDateFrom = params['punchDateFrom'];
        }

        if (Helpers.isDefined(params['punchDateTo'])) {
            this.punchDateTo = params['punchDateTo'];
        }
    }

    updateQueryParameters(filter: any): void {
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);
        params['companyIds'] = filter.companyIds.length > 0 ? filter.companyIds.join(',') : null;
        params['punchDateFrom'] = filter.punchDateFrom.length > 0 ? filter.punchDateFrom : null;
        params['punchDateTo'] = filter.punchDateTo.length > 0 ? filter.punchDateTo : null;
        this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params });
    }
}
