<div class="container-fluid" [formGroup]="milageForm">
    <div class="row">
        <div class="col-6">
            <mat-form-field>
                <mat-label>Dag</mat-label>
                <input matInput [matDatepicker]="dayPicker" formControlName="day" class="day-picker" required />
                <mat-datepicker-toggle matSuffix [for]="dayPicker"></mat-datepicker-toggle>
                <mat-datepicker #dayPicker disabled="false"></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-6">
            <mat-form-field>
                <mat-label>Sträcka (km)</mat-label>
                <input type="number" matInput formControlName="distance" required />
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Bolag</mat-label>
                <mat-select formControlName="company" required>
                    <mat-option *ngFor="let company of companies" [value]="company.companyId">
                        {{company.companyName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Kommentar</mat-label>
                <textarea matInput formControlName="description" required></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col" align="end">
            <button mat-raised-button color="primary" (click)="save()">Spara</button>
        </div>
    </div>
</div>

<div class="loading-wrapper" *ngIf="loading">
    <mat-spinner diameter="40"></mat-spinner>
</div>
