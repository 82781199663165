import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { OverlayRef } from '@angular/cdk/overlay';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {
    EMPLOYEE_ID,
    NEW_MESSAGE,
    TO_EMPLOYEE_ID,
    WITH_EMPLOYEE_ID,
    PUNCH_WARNING_HASH,
    CLOSE_METHOD,
} from '../messages.partial.tokens';
import { Helpers } from '@app/helpers/helpers.class';
import { AuthService } from '@app/services/auth.service';
import { EmployeeMessageService } from '@app/services/employee-message.service';
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';
import { IEmployeeMessage } from '@app/interfaces/employee-message.interface';
import { IPage } from '@app/interfaces/page.interface';
import { IConversation } from '@app/interfaces/conversation.interface';
import { IMessageRecipient } from '@app/interfaces/message-recipient.interface';

@Component({
    selector: 'app-messages-panel',
    templateUrl: './messages-panel.partial.html',
    styleUrls: ['./messages-panel.partial.scss'],
})
export class MessagesPanelPartial implements OnInit {
    @ViewChild('newMessageTextarea') public newMessageTextarea: ElementRef;

    public sendMessageForm: UntypedFormGroup;
    public conversationsPage: IPage<any>;
    public adminMode: boolean;
    public adminId: number;
    public conversationsPageIndex: number = 0;
    public loading: boolean = true;
    public activeConversation: IConversation;
    public messagePage: IPage<any>;
    public messagePageIndex: number = 0;
    public recipients: any[];
    public selectedRecipient: number = 6682;
    public filteredRecipients: Observable<IMessageRecipient[]>;
    public filterQuery: string;

    get f() {
        return this.sendMessageForm.controls;
    }

    constructor(
        public panelHandle: OverlayRef,
        @Inject(EMPLOYEE_ID) private employeeId: number,
        @Inject(NEW_MESSAGE) private externalMessage: string,
        @Inject(TO_EMPLOYEE_ID) private externalToEmployeeId: number,
        @Inject(WITH_EMPLOYEE_ID) private externalWithEmployeeId: number,
        @Inject(PUNCH_WARNING_HASH) private punchWarningHash: string,
        @Inject(CLOSE_METHOD) public close: any,
        private messageService: EmployeeMessageService,
        private authService: AuthService,
        private dialog: MatDialog,
        private formBuilder: UntypedFormBuilder
    ) {
        // Hack
        this.employeeId = parseInt(employeeId.toString());
        this.externalToEmployeeId = parseInt(externalToEmployeeId.toString());
        this.externalWithEmployeeId = parseInt(externalWithEmployeeId.toString());
    }

    ngOnInit(): void {
        const adminId = this.authService.getAdminId();
        this.adminMode = this.authService.isAdminMode();

        this.sendMessageForm = this.formBuilder.group({
            recipient: null,
            message: null,
        });

        this.resetForm();

        if (this.adminMode) {
            this.adminId = adminId;
            this.selectedRecipient = null;
        }

        if (Helpers.isDefined(this.externalMessage)) {
            this.f.message.setValue(this.externalMessage);
        }

        if (Helpers.isDefined(this.externalToEmployeeId) && this.externalToEmployeeId > 0) {
            this.selectedRecipient = this.externalToEmployeeId;
            this.externalToEmployeeId = null;
        }

        this.filteredRecipients = this.f.recipient.valueChanges.pipe(
            startWith(''),
            map((value) => (value !== null && value.length > 1 ? this.filter(value) : []))
        );

        this.getConversationPage(1);
        this.getRecipients();
    }

    resetForm(): void {
        this.sendMessageForm.reset();
        this.f.recipient.setValue(null);
        this.f.message.setValue('');
    }

    getConversationPage(page: number) {
        this.loading = true;
        this.messageService.getConversations(this.employeeId, this.filterQuery, page).subscribe({
            next: (data) => {
                this.loading = false;
                this.conversationsPage = data;

                if (Helpers.isDefined(this.externalWithEmployeeId) && this.externalWithEmployeeId > 0) {
                    for (let i = 0; i < this.conversationsPage.items.length; i++) {
                        if (this.conversationsPage.items[i].withEmployeeId == this.externalWithEmployeeId) {
                            this.showConversation(this.conversationsPage.items[i]);
                            this.externalWithEmployeeId = null;
                            break;
                        }
                    }
                }

                if (
                    Helpers.isDefined(this.externalMessage) &&
                    this.externalMessage.length > 0 &&
                    Helpers.isDefined(this.newMessageTextarea)
                ) {
                    setTimeout(() => {
                        this.newMessageTextarea.nativeElement.focus();
                        this.externalMessage = null;
                    }, 100);
                }
            },
            error: (error) => {
                this.loading = false;
                console.error(error);
                Helpers.captureSentryError('Could not get conversation page');
            },
        });
    }

    pageChanged(event: any): void {
        this.getConversationPage(event.pageIndex + 1);
        this.conversationsPageIndex = event.pageIndex;
    }

    showConversation(conversation: IConversation): void {
        this.activeConversation = conversation;
        this.getConversationMessages(1);
    }

    getConversationMessages(page: number): void {
        if (!Helpers.isDefined(this.activeConversation)) {
            return;
        }

        this.loading = true;
        this.messageService.getConversation(this.employeeId, this.activeConversation.withEmployeeId, page).subscribe({
            next: (data) => {
                this.loading = false;
                this.messagePage = data;

                if (this.activeConversation.unreadMessagesCount > 0) {
                    this.messageService.setConversationRead(this.employeeId, this.activeConversation.withEmployeeId).subscribe({
                        next: (data) => {},
                        error: (error) => {
                            console.error(error);
                            Helpers.captureSentryError('Could not set conversation as read');
                        },
                    });
                }
            },
            error: (error) => {
                this.loading = false;
                console.error(error);
                Helpers.captureSentryError('Could not get conversation');
            },
        });
    }

    messagesPageChanged(event: any): void {
        this.getConversationMessages(event.pageIndex + 1);
        this.messagePageIndex = event.pageIndex;
    }

    backToConversations(): void {
        this.activeConversation = null;
    }

    sendMessage(): void {
        if (!Helpers.isDefined(this.f.message.value) || this.f.message.value.length == 0) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Meddelande saknas',
                    message: 'Du måste ange ett meddelande att skicka',
                },
            });
            return;
        }

        if (this.f.message.value.length > 2000) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Meddelande för långt',
                    message: 'Ditt meddelande är för långt och skickades inte. Max längd är 2000 tecken',
                },
            });
            return;
        }

        /*if(Helpers.isDefined(this.externalMessage) &&
            this.newMessageTextarea.nativeElement.classList.contains('ng-pristine')) {
            this.dialog.open(AlertDialog,
                {
                    width: '400px',
                    data: {
                        title: 'Ange meddelande',
                        message: 'Du måste ange en beskrivning av vad som är fel i meddelandet'
                    }
                }
            );
            return;
            }*/

        let toEmployeeId = this.selectedRecipient;

        console.log(toEmployeeId);

        if (Helpers.isDefined(this.activeConversation) && Helpers.isDefined(this.activeConversation.withEmployeeId)) {
            toEmployeeId = this.activeConversation.withEmployeeId;
            console.log('Conversation');
        }

        if (!Helpers.isDefined(toEmployeeId) || toEmployeeId == 0) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Ingen mottagare',
                    message: 'Du måste välja en mottagare',
                },
            });
            return;
        }

        this.loading = true;
        let message: IEmployeeMessage = {
            fromEmployeeId: this.employeeId,
            toEmployeeId: toEmployeeId,
            message: this.f.message.value,
            punchWarningHashCode:
                Helpers.isDefined(this.punchWarningHash) && this.punchWarningHash.length > 0 ? this.punchWarningHash : null,
        };

        this.messageService.sendMessage(message).subscribe({
            next: (data) => {
                this.resetForm();
                this.punchWarningHash = null;

                if (Helpers.isDefined(this.activeConversation)) {
                    this.getConversationMessages(this.messagePageIndex + 1);
                } else {
                    this.getConversationPage(this.conversationsPageIndex + 1);
                }
            },
            error: (error) => {
                this.loading = false;
                console.error(error);

                let title: string = 'Fel';
                let message: string = 'Något gick fel när ditt meddelande skulle skickas';
                let errors: string[];

                if (error.status === 400 && error.error.errors) {
                    title = 'Felaktiga uppgifter';
                    message = 'Följande är fel och måste korrigeras:';
                    errors = error.error.errors;
                } else {
                    Helpers.captureSentryError('Could not send message');
                }

                this.dialog.open(AlertDialog, {
                    width: '400px',
                    data: {
                        title: title,
                        message: message,
                        list: errors,
                    },
                });
            },
        });
    }

    getRecipients(): void {
        this.messageService.getRecipients(this.employeeId).subscribe({
            next: (data) => {
                this.recipients = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get recipients');
            },
        });
    }

    filter(value: string): IMessageRecipient[] {
        const queries = value.toLowerCase().replace(/,/g, '').replace(/\(/g, '').replace(/\)/g, '').split(' ');
        return this.recipients.filter((e) => {
            let allMatch = true;
            for (let i = 0; i < queries.length; i++) {
                if (queries[i].length === 0) {
                    continue;
                }
                if ((e.firstName + e.lastName + e.companyShort).toLowerCase().indexOf(queries[i]) === -1) {
                    allMatch = false;
                    break;
                }
            }
            return allMatch;
        });
    }

    displaySelectedRecipient(employee?: IMessageRecipient): string | undefined {
        return employee ? employee.lastName + ', ' + employee.firstName + ' (' + employee.companyShort + ')' : undefined;
    }

    recipientSelected(e): void {
        this.selectedRecipient = e.option.value.employeeId;
    }
}
