import { Pipe, PipeTransform } from '@angular/core';
import { AttestedTimeStatusEnum } from '@app/enums/attested-time-status.enum';
import { Helpers } from '@app/helpers/helpers.class';
import { IAttestedTime } from '@app/interfaces/attested-time.interface';
import { IPunchPair } from '@app/interfaces/punch-pair.interface';

@Pipe({ name: 'attestTimeDetails' })
export class AttestTimeDetailsPipe implements PipeTransform {
    transform(punchPair: IPunchPair): string {
        if (!punchPair || !punchPair.attestedTime) {
            return '';
        }

        const attestedTime = punchPair.attestedTime;

        if (attestedTime.statusId === AttestedTimeStatusEnum.Approved) {
            return (
                'Tiden godkändes ' +
                Helpers.toShortDateString(attestedTime.added) +
                ' av ' +
                attestedTime.attestedByEmployee.firstName +
                ' ' +
                attestedTime.attestedByEmployee.lastName
            );
        } else if (attestedTime.statusId === AttestedTimeStatusEnum.Problem) {
            return (
                'Tiden markerades med problem ' +
                Helpers.toShortDateString(attestedTime.added) +
                ' av ' +
                attestedTime.attestedByEmployee.firstName +
                ' ' +
                attestedTime.attestedByEmployee.lastName
            );
        }

        return '';
    }
}
