<div class="container-fluid filter">
    <div class="row" *ngIf="!externalEmployeeId">
        <div class="col">
            <mat-form-field class="example-full-width">
                <mat-label>Anställd</mat-label>
                <input
                    type="text"
                    aria-label="Employee"
                    matInput
                    [formControl]="employee"
                    [matAutocomplete]="employeeAutoComplete" />
                <mat-autocomplete
                    autoActiveFirstOption
                    #employeeAutoComplete="matAutocomplete"
                    [displayWith]="displaySelectedEmployee"
                    (optionSelected)="employeeSelected($event)">
                    <mat-option *ngFor="let employee of filteredEmployees | async" [value]="employee">
                        {{employee.lastName}}, {{employee.firstName}} ({{employee.companyShort}})
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field (click)="fromDatePicker.open()">
                <mat-label>Från</mat-label>
                <input matInput [matDatepicker]="fromDatePicker" [(ngModel)]="from" readonly />
                <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #fromDatePicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field (click)="toDatePicker.open()">
                <mat-label>Till</mat-label>
                <input matInput [matDatepicker]="toDatePicker" [(ngModel)]="to" readonly />
                <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #toDatePicker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col" align="end">
            <button mat-raised-button color="primary" (click)="getPunches()">Visa</button>
        </div>
    </div>
</div>
<div class="container-fluid" *ngIf="hasSearched">
    <div class="row divider">
        <div class="col">
            <mat-divider></mat-divider>
        </div>
    </div>
    <div class="row" *ngIf="punches; else noResults;">
        <div class="col">
            <mat-accordion>
                <mat-expansion-panel *ngFor="let key of punches | arrayKeys">
                    <mat-expansion-panel-header>
                        <mat-panel-title> {{key | readableDate}} </mat-panel-title>
                        <mat-panel-description>
                            {{punches[key].length}} {{punches[key].length === 1 ? 'stämpling' : 'stämplingar'}}
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="table-wrapper">
                        <table mat-table [dataSource]="punches[key]">
                            <ng-container matColumnDef="punchTypeId">
                                <th mat-header-cell *matHeaderCellDef class="icon"></th>
                                <td
                                    mat-cell
                                    *matCellDef="let punch"
                                    class="icon"
                                    [ngClass]="{'in': punch.punchTypeId === 1, 'out': punch.punchTypeId === 2}">
                                    <mat-icon *ngIf="punch.punchTypeId === 1">arrow_forward</mat-icon>
                                    <mat-icon *ngIf="punch.punchTypeId === 2">arrow_back</mat-icon>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="punchTypeName">
                                <th mat-header-cell *matHeaderCellDef class="name">Typ</th>
                                <td mat-cell *matCellDef="let punch" class="name">
                                    <span *ngIf="punch.punchTypeId === 1">IN</span>
                                    <span *ngIf="punch.punchTypeId === 2">UT</span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="costPool">
                                <th mat-header-cell *matHeaderCellDef class="cost-pool">Avdelning</th>
                                <td mat-cell *matCellDef="let punch" class="cost-pool">
                                    {{punch.costPool ? punch.costPool.name : '-'}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="punchTime">
                                <th mat-header-cell *matHeaderCellDef class="punch-time">Tid</th>
                                <td mat-cell *matCellDef="let punch" class="punch-time">{{punch.punchTime | shortTime}}</td>
                            </ng-container>
                            <ng-container matColumnDef="remove">
                                <th mat-header-cell *matHeaderCellDef class="remove"></th>
                                <td mat-cell *matCellDef="let punch" class="remove">
                                    <mat-icon (click)="deletePunch(punch)">delete_outline</mat-icon>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="punchColumns"></tr>
                            <tr mat-row *matRowDef="let punch; columns: punchColumns;"></tr>
                        </table>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <ng-template #noResults>
        <div class="row">
            <div class="col">
                <p class="no-results">Inga stämplingar att visa</p>
            </div>
        </div>
    </ng-template>

    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
