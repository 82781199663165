import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment'

// Interfaces
import { IAbsenceType } from '@app/interfaces/absence-type.interface';
import { IEmployeeAbsence } from '@app/interfaces/employee-absence.interface';

@Injectable({ providedIn: 'root' })
export class AbsenceService {

    constructor(private http: HttpClient) { }

    getTypes(): Observable<IAbsenceType[]> {
        return this.http.get<IAbsenceType[]>(environment.apiUrl + "/absence/types");
    }

    getTypesValidForEmployee(employeeId: number): Observable<IAbsenceType[]> {
        return this.http.get<IAbsenceType[]>(environment.apiUrl + "/absence/types/for-employee/" + employeeId);
    }

    addForEmployee(absence: IEmployeeAbsence): Observable<IEmployeeAbsence[]> {
        return this.http.post<IEmployeeAbsence[]>(environment.apiUrl + "/absence/employee-absence", absence);
    }

    getByEmployeeBetweenDates(from: string, to: string, employeeId: number): Observable<IEmployeeAbsence[]> {
        return this.http.get<IEmployeeAbsence[]>(environment.apiUrl + "/absence/employee-absence/" + employeeId, {
            params: new HttpParams()
                .set('from', from)
                .set('to', to)
        });
    }

    delete(absenceId: number): Observable<any> {
        return this.http.delete<any>(environment.apiUrl + "/absence/employee-absence/" + absenceId, { });
    }
}
