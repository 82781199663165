import { formatDate } from "@angular/common";
import { UntypedFormGroup } from "@angular/forms";
import { environment } from "@env/environment";
import * as Sentry from "@sentry/browser";

export class Helpers {
    public static identityNumberRegex: RegExp =
        /^(([1][9])|([2][0]))[0-9][0-9](0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]|6[0-9]|7[0-9]|8[0-9]|9[0-1])-[0-9]{4}$/i;
    public static integerRegex: RegExp = /^[0-9]+$/i;
    public static decimalRegex: RegExp = /^[0-9]+((\.|,)[0-9]+)?$/i;
    public static shortTimeRegex: RegExp = /^([01]\d|2[0-3]):([0-5]\d)$/i;
    public static economyContactEmployeeId: number = 6682;

    public static getDayName(day: number): string {
        switch (day) {
            case 0:
                return "Söndag";

            case 1:
                return "Måndag";

            case 2:
                return "Tisdag";

            case 3:
                return "Onsdag";

            case 4:
                return "Torsdag";

            case 5:
                return "Fredag";

            case 6:
                return "Lördag";
        }
    }

    public static getMonthName(month: number): string {
        switch (month) {
            case 0:
                return "januari";

            case 1:
                return "februari";

            case 2:
                return "mars";

            case 3:
                return "april";

            case 4:
                return "maj";

            case 5:
                return "juni";

            case 6:
                return "juli";

            case 7:
                return "augusti";

            case 8:
                return "september";

            case 9:
                return "oktober";

            case 10:
                return "november";

            case 11:
                return "december";
        }
    }

    private static urlBase64Decode(str: string): any {
        let output = str.replace(/-/g, "+").replace(/_/g, "/");
        switch (output.length % 4) {
            case 0:
                break;
            case 2:
                output += "==";
                break;
            case 3:
                output += "=";
                break;
            default:
                throw "Illegal base64url string!";
        }
        return decodeURIComponent((<any>window).escape(window.atob(output)));
    }

    public static decodeToken(token: string = ""): any {
        if (token === null || token === "") {
            return { upn: "" };
        }
        const parts = token.split(".");
        if (parts.length !== 3) {
            throw new Error("JWT must have 3 parts");
        }
        const decoded = this.urlBase64Decode(parts[1]);
        if (!decoded) {
            throw new Error("Cannot decode the token");
        }
        return JSON.parse(decoded);
    }

    public static toShortDateString(dateTime: any): string {
        if (typeof dateTime === "undefined" || dateTime === null) {
            return "";
        }

        if (typeof dateTime === "string" && dateTime.length === 0) {
            return "";
        }

        return formatDate(dateTime, "yyyy-MM-dd", "se-SE");
    }

    public static toDateTimeString(dateTime: any): string {
        if (typeof dateTime === "string" && dateTime.length === 0) {
            return "";
        }

        return formatDate(dateTime, "yyyy-MM-ddTHH:mm:ss", "se-SE");
    }

    public static toShortTimeString(dateTime: any): string {
        if (dateTime === null) {
            return "";
        }

        if (typeof dateTime === "string" && dateTime.toString().length === 0) {
            return "";
        }

        if (
            dateTime.toString().length === 8 &&
            dateTime.toString().indexOf(":") > -1
        ) {
            return dateTime.toString().substr(0, 5);
        }

        if (
            dateTime.toString().length === 5 &&
            dateTime.toString().indexOf(":") > -1
        ) {
            return dateTime;
        }

        return formatDate(dateTime, "HH:mm", "se-SE");
    }

    public static getTime(): number {
        return Math.round(new Date().getTime() / 1000);
    }

    public static isDefined(input: any): boolean {
        return typeof input !== "undefined" && input !== null;
    }

    public static replaceNewLines(input: string): string {
        if (!this.isDefined(input)) {
            return input;
        }

        return input
            .replace(/\r\n/g, " ")
            .replace(/\n/g, " ")
            .replace(/\r/g, " ")
            .replace(/<br>/g, " ")
            .replace(/<br\/>/g, " ")
            .replace(/<br \/>/g, " ");
    }

    public static minutesToHours(minutes: number): number {
        return Math.ceil((minutes / 60) * 100) / 100;
        //return Math.round((minutes / 60) * 100 + Number.EPSILON) / 100;
    }

    public static toReadableTimeSpan(minutes: number): string {
        let hours = Math.floor(minutes / 60);
        minutes = minutes - hours * 60;

        if (hours > 0) {
            return (
                this.formatHours(hours.toString()) + " tim " + minutes + " min"
            );
        }

        return minutes + " min";
    }

    public static padLeft(
        input: string,
        totalLength: number,
        padChar: string = "0"
    ) {
        if (input == null || input.length >= totalLength) {
            return input;
        }

        for (let i = 0; i < totalLength - input.length; i++) {
            input = padChar + input;
        }

        return input;
    }

    private static formatHours(hours: string): string {
        if (hours.length == 9) {
            return (
                hours.substr(0, 3) +
                " " +
                hours.substr(3, 3) +
                " " +
                hours.substr(6, 3)
            );
        } else if (hours.length == 8) {
            return (
                hours.substr(0, 2) +
                " " +
                hours.substr(2, 3) +
                " " +
                hours.substr(5, 3)
            );
        } else if (hours.length == 7) {
            return (
                hours.substr(0, 1) +
                " " +
                hours.substr(1, 3) +
                " " +
                hours.substr(4, 3)
            );
        } else if (hours.length == 6) {
            return hours.substr(0, 3) + " " + hours.substr(3, 3);
        } else if (hours.length == 5) {
            return hours.substr(0, 2) + " " + hours.substr(2, 3);
        } else if (hours.length == 4) {
            return hours.substr(0, 1) + " " + hours.substr(1, 3);
        }

        return hours;
    }

    public static setFormTouched(form: UntypedFormGroup): void {
        if (!this.isDefined(form)) {
            return;
        }

        (<any>Object).values(form.controls).forEach((control: any) => {
            control.markAsTouched();
        });
    }

    public static getCurrentMonthStart(): string {
        let now = new Date(),
            y = now.getFullYear(),
            m = now.getMonth();
        return this.toShortDateString(new Date(y, m, 1));
    }

    public static getCurrentMonthEnd(): string {
        let now = new Date(),
            y = now.getFullYear(),
            m = now.getMonth();
        return this.toShortDateString(new Date(y, m + 1, 0));
    }

    public static getCurrentWeekStart(): string {
        let now = new Date(),
            day = now.getDay();
        let diff = now.getDate() - day + (day == 0 ? -6 : 1);
        return this.toShortDateString(new Date(now.setDate(diff)));
    }

    public static isValidIdentityNumber(identityNumber: string): boolean {
        if (!this.identityNumberRegex.test(identityNumber)) {
            return false;
        }

        return this.validIdentityNumberChecksum(identityNumber);
    }

    public static validIdentityNumberChecksum(identityNumber: string): boolean {
        identityNumber = identityNumber.replace("-", "");

        if (identityNumber.length === 12) {
            identityNumber = identityNumber.substr(2);
        }

        let products: string = "",
            numbers: string[] = identityNumber.split(""),
            times: number = 2,
            sum: number = 0;

        for (let i = 0; i < 9; i++) {
            products += (parseInt(numbers[i], 10) * times).toString();
            times = times === 2 ? 1 : 2;
        }

        for (let number of products.split("")) {
            sum += parseInt(number, 10);
        }

        let checksum = (10 - (sum % 10)) % 10;

        return (
            checksum ===
            parseInt(identityNumber.substr(identityNumber.length - 1, 1), 10)
        );
    }

    public static captureSentryError(message: string): void {
        if (environment.production) {
            Sentry.captureMessage(message);
        }
    }
}
