<div class="container-fluid" *ngIf="employee">
    <div class="row">
        <div class="col-6">
            <label>Namn</label>
            <span>{{employee.firstName}} {{employee.lastName}}</span>
        </div>
        <div class="col-6">
            <label>Personnummer</label>
            <span>{{employee.identityNumber | emptyReplacement}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <label>E-post</label>
            <span>{{employee.email | emptyReplacement}}</span>
        </div>
        <div class="col-6">
            <label>Mobil</label>
            <span>{{employee.mobile | emptyReplacement}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <label>Telefon</label>
            <span>{{employee.phone | emptyReplacement}}</span>
        </div>
        <div class="col-6">
            <label>Adress</label>
            <span>{{employee.address | emptyReplacement}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <label>Postnummer</label>
            <span>{{employee.zip | emptyReplacement}}</span>
        </div>
        <div class="col-6">
            <label>Postort</label>
            <span>{{employee.city | emptyReplacement}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <label>IBAN</label>
            <span>{{employee.bankName | emptyReplacement}}</span>
        </div>
        <div class="col-6">
            <label>Clearingnummer</label>
            <span>{{employee.clearingNumber | emptyReplacement}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <label>Kontonummer</label>
            <span>{{employee.bankAccount | emptyReplacement}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="container-fluid next-of-kin" *ngFor="let kin of employee.nextOfKins; let i = index">
                <div class="row">
                    <div class="col">
                        <mat-icon>group</mat-icon>
                        <strong>Närmast anhörig {{i + 1}}</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <label>Namn</label>
                        <span>{{kin.firstName}} {{kin.lastName}}</span>
                    </div>
                    <div class="col-6">
                        <label>Adress</label>
                        <span>{{kin.address | emptyReplacement}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <label>Postnummer</label>
                        <span>{{kin.zip | emptyReplacement}}</span>
                    </div>
                    <div class="col-6">
                        <label>Postort</label>
                        <span>{{kin.city | emptyReplacement}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <label>Mobil</label>
                        <span>{{kin.mobile | emptyReplacement}}</span>
                    </div>
                    <div class="col-6">
                        <label>Telefon</label>
                        <span>{{kin.phone | emptyReplacement}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Kommentar</label>
                        <span>{{kin.comment | emptyReplacement}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col" align="end">
            <button mat-button routerLink="/edit-my-details">
                <mat-icon>edit</mat-icon>
                Redigera uppgifter
            </button>
        </div>
    </div>
</div>

<div class="loading-wrapper" *ngIf="loading">
    <mat-spinner diameter="40"></mat-spinner>
</div>
