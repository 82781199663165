<h2 mat-dialog-title>Tid per kostnadsställe för {{employee.lastName}}, {{employee.firstName}}</h2>
<mat-dialog-content>
    <table mat-table [dataSource]="pools">
        <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef class="code">KST-kod</th>
            <td mat-cell *matCellDef="let pool" class="code">{{pool.costPoolCode}}</td>
        </ng-container>
        <ng-container matColumnDef="scheduled">
            <th mat-header-cell *matHeaderCellDef class="scheduled">Schemalagt</th>
            <td mat-cell *matCellDef="let pool" class="scheduled">{{pool.scheduledMinutes | readableTimeSpan}}</td>
        </ng-container>
        <ng-container matColumnDef="overtime">
            <th mat-header-cell *matHeaderCellDef class="overtime">Övertid</th>
            <td mat-cell *matCellDef="let pool" class="overtime">{{pool.overtimeMinutes | readableTimeSpan}}</td>
        </ng-container>
        <ng-container matColumnDef="ob">
            <th mat-header-cell *matHeaderCellDef class="ob">OB</th>
            <td mat-cell *matCellDef="let pool" class="ob">{{pool.unsocialHoursMinutes | readableTimeSpan}}</td>
        </ng-container>
        <ng-container matColumnDef="extra">
            <th mat-header-cell *matHeaderCellDef class="extra">Mertid</th>
            <td mat-cell *matCellDef="let pool" class="extra">{{pool.extraWorkMinutes | readableTimeSpan}}</td>
        </ng-container>
        <ng-container matColumnDef="absence">
            <th mat-header-cell *matHeaderCellDef class="absence">Frånvaro</th>
            <td mat-cell *matCellDef="let pool" class="absence">{{pool.absenceMinutes | readableTimeSpan}}</td>
        </ng-container>
        <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef class="total">Totalt</th>
            <td mat-cell *matCellDef="let pool" class="total">{{pool.total | readableTimeSpan}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="costPoolColumns"></tr>
        <tr mat-row *matRowDef="let pool; columns: costPoolColumns;"></tr>
    </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">OK</button>
</mat-dialog-actions>
