<div class="container-fluid">
    <div class="row">
        <div class="col">
            <div class="table-wrapper" *ngIf="tripsPage && tripsPage.totalItems; else noResults;">
                <table mat-table *ngIf="tripsPage" [dataSource]="tripsPage.items">
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef class="date">Datum</th>
                        <td mat-cell *matCellDef="let trip" class="date">{{trip.endDate | readableDate}}</td>
                    </ng-container>
                    <ng-container matColumnDef="car">
                        <th mat-header-cell *matHeaderCellDef class="car">Bil</th>
                        <td mat-cell *matCellDef="let trip" class="car">{{trip.car.registrationNumber}}</td>
                    </ng-container>
                    <ng-container matColumnDef="length">
                        <th mat-header-cell *matHeaderCellDef class="length">Längd</th>
                        <td mat-cell *matCellDef="let trip" class="length">{{trip.carKilometersEnd - trip.carKilometersStart}} km</td>
                    </ng-container>
                    <!--<tr mat-header-row *matHeaderRowDef="tripColumns"></tr>-->
                    <tr mat-row *matRowDef="let trip; columns: tripColumns;"
                        [routerLink]="'/driving-log/trip-history/' + trip.logId">
                    </tr>
                </table>
            </div>
            <mat-paginator *ngIf="tripsPage && tripsPage.totalItems > tripsPage.pageSize" [length]="tripsPage.totalItems"
                [pageSize]="tripsPage.pageSize" (page)="pageChanged($event)" [hidePageSize]="true" [pageIndex]="pageIndex">
            </mat-paginator>
        </div>
        <ng-template #noResults>
            <div class="col">
                <p class="no-results">Inga resor att visa</p>
            </div>
        </ng-template>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
