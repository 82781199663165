import { Pipe, PipeTransform } from '@angular/core';
import { IPunchWarning } from '@app/interfaces/punch-warning.interface';
import { Helpers } from '@app/helpers/helpers.class';
import { some } from 'lodash-es';
import { IWorkPeriod } from '@app/interfaces/work-period.interface';

@Pipe({ name: 'periodWarningsSeverityClass' })
export class PeriodWarningsSeverityClassPipe implements PipeTransform {
    transform(period: IWorkPeriod): string {
        if (!Helpers.isDefined(period) || !Helpers.isDefined(period.warnings) || period.warnings.length === 0) {
            return '';
        }

        const levelOneSeverity = some(period.warnings, function (warning: IPunchWarning) {
            return warning.severity == 1;
        });

        const levelTwoSeverity = some(period.warnings, function (warning: IPunchWarning) {
            return warning.severity == 2 && !warning.ignored;
        });

        return levelOneSeverity ? 'severity-1' : levelTwoSeverity ? 'severity-2' : '';
    }
}
