import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Helpers } from '@app/helpers/helpers.class';
import { formatDate } from '@angular/common';
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';
import { EmployeeService } from '@app/services/employee.service';
import { PunchService } from '@app/services/punch.service';
import { CostPoolService } from '@app/services/cost-pool.service';
import { IEmployeeForSelect } from '@app/interfaces/employee-for-select.interface';
import { IEmployeeShort } from '@app/interfaces/employee-short.interface';
import { IPunch } from '@app/interfaces/punch.interface';
import { ICostPool } from '@app/interfaces/cost-pool.interface';

@Component({
    selector: 'app-employee-add-punch',
    templateUrl: './employee-add-punch.partial.html',
    styleUrls: ['./employee-add-punch.partial.scss'],
})
export class EmployeeAddPunchPartial implements OnInit {
    @Input() public employeeId: number;
    @Output() public selectedEmployeeChanged = new EventEmitter<any>();

    public loading: boolean = false;
    public externalEmployeeId: boolean = false;
    public selectedEmployeeId: number;
    public addPunchForm: UntypedFormGroup;
    public employees: IEmployeeForSelect[];
    public filteredEmployees: Observable<IEmployeeForSelect[]>;
    public overtimeEmployees: IEmployeeShort[];
    public costPools: ICostPool[];

    constructor(
        private employeeService: EmployeeService,
        private punchService: PunchService,
        private costPoolService: CostPoolService,
        private formBuilder: UntypedFormBuilder,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.externalEmployeeId = Helpers.isDefined(this.employeeId) && this.employeeId > 0;

        this.addPunchForm = this.formBuilder.group({
            employee: null,
            day: [null, [Validators.required, Validators.minLength(10)]],
            time: [null, [Validators.pattern(Helpers.shortTimeRegex), Validators.required]],
            type: [1, [Validators.pattern(Helpers.integerRegex), Validators.required]],
            costPool: [null, [Validators.pattern(Helpers.integerRegex), Validators.required]],
            overtime: [false, Validators.required],
            overtimeEmployee: [null, Validators.pattern(Helpers.integerRegex)],
        });

        this.resetForm();

        this.filteredEmployees = this.f.employee.valueChanges.pipe(
            startWith(''),
            map((value) => (value !== null && value.length > 1 ? this.filter(value) : []))
        );

        this.getEmployees();
        this.getOvertimeEmployees();
        this.getCostPools();
    }

    public get f() {
        return this.addPunchForm.controls;
    }

    public selectEmployee(employeeId: number): void {
        if (!Helpers.isDefined(employeeId) || employeeId <= 0) {
            return;
        }

        if (!Helpers.isDefined(this.employees) || this.employees.length === 0) {
            return;
        }

        this.selectedEmployeeId = employeeId;

        for (let employee of this.employees) {
            if (employee.employeeId === this.selectedEmployeeId) {
                this.f.employee.setValue(employee);
                break;
            }
        }
    }

    public resetForm(): void {
        this.addPunchForm.reset();
        this.f.type.setValue(1);
        this.f.employee.setValue(null);
        this.f.costPool.setValue(null);
        this.f.day.setValue(new Date());
        this.f.time.setValue(formatDate(new Date(), 'HH:mm', 'se-SE'));
        this.f.overtime.setValue(false);
        this.f.overtimeEmployee.setValue(null);
        this.f.overtime.disable();
        this.f.overtimeEmployee.disable();
    }

    public filter(value: string): IEmployeeForSelect[] {
        const queries = value.toLowerCase().replace(/,/g, '').replace(/\(/g, '').replace(/\)/g, '').split(' ');
        return this.employees.filter((e) => {
            let allMatch = true;
            for (let i = 0; i < queries.length; i++) {
                if (queries[i].length === 0) {
                    continue;
                }
                if ((e.firstName + e.lastName + e.companyShort).toLowerCase().indexOf(queries[i]) === -1) {
                    allMatch = false;
                    break;
                }
            }
            return allMatch;
        });
    }

    public displaySelectedEmployee(employee?: IEmployeeForSelect): string | undefined {
        return employee ? employee.lastName + ', ' + employee.firstName + ' (' + employee.companyShort + ')' : undefined;
    }

    public getEmployees(): void {
        this.employeeService.getAllForSelect().subscribe({
            next: (data) => {
                this.employees = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get employees');
            },
        });
    }

    public getCostPools(): void {
        this.costPoolService.getParents().subscribe({
            next: (data) => {
                this.costPools = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get cost pools');
            },
        });
    }

    public getOvertimeEmployees(): void {
        this.employeeService.getCanOrderOvertime().subscribe({
            next: (data) => {
                this.overtimeEmployees = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get overtime employees');
            },
        });
    }

    public employeeSelected(event: any): void {
        this.selectedEmployeeId = event.option.value.employeeId;
        this.selectedEmployeeChanged.emit({
            employeeId: this.selectedEmployeeId,
            source: 'EmployeeAddPunchPartial',
        });
        this.getSuggestedCostPool();
    }

    public getSuggestedCostPool(): void {
        if (Helpers.isDefined(this.selectedEmployeeId) && this.selectedEmployeeId > 0) {
            this.costPoolService.getSuggestedPoolForEmployee(this.selectedEmployeeId).subscribe({
                next: (data) => {
                    if (Helpers.isDefined(data)) {
                        this.f.costPool.setValue(data.poolId);
                    }
                },
                error: (error) => {
                    console.error(error);
                    Helpers.captureSentryError('Could not get suggested cost pool');
                },
            });
        }
    }

    public insertPunch(): void {
        if (!this.addPunchForm.valid || !Helpers.isDefined(this.selectedEmployeeId || this.selectedEmployeeId == 0)) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Fel i formuläret',
                    message: 'Alla fält är inte korrekt ifyllda, korrigera felen och försök igen.',
                },
            });
            Helpers.setFormTouched(this.addPunchForm);
            return;
        }

        this.loading = true;

        let punch: IPunch = {
            employeeId: this.selectedEmployeeId,
            punchTypeId: this.f.type.value,
            punchTime: Helpers.toShortDateString(this.f.day.value) + 'T' + this.f.time.value + ':00',
            overtime: this.f.overtime.value,
            overtimeEmployeeId: this.f.overtimeEmployee.value,
            mayForgot: true,
            poolId: this.f.costPool.value,
        };

        this.punchService.create(punch).subscribe({
            next: (data) => {
                this.loading = false;
                //this.resetForm();
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not insert punch');
            },
        });
    }

    public punchTypeChanged(): void {
        if (this.f.type.value == 1) {
            this.f.overtime.setValue(false);
            this.f.overtime.disable();
            this.f.overtimeEmployee.setValue(null);
        } else {
            this.f.overtime.enable();
        }

        if (!this.f.overtime.value) {
            this.f.overtimeEmployee.disable();
        } else {
            this.f.overtimeEmployee.enable();
        }
    }

    public overtimeChanged(): void {
        if (!this.f.overtime.value) {
            this.f.overtimeEmployee.disable();
        } else {
            this.f.overtimeEmployee.enable();
        }
    }
}
