<app-admin-menu [loadMenu]="false"></app-admin-menu>

<h1 class="mat-headline-3">Återställ lösenord</h1>

<div class="container">
    <div class="row">
        <div class="col">
            <app-admin-reset-password
                [token]="token"></app-admin-reset-password>
        </div>
    </div>
</div>
