import { Component, OnInit, Inject } from '@angular/core';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { EmployeeLoginResetPasswordDialogComponent } from '../employee-login-reset-password-dialog/employee-login-reset-password-dialog.component';

@Component({
    selector: 'app-employee-login-password-prompt-dialog',
    templateUrl: './employee-login-password-prompt-dialog.component.html',
    styleUrls: ['./employee-login-password-prompt-dialog.component.scss'],
})
export class EmployeeLoginPasswordPromptDialogComponent {
    password: string;

    constructor(
        private dialogRef: MatDialogRef<EmployeeLoginPasswordPromptDialogComponent>,
        private dialog: MatDialog
    ) {}

    close(): void {
        this.dialogRef.close();
    }

    login(): void {
        this.dialogRef.close(this.password);
    }

    showResetPassword(): void {
        this.close();
        this.dialog.open(EmployeeLoginResetPasswordDialogComponent, {
            width: '400px',
            data: {
                passwordExpired: false,
            },
        });
    }
}
