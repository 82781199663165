import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

// Interfaces
import { IPunchSearch } from '@app/interfaces/punch-search.interface';
import { IReport } from '@app/interfaces/report.interface';
import { IWorkingEmployeesReportFilter } from '@app/interfaces/workinng-employees-report-filter.interface';
import {IStaffingCompanyEmployeesTimeSearch} from "@app/interfaces/staffing-company-employees-time.interface";

@Injectable({ providedIn: 'root' })
export class ReportService {
    constructor(private http: HttpClient) {}

    getTimePerCostPoolReport(search: IPunchSearch): Observable<IReport> {
        return this.http.post<IReport>(environment.apiUrl + '/reports/cost-pools/time-per-cost-pool', search);
    }

    getWorkingEmployeesReport(filter: IWorkingEmployeesReportFilter): Observable<IReport> {
        return this.http.post<IReport>(environment.apiUrl + '/reports/employees/working', filter);
    }

    getStaffingCompanyTimeReport(search: IStaffingCompanyEmployeesTimeSearch): Observable<HttpResponse<Blob>> {
        return this.http.post(environment.apiUrl + '/reports/staffing-companies/employee-punches', search, {
            responseType: 'blob', observe: 'response'});
    }
}
