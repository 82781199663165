<div class="container-fluid">
    <div *ngIf="schedule; else noSchedule;">
        <div class="schedule-name">
            <a [routerLink]="[]" (click)="editSchedule()">{{schedule.name}} (<i>tillåter
                    {{schedule.allowFlex ? '' : 'inte'}} flex</i>)</a>
        </div>
        <table mat-table [dataSource]="schedule.scheduleDays">
            <ng-container matColumnDef="weekday">
                <td mat-cell *matCellDef="let day" class="weekday">{{day.dayName}}</td>
            </ng-container>
            <ng-container matColumnDef="periods">
                <td mat-cell *matCellDef="let day" class="periods">
                    <ng-container *ngFor="let period of day.periods">
                        <span>{{(period.fromHour | shortTime)}} - {{(period.toHour | shortTime)}}</span>
                    </ng-container>
                </td>
            </ng-container>
            <ng-container matColumnDef="breakLength">
                <td mat-cell *matCellDef="let day" class="break-length">{{day.totalBreakLength}} min rast</td>
            </ng-container>
            <tr mat-row *matRowDef="let day; columns: scheduleDayColumns;"></tr>
        </table>
    </div>

    <ng-template #noSchedule>
        <div class="no-schedule">
            Inget aktivt schema
        </div>
    </ng-template>

    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
