import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Helpers } from '@app/helpers/helpers.class';
import { Observable } from 'rxjs';

// Error handling
import * as Sentry from '@sentry/browser';

// Services
import { TravelCompensationService } from '@app/services/travel-compensation.service';
import { EmployeeService } from '@app/services/employee.service';

// Interfaces
import { ITravelCompensation } from '@app/interfaces/travel-compensation.interface';
import { IEmployeeForSelect } from '@app/interfaces/employee-for-select.interface';
import { UntypedFormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';

@Component({
    selector: 'app-employee-travel-compensations',
    templateUrl: './employee-travel-compensations.partial.html',
    styleUrls: ['./employee-travel-compensations.partial.scss'],
})
export class EmployeeTravelCompensationsPartial implements OnInit {
    @Input() public employeeId: number;
    @Input() public data: ITravelCompensation[];
    @Input() public useExternalData: boolean;
    @Output() public selectedEmployeeChanged = new EventEmitter<any>();

    public loading: boolean = false;
    public compensations: ITravelCompensation[];
    public compensationColumns: string[] = ['day', 'distance', 'description', 'company', 'paid'];
    public from: string;
    public to: string;
    public externalEmployeeId: boolean;
    public selectedEmployeeId: number;
    public employees: IEmployeeForSelect[];
    public filteredEmployees: Observable<IEmployeeForSelect[]>;
    public employee: UntypedFormControl = new UntypedFormControl();

    constructor(private compensationService: TravelCompensationService, private employeeService: EmployeeService) {}

    public ngOnInit(): void {
        this.from = Helpers.getCurrentMonthStart();
        this.to = Helpers.getCurrentMonthEnd();

        this.externalEmployeeId = Helpers.isDefined(this.employeeId) && this.employeeId > 0;

        if (this.externalEmployeeId) {
            this.selectedEmployeeId = this.employeeId;
        }

        if (!this.useExternalData && this.externalEmployeeId) {
            this.update();
        } else if (this.data) {
            this.compensations = this.data;
        }

        this.filteredEmployees = this.employee.valueChanges.pipe(
            startWith(''),
            map((value) => (value !== null && value.length > 1 ? this.filter(value) : []))
        );

        this.getEmployees();
    }

    public externalDataLoaded(externalData: ITravelCompensation[]): void {
        this.compensations = externalData;
        this.loading = false;
    }

    public update(): void {
        this.getCompensations();
    }

    public getCompensations(): void {
        this.loading = true;
        this.compensationService
            .getByEmployeeBetweenDates(
                Helpers.toShortDateString(this.from),
                Helpers.toShortDateString(this.to),
                this.selectedEmployeeId
            )
            .subscribe({
                next: (data) => {
                    this.loading = false;
                    if (data.length > 0) {
                        this.compensations = data;
                    }
                },
                error: (error) => {
                    console.error(error);
                    this.loading = false;
                    Helpers.captureSentryError('Could not get compensations');
                },
            });
    }

    public displaySelectedEmployee(employee?: IEmployeeForSelect): string | undefined {
        return employee ? employee.lastName + ', ' + employee.firstName + ' (' + employee.companyShort + ')' : undefined;
    }

    public filter(value: string): IEmployeeForSelect[] {
        const queries = value.toLowerCase().replace(/,/g, '').replace(/\(/g, '').replace(/\)/g, '').split(' ');
        return this.employees.filter((e) => {
            let allMatch = true;
            for (let i = 0; i < queries.length; i++) {
                if (queries[i].length === 0) {
                    continue;
                }
                if ((e.firstName + e.lastName + e.companyShort).toLowerCase().indexOf(queries[i]) === -1) {
                    allMatch = false;
                    break;
                }
            }
            return allMatch;
        });
    }

    public getEmployees(): void {
        if (this.externalEmployeeId) {
            return;
        }

        this.employeeService.getAllForSelect().subscribe({
            next: (data) => {
                this.employees = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get employees');
            },
        });
    }

    public employeeSelected(event: any): void {
        this.selectedEmployeeId = event.option.value.employeeId;
        this.selectedEmployeeChanged.emit({
            employeeId: this.selectedEmployeeId,
            source: 'EmployeeTravelCompensationsPartial',
        });
    }

    public selectEmployee(employeeId: number): void {
        if (!Helpers.isDefined(employeeId) || employeeId <= 0) {
            return;
        }

        if (!Helpers.isDefined(this.employees) || this.employees.length === 0) {
            return;
        }

        this.selectedEmployeeId = employeeId;

        for (let employee of this.employees) {
            if (employee.employeeId === this.selectedEmployeeId) {
                this.employee.setValue(employee);
                break;
            }
        }
    }
}
