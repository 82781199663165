<h2 mat-dialog-title>Ange lösenord</h2>
<mat-dialog-content>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <p>Du måste verifiera dig med ditt lösenord för att fortsätta</p>
                <mat-form-field>
                    <mat-label>Lösenord</mat-label>
                    <input
                        matInput
                        type="password"
                        autocomplete="off"
                        [(ngModel)]="password"
                        (keydown.enter)="login()"
                        name="remoteLoginPassword" />
                </mat-form-field>
            </div>
        </div>
        <div class="row reset-password">
            <div class="col">
                <p>
                    Om du inte fått ett lösenord, eller om du glömt det, kan du få ett nytt genom att
                    <a [routerLink]="[]" (click)="showResetPassword()">återställa lösenordet</a>.
                </p>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">Avbryt</button>
    <button mat-raised-button color="primary" (click)="login()">OK</button>
</mat-dialog-actions>
