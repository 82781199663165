import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from "@angular/router";

// Dialogs
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';

// Interfaces
import { IEmployeeShort } from '@app/interfaces/employee-short.interface';
import { Helpers } from '@app/helpers/helpers.class';

@Component({
    selector: 'app-register-page',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit {
    companyId: number = 1;
    employmentTypeId: number = 4;

    constructor(private dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private router: Router) {

    }

    ngOnInit(): void {
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);

        if (Helpers.isDefined(params['companyId'])) {
            this.companyId = parseInt(params['companyId'], 10);
        }

        if (Helpers.isDefined(params['employmentTypeId'])) {
            this.employmentTypeId = parseInt(params['employmentTypeId'], 10);
        }
    }

    saved(employee: IEmployeeShort): void {
        let handle = this.dialog.open(AlertDialog,
            {
                width: '400px',
                data: {
                    title: 'Välkommen, ' + employee.firstName + '!',
                    message: 'Du är nu registrerad och kan logga in i stämpelklockan. ' +
                        'Din inloggnigskod är ' + employee.login
                }
            }
        );

        handle.afterClosed().subscribe(result => {
            this.router.navigate(['/login']);
        });
    }
}
