<div class="card-wrapper">
    <mat-card class="compact" *ngIf="clientAuthorizedResponse" [formGroup]="accessForm">
        <div
            class="request-access"
            *ngIf="clientAuthorizedResponse.isAuthorized === false && !clientAuthorizedResponse.isHandled">
            <mat-card-title>
                <mat-icon>lock</mat-icon>
                Begär åtkomst till Stämpelklockan
            </mat-card-title>
            <mat-card-content>
                <div class="container-fluid">
                    <div class="row description-row">
                        <div class="col">
                            Denna enhet har inte åtkomst till Stämpelklockan. Om den borde ha det så kan du begära åtkomst genom
                            att fylla i formuläret nedan. Förfrågan godkänns eller nekas sedan av en administratör. Efter att den
                            blivit godkänd så kan enheten komma åt Stämpelklockan.
                        </div>
                    </div>
                    <div class="row has-pending-request-row" *ngIf="clientAuthorizedResponse.hasPendingRequest">
                        <div class="col">
                            <div class="alert">
                                <div class="alert-icon">
                                    <mat-icon>schedule</mat-icon>
                                </div>
                                <div class="alert-message">
                                    En förfrågan om åtkomst har skickats in för denna enhet men ännu inte blivit behandlad av en
                                    administratör. Referensnummer: {{ clientAuthorizedResponse.requestReferenceNumber }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!clientAuthorizedResponse.hasPendingRequest">
                        <div class="row">
                            <div class="col col-md">
                                <mat-form-field>
                                    <mat-label>Din inloggningskod</mat-label>
                                    <input matInput type="password" autocomplete="off" formControlName="loginCode" required />
                                </mat-form-field>
                            </div>
                            <div class="col col-md">
                                <mat-form-field>
                                    <mat-label>Ditt lösenord</mat-label>
                                    <input matInput type="password" autocomplete="off" formControlName="password" required />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <mat-form-field>
                                    <mat-label>Enhetens plats/anledning till åtkomst</mat-label>
                                    <textarea
                                        matInput
                                        autocomplete="off"
                                        formControlName="description"
                                        rows="5"
                                        required></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row row-buttons">
                            <div class="col">
                                <button mat-raised-button color="primary" (click)="sendAccessRequest()">Begär åtkomst</button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </div>
        <div class="access-denied" *ngIf="clientAuthorizedResponse.isAuthorized === false && clientAuthorizedResponse.isHandled">
            <mat-card-title>
                <mat-icon>lock</mat-icon>
                Åtkomst till Stämpelklockan nekad
            </mat-card-title>
            <mat-card-content>
                <div class="container-fluid">
                    <div class="row description-row">
                        <div class="col">
                            Den här enheten har nekats åtkomst till Stämpelklockan. Om detta är felaktigt kan du kontakta HR och
                            uppge referensnummer: {{ clientAuthorizedResponse.requestReferenceNumber }}
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </div>
        <div class="loading-wrapper" *ngIf="loading">
            <mat-spinner diameter="40"></mat-spinner>
        </div>
    </mat-card>
    <button class="whistle-blower" mat-stroked-button color="default" (click)="showWhistleblowerInfo()">
        <mat-icon>campaign</mat-icon> Visselblåsning
    </button>
</div>
