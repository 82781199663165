import { Component, OnInit } from '@angular/core';

// Services
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'app-admin-claim-settings-admin-page',
    templateUrl: './admin-claim-settings.component.html',
    styleUrls: ['./admin-claim-settings.component.scss']
})

export class AdminClaimSettingsAdminComponent implements OnInit {
    public employeeId: number;

    constructor(private authService: AuthService) { }

    ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
    }
}
