import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';

@Pipe({ name: 'scheduleDayPeriods' })
export class ScheduleDayPeriodsPipe implements PipeTransform {
    transform(scheduleDay: UntypedFormGroup): any[] {
        let periods: UntypedFormArray = scheduleDay.get('periods') as UntypedFormArray;
        return periods.controls;
    }
}
