<main role="main">
    <mat-card class="login-wrapper">
        <form [formGroup]="loginForm" (ngSubmit)="login()">
            <mat-toolbar color="primary">
                <mat-toolbar-row>
                    <span>Stämpelklockan - logga in</span>
                </mat-toolbar-row>
            </mat-toolbar>
            <mat-card-content>
                <div class="row">
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Användarnamn</mat-label>
                            <input
                                matInput
                                formControlName="username"
                                name="stampelklockanLoginUsername"
                                required />
                            <mat-error *ngIf="submitted && f.username.hasError('required')">
                                Du måste ange ett användarnamn
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Lösenord</mat-label>
                            <input
                                type="password"
                                matInput
                                formControlName="password"
                                name="stampelklockanLoginPassword"
                                required />
                            <mat-error *ngIf="submitted && f.password.hasError('required')">
                                Du måste ange ett lösenord
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col button-wrapper">
                        <button mat-raised-button color="primary" mat-button>Logga in</button>
                    </div>
                </div>
            </mat-card-content>
        </form>
    </mat-card>
    <div class="inactivated-accounts-message alert">
        <div class="alert-icon">
            <mat-icon>info</mat-icon>
        </div>
        <div class="alert-message">
            Av säkerhetskäl har många konton inaktiverats. Om du inte längre kan logga in, men anser att du borde det, kontakta
            HR.
        </div>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</main>
