import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Helpers } from '@app/helpers/helpers.class';
import { MatDialog } from '@angular/material/dialog';
import { CompanyService } from '@app/services/company.service';
import { TravelCompensationService } from '@app/services/travel-compensation.service';
import { ICompany } from '@app/interfaces/company.interface';
import { ITravelCompensation } from '@app/interfaces/travel-compensation.interface';
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';

@Component({
    selector: 'app-report-milage',
    templateUrl: './report-milage.partial.html',
    styleUrls: ['./report-milage.partial.scss'],
})
export class ReportMilagePartial implements OnInit {
    @Input() employeeId: number;
    @Output() compensationAdded = new EventEmitter<ITravelCompensation>();

    loading: boolean = false;
    milageForm: UntypedFormGroup;
    companies: ICompany[];

    constructor(
        private companyService: CompanyService,
        private travelCompensationService: TravelCompensationService,
        private formBuilder: UntypedFormBuilder,
        public dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.getCompanies();

        this.milageForm = this.formBuilder.group({
            day: ['', Validators.required],
            distance: ['', [Validators.required, Validators.pattern(Helpers.integerRegex)]],
            company: ['', Validators.required],
            description: ['', Validators.required],
        });

        this.milageForm.get('day').disable();
        this.resetForm();
    }

    getCompanies(): void {
        this.companyService.getAllActive().subscribe({
            next: (data) => {
                this.companies = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get companies');
            },
        });
    }

    resetForm(): void {
        this.milageForm.reset();
        this.milageForm.get('day').setValue(new Date());
        this.milageForm.get('company').setValue(1);
    }

    save(): void {
        if (!this.milageForm.valid) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Fel i formuläret',
                    message: 'Alla fält är inte korrekt ifyllda, korrigera felen och försök igen.',
                },
            });
            return;
        }

        let compensation: ITravelCompensation = {
            travelId: null,
            added: null,
            employeeId: this.employeeId,
            kilometers: this.milageForm.get('distance').value,
            description: this.milageForm.get('description').value,
            paid: false,
            travelDate: Helpers.toShortDateString(this.milageForm.get('day').value),
            companyId: this.milageForm.get('company').value,
            payDate: null,
        };

        console.log(compensation);

        this.loading = true;

        this.travelCompensationService.add(compensation).subscribe({
            next: (data) => {
                this.resetForm();
                this.compensationAdded.emit(data);
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;

                let title = 'Fel uppstod';
                let message =
                    'Något gick fel när milrapporteringen skulle sparas, ' +
                    'kontrollera att allt är korrekt ifyllt och försök igen';
                let errors: string[];

                if (error.status === 400 && error.error.errors) {
                    message = 'Informationen du angav var felaktig, ' + 'kontrollera meddeladena nedan och försök igen.';
                    errors = error.error.errors;
                } else {
                    Helpers.captureSentryError('Could not create travel compensation');
                }

                this.dialog.open(AlertDialog, {
                    width: '400px',
                    data: {
                        title: title,
                        message: message,
                        list: errors,
                    },
                });
            },
        });
    }
}
