import { Component, OnInit, Input } from '@angular/core';

// Error handling
import * as Sentry from '@sentry/browser';

// Services
import { CarService } from '@app/services/car.service';

// Interfaces
import { ICar } from '@app/interfaces/car.interface';
import { Helpers } from '@app/helpers/helpers.class';

@Component({
    selector: 'app-driving-log-cars',
    templateUrl: './driving-log-cars.partial.html',
    styleUrls: ['./driving-log-cars.partial.scss'],
})
export class DrivingLogCarsPartial implements OnInit {
    @Input() public employeeId: number;

    public loading: boolean = false;
    public cars: ICar[];
    public carColumns: string[] = ['registrationNumber', 'description', 'type'];

    constructor(private carService: CarService) {}

    public ngOnInit(): void {
        this.getCars();
    }

    public getCars(): void {
        this.loading = true;
        this.carService.getByEmployee(this.employeeId).subscribe({
            next: (data) => {
                this.cars = data;
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get cars');
                this.loading = false;
            },
        });
    }
}
