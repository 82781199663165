import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { Helpers } from '@app/helpers/helpers.class';

@Pipe({name: 'verboseDateTime'})
export class VerboseDateTimePipe implements PipeTransform {
    transform(dateTime: string): string {
        const now = new Date();
        const date = new Date(dateTime);
        const day = Helpers.getDayName(date.getDay());

        if(now.getFullYear() === date.getFullYear() &&
            now.getMonth() === date.getMonth() &&
            now.getDate() == date.getDate()) {
            return 'Idag ' + formatDate(dateTime, 'HH:mm', 'se-SE');
        } else if (now.getFullYear() === date.getFullYear() &&
            now.getMonth() === date.getMonth() &&
            (now.getDate() - date.getDate()) < 7) {
            return Helpers.getDayName(date.getDay()) + ' ' + formatDate(dateTime, 'HH:mm', 'se-SE');
        } else if (now.getFullYear() === date.getFullYear()) {
            return Helpers.getDayName(date.getDay()).substr(0, 3) + ' ' + date.getDate() + ' ' +
                Helpers.getMonthName(date.getMonth()).toLowerCase().substr(0, 3)  + ', ' +
                formatDate(dateTime, 'HH:mm', 'se-SE');
        } else {
            return date.getDate() + ' ' +
                Helpers.getMonthName(date.getMonth()).toLowerCase().substr(0, 3) + ' ' +
                date.getFullYear() + ', ' +
                formatDate(dateTime, 'HH:mm', 'se-SE');
        }
    }
}
