<div class="container-fluid">
    <div class="row filters">
        <!--<div class="col company-filter">
            <label>Bolag</label>
            <mat-form-field>
                <mat-label>Välj bolag</mat-label>
                <mat-select [(ngModel)]="filterCompanyIds" multiple>
                    <mat-option *ngFor="let company of companies" [value]="company.companyId">{{company.companyName}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>-->
        <div class="col car-filter">
            <label>Bil</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filterCarIds" placeholder="(Välj bil)" multiple>
                    <mat-option *ngFor="let car of cars" [value]="car.carId"
                        >{{car.registrationNumber}} ({{car.description}})</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>
        <!--<div class="col employee-filter">
            <label>Anställd</label>
            <mat-form-field>
                <mat-label>Välj anställd</mat-label>
                <mat-select [(ngModel)]="filterEmployeeIds" multiple>
                    <mat-option *ngFor="let employee of employees" [value]="employee.employeeId">{{employee.lastName}}, {{employee.firstName}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>-->
        <div class="col start-date-filter">
            <label>Resan startade</label>
            <div class="date-range">
                <mat-form-field class="from" (click)="pickerFrom.open()">
                    <input matInput [matDatepicker]="pickerFrom" [(ngModel)]="filterFrom" placeholder="Från" readonly />
                    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFrom disabled="false"></mat-datepicker>
                </mat-form-field>
                <span>-</span>
                <mat-form-field class="to" (click)="pickerTo.open()">
                    <input matInput [matDatepicker]="pickerTo" [(ngModel)]="filterTo" placeholder="Till" readonly />
                    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                    <mat-datepicker #pickerTo disabled="false"></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="col button-filter">
            <button mat-raised-button (click)="getDrivingLogsPage(1)" color="primary">Sök</button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="divider-wrapper">
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col" *ngIf="drivingLogsPage && drivingLogsPage.totalItems; else noResults;">
            <mat-paginator
                *ngIf="drivingLogsPage && drivingLogsPage.totalItems > drivingLogsPage.pageSize"
                [length]="drivingLogsPage.totalItems"
                [pageSize]="drivingLogsPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
            <div class="table-wrapper">
                <table mat-table *ngIf="drivingLogsPage" [dataSource]="drivingLogsPage.items">
                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef class="type"></th>
                        <td mat-cell *matCellDef="let log" class="type">
                            <ng-container *ngIf="log.private; else workTrip">
                                <mat-icon matTooltip="Privat resa">home</mat-icon>
                            </ng-container>
                            <ng-template #workTrip>
                                <mat-icon matTooltip="Tjänsteresa">business</mat-icon>
                            </ng-template>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef class="date">Datum</th>
                        <td mat-cell *matCellDef="let log" class="date">{{log.startDate | shortDate}}</td>
                    </ng-container>
                    <ng-container matColumnDef="company">
                        <th mat-header-cell *matHeaderCellDef class="company">Bolag</th>
                        <td mat-cell *matCellDef="let log" class="company">
                            <ng-container *ngIf="log.private; else companyTrip"> - </ng-container>
                            <ng-template #companyTrip> {{log.company.companyShort}} </ng-template>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="car">
                        <th mat-header-cell *matHeaderCellDef class="car">Bil</th>
                        <td mat-cell *matCellDef="let log" class="car">{{log.car.registrationNumber}}</td>
                    </ng-container>
                    <ng-container matColumnDef="mileage">
                        <th mat-header-cell *matHeaderCellDef class="mileage">Mätarställning</th>
                        <td mat-cell *matCellDef="let log" class="mileage">
                            <ng-container *ngIf="log.endDate; else notEnded">
                                {{log.carKilometersStart}} - {{log.carKilometersEnd}}
                            </ng-container>
                            <ng-template #notEnded> {{log.carKilometersStart}} </ng-template>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="tripLength">
                        <th mat-header-cell *matHeaderCellDef class="trip-length">Resans längd</th>
                        <td mat-cell *matCellDef="let log" class="trip-length">
                            <ng-container *ngIf="log.endDate; else notEnded">
                                {{log.carKilometersEnd - log.carKilometersStart}} km
                            </ng-container>
                            <ng-template #notEnded> - </ng-template>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="employee">
                        <th mat-header-cell *matHeaderCellDef class="employee">Förare</th>
                        <td mat-cell *matCellDef="let log" class="employee">
                            {{log.employee.lastName}}, {{log.employee.firstName}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef class="description">Syfte</th>
                        <td mat-cell *matCellDef="let log" class="description">{{log.description}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="drivingLogsColumns"></tr>
                    <tr mat-row *matRowDef="let log; columns: drivingLogsColumns;"></tr>
                </table>
            </div>
            <div class="page-summary container-fluid">
                <div class="total row" *ngFor="let total of drivingLogsPage.metaData.totals">
                    <div class="col">&nbsp;</div>
                    <div class="col title">
                        <ng-container *ngIf="total.private; else notPrivate">
                            {{total.employee.lastName}}, {{total.employee.firstName}} - {{total.car.registrationNumber}} (privat)
                        </ng-container>
                        <ng-template #notPrivate>
                            {{total.employee.lastName}}, {{total.employee.firstName}} - {{total.car.registrationNumber}}
                            ({{total.company.companyShort}})
                        </ng-template>
                    </div>
                    <div class="col kilometers">
                        <strong>{{total.totalKilometers}} km</strong>
                    </div>
                </div>
            </div>
            <mat-paginator
                *ngIf="drivingLogsPage && drivingLogsPage.totalItems > drivingLogsPage.pageSize"
                [length]="drivingLogsPage.totalItems"
                [pageSize]="drivingLogsPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
        </div>
        <ng-template #noResults>
            <div class="col">
                <p class="no-results">Inga händelser att visa</p>
            </div>
        </ng-template>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
