<h2 mat-dialog-title>Val för varning</h2>
<mat-dialog-content>
    <div class="wrapper">
        <p>"{{warning.description}}"</p>
        <mat-radio-group [(ngModel)]="selectedAction">
            <mat-radio-button value="1" *ngIf="warning.ignorable">Ignorera varningen, allt är korrekt</mat-radio-button>
            <mat-radio-button value="2" *ngIf="warning.canBeFixedWithAbsence">Lägg in frånvaro</mat-radio-button>
            <mat-radio-button value="3">Skicka meddelande till Lön</mat-radio-button>
        </mat-radio-group>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">Avbryt</button>
    <button mat-raised-button (click)="closeWithAction()" color="primary">OK</button>
</mat-dialog-actions>
