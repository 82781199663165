import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { Helpers } from '@app/helpers/helpers.class';

// Error handling
import * as Sentry from '@sentry/browser';

// Services
import { EmployeeService } from '@app/services/employee.service';
import { CompanyService } from '@app/services/company.service';

// Dialogs
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';
import { ConfirmDialog } from '@app/shared/dialogs/confirm/confirm.dialog';

// Interfaces
import { IEmployeeBasic } from '@app/interfaces/employee-basic.interface';
import { IEmployeeCostPoolHandledByEmployee } from '@app/interfaces/employee-cost-pool-handled-by-employee.interface';
import { IPage } from '@app/interfaces/page.interface';
import { ICompany } from '@app/interfaces/company.interface';
import { IEmployeeCostPoolHandledBy } from '@app/interfaces/employee-cost-pool-handled-by.interface';

@Component({
    selector: 'app-employee-cost-pool-handled-by',
    templateUrl: './employee-cost-pool-handled-by.partial.html',
    styleUrls: ['./employee-cost-pool-handled-by.partial.scss'],
})
export class EmployeeCostPoolHandledByPartial implements OnInit {
    @Output() filterChanged: EventEmitter<any> = new EventEmitter();
    @Input('companyId') inputCompanyId: number;
    @Input('status') inputStatus: number;
    @Input('page') inputPage: number;

    companies: ICompany[];
    employeesPage: IPage<any>;
    employeeColumns: string[] = ['checkbox', 'name', 'company', 'department', 'handledBy'];
    optionEmployees: IEmployeeBasic[];
    filterCompanyId: number = 0;
    filterStatus: number = 2;
    pageIndex: number = 0;
    loading: boolean = false;
    selection: SelectionModel<IEmployeeCostPoolHandledByEmployee> = new SelectionModel<IEmployeeCostPoolHandledByEmployee>(true);
    dataSource: MatTableDataSource<IEmployeeCostPoolHandledByEmployee>;

    constructor(private employeeService: EmployeeService, private companyService: CompanyService, private dialog: MatDialog) {}

    ngOnInit(): void {
        if (Helpers.isDefined(this.inputCompanyId)) {
            this.filterCompanyId = this.inputCompanyId;
        }

        if (Helpers.isDefined(this.inputStatus)) {
            this.filterStatus = this.inputStatus;
        }

        if (Helpers.isDefined(this.inputPage)) {
            this.pageIndex = this.inputPage - 1;
        }

        this.getOptionEmployees();
        this.getCompanies();
        this.getEmployeesPage(this.pageIndex + 1);
    }

    getEmployeesPage(page: number): void {
        this.loading = true;
        this.employeeService.getCostPoolHandledByEmployees(this.filterStatus, this.filterCompanyId, page).subscribe({
            next: (data) => {
                this.employeesPage = data;
                this.dataSource = new MatTableDataSource<IEmployeeCostPoolHandledByEmployee>(this.employeesPage.items);
                this.selection.clear();
                this.loading = false;
            },
            error: (error) => {
                this.loading = false;
                console.error(error);
                Helpers.captureSentryError('Could not get employee page');
            },
        });

        let filterSettings = {
            status: this.filterStatus,
            companyId: this.filterCompanyId,
            page: page,
        };

        this.filterChanged.emit(filterSettings);
    }

    getCompanies(): void {
        this.companyService.getAllActive().subscribe({
            next: (data) => {
                this.companies = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get companies');
            },
        });
    }

    getOptionEmployees() {
        this.employeeService.getByTitle('driftchef').subscribe({
            next: (data) => {
                this.optionEmployees = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get option employees');
            },
        });
    }

    pageChanged(event: any): void {
        this.getEmployeesPage(event.pageIndex + 1);
        this.pageIndex = event.pageIndex;
    }

    setHandledBy(handledByEmployee: IEmployeeBasic): void {
        let selectedEmployees = this.selection.selected;

        if (selectedEmployees.length === 0) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Inga valda',
                    message: 'Du har inte valt några anställda att koppla',
                },
            });

            return;
        }

        let message = '';

        if (handledByEmployee === null) {
            message = 'Vill du ta bort hanterare för de ' + selectedEmployees.length + 'st valda anställda nedan?';
        } else {
            message =
                'Vill du att ' +
                handledByEmployee.firstName +
                ' ska hantera kostnadsställen för de ' +
                selectedEmployees.length +
                'st valda anställda nedan?';
        }

        let handle = this.dialog.open(ConfirmDialog, {
            width: '400px',
            data: {
                title: 'Fortsätta?',
                message: message,
            },
        });

        handle.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.loading = true;
                let data: IEmployeeCostPoolHandledBy[] = [];

                for (let employee of selectedEmployees) {
                    data.push({
                        employeeId: employee.employeeId,
                        handledByEmployeeId: handledByEmployee == null ? 0 : handledByEmployee.employeeId,
                    });
                }

                this.employeeService.setCostPoolHandledBy(data).subscribe({
                    next: (data) => {
                        this.getEmployeesPage(1);
                    },
                    error: (error) => {
                        this.loading = false;
                        console.error(error);
                        Helpers.captureSentryError('Could not set cost pool handled by');
                    },
                });
            }
        });
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
    }
}
