import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'isEmpty'})
export class IsEmptyPipe implements PipeTransform {
    transform(input: any): boolean {
        if(typeof input === 'undefined' || input === null ||
            input.length === 0 || Object.getOwnPropertyNames(input).length === 0) {
            return true;
        }

        return false;
    }
}
