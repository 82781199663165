<div class="container-fluid" [formGroup]="addPunchForm">
    <div class="row">
        <div class="col">
            <mat-radio-group formControlName="type" (change)="punchTypeChanged()">
                <mat-radio-button [value]="1">In</mat-radio-button>
                <mat-radio-button [value]="2">Ut</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="row" *ngIf="!externalEmployeeId">
        <div class="col">
            <mat-form-field class="example-full-width">
                <mat-label>Anställd</mat-label>
                <input
                    type="text"
                    aria-label="Employee"
                    matInput
                    formControlName="employee"
                    [matAutocomplete]="employeeAutoComplete"
                    required />
                <mat-autocomplete
                    autoActiveFirstOption
                    #employeeAutoComplete="matAutocomplete"
                    [displayWith]="displaySelectedEmployee"
                    (optionSelected)="employeeSelected($event)">
                    <mat-option *ngFor="let employee of filteredEmployees | async" [value]="employee">
                        {{employee.lastName}}, {{employee.firstName}} ({{employee.companyShort}})
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Kostnadsställe</mat-label>
                <mat-select formControlName="costPool" required>
                    <mat-option [value]="0"> (Vet ej) </mat-option>
                    <mat-option *ngFor="let pool of costPools" [value]="pool.poolId"> {{pool.name}} </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Dag</mat-label>
                <input matInput [matDatepicker]="dayPicker" formControlName="day" required readonly />
                <mat-datepicker-toggle color="primary" matSuffix [for]="dayPicker"></mat-datepicker-toggle>
                <mat-datepicker #dayPicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field>
                <mat-label>Tidpunkt</mat-label>
                <input type="time" matInput formControlName="time" required />
            </mat-form-field>
        </div>
    </div>
    <div class="row overtime">
        <div class="col checkbox">
            <mat-checkbox formControlName="overtime" (change)="overtimeChanged()">Övertid</mat-checkbox>
        </div>
        <div class="col">
            <mat-form-field>
                <mat-label>Beordrat av</mat-label>
                <mat-select formControlName="overtimeEmployee">
                    <mat-option *ngFor="let employee of overtimeEmployees" [value]="employee.employeeId">
                        {{employee.lastName}}, {{employee.firstName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col" align="end">
            <button mat-raised-button color="primary" (click)="insertPunch()">Lägg in stämpling</button>
        </div>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
