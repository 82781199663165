import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Helpers } from '@app/helpers/helpers.class';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AbsenceService } from '@app/services/absence.service';
import { EmployeeService } from '@app/services/employee.service';
import { AuthService } from '@app/services/auth.service';
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';
import { IEmployeeForSelect } from '@app/interfaces/employee-for-select.interface';
import { IAbsenceType } from '@app/interfaces/absence-type.interface';
import { IEmployeeAbsence } from '@app/interfaces/employee-absence.interface';

@Component({
    selector: 'app-employee-add-absence',
    templateUrl: './employee-add-absence.partial.html',
    styleUrls: ['./employee-add-absence.partial.scss'],
})
export class EmployeeAddAbsencePartial implements OnInit {
    @Input() public employeeId: number;
    @Output() public selectedEmployeeChanged = new EventEmitter<any>();

    public externalEmployeeId: boolean = false;
    public employee: UntypedFormControl = new UntypedFormControl();
    public employees: IEmployeeForSelect[];
    public filteredEmployees: Observable<IEmployeeForSelect[]>;
    public selectedEmployeeId: number;
    public addAbsenceFormSingle: UntypedFormGroup;
    public addAbsenceFormMulti: UntypedFormGroup;
    public mode: number = 1;
    public types: IAbsenceType[];
    public loading: boolean = false;

    constructor(
        private absenceService: AbsenceService,
        private employeeService: EmployeeService,
        private authService: AuthService,
        private formBuilder: UntypedFormBuilder,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.externalEmployeeId = Helpers.isDefined(this.employeeId) && this.employeeId > 0;

        if (this.externalEmployeeId) {
            this.selectedEmployeeId = this.employeeId;
        }

        this.addAbsenceFormSingle = this.formBuilder.group({
            day: ['', Validators.required],
            fromTime: ['', [Validators.required, Validators.pattern(Helpers.shortTimeRegex)]],
            toTime: ['', [Validators.required, Validators.pattern(Helpers.shortTimeRegex)]],
            type: ['', Validators.required],
            comment: '',
        });

        this.addAbsenceFormMulti = this.formBuilder.group({
            fromDate: ['', Validators.required],
            toDate: ['', Validators.required],
            type: ['', Validators.required],
            comment: '',
        });

        this.resetForms();

        this.filteredEmployees = this.employee.valueChanges.pipe(
            startWith(''),
            map((value) => (value !== null && value.length > 1 ? this.filter(value) : []))
        );

        this.getEmployees();
        this.getTypes();
    }

    public resetForms(): void {
        this.addAbsenceFormSingle.reset();
        this.addAbsenceFormMulti.reset();
        this.addAbsenceFormSingle.get('day').setValue(new Date());
        this.addAbsenceFormMulti.get('fromDate').setValue(new Date());
        this.addAbsenceFormMulti.get('toDate').setValue(new Date());
    }

    public employeeSelected(event: any): void {
        this.selectedEmployeeId = event.option.value.employeeId;
        this.selectedEmployeeChanged.emit({
            employeeId: this.selectedEmployeeId,
            source: 'EmployeeAddAbsencePartial',
        });
    }

    public displaySelectedEmployee(employee?: IEmployeeForSelect): string | undefined {
        return employee ? employee.lastName + ', ' + employee.firstName + ' (' + employee.companyShort + ')' : undefined;
    }

    public getEmployees(): void {
        this.employeeService.getAllForSelect().subscribe({
            next: (data) => {
                this.employees = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get employees');
            },
        });
    }

    public selectEmployee(employeeId: number): void {
        if (!Helpers.isDefined(employeeId) || employeeId <= 0) {
            return;
        }

        if (!Helpers.isDefined(this.employees) || this.employees.length === 0) {
            return;
        }

        this.selectedEmployeeId = employeeId;

        for (let employee of this.employees) {
            if (employee.employeeId === this.selectedEmployeeId) {
                this.employee.setValue(employee);
                break;
            }
        }
    }

    public getTypes(): void {
        if (this.authService.isAdminMode()) {
            this.absenceService.getTypes().subscribe({
                next: (data) => {
                    this.types = data;
                },
                error: (error) => {
                    console.error(error);
                    Helpers.captureSentryError('Could not get types');
                },
            });
        } else {
            this.absenceService.getTypesValidForEmployee(this.authService.getEmployeeId()).subscribe({
                next: (data) => {
                    this.types = data;
                },
                error: (error) => {
                    console.error(error);
                    Helpers.captureSentryError('Could not get types');
                },
            });
        }
    }

    public filter(value: string): IEmployeeForSelect[] {
        const queries = value.toLowerCase().replace(/,/g, '').replace(/\(/g, '').replace(/\)/g, '').split(' ');
        return this.employees.filter((e) => {
            let allMatch = true;
            for (let i = 0; i < queries.length; i++) {
                if (queries[i].length === 0) {
                    continue;
                }
                if ((e.firstName + e.lastName + e.companyShort).toLowerCase().indexOf(queries[i]) === -1) {
                    allMatch = false;
                    break;
                }
            }
            return allMatch;
        });
    }

    public save(): void {
        let valid = false;

        if (this.mode === 1) {
            valid = this.addAbsenceFormSingle.valid;
        } else {
            valid = this.addAbsenceFormMulti.valid;
        }

        if (!valid) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Fel i formuläret',
                    message: 'Alla fält är inte korrekt ifyllda, korrigera felen och försök igen.',
                },
            });
            if (this.mode === 1) {
                Helpers.setFormTouched(this.addAbsenceFormSingle);
            } else {
                Helpers.setFormTouched(this.addAbsenceFormMulti);
            }
            return;
        }

        let absence: IEmployeeAbsence = {
            absenceTypeId: 0,
            absenceId: 0,
            employeeId: this.selectedEmployeeId,
            added: '',
            comment: '',
            fromDate: '',
            toDate: '',
        };

        if (this.mode === 1) {
            absence.absenceTypeId = this.addAbsenceFormSingle.get('type').value;
            absence.comment = this.addAbsenceFormSingle.get('comment').value;
            absence.fromDate =
                Helpers.toShortDateString(this.addAbsenceFormSingle.get('day').value) +
                ' ' +
                this.addAbsenceFormSingle.get('fromTime').value;
            absence.toDate =
                Helpers.toShortDateString(this.addAbsenceFormSingle.get('day').value) +
                ' ' +
                this.addAbsenceFormSingle.get('toTime').value;
        } else {
            absence.absenceTypeId = this.addAbsenceFormMulti.get('type').value;
            absence.comment = this.addAbsenceFormMulti.get('comment').value;
            absence.fromDate = Helpers.toShortDateString(this.addAbsenceFormMulti.get('fromDate').value);
            absence.toDate = Helpers.toShortDateString(this.addAbsenceFormMulti.get('toDate').value);
        }

        this.loading = true;
        this.absenceService.addForEmployee(absence).subscribe({
            next: (data) => {
                this.loading = false;
                this.resetForms();
            },
            error: (error) => {
                this.loading = false;
                console.error(error);

                let title = 'Fel uppstod';
                let message =
                    'Något gick fel när frånvaron skulle sparas, ' + 'kontrollera att allt är korrekt ifyllt och försök igen';
                let errors: string[];

                if (error.status === 400 && error.error.errors) {
                    message = 'Informationen du angav var felaktig, ' + 'kontrollera meddeladena nedan och försök igen.';
                    errors = error.error.errors;
                } else {
                    Helpers.captureSentryError('Could not save absence');
                }

                this.dialog.open(AlertDialog, {
                    width: '400px',
                    data: {
                        title: title,
                        message: message,
                        list: errors,
                    },
                });
            },
        });
    }
}
