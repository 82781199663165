import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './material/material.module';
import { AttestTimeProblemDialogComponent } from './dialogs/attest-time-problem-dialog/attest-time-problem-dialog.component';
import { AttestTimeMoveDialogComponent } from './dialogs/attest-time-move-dialog/attest-time-move-dialog.component';
import { AttestTimeSplitDialogComponent } from './dialogs/attest-time-split-dialog/attest-time-split-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MaterialModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MaterialModule
    ],
    declarations: [

    
    AttestTimeProblemDialogComponent,
           AttestTimeMoveDialogComponent,
           AttestTimeSplitDialogComponent
  ]
})
export class SharedModule { }
