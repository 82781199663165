import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Helpers } from '@app/helpers/helpers.class';
import { MatDialog } from '@angular/material/dialog';

// Error handling
import * as Sentry from '@sentry/browser';

// Services
import { CompanyService } from '@app/services/company.service';
import { EmploymentTypeService } from '@app/services/employment-type.service';
import { CompanyDepartmentService } from '@app/services/company-department.service';

// Dialogs
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';

// Interfaces
import { IPage } from '@app/interfaces/page.interface';
import { ICompany } from '@app/interfaces/company.interface';
import { IEmploymentType } from '@app/interfaces/employment-type.interface';
import { ICompanyDepartment } from '@app/interfaces/company-department.interface';
import { ITravelCompensationSearch } from '@app/interfaces/travel-compensation-search.interface';
import { TravelCompensationService } from '@app/services/travel-compensation.service';
import { ITravelCompensationExtended } from '@app/interfaces/travel-compensation-extended.interface';
import { ITravelCompensation } from '@app/interfaces/travel-compensation.interface';

@Component({
    selector: 'app-travel-compensations-list',
    templateUrl: './travel-compensations-list.partial.html',
    styleUrls: ['./travel-compensations-list.partial.scss'],
})
export class TravelCompensationsListPartial implements OnInit {
    @Output() public filterChanged: EventEmitter<any> = new EventEmitter();
    @Input('query') public inputQuery: string;
    @Input('companyIds') public inputCompanyIds: string;
    @Input('employmentTypeIds') public inputEmploymentTypeIds: string;
    @Input('departmentIds') public inputDepartmentIds: string;
    @Input('travelDateFrom') public inputTravelDateFrom: string;
    @Input('travelDateTo') public inputTravelDateTo: string;
    @Input('paid') public inputPaid: number;
    @Input('page') public inputPage: number;

    public pageIndex: number;
    public loading: boolean = false;
    public filterQuery: string;
    public filterCompanyIds: number[];
    public filterEmploymentTypeIds: number[];
    public filterDepartmentIds: number[];
    public filterTravelDateFrom: string;
    public filterTravelDateTo: string;
    public filterPaid: boolean;
    public travelCompensationsPage: IPage<any>;
    public travelCompensationColumns: string[] = ['paidIcon', 'travelDate', 'name', 'company', 'distance', 'comment', 'paid'];
    public companies: ICompany[];
    public employmentTypes: IEmploymentType[];
    public companyDepartments: ICompanyDepartment[];

    constructor(
        private companyService: CompanyService,
        private employmentTypeService: EmploymentTypeService,
        private companyDepartmentService: CompanyDepartmentService,
        private travelCompensationService: TravelCompensationService,
        public dialog: MatDialog
    ) {}

    public ngOnInit(): void {
        this.filterQuery = '';
        this.filterCompanyIds = [];
        this.filterEmploymentTypeIds = [];
        this.filterDepartmentIds = [];
        this.filterTravelDateFrom = '';
        this.filterTravelDateTo = '';
        this.filterPaid = null;

        if (Helpers.isDefined(this.inputQuery)) {
            this.filterQuery = this.inputQuery;
        }

        if (Helpers.isDefined(this.inputCompanyIds)) {
            this.filterCompanyIds = this.inputCompanyIds.split(',').map(function (item) {
                return parseInt(item, 10);
            });
        }

        if (Helpers.isDefined(this.inputEmploymentTypeIds)) {
            this.filterEmploymentTypeIds = this.inputEmploymentTypeIds.split(',').map(function (item) {
                return parseInt(item, 10);
            });
        }

        if (Helpers.isDefined(this.inputDepartmentIds)) {
            this.filterDepartmentIds = this.inputDepartmentIds.split(',').map(function (item) {
                return parseInt(item, 10);
            });
        }

        if (Helpers.isDefined(this.inputTravelDateFrom)) {
            this.filterTravelDateFrom = this.inputTravelDateFrom;
        }

        if (Helpers.isDefined(this.inputTravelDateTo)) {
            this.filterTravelDateTo = this.inputTravelDateTo;
        }

        if (Helpers.isDefined(this.inputPaid)) {
            this.filterPaid = this.inputPaid == 1;
        }

        if (Helpers.isDefined(this.inputPage)) {
            this.pageIndex = this.inputPage - 1;
        }

        this.getCompanies();
        this.getEmploymentTypes();
        this.getCompanyDepartments();
        this.getTravelCompensationsPage(1);
    }

    public getTravelCompensationsPage(page: number): void {
        let search: ITravelCompensationSearch = {
            query: this.filterQuery,
            companyIds: this.filterCompanyIds,
            employmentTypeIds: this.filterEmploymentTypeIds,
            departmentIds: this.filterDepartmentIds,
            travelDateFrom: Helpers.toShortDateString(this.filterTravelDateFrom),
            travelDateTo: Helpers.toShortDateString(this.filterTravelDateTo),
            paid: this.filterPaid,
            page: page,
        };

        this.loading = true;
        this.travelCompensationService.getTravelCompensations(search).subscribe({
            next: (data) => {
                this.travelCompensationsPage = data;
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not get travel compensations page');
            },
        });

        let filterSettings = {
            query: this.filterQuery,
            companyIds: this.filterCompanyIds,
            employmentTypeIds: this.filterEmploymentTypeIds,
            departmentIds: this.filterDepartmentIds,
            travelDateFrom: Helpers.toShortDateString(this.filterTravelDateFrom),
            travelDateTo: Helpers.toShortDateString(this.filterTravelDateTo),
            paid: this.filterPaid,
            page: page,
        };

        this.filterChanged.emit(filterSettings);
    }

    public getCompanies() {
        this.companyService.getAll().subscribe({
            next: (data) => {
                this.companies = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get companies');
            },
        });
    }

    public getEmploymentTypes() {
        this.employmentTypeService.getAll().subscribe({
            next: (data) => {
                this.employmentTypes = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get employment types');
            },
        });
    }

    public getCompanyDepartments() {
        this.companyDepartmentService.getAll().subscribe({
            next: (data) => {
                this.companyDepartments = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get copmany departments');
            },
        });
    }

    public pageChanged(event: any): void {
        this.getTravelCompensationsPage(event.pageIndex + 1);
        this.pageIndex = event.pageIndex;
    }

    public setPaid(event: any, travelCompensation: ITravelCompensationExtended): void {
        travelCompensation.paid = event.checked;
        this.loading = true;

        this.travelCompensationService.setPaid(travelCompensation).subscribe({
            next: (data) => {
                this.loading = false;
            },
            error: (error) => {
                this.loading = false;
                console.error(error);

                let title = 'Fel uppstod';
                let message = 'Något gick fel när raden skulle uppdateras.';
                let errors: string[];

                if (error.status === 400 && error.error.errors) {
                    message = 'Ett fel uppstod när raden skulle uppdateras:';
                    errors = error.error.errors;
                } else {
                    Helpers.captureSentryError('Could not set travel compensation paid status');
                }

                this.dialog.open(AlertDialog, {
                    width: '400px',
                    data: {
                        title: title,
                        message: message,
                        list: errors,
                    },
                });
            },
        });
    }
}
