import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

// Interfaces
import { IEmployeeShort } from '@app/interfaces/employee-short.interface';
import { IWorkPeriod } from '@app/interfaces/work-period.interface';
import { IEmployeeShortExtended } from '@app/interfaces/employee-short-extended.interface';
import { IPunchWarningEvent } from '@app/interfaces/punch-warning-event.interface';
import { IPreCompleteWorkPeriodCheck } from '@app/interfaces/pre-complete-work-period-check.interface';
import { IEmployeeCompletedWorkPeriod } from '@app/interfaces/employee-completed-work-period.interface';
import { IPage } from '@app/interfaces/page.interface';
import { IEmployeeCostPoolHandledBy } from '@app/interfaces/employee-cost-pool-handled-by.interface';
import { IEmployeeSearch } from '@app/interfaces/employee-search.interface';
import { IEmployeeFull } from '@app/interfaces/employee-full.interface';
import { IEmployeeFullExtended } from '@app/interfaces/employee-full-extended.interface';
import { IEmployeeForSelect } from '@app/interfaces/employee-for-select.interface';
import { IEmployeeEditPunches } from '@app/interfaces/employee-edit-puches.interface';
import { IEditPunchesSearch } from '@app/interfaces/edit-punches-search.interface';
import { IEmployeeSearchConnectSchedule } from '@app/interfaces/employee-search-connect-schedule.interface';

@Injectable({ providedIn: 'root' })
export class EmployeeService {
    constructor(private http: HttpClient) {}

    getEmployees(search: IEmployeeSearch): Observable<IPage<any>> {
        return this.http.post<IPage<any>>(environment.apiUrl + '/employees/search', search);
    }

    getEmployeesForConnectSchedule(search: IEmployeeSearchConnectSchedule): Observable<IPage<any>> {
        return this.http.post<IPage<any>>(environment.apiUrl + '/employees/search-connect-schedule', search);
    }

    getActiveToday(): Observable<any[]> {
        return this.http.get<any[]>(environment.apiUrl + '/employees/active-today');
    }

    create(employee: IEmployeeShort): Observable<IEmployeeShort> {
        return this.http.post<IEmployeeShort>(environment.apiUrl + '/employees', employee);
    }

    getLoginCode(identityNumber: string): Observable<any> {
        return this.http.post<any>(environment.apiUrl + '/employees/next-free-login', { identityNumber: identityNumber });
    }

    getOpenWorkPeriods(employeeId: number): Observable<IWorkPeriod[]> {
        return this.http.get<IWorkPeriod[]>(environment.apiUrl + '/employees/' + employeeId + '/open-work-periods');
    }

    getShortDetails(employeeId: number): Observable<IEmployeeShort> {
        return this.http.get<IEmployeeShort>(environment.apiUrl + '/employees/' + employeeId + '/short');
    }

    getShortExtendedDetails(employeeId: number): Observable<IEmployeeShortExtended> {
        return this.http.get<IEmployeeShortExtended>(environment.apiUrl + '/employees/' + employeeId + '/short-extended');
    }

    update(employee: IEmployeeShort): Observable<any> {
        return this.http.patch<any>(environment.apiUrl + '/employees/' + employee.employeeId, employee);
    }

    updateByAdmin(employee: IEmployeeFullExtended): Observable<any> {
        return this.http.put<IEmployeeFullExtended>(environment.apiUrl + '/employees/' + employee.employeeId, employee);
    }

    getCanOrderOvertime(): Observable<IEmployeeShort[]> {
        return this.http.get<IEmployeeShort[]>(environment.apiUrl + '/employees/can-order-overtime');
    }

    ignorePunchWarning(employeeId: number, hash: string): Observable<IPunchWarningEvent> {
        return this.http.post<IPunchWarningEvent>(
            environment.apiUrl + '/employees/' + employeeId + '/open-work-periods/' + hash + '/ignore-warning',
            {}
        );
    }

    getPreCompleteWorkPeriodCheck(employeeId: number, period: IWorkPeriod): Observable<IPreCompleteWorkPeriodCheck> {
        return this.http.get<IPreCompleteWorkPeriodCheck>(
            environment.apiUrl + '/employees/' + employeeId + '/open-work-periods/' + period.hashCode + '/pre-complete-check',
            {
                params: new HttpParams().set('from', period.periodStart).set('to', period.periodEnd),
            }
        );
    }

    completeWorkPeriod(employeeId: number, period: IWorkPeriod): Observable<IEmployeeCompletedWorkPeriod> {
        return this.http.post<IEmployeeCompletedWorkPeriod>(
            environment.apiUrl + '/employees/' + employeeId + '/open-work-periods/' + period.hashCode + '/complete',
            period
        );
    }

    getCostPoolHandledByEmployees(mode: number, companyId: number, page: number): Observable<IPage<any>> {
        return this.http.get<IPage<any>>(environment.apiUrl + '/employees/cost-pool-handled-by', {
            params: new HttpParams()
                .set('mode', mode.toString())
                .set('companyId', companyId.toString())
                .set('page', page.toString()),
        });
    }

    setCostPoolHandledBy(employees: IEmployeeCostPoolHandledBy[]): Observable<IEmployeeCostPoolHandledBy[]> {
        return this.http.post<IEmployeeCostPoolHandledBy[]>(environment.apiUrl + '/employees/cost-pool-handled-by', employees);
    }

    getCostPoolHandledByTodoCount(): Observable<number> {
        return this.http.get<number>(environment.apiUrl + '/employees/cost-pool-handled-by/to-do-count');
    }

    getByTitle(title: string): Observable<IEmployeeShort[]> {
        return this.http.get<IEmployeeShort[]>(environment.apiUrl + '/employees/get-by-title', {
            params: new HttpParams().set('title', title),
        });
    }

    getPunchCostPoolEmployees(
        handledByEmployeeId: number,
        status: number,
        day: string,
        query: string,
        page: number
    ): Observable<IPage<any>> {
        return this.http.get<IPage<any>>(environment.apiUrl + '/employees/punch-cost-pool', {
            params: new HttpParams()
                .set('handledByEmployeeId', handledByEmployeeId.toString())
                .set('status', status.toString())
                .set('day', day)
                .set('page', page.toString())
                .set('query', query),
        });
    }

    getPunchCostPoolTodoCount(): Observable<number> {
        return this.http.get<number>(environment.apiUrl + '/employees/punch-cost-pool/to-do-count');
    }

    getAttestOvertimeEmployees(
        handledByEmployeeId: number,
        status: number,
        day: string,
        query: string,
        page: number
    ): Observable<IPage<any>> {
        return this.http.get<IPage<any>>(environment.apiUrl + '/employees/attest-overtime', {
            params: new HttpParams()
                .set('handledByEmployeeId', handledByEmployeeId.toString())
                .set('status', status.toString())
                .set('day', day)
                .set('page', page.toString())
                .set('query', query),
        });
    }

    getAttestOvertimeTodoCount(): Observable<number> {
        return this.http.get<number>(environment.apiUrl + '/employees/attest-overtime/to-do-count');
    }

    getAttestTimeEmployees(handledByEmployeeId: number, day: string, page: number): Observable<IPage<any>> {
        return this.http.get<IPage<any>>(environment.apiUrl + '/employees/attest-time', {
            params: new HttpParams()
                .set('handledByEmployeeId', handledByEmployeeId.toString())
                .set('day', day)
                .set('page', page.toString()),
        });
    }

    getFullDetails(employeeId: number): Observable<IEmployeeFull> {
        return this.http.get<IEmployeeFull>(environment.apiUrl + '/employees/' + employeeId + '/full');
    }

    getFullExtendedDetails(employeeId: number): Observable<IEmployeeFullExtended> {
        return this.http.get<IEmployeeFullExtended>(environment.apiUrl + '/employees/' + employeeId + '/full-extended');
    }

    getAllForSelect(): Observable<IEmployeeForSelect[]> {
        return this.http.get<IEmployeeForSelect[]>(environment.apiUrl + '/employees/for-select');
    }

    getEditPunches(search: IEditPunchesSearch): Observable<IEmployeeEditPunches[]> {
        return this.http.post<IEmployeeEditPunches[]>(environment.apiUrl + '/employees/edit-punches', search);
    }

    getDrivingLogEmployees(): Observable<IEmployeeForSelect[]> {
        return this.http.get<IEmployeeForSelect[]>(environment.apiUrl + '/employees/driving-log');
    }

    sendToDreamPack(employeeId: number): Observable<any> {
        return this.http.post<any>(environment.apiUrl + '/employees/' + employeeId + '/send-to-dream-pack', {});
    }
}
