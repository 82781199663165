import { Pipe, PipeTransform } from '@angular/core';
import { Helpers } from '@app/helpers/helpers.class';
import { find } from 'lodash-es';

@Pipe({ name: 'valueFromObjectArray' })
export class ValueFromObjectArrayPipe implements PipeTransform {
    transform(data: any[], checkName: string, value: string, resultName: string): string {
        if (
            !Helpers.isDefined(data) ||
            !Helpers.isDefined(checkName) ||
            !Helpers.isDefined(value) ||
            !Helpers.isDefined(resultName)
        ) {
            return '';
        }

        let item = find(data, function (i) {
            return i[checkName].toString() === value.toString();
        });

        return item[resultName];
    }
}
