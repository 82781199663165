import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment'
import { IWorkPeriodWithEmployeeStatus } from '@app/interfaces/work-period-with-employee-status.interface';
import { IWorkPeriod } from '@app/interfaces/work-period.interface';

// Interfaces

@Injectable({ providedIn: 'root' })
export class WorkPeriodService {

    constructor(private http: HttpClient) { }

    getOpen(): Observable<IWorkPeriodWithEmployeeStatus[]> {
        return this.http.get<IWorkPeriodWithEmployeeStatus[]>(environment.apiUrl + "/work-periods/open");
    }

    closePeriod(period: IWorkPeriod): Observable<any> {
        return this.http.post<any>(environment.apiUrl + "/work-periods/close", period);
    }
}
