import { Component, OnInit } from '@angular/core';

// Services
import { AuthService } from '@app/services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-show-trip-driving-log-page',
    templateUrl: './show-trip.component.html',
    styleUrls: ['./show-trip.component.scss']
})

export class ShowTripDrivingLogComponent implements OnInit {
    public employeeId: number;
    public logId: number;

    constructor(private authService: AuthService,
        private activatedRoute: ActivatedRoute) { }

    public ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
        this.activatedRoute.params.subscribe(params => {
            this.logId = +params['id'];
        });
    }
}
