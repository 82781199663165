import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import {IStaffingCompany} from "@app/interfaces/StaffingCompany.interface";

// Interfaces


@Injectable({ providedIn: 'root' })
export class StaffingCompanyService {
    constructor(private http: HttpClient) {}

    getAll(): Observable<IStaffingCompany[]> {
        return this.http.get<IStaffingCompany[]>(environment.apiUrl + '/staffing-companies/all');
    }

    getById(staffingCompanyId: number): Observable<IStaffingCompany> {
        return this.http.get<IStaffingCompany>(environment.apiUrl + '/staffing-companies/' + staffingCompanyId);
    }

    create(staffingCompany: IStaffingCompany): Observable<IStaffingCompany> {
        return this.http.post<IStaffingCompany>(environment.apiUrl + '/staffing-companies/', staffingCompany);
    }
}
