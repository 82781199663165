<div class="wrapper">
    <table class="periods" mat-table [dataSource]="openWorkPeriods" multiTemplateDataRows>
        <ng-container matColumnDef="periodStart">
            <th mat-header-cell *matHeaderCellDef class="name">Period</th>
            <td mat-cell *matCellDef="let period" class="name"> {{period | workPeriodName}} </td>
        </ng-container>
        <ng-container matColumnDef="confirmDue">
            <th mat-header-cell *matHeaderCellDef class="due">Färdig</th>
            <td mat-cell *matCellDef="let period" class="due">{{period.confirmDue | shortDate}}</td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="status">Status</th>
            <td mat-cell *matCellDef="let period" class="status">
                <mat-icon *ngIf="period.unfixedWarnings > 0" [matTooltip]="period.unfixedWarnings | workPeriodStatus"
                    class="warning" [ngClass]="period | periodWarningsSeverityClass">warning</mat-icon>
                <mat-icon *ngIf="period.unfixedWarnings == 0" class="check">check</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="close">
            <th mat-header-cell *matHeaderCellDef class="close"></th>
            <td mat-cell *matCellDef="let period" class="close"
                [matTooltip]="!period.periodEnded ? 'Du kan inte klarmarkera rapporten eftersom perioden inte är slut än' : null">
                <button mat-button [disabled]="period.unfixedWarnings > 0 || !period.periodEnded"
                    (click)="completePeriodPreCheck(period)">Klarmarkera</button>
            </td>
        </ng-container>
        <ng-container matColumnDef="caret">
            <th mat-header-cell *matHeaderCellDef class="caret"></th>
            <td mat-cell *matCellDef="let period" class="caret">
                <mat-icon *ngIf="expandedPeriod !== period && period.unfixedWarnings > 0">expand_more</mat-icon>
                <mat-icon *ngIf="expandedPeriod === period && period.unfixedWarnings > 0">expand_less</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="warnings">
            <td class="warnings" mat-cell *matCellDef="let period" [attr.colspan]="workPeriodColumns.length">
                <div class="period-warnings-wrapper"
                    [@toggleWarnings]="period == expandedPeriod ? 'expanded' : 'collapsed'">
                    <table class="warnings" mat-table [dataSource]="period.warnings" *ngIf="period.unfixedWarnings > 0">
                        <ng-container [matColumnDef]="period.warningColumns[0]">
                            <th mat-header-cell *matHeaderCellDef class="severity"></th>
                            <td mat-cell *matCellDef="let warning" class="severity"
                                [ngClass]="{'error': warning.severity == 1, 'warning': warning.severity == 2}">
                                <mat-icon>warning</mat-icon>
                            </td>
                        </ng-container>
                        <ng-container [matColumnDef]="period.warningColumns[1]">
                            <th mat-header-cell *matHeaderCellDef class="date">Datum</th>
                            <td mat-cell *matCellDef="let warning" class="date">{{warning.day | shortDate}}</td>
                        </ng-container>
                        <ng-container [matColumnDef]="period.warningColumns[2]">
                            <th mat-header-cell *matHeaderCellDef class="description">Varning</th>
                            <td mat-cell *matCellDef="let warning" class="description">
                                {{warning.header == 'Ej definierat' ? 'Ej definierat fel' : warning.description}}
                            </td>
                        </ng-container>
                        <ng-container [matColumnDef]="period.warningColumns[3]">
                            <th mat-header-cell *matHeaderCellDef class="message-sent"></th>
                            <td mat-cell *matCellDef="let warning" class="message-sent">
                                <mat-icon *ngIf="warning.messageSent"
                                    matTooltip="Du har skickat ett meddelande angående denna varning. Klicka för att visa meddelandet"
                                    (click)="openConversation($event)">email</mat-icon>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="period.warningColumns"></tr>
                        <tr mat-row *matRowDef="let warning; columns: period.warningColumns;" class="warning-row"
                            (click)="showWarningOptions(warning)" [class.ignored]="warning.ignored"></tr>
                    </table>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="workPeriodColumns"></tr>
        <tr mat-row *matRowDef="let period; columns: workPeriodColumns;" class="period-row"
            [class.clickable]="period.unfixedWarnings > 0"
            (click)="expandedPeriod == period ? expandedPeriod = null : expandedPeriod = period"></tr>
        <tr mat-row *matRowDef="let row; columns: ['warnings']" class="period-warnings"></tr>
    </table>

    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
