import { Component, OnInit } from '@angular/core';

// Services
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'app-general-settings-admin-page',
    templateUrl: './general-settings.component.html',
    styleUrls: ['./general-settings.component.scss']
})

export class GeneralSettingsAdminComponent implements OnInit {
    public employeeId: number;

    constructor(private authService: AuthService) { }

    ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
    }
}
