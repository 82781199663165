<h2 mat-dialog-title>Meddelanden från {{employee.lastName}}, {{employee.firstName}}</h2>
<mat-dialog-content>
    <div class="messages-wrapper">
        <div class="message" *ngFor="let message of employee.messages">
            <div class="date">
                <ng-container *ngIf="message.displayAtDate; else notMoved">
                    {{message.displayAtDate | shortDate}}
                </ng-container>
                <ng-template #notMoved>
                    {{message.sendDate | dateTime}}
                </ng-template>
                <span class="replied" *ngIf="message.replied"> - Besvarat <mat-icon>check</mat-icon></span>
            </div>
            <div class="moved-date" *ngIf="message.displayAtDate">
                <i>OBS! Flyttat från {{message.sendDate | dateTime}}</i>
            </div>
            <div class="body">{{message.message}}</div>
            <div class="actions">
                <mat-form-field class="day" (click)="pickerDay.open()">
                    <input matInput [matDatepicker]="pickerDay" [(ngModel)]="messageMoveDate" (dateChange)="moveTo(message)"
                        disabled>
                    <mat-datepicker-toggle matSuffix [for]="pickerDay"></mat-datepicker-toggle>
                    <mat-datepicker #pickerDay disabled="false"></mat-datepicker>
                </mat-form-field>

                <mat-icon class="ignore" [class.ignored]="message.ignoreInEditPunches" [matTooltip]="message.ignoreInEditPunches ? 'Avignorera' : 'Ignorera'"
                    (click)="toggleIgnore(message)">notifications_off</mat-icon>
                <mat-icon class="move" matTooltip="Flytta till annan dag" (click)="pickerDay.open()">redo</mat-icon>
                <mat-icon [class.disabled]="message.replied" class="edit-answer" [matTooltip]="message.replied ? 'Du har redan svarat på detta meddelande' : 'Redigera svar'"
                    (click)="showEditReply(message)">edit</mat-icon>
                <mat-checkbox [disabled]="message.replied" class="send-answer" (click)="setDefaultReply(message)"
                    [matTooltip]="message.replied ? 'Du har redan svarat på detta meddelande' : 'Skicka svar på detta meddelande'"
                    [(ngModel)]="message.sendReply">
                </mat-checkbox>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">OK</button>
</mat-dialog-actions>
