<h2 mat-dialog-title>{{punch ? 'Redigera stämpling' : 'Ny stämpling'}}</h2>
<mat-dialog-content>
    <div class="wrapper">
        <div class="row">
            <div class="col punch-type-col">
                <mat-radio-group [(ngModel)]="punchTypeId" [disabled]="punch !== null">
                    <mat-radio-button [value]="1" (click)="punchTypeUpdated(1)">In</mat-radio-button>
                    <mat-radio-button [value]="2" (click)="punchTypeUpdated(2)">Ut</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field class="punch-time">
                    <mat-label>Tid</mat-label>
                    <input matInput [(ngModel)]="punchTime" />
                </mat-form-field>
            </div>
            <div class="col">
                <mat-checkbox [disabled]="punchTypeId == 1" [(ngModel)]="overtime">Övertid</mat-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field>
                    <mat-label>Övertid beordrad av</mat-label>
                    <mat-select [(value)]="overtimeOrderedByEmployeeId"
                        [disabled]="!overtime">
                        <mat-option *ngFor="let employee of employees" [value]="employee.employeeId">
                            [{{employee.title}}] {{employee.lastName}}, {{employee.firstName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field>
                    <mat-label>Kostnadsställe</mat-label>
                    <mat-select [(ngModel)]="costPoolId">
                        <mat-option *ngFor="let costPool of costPools" [value]="costPool.poolId">
                            {{costPool.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="deletePunch()" class="delete" color="warn" *ngIf="punch">Ta bort</button>
    <button mat-raised-button (click)="close()">Avbryt</button>
    <button mat-raised-button (click)="updatePunch()" color="primary" *ngIf="punch">Uppdatera</button>
    <button mat-raised-button (click)="createPunch()" color="primary" *ngIf="!punch">Lägg till</button>
</mat-dialog-actions>
