<div class="container-fluid" [formGroup]="costPoolForm">
    <div class="row">
        <div class="col">
            <mat-card class="compact">
                <mat-card-title>Kostnadsställets information</mat-card-title>
                <mat-card-content>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col">
                                <mat-form-field>
                                    <mat-label>Namn</mat-label>
                                    <input matInput autocomplete="off" formControlName="name" required />
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <mat-label>Bolag</mat-label>
                                    <mat-select formControlName="company">
                                        <mat-option [value]="null">(Inget valt)</mat-option>
                                        <mat-option *ngFor="let company of companies" [value]="company.companyId">
                                            {{company.companyName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <mat-form-field>
                                    <mat-label>Kod</mat-label>
                                    <input matInput autocomplete="off" formControlName="code" required />
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <mat-label>Projekt</mat-label>
                                    <input matInput autocomplete="off" formControlName="project" />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <mat-form-field>
                                    <mat-label>Underkategori till</mat-label>
                                    <mat-select formControlName="parentPool">
                                        <mat-option *ngFor="let pool of parentCostPools" [value]="pool.poolId">
                                            {{pool.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-checkbox formControlName="visible">Aktiv</mat-checkbox>
                            </div>
                            <div class="col">
                                <mat-checkbox formControlName="showInPunchMenu">Synlig i stämpelmenyn</mat-checkbox>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="row">
        <div class="col col-6">
            <mat-card class="compact">
                <mat-card-title>Attestering av tid</mat-card-title>
                <mat-card-content>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col">
                                <mat-form-field>
                                    <mat-label>Anställd</mat-label>
                                    <input
                                        type="text"
                                        aria-label="Employee"
                                        matInput
                                        [formControl]="newManager"
                                        [matAutocomplete]="employeeAutoComplete" />
                                    <mat-autocomplete
                                        autoActiveFirstOption
                                        #employeeAutoComplete="matAutocomplete"
                                        [displayWith]="displaySelectedEmployee"
                                        (optionSelected)="employeeSelected($event)">
                                        <mat-option *ngFor="let employee of filteredEmployees | async" [value]="employee">
                                            {{employee.lastName}}, {{employee.firstName}} ({{employee.companyShort}})
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <strong>Hanteras av</strong>
                                <mat-list role="list" class="attest-time-employees" *ngIf="managers && managers.length">
                                    <mat-list-item role="listitem" *ngFor="let manager of managers">
                                        <div class="employee">
                                            <div>
                                                {{manager.employee.lastName}}, {{manager.employee.firstName}}
                                                ({{manager.employee.companyShort}})
                                            </div>
                                            <mat-icon (click)="deleteManager(manager)">delete_outline</mat-icon>
                                        </div>
                                    </mat-list-item>
                                </mat-list>
                                <div class="no-results" *ngIf="!managers || !managers.length">Inga valda personer</div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="row buttons">
        <ng-container *ngIf="!poolId; else updateButtonWrapper">
            <div class="col" align="end">
                <button mat-raised-button color="primary" (click)="save()">Skapa kostnadsställe</button>
            </div>
        </ng-container>
        <ng-template #updateButtonWrapper>
            <div class="col update-button" align="end">
                <button mat-raised-button color="primary" (click)="save()">Uppdatera kostnadsställe</button>
            </div>
        </ng-template>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
