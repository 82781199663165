import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { PunchLoginComponent } from '@app/components/pages/employee/login/punch-login.component';
import { RegisterComponent } from '@app/components/pages/employee/register/register.component';
import { PunchComponent } from '@app/components/pages/employee/punch/punch.component';
import { EditEmployeeComponent } from '@app/components/pages/employee/edit/edit-employee.component';
import { LoginAdminComponent } from '@app/components/pages/admin/login/admin-login.component';
import { StartAdminComponent } from '@app/components/pages/admin/start/admin-start.component';
import { EmployeeCostPoolHandledByAdminComponent } from '@app/components/pages/admin/employee-cost-pool-handled-by/employee-cost-pool-handled-by.component';
import { PunchesCostPoolAdminComponent } from '@app/components/pages/admin/punches-cost-pool/punches-cost-pool.component';
import { AttestOvertimeAdminComponent } from '@app/components/pages/admin/attest-overtime/attest-overtime.component';
import { EmployeesListAdminComponent } from '@app/components/pages/admin/employees-list/employees-list.component';
import { EmployeeDetailsAdminComponent } from '@app/components/pages/admin/employee-details/employee-details.component';
import { PunchesListAdminComponent } from '@app/components/pages/admin/punches-list/punches-list.component';
import { PunchesEditAdminComponent } from '@app/components/pages/admin/punches-edit/punches-edit.component';
import { DrivingLogLoginComponent } from '@app/components/pages/driving-log/login/driving-log-login.component';
import { NewTripDrivingLogComponent } from '@app/components/pages/driving-log/new-trip/new-trip.component';
import { AllTripsDrivingLogComponent } from '@app/components/pages/driving-log/all-trips/all-trips.component';
import { ShowTripDrivingLogComponent } from '@app/components/pages/driving-log/show-trip/show-trip.component';
import { CarsDrivingLogComponent } from '@app/components/pages/driving-log/cars/cars.component';
import { NewCarDrivingLogComponent } from '@app/components/pages/driving-log/new-car/new-car.component';
import { EditCarDrivingLogComponent } from '@app/components/pages/driving-log/edit-car/edit-car.component';
import { DrivingLogAdminComponent } from '@app/components/pages/admin/driving-log/driving-log.component';
import { SchedulesListAdminComponent } from '@app/components/pages/admin/schedules-list/schedules-list.component';
import { ScheduleEditAdminComponent } from '@app/components/pages/admin/schedule-edit/schedule-edit.component';
import { ScheduleNewAdminComponent } from '@app/components/pages/admin/schedule-new/schedule-new.component';
import { SchedulesConnectAdminComponent } from '@app/components/pages/admin/schedules-connect/schedules-connect.component';
import { TravelCompensationAdminComponent } from '@app/components/pages/admin/travel-compensation/travel-compensation.component';
import { CostPoolsListAdminComponent } from '@app/components/pages/admin/cost-pools-list/cost-pools-list.component';
import { CostPoolEditAdminComponent } from '@app/components/pages/admin/cost-pool-edit/cost-pool-edit.component';
import { CostPoolNewAdminComponent } from '@app/components/pages/admin/cost-pool-new/cost-pool-new.component';
import { GeneralSettingsAdminComponent } from '@app/components/pages/admin/general-settings/general-settings.component';
import { AdminClaimSettingsAdminComponent } from '@app/components/pages/admin/admin-claim-settings/admin-claim-settings.component';
import { ReportTimePerCostPoolAdminComponent } from '@app/components/pages/admin/report-time-per-cost-pool/report-time-per-cost-pool.component';

// Guards
import { EmployeeAuthGuard } from '@app/guards/employee-auth.guard';
import { AdminAuthGuard } from '@app/guards/admin-auth.guard';
import { DrivingLogAuthGuard } from '@app/guards/driving-log-auth.guard';
import { AdminAccountsAdminComponent } from './components/pages/admin/admin-accounts/admin-accounts.component';
import { AdminNewAdminComponent } from './components/pages/admin/admin-new/admin-new.component';
import { AdminResetPasswordPartial } from './components/partials/admin-reset-password/admin-reset-password.partial';
import { AdminResetPasswordComponent } from './components/pages/admin/admin-reset-password/admin-reset-password.component';
import { AdminEditAdminComponent } from './components/pages/admin/admin-edit/admin-edit.component';
import { EmployeeResetPasswordComponent } from './components/pages/employee/reset-password/employee-reset-password.component';
import { ApplicationClientsListComponent } from './components/pages/admin/application-clients-list/application-clients-list.component';
import { AttestTimeAdminComponent } from './components/pages/admin/attest-time/attest-time.component';
import { AttestTimeIssuesListAdminComponent } from './components/pages/admin/attest-time-issues-list/attest-time-issues-list.component';
import { ReportWorkingEmployeesPartial } from './components/partials/report-working-employees/report-working-employees.partial';
import { ReportWorkingEmployeesAdminComponent } from './components/pages/admin/report-working-employees/report-working-employees.component';
import {
    ReportStaffingCompanyTimeComponent
} from "@app/components/pages/admin/report-staffing-company-time/report-staffing-company-time.component";

const routes: Routes = [
    // Employee
    { path: 'login', component: PunchLoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'reset-password', component: EmployeeResetPasswordComponent },
    {
        path: 'punch',
        component: PunchComponent,
        canActivate: [EmployeeAuthGuard],
    },
    {
        path: 'edit-my-details',
        component: EditEmployeeComponent,
        canActivate: [EmployeeAuthGuard],
    },
    { path: '', redirectTo: '/login', pathMatch: 'full' },

    // Admin
    {
        path: 'admin',
        redirectTo: '/admin/start',
        pathMatch: 'full',
    },
    { path: 'admin/login', component: LoginAdminComponent },
    {
        path: 'admin/start',
        component: StartAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/cost-pools/assign-employees',
        component: EmployeeCostPoolHandledByAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/cost-pools/categorize-punches',
        component: PunchesCostPoolAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/employees/attest-overtime',
        component: AttestOvertimeAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/employees/attest-time',
        component: AttestTimeAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/employees/attest-time/issues',
        component: AttestTimeIssuesListAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/employees',
        component: EmployeesListAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/employees/:id',
        component: EmployeeDetailsAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/punches',
        component: PunchesListAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/edit-punches',
        component: PunchesEditAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/driving-log',
        component: DrivingLogAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/schedules',
        component: SchedulesListAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/schedules/new',
        component: ScheduleNewAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/schedules/connect',
        component: SchedulesConnectAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/schedules/:id',
        component: ScheduleEditAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/travel-compensations',
        component: TravelCompensationAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/cost-pools',
        component: CostPoolsListAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/cost-pools/new',
        component: CostPoolNewAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/cost-pools/:id',
        component: CostPoolEditAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/settings/general',
        component: GeneralSettingsAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/settings/admin-claims',
        component: AdminClaimSettingsAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/reports/cost-pools/time-per-cost-pool',
        component: ReportTimePerCostPoolAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/reports/staffing-company-time',
        component: ReportStaffingCompanyTimeComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/settings/accounts',
        component: AdminAccountsAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/settings/accounts/new',
        component: AdminNewAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/settings/accounts/:id',
        component: AdminEditAdminComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/reset-password',
        component: AdminResetPasswordComponent,
    },
    {
        path: 'admin/settings/clients',
        component: ApplicationClientsListComponent,
        canActivate: [AdminAuthGuard],
    },
    {
        path: 'admin/reports/employees/working',
        component: ReportWorkingEmployeesAdminComponent,
        canActivate: [AdminAuthGuard],
    },

    // Körjournal
    {
        path: 'driving-log',
        redirectTo: '/driving-log/trip',
        pathMatch: 'full',
    },
    { path: 'driving-log/login', component: DrivingLogLoginComponent },
    {
        path: 'driving-log/trip',
        component: NewTripDrivingLogComponent,
        canActivate: [DrivingLogAuthGuard],
    },
    {
        path: 'driving-log/trip-history',
        component: AllTripsDrivingLogComponent,
        canActivate: [DrivingLogAuthGuard],
    },
    {
        path: 'driving-log/trip-history/:id',
        component: ShowTripDrivingLogComponent,
        canActivate: [DrivingLogAuthGuard],
    },
    {
        path: 'driving-log/cars',
        component: CarsDrivingLogComponent,
        canActivate: [DrivingLogAuthGuard],
    },
    {
        path: 'driving-log/cars/new',
        component: NewCarDrivingLogComponent,
        canActivate: [DrivingLogAuthGuard],
    },
    {
        path: 'driving-log/cars/:id',
        component: EditCarDrivingLogComponent,
        canActivate: [DrivingLogAuthGuard],
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
        }),
    ],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {}
