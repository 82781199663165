<div class="container-fluid">
    <div class="row filters">
        <div class="col query-filter">
            <label>Sök</label>
            <mat-form-field>
                <input
                    matInput
                    [(ngModel)]="filterQuery"
                    autocomplete="off"
                    placeholder="Fritextsök..."
                    (keydown.enter)="getTravelCompensationsPage(1)" />
            </mat-form-field>
        </div>
        <div class="col company-filter">
            <label>Bolag</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filterCompanyIds" placeholder="Välj bolag" multiple>
                    <mat-option *ngFor="let company of companies" [value]="company.companyId"
                        >{{company.companyName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row filters">
        <div class="col employment-type-filter">
            <label>Anställnig</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filterEmploymentTypeIds" placeholder="Välj anställningstyp" multiple>
                    <mat-option *ngFor="let employmentType of employmentTypes" [value]="employmentType.employmentTypeId">
                        {{employmentType.employmentTypeName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col company-department-filter">
            <label>Avdelning</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filterDepartmentIds" placeholder="Välj avdelning" multiple>
                    <mat-option *ngFor="let department of companyDepartments" [value]="department.departmentId">
                        {{companies | valueFromObjectArray:'companyId':department.companyId:'companyShort'}} -
                        {{department.departmentName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col travel-dates-filter">
            <label>Resedatum</label>
            <div class="date-range">
                <mat-form-field class="from" (click)="pickerFrom.open()">
                    <input matInput [matDatepicker]="pickerFrom" [(ngModel)]="filterTravelDateFrom" placeholder="Från" readonly />
                    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFrom></mat-datepicker>
                </mat-form-field>
                <span>-</span>
                <mat-form-field class="to" (click)="pickerTo.open()">
                    <input matInput [matDatepicker]="pickerTo" [(ngModel)]="filterTravelDateTo" placeholder="Till" readonly />
                    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                    <mat-datepicker #pickerTo></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="row filters">
        <div class="col paid-filter">
            <label>Utbetald</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filterPaid" placeholder="(Alla)">
                    <mat-option [value]="null">(Alla)</mat-option>
                    <mat-option [value]="true">Ja</mat-option>
                    <mat-option [value]="false">Nej</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col spacer">&nbsp;</div>
        <div class="col button-filter">
            <button mat-raised-button (click)="getTravelCompensationsPage(1)" color="primary">Sök</button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="divider-wrapper">
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col" *ngIf="travelCompensationsPage && travelCompensationsPage.totalItems; else noResults;">
            <mat-paginator
                *ngIf="travelCompensationsPage && travelCompensationsPage.totalItems > travelCompensationsPage.pageSize"
                [length]="travelCompensationsPage.totalItems"
                [pageSize]="travelCompensationsPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
            <div class="table-wrapper">
                <table mat-table *ngIf="travelCompensationsPage" [dataSource]="travelCompensationsPage.items">
                    <ng-container matColumnDef="paidIcon">
                        <th mat-header-cell *matHeaderCellDef class="paid-icon"></th>
                        <td mat-cell *matCellDef="let travelCompensation" class="paid-icon">
                            <mat-icon class="paid" *ngIf="travelCompensation.paid" matTooltip="Utbetald">check_circle</mat-icon>
                            <mat-icon *ngIf="!travelCompensation.paid" matTooltip="Ej utbetald">cancel</mat-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="travelDate">
                        <th mat-header-cell *matHeaderCellDef class="travel-date">Resedatum</th>
                        <td mat-cell *matCellDef="let travelCompensation" class="travel-date">
                            {{travelCompensation.travelDate | shortDate}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="name">Anställd</th>
                        <td mat-cell *matCellDef="let travelCompensation" class="name">
                            {{travelCompensation.employee.lastName}}, {{travelCompensation.employee.firstName}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="company">
                        <th mat-header-cell *matHeaderCellDef class="company">Bolag</th>
                        <td mat-cell *matCellDef="let travelCompensation" class="company">
                            {{travelCompensation.company.companyShort}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="distance">
                        <th mat-header-cell *matHeaderCellDef class="distance">Sträcka</th>
                        <td mat-cell *matCellDef="let travelCompensation" class="distance">
                            {{travelCompensation.kilometers}} km
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="comment">
                        <th mat-header-cell *matHeaderCellDef class="comment">Kommentar</th>
                        <td mat-cell *matCellDef="let travelCompensation" class="comment">{{travelCompensation.description}}</td>
                    </ng-container>
                    <ng-container matColumnDef="paid">
                        <th mat-header-cell *matHeaderCellDef class="paid">Utbetald</th>
                        <td mat-cell *matCellDef="let travelCompensation" class="paid">
                            <mat-checkbox
                                [checked]="travelCompensation.paid"
                                (change)="setPaid($event, travelCompensation)"></mat-checkbox>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="travelCompensationColumns"></tr>
                    <tr mat-row *matRowDef="let travelCompensation; columns: travelCompensationColumns;"></tr>
                </table>
            </div>
            <mat-paginator
                *ngIf="travelCompensationsPage && travelCompensationsPage.totalItems > travelCompensationsPage.pageSize"
                [length]="travelCompensationsPage.totalItems"
                [pageSize]="travelCompensationsPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
        </div>
        <ng-template #noResults>
            <div class="col">
                <p class="no-results">Inga anställda att visa</p>
            </div>
        </ng-template>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
