<div class="container-fluid">
    <div class="row filters">
        <div class="col query-filter">
            <label>Sök</label>
            <mat-form-field>
                <mat-label>Fritextsök...</mat-label>
                <input matInput [(ngModel)]="filterQuery" autocomplete="off" (keydown.enter)="getEmployees()" />
            </mat-form-field>
        </div>
        <div class="col day-filter">
            <label>Dag</label>
            <mat-form-field class="day" (click)="pickerDay.open()">
                <input matInput [matDatepicker]="pickerDay" [(ngModel)]="filterDay" readonly />
                <mat-datepicker-toggle matSuffix [for]="pickerDay"></mat-datepicker-toggle>
                <mat-datepicker #pickerDay [dateClass]="dateClass"></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col company-filter">
            <label>Bolag</label>
            <mat-form-field>
                <mat-label>Välj bolag</mat-label>
                <mat-select [(ngModel)]="filterCompanyIds" multiple>
                    <mat-option *ngFor="let company of companies" [value]="company.companyId">{{company.companyName}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col company-department-filter">
            <label>Avdelning</label>
            <mat-form-field>
                <mat-label>Välj avdelning</mat-label>
                <mat-select [(ngModel)]="filterDepartmentIds" multiple>
                    <mat-option *ngFor="let department of companyDepartments" [value]="department.departmentId">
                        {{companies | valueFromObjectArray:'companyId':department.companyId:'companyShort'}} -
                        {{department.departmentName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col employment-type-filter">
            <label>Anställnig</label>
            <mat-form-field>
                <mat-label>Välj anställningstyp</mat-label>
                <mat-select [(ngModel)]="filterEmploymentTypeIds" multiple>
                    <mat-option *ngFor="let employmentType of employmentTypes" [value]="employmentType.employmentTypeId">
                        {{employmentType.employmentTypeName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col has-messages-filter">
            <mat-checkbox [(ngModel)]="filterHasMessages">Har skickat meddelande</mat-checkbox>
        </div>
        <div class="col get-punches">
            <button mat-raised-button color="primary" (click)="getEmployees()">Visa</button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="divider-wrapper">
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
    <div *ngIf="employees && employees.length > 0; else noResults;">
        <div class="row save-top">
            <div class="col" align="end">
                <button
                    mat-raised-button
                    (click)="updatePunches()"
                    color="primary"
                    [disabled]="!(changedPunches && changedPunches.length > 0)">
                    Spara
                </button>
            </div>
        </div>
        <div class="row header" *ngIf="hours && hours.length > 0">
            <div class="col spacer">&nbsp;</div>
            <div class="col timeline-wrapper">
                <div #timelineHeader class="row timeline">
                    <div class="col hour" *ngFor="let hour of hours">{{hour < 10 ? '0' + hour : hour}}</div>
                    <div class="last-hour">{{lastHour}}</div>
                </div>
            </div>
        </div>
        <div class="row employees-row">
            <div class="indicators">
                <div class="hours">
                    <div class="col hour" *ngFor="let hour of hours"></div>
                </div>
            </div>
            <div class="col employees">
                <div class="row employee" *ngFor="let employee of employees">
                    <div class="col name">
                        {{employee.lastName}}, {{employee.firstName}}
                        <mat-icon
                            class="messages"
                            (click)="showMessages(employee)"
                            *ngIf="employee.messages.length > 0"
                            matTooltip="Visa meddelanden"
                            [class.ignored]="employee | editPunchMessagesIgnored"
                            [matBadge]="(employee.messages | editPunchMessageReplies) > 0 ? (employee.messages | editPunchMessageReplies) : ''">
                            message
                        </mat-icon>
                    </div>
                    <div class="col timeline-wrapper">
                        <div #timelineElem class="row timeline" (click)="showInsertPunch($event, employee)">
                            <div class="line"></div>
                            <div
                                #punchElem
                                class="punch"
                                *ngFor="let punch of employee.punches; index as i"
                                [class.in]="punch.punchTypeId === 1"
                                [class.out]="punch.punchTypeId === 2"
                                [class.tight]="punch | tightPunch : employee : i"
                                [class.overtime]="punch.overtime"
                                [class.edited]="punch.edited"
                                [ngStyle]="{'left.px': (punch | editPunchPosition : timelineStartTime : pixelsPerMinute : punchElem)}"
                                (mouseenter)="mouseEnterPunch(punchElem)"
                                (mouseleave)="mouseLeavePunch()"
                                cdkDragBoundary=".timeline"
                                cdkDragLockAxis="x"
                                (cdkDragStarted)="punchDragStarted(punchElem)"
                                (cdkDragEnded)="punchDragEnded(punch, employee)"
                                (click)="showEditPunch(punch, employee)"
                                click-stop-propagation
                                cdkDrag></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row save-bottom">
            <div class="col" align="end">
                <button
                    mat-raised-button
                    (click)="updatePunches()"
                    color="primary"
                    [disabled]="!(changedPunches && changedPunches.length > 0)">
                    Spara
                </button>
            </div>
        </div>
    </div>
    <ng-template #noResults>
        <div class="col">
            <p class="no-results">Inga stämplingar att visa</p>
        </div>
    </ng-template>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
    <div
        #timelineLabel
        class="timeline-label"
        *ngIf="timelineLabelVisible"
        [ngStyle]="{'left.px': timelineLabelLeft, 'top.px': timelineLabelTop}">
        {{timelineLabelText}}
    </div>
</div>
