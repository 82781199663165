import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { remove } from 'lodash-es';
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';
import { ConfirmDialog } from '@app/shared/dialogs/confirm/confirm.dialog';
import { AttestDenyOvertimeDialog } from '@app/shared/dialogs/attest-deny-overtime/attest-deny-overtime.dialog';
import { Helpers } from '@app/helpers/helpers.class';
import { EmployeeService } from '@app/services/employee.service';
import { AuthService } from '@app/services/auth.service';
import { PunchService } from '@app/services/punch.service';
import { IPage } from '@app/interfaces/page.interface';
import { IEmployeeAttestOvertime } from '@app/interfaces/employee-attest-overtime.interface';
import { IAttestOvertimeDay } from '@app/interfaces/attest-overtime-day.interface';
import { IDenyOvertimeDialogResult } from '@app/interfaces/deny-overtime-dialog-result.interface';

@Component({
    selector: 'app-attest-overtime',
    templateUrl: './attest-overtime.partial.html',
    styleUrls: ['./attest-overtime.partial.scss'],
    animations: [
        trigger('toggle', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class AttestOvertimePartial implements OnInit {
    @Output() filterChanged: EventEmitter<any> = new EventEmitter();
    @Input('mode') inputMode: number;
    @Input('status') inputStatus: number;
    @Input('day') inputDay: string;
    @Input('query') inputQuery: string;
    @Input('page') inputPage: number;

    employeeId: number;
    filterEmployeeId: number;
    filterStatus: number;
    filterDay: string;
    filterQuery: string;
    pageIndex: number;
    employeesPage: IPage<any>;
    loading: boolean = false;
    employeeColumns: string[] = ['checkbox', 'name', 'company', 'department', 'daysWithOvertime', 'expand'];
    selection: SelectionModel<IAttestOvertimeDay> = new SelectionModel<IAttestOvertimeDay>(true);
    dataSource: MatTableDataSource<IEmployeeAttestOvertime>;
    expandedEmployee: IEmployeeAttestOvertime;
    expandedOvertimeDay: IEmployeeAttestOvertime;
    visibleEmployeeIds: number[] = [];

    constructor(
        private dialog: MatDialog,
        private employeeService: EmployeeService,
        private authService: AuthService,
        private punchService: PunchService
    ) {}

    ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
        this.filterEmployeeId = this.employeeId;
        this.filterStatus = 1;
        this.filterQuery = '';
        this.filterDay = Helpers.toShortDateString(new Date());

        if (Helpers.isDefined(this.inputMode)) {
            this.filterEmployeeId = this.inputMode == 0 ? 0 : this.employeeId;
        }

        if (Helpers.isDefined(this.inputStatus)) {
            this.filterStatus = this.inputStatus;
        }

        if (Helpers.isDefined(this.inputDay)) {
            this.filterDay = this.inputDay;
        }

        if (Helpers.isDefined(this.inputQuery)) {
            this.filterQuery = this.inputQuery;
        }

        if (Helpers.isDefined(this.inputPage)) {
            this.pageIndex = this.inputPage - 1;
        }

        this.getEmployeesPage(1);
    }

    getEmployeesPage(page: number): void {
        this.loading = true;
        let day = Helpers.toShortDateString(this.filterDay);
        this.employeeService
            .getAttestOvertimeEmployees(this.filterEmployeeId, this.filterStatus, day, this.filterQuery, page)
            .subscribe({
                next: (data) => {
                    for (let i = 0; i < data.items.length; i++) {
                        data.items[i].warningColumns = this.getOvertimeDayColumns(i);
                    }
                    this.employeesPage = data;
                    this.dataSource = new MatTableDataSource<IEmployeeAttestOvertime>(this.employeesPage.items);
                    this.selection.clear();
                    this.loading = false;
                },
                error: (error) => {
                    console.error(error);
                    Helpers.captureSentryError('Could not get employees page');
                },
            });

        let filterSettings = {
            mode: this.filterEmployeeId == 0 ? 0 : 1,
            status: this.filterStatus,
            day: day,
            query: this.filterQuery,
            page: page,
        };

        this.filterChanged.emit(filterSettings);
    }

    toggleEmployeeRow(employee: IEmployeeAttestOvertime): void {
        if (this.expandedEmployee == employee) {
            this.expandedEmployee = null;
            setTimeout(
                (e) => {
                    remove(this.visibleEmployeeIds, (employeeId) => {
                        return employeeId === e.employeeId;
                    });
                },
                1,
                employee
            );
        } else {
            this.visibleEmployeeIds.push(employee.employeeId);
            setTimeout(
                (e) => {
                    this.expandedEmployee = e;
                },
                1,
                employee
            );
        }
    }

    isAllSelected() {
        let numSelected = 0;
        let numRows = 0;

        for (let employee of this.dataSource.data) {
            for (let overtimeDay of employee.overtimeDays) {
                numRows++;
                if (this.selection.isSelected(overtimeDay)) {
                    numSelected++;
                }
            }
        }

        return numSelected === numRows;
    }

    masterToggle() {
        if (this.isAllSelected()) {
            for (let employee of this.dataSource.data) {
                for (let overtimeDay of employee.overtimeDays) {
                    this.selection.deselect(overtimeDay);
                }
            }
        } else {
            for (let employee of this.dataSource.data) {
                for (let overtimeDay of employee.overtimeDays) {
                    this.selection.select(overtimeDay);
                }
            }
        }
    }

    isAllSelectedEmployee(employee: IEmployeeAttestOvertime): boolean {
        let numSelected = 0;
        let numRows = 0;

        for (let overtimeDay of employee.overtimeDays) {
            numRows++;
            if (this.selection.isSelected(overtimeDay)) {
                numSelected++;
            }
        }

        return numSelected === numRows;
    }

    isAnySelectedEmployee(employee: IEmployeeAttestOvertime): boolean {
        for (let overtimeDay of employee.overtimeDays) {
            if (this.selection.isSelected(overtimeDay)) {
                return true;
            }
        }
    }

    masterToggleEmployee(employee: IEmployeeAttestOvertime): void {
        if (this.isAllSelectedEmployee(employee)) {
            for (let overtimeDay of employee.overtimeDays) {
                this.selection.deselect(overtimeDay);
            }
        } else {
            for (let overtimeDay of employee.overtimeDays) {
                this.selection.select(overtimeDay);
            }
        }
    }

    overtimeAction(approved: boolean): void {
        console.log(this.selection.selected.length);
        if (this.selection.isEmpty()) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Inga valda',
                    message: 'Du har inte valt några dagar',
                },
            });
            return;
        }

        if (approved) {
            let message = 'Är du säker på att du vill godkänna valda (' + this.selection.selected.length + ') dagar?';

            if (this.filterStatus == 2) {
                message = '<u><strong>OBS!</strong> Du kommer att ändra redan hanterad övertid.</u><br /><br />' + message;
            }

            let handle = this.dialog.open(ConfirmDialog, {
                width: '400px',
                data: {
                    title: 'Fortsätta?',
                    message: message,
                },
            });

            handle.afterClosed().subscribe((result: boolean) => {
                if (result) {
                    this.attest(true);
                }
            });
        } else {
            let message =
                'För att neka övertid för valda (' +
                this.selection.selected.length +
                ') dagar måste du ange en beskrivning. Beskrivningen du anger här kommer att skickas ' +
                'som ett meddelande till ekonomi och personen som stämplat.';

            if (this.filterStatus == 2) {
                message = '<u><strong>OBS!</strong> Du kommer att ändra redan hanterad övertid.</u><br /><br />' + message;
            }

            let handle = this.dialog.open(AttestDenyOvertimeDialog, {
                width: '600px',
                data: {
                    title: 'Neka övertid',
                    message: message,
                },
            });

            handle.afterClosed().subscribe((result: IDenyOvertimeDialogResult) => {
                if (result.deny) {
                    this.attest(false, result.message);
                }
            });
        }
    }

    attest(approved: boolean, message: string = ''): void {
        if (this.selection.isEmpty()) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Inga valda',
                    message: 'Du har inte valt några dagar',
                },
            });
            return;
        }

        this.loading = true;
        this.punchService.attestOvertime(this.selection.selected, approved, message).subscribe({
            next: (data) => {
                this.getEmployeesPage(1);
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not attest overtime');
            },
        });
    }

    pageChanged(event: any): void {
        this.getEmployeesPage(event.pageIndex + 1);
        this.pageIndex = event.pageIndex;
    }

    getOvertimeDayColumns(i: number): any[] {
        return [
            'checkbox_' + i,
            'day_' + i,
            'totalTime_' + i,
            'scheduledTime_' + i,
            'totalOvertime_' + i,
            'orderedBy_' + i,
            'status_' + i,
        ];
    }
}
