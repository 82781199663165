import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Helpers } from '@app/helpers/helpers.class';
import { MatDialog } from '@angular/material/dialog';

// Error handling
import * as Sentry from '@sentry/browser';

// Dialogs
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';
import { ConfirmDialog } from '@app/shared/dialogs/confirm/confirm.dialog';

// Services
import { CarDrivingLogService } from '@app/services/car-driving-log.service';
import { ICarDrivingLogExtended } from '@app/interfaces/car-driving-log-extended.interface';

@Component({
    selector: 'app-driving-log-show-trip',
    templateUrl: './driving-log-show-trip.partial.html',
    styleUrls: ['./driving-log-show-trip.partial.scss'],
})
export class DrivingLogShowTripPartial implements OnInit {
    @Output('edit') public editTrip = new EventEmitter<any>();
    @Output('end') public endTrip = new EventEmitter<any>();

    @Input() public logId: number;

    public trip: ICarDrivingLogExtended;
    public loading: boolean = false;
    public endTripForm: UntypedFormGroup;
    public tripLength: number = 0;

    constructor(
        private carDrivingLogService: CarDrivingLogService,
        private formBuilder: UntypedFormBuilder,
        private dialog: MatDialog
    ) {}

    public ngOnInit(): void {
        this.endTripForm = this.formBuilder.group({
            mileage: [null, Validators.pattern(Helpers.integerRegex)],
            endDate: [null, Validators.required],
            endTime: [null, [Validators.pattern(Helpers.shortTimeRegex), Validators.required]],
        });

        this.resetEndTripForm();
        this.getTrip();
    }

    public get f() {
        return this.endTripForm.controls;
    }

    public resetEndTripForm(): void {
        let now = new Date();
        this.endTripForm.reset();
        this.f.mileage.setValue(null);
        this.f.endDate.setValue(Helpers.toShortDateString(now));
        this.f.endTime.setValue(Helpers.toShortTimeString(now));
        this.f.endDate.disable();
    }

    public calculateTripLength(): void {
        if (
            !Helpers.isDefined(this.trip) ||
            !Helpers.isDefined(this.f.mileage.value) ||
            this.f.mileage.value < this.trip.carKilometersStart
        ) {
            this.tripLength = 0;
            return;
        }

        this.tripLength = this.f.mileage.value - this.trip.carKilometersStart;
    }

    public getTrip(): void {
        this.loading = true;
        this.carDrivingLogService.get(this.logId).subscribe({
            next: (data) => {
                this.trip = data;
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get active trip');
                this.loading = false;
            },
        });
    }

    public end(): void {
        if (!this.endTripForm.valid) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Fel i formuläret',
                    message: 'Alla fält är inte korrekt ifyllda, korrigera felen och försök igen.',
                },
            });
            Helpers.setFormTouched(this.endTripForm);
            return;
        }

        let handle = this.dialog.open(ConfirmDialog, {
            width: '400px',
            data: {
                title: 'Avsluta',
                message: 'Är du säker på att du vill avsluta resan?',
            },
        });

        handle.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.loading = true;
                this.trip.endDate = Helpers.toShortDateString(this.f.endDate.value) + 'T' + this.f.endTime.value + ':00';
                this.trip.carKilometersEnd = this.f.mileage.value;

                this.carDrivingLogService.endTrip(this.trip).subscribe({
                    next: (data) => {
                        this.endTrip.emit(this.trip);
                        this.trip = null;
                        this.loading = false;
                    },
                    error: (error) => {
                        console.error(error);

                        let title = 'Fel uppstod';
                        let message =
                            'Något gick fel när resan skulle avslutas, kontrollera ' +
                            'att allt är korrekt ifyllt och försök igen';
                        let errors: string[];

                        if (error.status === 400 && error.error.errors) {
                            message = 'Följande fel hittades';
                            errors = error.error.errors;
                        } else {
                            Helpers.captureSentryError('Could not end trip');
                        }

                        this.dialog.open(AlertDialog, {
                            width: '400px',
                            data: {
                                title: title,
                                message: message,
                                list: errors,
                            },
                        });

                        this.loading = false;
                    },
                });
            }
        });
    }

    public edit(): void {
        this.editTrip.emit(this.trip);
    }
}
