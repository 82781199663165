import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'help-dialog',
    templateUrl: './help.dialog.html',
    styleUrls: ['./help.dialog.scss']
})

export class HelpDialog implements OnInit {

    ngOnInit() {

    }

    constructor(private dialogRef: MatDialogRef<HelpDialog>) {

    }

    close() {
        this.dialogRef.close();
    }
}
