import { Component, OnInit } from '@angular/core';

// Services
import { AuthService } from '@app/services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-schedule-new-admin-page',
    templateUrl: './schedule-new.component.html',
    styleUrls: ['./schedule-new.component.scss']
})

export class ScheduleNewAdminComponent implements OnInit {
    public employeeId: number;

    constructor(private authService: AuthService,
        private activatedRoute: ActivatedRoute) { }

    ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
    }
}
