import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'weekOfYear'})
export class WeekOfYearPipe implements PipeTransform {
    transform(dateTime: string): number {
        let dateTimeCopy = new Date(+dateTime);

        dateTimeCopy.setHours(0, 0, 0);
        dateTimeCopy.setDate(dateTimeCopy.getDate() + 4 - (dateTimeCopy.getDay() || 7));

        let yearStart = new Date(dateTimeCopy.getFullYear(), 0, 1);
        let weekOfYear = Math.ceil((((dateTimeCopy.valueOf() - yearStart.valueOf()) / 86400000) + 1) / 7);

        return weekOfYear;
    }
}
