import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IEmployeeMessageEditPunch } from '@app/interfaces/employee-message-edit-punch.interface';

@Component({
    selector: 'edit-punch-reply-message-dialog',
    templateUrl: './edit-punch-reply-message.dialog.html',
    styleUrls: ['./edit-punch-reply-message.dialog.scss']
})

export class EditPunchReplyMessageDialog {
    message: IEmployeeMessageEditPunch;

    constructor(
        private dialogRef: MatDialogRef<EditPunchReplyMessageDialog>,
        @Inject(MAT_DIALOG_DATA) {
            message
        }: any) {

        this.message = message;
    }

    close(): void {
        this.dialogRef.close();
    }
}
