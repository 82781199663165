import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";

// Services
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'app-cost-pool-edit-admin-page',
    templateUrl: './cost-pool-edit.component.html',
    styleUrls: ['./cost-pool-edit.component.scss']
})

export class CostPoolEditAdminComponent implements OnInit {
    public employeeId: number;
    public poolId: number;

    constructor(private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private router: Router) { }

    ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
        this.activatedRoute.params.subscribe(params => {
            this.poolId = +params['id'];
        });
    }
}
