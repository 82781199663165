<div class="container-fluid filter">
    <div class="row" *ngIf="!externalEmployeeId">
        <div class="col">
            <mat-form-field class="example-full-width">
                <mat-label>Anställd</mat-label>
                <input
                    type="text"
                    aria-label="Employee"
                    matInput
                    [formControl]="employee"
                    [matAutocomplete]="employeeAutoComplete" />
                <mat-autocomplete
                    autoActiveFirstOption
                    #employeeAutoComplete="matAutocomplete"
                    [displayWith]="displaySelectedEmployee"
                    (optionSelected)="employeeSelected($event)">
                    <mat-option *ngFor="let employee of filteredEmployees | async" [value]="employee">
                        {{employee.lastName}}, {{employee.firstName}} ({{employee.companyShort}})
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field (click)="fromDatePicker.open()">
                <mat-label>Från</mat-label>
                <input matInput [matDatepicker]="fromDatePicker" [(ngModel)]="from" readonly />
                <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #fromDatePicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field (click)="toDatePicker.open()">
                <mat-label>Till</mat-label>
                <input matInput [matDatepicker]="toDatePicker" [(ngModel)]="to" readonly />
                <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #toDatePicker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col" align="end">
            <button mat-raised-button color="primary" (click)="getCompensations()">Visa</button>
        </div>
    </div>
    <div class="row divider">
        <div class="col">
            <mat-divider></mat-divider>
        </div>
    </div>
</div>
<div class="wrapper" *ngIf="compensations && compensations.length > 0">
    <table mat-table [dataSource]="compensations">
        <ng-container matColumnDef="day">
            <th mat-header-cell *matHeaderCellDef class="day">Datum</th>
            <td mat-cell *matCellDef="let compensation" class="day">{{compensation.travelDate | shortDate}}</td>
        </ng-container>
        <ng-container matColumnDef="distance">
            <th mat-header-cell *matHeaderCellDef class="distance">Sträcka</th>
            <td mat-cell *matCellDef="let compensation" class="distance">{{compensation.kilometers}} km</td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef class="description">Beskrivning</th>
            <td mat-cell *matCellDef="let compensation" class="description">{{compensation.description}}</td>
        </ng-container>
        <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef class="company">Företag</th>
            <td mat-cell *matCellDef="let compensation" class="company">{{compensation.company.companyName}}</td>
        </ng-container>
        <ng-container matColumnDef="paid">
            <th mat-header-cell *matHeaderCellDef class="paid">Utbetald</th>
            <td mat-cell *matCellDef="let compensation" class="paid">{{compensation.paid ? 'Ja' : 'Nej'}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="compensationColumns"></tr>
        <tr mat-row *matRowDef="let compensation; columns: compensationColumns;"></tr>
    </table>
</div>

<div class="no-compensations" *ngIf="(!compensations || compensations.length === 0) && !loading">
    Inga milrapporteringar att visa
</div>

<div class="loading-wrapper" *ngIf="loading">
    <mat-spinner diameter="40"></mat-spinner>
</div>
