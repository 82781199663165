import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

// Interfaces
import { IClaim } from '@app/interfaces/claim.interface';
import { IAdministratorClaim } from '@app/interfaces/administrator-claim.interface';

@Injectable({ providedIn: 'root' })
export class ClaimService {
    constructor(private http: HttpClient) {}

    getAll(): Observable<IClaim[]> {
        return this.http.get<IClaim[]>(environment.apiUrl + '/claims');
    }

    getByAdministrator(adminId: number): Observable<IClaim[]> {
        return this.http.get<IClaim[]>(environment.apiUrl + '/claims/by-admin/' + adminId);
    }

    grantAdministratorClaim(adminClaim: IAdministratorClaim): Observable<IAdministratorClaim> {
        return this.http.post<IAdministratorClaim>(environment.apiUrl + '/claims/by-admin/', adminClaim);
    }

    denyAdministratorClaim(adminClaim: IAdministratorClaim): Observable<any> {
        return this.http.delete<any>(environment.apiUrl + '/claims/by-admin/' + adminClaim.adminId, {
            params: new HttpParams().set('claimId', adminClaim.claimId.toString()),
        });
    }
}
