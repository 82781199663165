<app-driving-log-menu></app-driving-log-menu>

<h1 class="mat-headline-3">Redigera bil</h1>

<div class="container">
    <div class="row">
        <div class="col">
            <button mat-button class="back" routerLink="/driving-log/cars">
                <mat-icon>chevron_left</mat-icon>
                Tillbaka
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-card class="compact">
                <mat-card-content>
                    <app-driving-log-car [employeeId]="employeeId" [carId]="carId"></app-driving-log-car>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<app-driving-log-footer></app-driving-log-footer>
