import { Component, OnInit, Input, Injector, Output, EventEmitter } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import {
    EMPLOYEE_ID,
    NEW_MESSAGE,
    TO_EMPLOYEE_ID,
    WITH_EMPLOYEE_ID,
    PUNCH_WARNING_HASH,
    CLOSE_METHOD,
} from './messages.partial.tokens';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Helpers } from '@app/helpers/helpers.class';

// Error handling
import * as Sentry from '@sentry/browser';

// Partials
import { MessagesPanelPartial } from './messages-panel/messages-panel.partial';

// Services
import { EmployeeMessageService } from '@app/services/employee-message.service';

// Interfaces
import { IEmployeeMessageNewCount } from '@app/interfaces/employee-message-new-count.interface';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.partial.html',
    styleUrls: ['./messages.partial.scss'],
})
export class MessagesPartial implements OnInit {
    @Input() employeeId: number;
    @Output() opened = new EventEmitter<void>();
    @Output() closed = new EventEmitter<void>();

    isOpen: boolean = false;
    loading: boolean = false;
    panelHandle: OverlayRef;
    newMessage: string;
    toEmployeeId: number;
    withEmployeeId: number;
    punchWarningHash: string;
    newMessagesCount: IEmployeeMessageNewCount;

    constructor(
        private overlay: Overlay,
        private injector: Injector,
        private snackBar: MatSnackBar,
        private messageService: EmployeeMessageService
    ) {}

    ngOnInit(): void {
        this.getNewMessagesCount();
    }

    writeNew(newMessage: string, toEmployeeId: number): void {
        this.newMessage = newMessage;
        this.toEmployeeId = toEmployeeId;
        this.openPanel();
    }

    showConversation(withEmployeeId: number): void {
        this.withEmployeeId = withEmployeeId;
        this.openPanel();
    }

    writePunchWarningMessage(punchWarningHash: string, newMessage: string): void {
        this.punchWarningHash = punchWarningHash;
        this.newMessage = newMessage;
        this.toEmployeeId = Helpers.economyContactEmployeeId;
        this.openPanel();
    }

    getNewMessagesCount(): void {
        this.messageService.getNewMessagesCount(this.employeeId).subscribe({
            next: (data) => {
                this.newMessagesCount = data;

                if (this.newMessagesCount.newMessages > 0) {
                    this.snackBar.open(
                        'Du har ' +
                            this.newMessagesCount.newMessages +
                            (this.newMessagesCount.newMessages > 1 ? ' nya meddelanden' : ' nytt meddelande') +
                            '. Klicka på ikonen nere i högra hörnet för att visa meddelanden.',
                        '',
                        {
                            duration: 10000,
                        }
                    );
                }
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get new messages count');
            },
        });
    }

    openPanel(): void {
        const positionStrategy = this.overlay.position().global().right().top();

        this.panelHandle = this.overlay.create({
            hasBackdrop: true,
            backdropClass: 'messages-panel-backdrop',
            panelClass: 'messages-panel',
            positionStrategy: positionStrategy,
            scrollStrategy: this.overlay.scrollStrategies.block(),
        });

        const injectionTokens = new WeakMap();
        injectionTokens.set(OverlayRef, this.panelHandle);
        injectionTokens.set(EMPLOYEE_ID, this.employeeId);
        injectionTokens.set(NEW_MESSAGE, Helpers.isDefined(this.newMessage) ? this.newMessage : '');
        injectionTokens.set(TO_EMPLOYEE_ID, Helpers.isDefined(this.toEmployeeId) ? this.toEmployeeId : 0);
        injectionTokens.set(WITH_EMPLOYEE_ID, Helpers.isDefined(this.withEmployeeId) ? this.withEmployeeId : 0);
        injectionTokens.set(PUNCH_WARNING_HASH, Helpers.isDefined(this.punchWarningHash) ? this.punchWarningHash : '');
        injectionTokens.set(CLOSE_METHOD, (e) => {
            this.closePanel();
        });

        this.newMessage = null;
        this.toEmployeeId = null;
        this.withEmployeeId = null;
        this.punchWarningHash = null;

        const injector = new PortalInjector(this.injector, injectionTokens);
        const panel = new ComponentPortal(MessagesPanelPartial, null, injector);

        this.panelHandle.attach(panel);
        this.panelHandle.backdropClick().subscribe((e) => this.closePanel());
        this.opened.emit();
    }

    closePanel(): void {
        this.panelHandle.dispose();
        this.closed.emit();
    }
}
