<app-admin-menu></app-admin-menu>

<h1 class="mat-headline-3">Reseersättning</h1>

<div class="container">
    <div class="row">
        <div class="col">
            <mat-card class="compact">
                <mat-card-content>
                    <app-travel-compensations-list (filterChanged)="updateQueryParameters($event)" [query]="query"
                        [companyIds]="companyIds" [employmentTypeIds]="employmentTypeIds"
                        [departmentIds]="departmentIds" [travelDateFrom]="travelDateFrom" [travelDateTo]="travelDateTo"
                        [paid]="paid" [page]="page"></app-travel-compensations-list>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<app-messages #messagesPartial [employeeId]="employeeId"></app-messages>

<app-admin-footer></app-admin-footer>
