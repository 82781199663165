<h2 mat-dialog-title>Återställ lösenord</h2>
<mat-dialog-content>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <ng-container *ngIf="!passwordExpired; else passwordExpiredContent">
                    <p>
                        Ange din inloggningskod nedan och klicka på "Återställ". Ett mail kommer skickas till den e-postadress som
                        finns knuten till ditt konto med vidare instruktioner.
                    </p>
                </ng-container>
                <ng-template #passwordExpiredContent>
                    <p>
                        Ditt lösenord är för gammalt och måste bytas innan du kan fortsätta. Ange din kod och klicka på knappen
                        för att påbörja bytet.
                    </p>
                    <p>
                        Ett mail kommer skickas till den e-postadress som finns knuten till ditt konto med vidare instruktioner.
                    </p>
                </ng-template>
                <mat-form-field>
                    <mat-label>Kod</mat-label>
                    <input
                        matInput
                        type="password"
                        autocomplete="off"
                        [(ngModel)]="code"
                        (keydown.enter)="reset()"
                        name="resetEmployeePasswordLoginCode" />
                </mat-form-field>
            </div>
        </div>
    </div>
    <app-loading-overlay *ngIf="loading"></app-loading-overlay>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()" *ngIf="!passwordExpired">Avbryt</button>
    <button mat-raised-button (click)="logout()" *ngIf="passwordExpired">Logga ut</button>
    <button mat-raised-button color="primary" (click)="reset()">Återställ</button>
</mat-dialog-actions>
