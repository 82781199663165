import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from "@angular/router";
import * as Sentry from "@sentry/browser";

// Services
import { AuthService } from '@app/services/auth.service';
import { EmployeeService } from '@app/services/employee.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';

@Component({
    selector: 'app-employee-details-admin-page',
    templateUrl: './employee-details.component.html',
    styleUrls: ['./employee-details.component.scss']
})

export class EmployeeDetailsAdminComponent implements OnInit {
    employeeId: number;
    editEmployeeId: number;
    loading: boolean = false;

    constructor(private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private employeeService: EmployeeService,
        public dialog: MatDialog) { }

    ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
        this.activatedRoute.params.subscribe(params => {
            this.editEmployeeId = +params['id'];
        });
    }
}
