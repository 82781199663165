import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Helpers } from '@app/helpers/helpers.class';
import { MatDialog } from '@angular/material/dialog';
import { AbsenceService } from '@app/services/absence.service';
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';
import { IAbsenceType } from '@app/interfaces/absence-type.interface';
import { IEmployeeAbsence } from '@app/interfaces/employee-absence.interface';
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'app-report-absence',
    templateUrl: './report-absence.partial.html',
    styleUrls: ['./report-absence.partial.scss'],
})
export class ReportAbsencePartial implements OnInit {
    @Input() employeeId: number;
    @ViewChild('fromTime', { static: true }) fromTime: ElementRef;
    @Output() absenceAdded = new EventEmitter<IEmployeeAbsence[]>();

    loading: boolean = false;
    mode: string = '1';
    absenceFormSingle: UntypedFormGroup;
    absenceFormMultiple: UntypedFormGroup;
    types: IAbsenceType[];

    constructor(
        private absenceService: AbsenceService,
        private formBuilder: UntypedFormBuilder,
        public dialog: MatDialog,
        public authService: AuthService
    ) {}

    ngOnInit(): void {
        this.absenceFormSingle = this.formBuilder.group({
            day: ['', Validators.required],
            fromTime: ['', [Validators.required, Validators.pattern(Helpers.shortTimeRegex)]],
            toTime: ['', [Validators.required, Validators.pattern(Helpers.shortTimeRegex)]],
            type: ['', Validators.required],
            comment: '',
        });

        this.absenceFormMultiple = this.formBuilder.group({
            fromDate: ['', Validators.required],
            toDate: ['', Validators.required],
            type: ['', Validators.required],
            comment: '',
        });

        this.resetForms();
        this.getTypes();
    }

    setDay(day: Date): void {
        this.absenceFormSingle.get('day').setValue(day);
    }

    setMode(mode: number): void {
        this.mode = mode.toString();
    }

    focusFromTime(): void {
        this.fromTime.nativeElement.focus();
    }

    getTypes(): void {
        if (this.authService.isAdminMode()) {
            this.absenceService.getTypes().subscribe({
                next: (data) => {
                    this.types = data;
                },
                error: (error) => {
                    console.error(error);
                    Helpers.captureSentryError('Could not get types');
                },
            });
        } else {
            this.absenceService.getTypesValidForEmployee(this.authService.getEmployeeId()).subscribe({
                next: (data) => {
                    this.types = data;
                },
                error: (error) => {
                    console.error(error);
                    Helpers.captureSentryError('Could not get types');
                },
            });
        }
    }

    resetForms(): void {
        this.absenceFormSingle.reset();
        this.absenceFormMultiple.reset();
        this.absenceFormSingle.get('day').setValue(new Date());
        this.absenceFormMultiple.get('fromDate').setValue(new Date());
        this.absenceFormMultiple.get('toDate').setValue(new Date());
    }

    save(): void {
        let valid = false;

        if (this.mode === '1') {
            valid = this.absenceFormSingle.valid;
        } else {
            valid = this.absenceFormMultiple.valid;
        }

        if (!valid) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Fel i formuläret',
                    message: 'Alla fält är inte korrekt ifyllda, korrigera felen och försök igen.',
                },
            });
            return;
        }

        let absence: IEmployeeAbsence = {
            absenceTypeId: 0,
            absenceId: 0,
            employeeId: this.employeeId,
            added: '',
            comment: '',
            fromDate: '',
            toDate: '',
        };

        if (this.mode === '1') {
            absence.absenceTypeId = this.absenceFormSingle.get('type').value;
            absence.comment = this.absenceFormSingle.get('comment').value;
            absence.fromDate =
                Helpers.toShortDateString(this.absenceFormSingle.get('day').value) +
                ' ' +
                this.absenceFormSingle.get('fromTime').value;
            absence.toDate =
                Helpers.toShortDateString(this.absenceFormSingle.get('day').value) +
                ' ' +
                this.absenceFormSingle.get('toTime').value;
        } else {
            absence.absenceTypeId = this.absenceFormMultiple.get('type').value;
            absence.comment = this.absenceFormMultiple.get('comment').value;
            absence.fromDate = Helpers.toShortDateString(this.absenceFormMultiple.get('fromDate').value);
            absence.toDate = Helpers.toShortDateString(this.absenceFormMultiple.get('toDate').value);
        }

        this.loading = true;
        this.absenceService.addForEmployee(absence).subscribe({
            next: (data) => {
                this.loading = false;
                this.resetForms();
                this.absenceAdded.emit(data);
            },
            error: (error) => {
                this.loading = false;
                console.error(error);

                let title = 'Fel uppstod';
                let message =
                    'Något gick fel när frånvaron skulle sparas, ' + 'kontrollera att allt är korrekt ifyllt och försök igen';
                let errors: string[];

                if (error.status === 400 && error.error.errors) {
                    message = 'Informationen du angav var felaktig, ' + 'kontrollera meddeladena nedan och försök igen.';
                    errors = error.error.errors;
                } else {
                    Helpers.captureSentryError('Could not save absence');
                }

                this.dialog.open(AlertDialog, {
                    width: '400px',
                    data: {
                        title: title,
                        message: message,
                        list: errors,
                    },
                });
            },
        });
    }
}
