import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Helpers } from '@app/helpers/helpers.class';
import { MatDialog } from '@angular/material/dialog';

// Error handling
import * as Sentry from '@sentry/browser';

// Dialogs
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';

// Services
import { CarService } from '@app/services/car.service';
import { CompanyService } from '@app/services/company.service';
import { CarDrivingLogService } from '@app/services/car-driving-log.service';

// Interfaces
import { ICar } from '@app/interfaces/car.interface';
import { ICompany } from '@app/interfaces/company.interface';
import { ICarDrivingLog } from '@app/interfaces/car-driving-log.interface';
import { ICarDrivingLogExtended } from '@app/interfaces/car-driving-log-extended.interface';
import { ICarDrivingLogSuggestedData } from '@app/interfaces/car-driving-log-suggested-data.interface';

@Component({
    selector: 'app-driving-log-trip',
    templateUrl: './driving-log-trip.partial.html',
    styleUrls: ['./driving-log-trip.partial.scss'],
})
export class DrivingLogTripPartial implements OnInit {
    @Input() public employeeId: number;

    public cars: ICar[] = [];
    public companies: ICompany[] = [];
    public tripForm: UntypedFormGroup;
    public loading: boolean = true;
    public activeTripLogId: number;
    public tripLength: number = 0;
    public editing: boolean = false;
    public suggestedData: ICarDrivingLogSuggestedData = null;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private carService: CarService,
        private companyService: CompanyService,
        private carDrivingLogService: CarDrivingLogService,
        private dialog: MatDialog
    ) {}

    public ngOnInit(): void {
        this.tripForm = this.formBuilder.group({
            car: [null, Validators.pattern(Helpers.integerRegex)],
            company: [null, Validators.pattern(Helpers.integerRegex)],
            mileage: [null, Validators.pattern(Helpers.integerRegex)],
            startDate: [null, Validators.required],
            startTime: [null, [Validators.pattern(Helpers.shortTimeRegex), Validators.required]],
            description: ['', Validators.required],
            private: [false, Validators.required],
        });

        this.resetTripForm();

        this.getCars();
        this.getCompanies();
        this.getActiveTrip();
    }

    public get f() {
        return this.tripForm.controls;
    }

    public resetTripForm(): void {
        let now = new Date();
        this.tripForm.reset();
        this.f.car.setValue(null);
        this.f.company.setValue(null);
        this.f.mileage.setValue(null);
        this.f.startDate.setValue(Helpers.toShortDateString(now));
        this.f.startTime.setValue(Helpers.toShortTimeString(now));
        this.f.description.setValue(null);
        this.f.private.setValue(false);
        this.f.startDate.disable();

        if (!this.editing) {
            this.getSuggestedData();
        }
    }

    public getCars(): void {
        this.carService.getByEmployee(this.employeeId).subscribe({
            next: (data) => {
                this.cars = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get cars');
            },
        });
    }

    public typeChanged(): void {
        if (this.f.private.value) {
            this.f.company.clearValidators();
            this.f.company.updateValueAndValidity();
            if (!Helpers.isDefined(this.f.description.value) || this.f.description.value.length === 0) {
                this.f.description.setValue('Privat');
            }
        } else {
            this.f.company.setValidators([Validators.pattern(Helpers.integerRegex)]);
            this.f.company.updateValueAndValidity();
            if (this.f.description.value === 'Privat') {
                this.f.description.setValue('');
            }
        }
    }

    public getCompanies(): void {
        this.companyService.getAllActive().subscribe({
            next: (data) => {
                this.companies = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get companies');
            },
        });
    }

    public getActiveTrip(): void {
        this.carDrivingLogService.getActiveTripByEmployee(this.employeeId).subscribe({
            next: (data) => {
                if (Helpers.isDefined(data)) {
                    this.activeTripLogId = data.logId;
                }

                this.loading = false;

                if (this.editing) {
                    this.cancelEdit();
                }
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get active trip');
                this.loading = false;
            },
        });
    }

    private validateTripForm(): boolean {
        if (!this.tripForm.valid) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Fel i formuläret',
                    message: 'Alla fält är inte korrekt ifyllda, korrigera felen och försök igen.',
                },
            });
            Helpers.setFormTouched(this.tripForm);
            return false;
        }

        return true;
    }

    public create(): void {
        if (!this.validateTripForm()) {
            return;
        }

        this.loading = true;

        let log: ICarDrivingLog = {
            employeeId: this.employeeId,
            private: this.f.private.value,
            carId: this.f.car.value,
            companyId: this.f.company.value,
            carKilometersStart: this.f.mileage.value,
            startDate: Helpers.toShortDateString(this.f.startDate.value) + 'T' + this.f.startTime.value + ':00',
            description: this.f.description.value,
        };

        this.carDrivingLogService.startNewTrip(log).subscribe({
            next: (data) => {
                this.getActiveTrip();
            },
            error: (error) => {
                console.error(error);

                let title = 'Fel uppstod';
                let message =
                    'Något gick fel när resan skulle startas, kontrollera ' + 'att allt är korrekt ifyllt och försök igen';
                let errors: string[];

                if (error.status === 400 && error.error.errors) {
                    message = 'Följande fel hittades';
                    errors = error.error.errors;
                } else {
                    Helpers.captureSentryError('Could not start new trip');
                }

                this.dialog.open(AlertDialog, {
                    width: '400px',
                    data: {
                        title: title,
                        message: message,
                        list: errors,
                    },
                });

                this.loading = false;
            },
        });
    }

    public update(): void {
        if (!this.validateTripForm() || !Helpers.isDefined(this.activeTripLogId) || !this.editing) {
            return;
        }

        this.loading = true;

        let log: ICarDrivingLog = {
            logId: this.activeTripLogId,
            employeeId: this.employeeId,
            private: this.f.private.value,
            carId: this.f.car.value,
            companyId: this.f.private.value ? null : this.f.company.value,
            carKilometersStart: this.f.mileage.value,
            startDate: Helpers.toShortDateString(this.f.startDate.value) + 'T' + this.f.startTime.value + ':00',
            description: this.f.description.value,
        };

        this.carDrivingLogService.update(log).subscribe({
            next: (data) => {
                this.loading = false;
                this.editing = false;
                this.getActiveTrip();
            },
            error: (error) => {
                console.error(error);

                let title = 'Fel uppstod';
                let message =
                    'Något gick fel när resan skulle uppdateras, kontrollera ' + 'att allt är korrekt ifyllt och försök igen';
                let errors: string[];

                if (error.status === 400 && error.error.errors) {
                    message = 'Följande fel hittades';
                    errors = error.error.errors;
                } else {
                    Helpers.captureSentryError('Could not update trip');
                }

                this.dialog.open(AlertDialog, {
                    width: '400px',
                    data: {
                        title: title,
                        message: message,
                        list: errors,
                    },
                });

                this.loading = false;
            },
        });
    }

    public edit(trip: ICarDrivingLogExtended): void {
        if (!Helpers.isDefined(trip)) {
            return;
        }

        this.editing = true;
        this.resetTripForm();
        this.f.car.setValue(trip.carId);
        this.f.company.setValue(trip.companyId);
        this.f.mileage.setValue(trip.carKilometersStart);
        this.f.startDate.setValue(Helpers.toShortDateString(trip.startDate));
        this.f.startTime.setValue(Helpers.toShortTimeString(trip.startDate));
        this.f.description.setValue(trip.description);
        this.f.private.setValue(trip.private);
    }

    public cancelEdit(): void {
        this.resetTripForm();
        this.editing = false;
    }

    public getSuggestedData(): void {
        this.carDrivingLogService.getSuggestedData(this.employeeId).subscribe({
            next: (data) => {
                this.suggestedData = data;

                if (Helpers.isDefined(this.suggestedData)) {
                    this.f.car.setValue(this.suggestedData.carId);
                    this.f.company.setValue(this.suggestedData.companyId);
                    this.f.mileage.setValue(this.suggestedData.carKilometersStart);
                    this.f.private.setValue(this.suggestedData.private);
                    this.typeChanged();
                }
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get suggested data');
            },
        });
    }

    public end(trip: ICarDrivingLogExtended): void {
        this.activeTripLogId = null;
        this.getSuggestedData();
    }
}
