import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'app-admin-reset-password-page',
    templateUrl: './admin-reset-password.component.html',
    styleUrls: ['./admin-reset-password.component.scss'],
})
export class AdminResetPasswordComponent implements OnInit {
    public employeeId: number;
    public token: string;

    constructor(
        private authService: AuthService,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
        this.activatedRoute.queryParams.subscribe((params) => {
            this.token = params['token'];
        });
    }
}
