<div class="container-fluid">
    <div class="row filters">
        <div class="col query-filter">
            <label>Sök</label>
            <mat-form-field>
                <input
                    matInput
                    [(ngModel)]="filterQuery"
                    autocomplete="off"
                    placeholder="Fritextsök..."
                    (keydown.enter)="getPunchesPage(1)" />
            </mat-form-field>
        </div>
        <div class="col company-filter">
            <label>Bolag</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filterCompanyIds" placeholder="Välj bolag" multiple>
                    <mat-option *ngFor="let company of companies" [value]="company.companyId"
                        >{{company.companyName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col employment-type-filter">
            <label>Anställning</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filterEmploymentTypeIds" placeholder="Välj anställningsform" multiple>
                    <mat-option *ngFor="let employmentType of employmentTypes" [value]="employmentType.employmentTypeId">
                        {{employmentType.employmentTypeName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row filters">
        <div class="col company-department-filter">
            <label>Avdelning</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filterDepartmentIds" placeholder="Välj avdelning" multiple>
                    <mat-option *ngFor="let department of companyDepartments" [value]="department.departmentId">
                        {{companies | valueFromObjectArray:'companyId':department.companyId:'companyShort'}} -
                        {{department.departmentName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col staffing-company-filter">
            <label>Bemanningsföretag</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filterStaffingCompanyIds" placeholder="Välj bemanningsföretag" multiple>
                    <mat-option *ngFor="let company of staffingCompanies" [value]="company.staffingCompanyId">
                        {{company.companyName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col punch-date-filter">
            <label>Stämpeldatum</label>
            <div class="date-range">
                <mat-form-field class="from" (click)="pickerFrom.open()">
                    <input matInput [matDatepicker]="pickerFrom" [(ngModel)]="filterPunchDateFrom" placeholder="Från" readonly />
                    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFrom></mat-datepicker>
                </mat-form-field>
                <span>-</span>
                <mat-form-field class="to" (click)="pickerTo.open()">
                    <input matInput [matDatepicker]="pickerTo" [(ngModel)]="filterPunchDateTo" placeholder="Till" readonly />
                    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                    <mat-datepicker #pickerTo></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="col button-filter">
            <button mat-raised-button (click)="exportPunchesPreCheck()">
                <mat-icon>attach_file</mat-icon>
                Exportera till fil
            </button>
            <button mat-raised-button (click)="getPunchesPage(1)" color="primary">Sök</button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="divider-wrapper">
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col" *ngIf="punchesPage && punchesPage.totalItems > 0; else noResults;">
            <mat-paginator
                *ngIf="punchesPage && punchesPage.totalItems > punchesPage.pageSize"
                [length]="punchesPage.totalItems"
                [pageSize]="punchesPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
            <div class="table-wrapper">
                <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="name">Namn</th>
                        <td mat-cell *matCellDef="let employee" class="name">{{employee.lastName}}, {{employee.firstName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="totalTime">
                        <th mat-header-cell *matHeaderCellDef class="total-time">Arbetad tid</th>
                        <td mat-cell *matCellDef="let employee" class="total-time">
                            <span
                                *ngIf="employee.totalMinutes > 0; else noTime"
                                (click)="showTimePerCostPool(employee)"
                                [matTooltip]="'Klicka för att visa tid per KST.' + ((employee | hasMultipleCostPools) ? '\nHar arbetat på fler än ett KST.' : '')"
                                [class.multiple-pools]="employee | hasMultipleCostPools"
                                click-stop-propagation>
                                {{employee.totalMinutes | readableTimeSpan}}
                            </span>
                            <ng-template #noTime>-</ng-template>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="totalOvertime">
                        <th mat-header-cell *matHeaderCellDef class="total-over-time">Varav Ö/M.t.</th>
                        <td mat-cell *matCellDef="let employee" class="total-over-time">
                            <span
                                class="{{employee.days | employeeOvertimeClass}}"
                                matTooltip="{{(employee.totalExtraWorkMinutes > 0 ? (employee.totalExtraWorkMinutes | readableTimeSpan) + ' mertid\n' : '') +
                                (employee.totalOvertimeMinutes > 0 ? (employee.totalOvertimeMinutes | readableTimeSpan) + ' övertid' : '')}}">
                                {{ employee.totalOvertimeMinutes === 0 && employee.totalExtraWorkMinutes === 0 ? '-' :
                                (employee.totalOvertimeMinutes + employee.totalExtraWorkMinutes | readableTimeSpan) }}
                            </span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="totalUnsocialHours">
                        <th mat-header-cell *matHeaderCellDef class="total-unsocial-hours">Varav OB</th>
                        <td mat-cell *matCellDef="let employee" class="total-unsocial-hours">
                            {{employee.totalUnsocialHoursMinutes === 0 ? '-' : (employee.totalUnsocialHoursMinutes |
                            readableTimeSpan)}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="totalAbsence">
                        <th mat-header-cell *matHeaderCellDef class="total-absence">Frånvaro</th>
                        <td mat-cell *matCellDef="let employee" class="total-absence">
                            <span
                                *ngIf="employee.totalAbsenceMinutes != employee.validAbsenceMinutes; else noDiffAbsence"
                                matTooltip="Giltig frånvaro: {{employee.validAbsenceMinutes | readableTimeSpan}}">
                                {{employee.totalAbsenceMinutes | readableTimeSpan}}
                            </span>
                            <ng-template #noDiffAbsence>
                                {{employee.totalAbsenceMinutes === 0 ? '-' : (employee.totalAbsenceMinutes | readableTimeSpan)}}
                            </ng-template>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="scheduledTime">
                        <th mat-header-cell *matHeaderCellDef class="scheduled-time">Schemalagt</th>
                        <td mat-cell *matCellDef="let employee" class="scheduled-time">
                            {{employee.scheduledMinutes === 0 ? '-' : (employee.scheduledMinutes | readableTimeSpan)}}
                            <mat-icon *ngIf="employee.hasActiveSchedule" class="has-active-schedule" click-stop-propagation
                                >calendar_today</mat-icon
                            >
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="statusIcons">
                        <th mat-header-cell *matHeaderCellDef class="status-icons"></th>
                        <td mat-cell *matCellDef="let employee" class="status-icons">
                            <mat-icon
                                class="severe"
                                *ngIf="employee.days | hasPunchWarnings : 1; else checkWarnings"
                                (click)="showWarnings(employee)"
                                click-stop-propagation
                                [class.all-fixed]="!(employee.days | hasUnfixedPunchWarnings)"
                                matTooltip="Visa varningar">
                                warning
                            </mat-icon>
                            <ng-template #checkWarnings>
                                <mat-icon
                                    class="warning"
                                    *ngIf="employee.days | hasPunchWarnings : 2"
                                    (click)="showWarnings(employee)"
                                    click-stop-propagation
                                    [class.all-fixed]="!(employee.days | hasUnfixedPunchWarnings)"
                                    matTooltip="Visa varningar"
                                    >warning</mat-icon
                                >
                            </ng-template>
                            <mat-icon
                                class="overtime-denied severe"
                                *ngIf="employee.hasDeniedOvertime"
                                matTooltip="Har nekad övertid"
                                >block</mat-icon
                            >
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="expand">
                        <th mat-header-cell *matHeaderCellDef class="expand"></th>
                        <td mat-cell *matCellDef="let employee" class="expand">
                            <mat-icon *ngIf="employee != expandedEmployee">keyboard_arrow_down</mat-icon>
                            <mat-icon *ngIf="employee == expandedEmployee">keyboard_arrow_up</mat-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="days">
                        <td mat-cell *matCellDef="let employee" [attr.colspan]="employeeColumns.length">
                            <div class="punch-days-wrapper" [@toggle]="employee == expandedEmployee ? 'expanded' : 'collapsed'">
                                <table
                                    class="punch-days"
                                    mat-table
                                    [dataSource]="employee.days"
                                    multiTemplateDataRows
                                    *ngIf="visibleEmployeeIds.indexOf(employee.employeeId) > -1">
                                    <ng-container [matColumnDef]="employee.punchDayColumns[0]">
                                        <th mat-header-cell *matHeaderCellDef class="punch-day">Dag</th>
                                        <td
                                            mat-cell
                                            *matCellDef="let punchDay"
                                            class="punch-day"
                                            [class.holiday]="punchDay.isHoliday">
                                            {{punchDay.day | shortDate}} - <i>{{punchDay.day | dayOfWeekShort}}</i>
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="employee.punchDayColumns[1]">
                                        <th mat-header-cell *matHeaderCellDef class="punch-day-total-time">Arbetad tid</th>
                                        <td mat-cell *matCellDef="let punchDay" class="punch-day-total-time">
                                            {{punchDay.totalMinutes === 0 ? '-' : (punchDay.totalMinutes | readableTimeSpan)}}
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="employee.punchDayColumns[2]">
                                        <th mat-header-cell *matHeaderCellDef class="punch-day-total-over-time">Varav Ö/M.t.</th>
                                        <td mat-cell *matCellDef="let punchDay" class="punch-day-total-over-time">
                                            <span
                                                *ngIf="punchDay.totalOvertimeMinutes + punchDay.totalExtraWorkMinutes > 0; else noOvertime"
                                                matTooltip="{{punchDay.overtimeApproved !== null ?
                                                    (punchDay.overtimeApproved ? 'Godkänd tid' : 'Ej godkänd tid') : 'Ej attesterad tid'}}
                                                    Beordrat av: {{punchDay.punches | overtimeEmployeeName}}
                                                    {{(punchDay.totalExtraWorkMinutes > 0 ? (punchDay.totalExtraWorkMinutes | readableTimeSpan) + ' mertid\n' : '') +
                                                    (punchDay.totalOvertimeMinutes > 0 ? (punchDay.totalOvertimeMinutes | readableTimeSpan) + ' övertid' : '')}}"
                                                [class.approved]="punchDay.overtimeApproved"
                                                [class.not-approved]="punchDay.overtimeApproved !== null && !punchDay.overtimeApproved">
                                                {{punchDay.totalOvertimeMinutes + punchDay.totalExtraWorkMinutes |
                                                readableTimeSpan}}
                                            </span>
                                            <ng-template #noOvertime>-</ng-template>
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="employee.punchDayColumns[3]">
                                        <th mat-header-cell *matHeaderCellDef class="punch-day-total-unsocial-hours">Varav OB</th>
                                        <td mat-cell *matCellDef="let punchDay" class="punch-day-total-unsocial-hours">
                                            {{punchDay.totalUnsocialHoursMinutes === 0 ? '-' : (punchDay.totalUnsocialHoursMinutes
                                            | readableTimeSpan)}}
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="employee.punchDayColumns[4]">
                                        <th mat-header-cell *matHeaderCellDef class="punch-day-total-absence">Frånvaro</th>
                                        <td mat-cell *matCellDef="let punchDay" class="punch-day-total-absence">
                                            <span
                                                *ngIf="punchDay.totalAbsenceMinutes > 0; else noAbsence"
                                                matTooltip="Giltig frånvaro: {{punchDay.validAbsenceMinutes | readableTimeSpan}}
                                                {{punchDay.validAbsenceMinutes > 0 ? 'varav\n' + (punchDay.workTime | absenceList) : ''}}">
                                                {{punchDay.totalAbsenceMinutes | readableTimeSpan}}
                                            </span>
                                            <ng-template #noAbsence>
                                                {{punchDay.totalAbsenceMinutes === 0 ? '-' : (punchDay.totalAbsenceMinutes |
                                                readableTimeSpan)}}
                                            </ng-template>
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="employee.punchDayColumns[5]">
                                        <th mat-header-cell *matHeaderCellDef class="punch-day-scheduled-time">Schemalagt</th>
                                        <td mat-cell *matCellDef="let punchDay" class="punch-day-scheduled-time">
                                            {{punchDay.scheduledMinutes === 0 ? '-' : (punchDay.scheduledMinutes |
                                            readableTimeSpan)}}
                                            <mat-icon
                                                *ngIf="punchDay.hasActiveSchedule"
                                                class="has-active-schedule"
                                                matTooltip="Visa schema"
                                                (click)="showEmployeeSchedule(employee.employeeId, punchDay.day)"
                                                click-stop-propagation
                                                >calendar_today</mat-icon
                                            >
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="employee.punchDayColumns[6]">
                                        <th mat-header-cell *matHeaderCellDef class="status-icons"></th>
                                        <td mat-cell *matCellDef="let punchDay" class="status-icons">
                                            <mat-icon
                                                class="severe"
                                                *ngIf="employee.days | hasPunchWarnings : 1 : punchDay.day; else checkWarnings"
                                                (click)="showWarnings(employee, punchDay.day)"
                                                click-stop-propagation
                                                matTooltip="Visa varningar"
                                                >warning</mat-icon
                                            >
                                            <ng-template #checkWarnings>
                                                <mat-icon
                                                    class="warning"
                                                    *ngIf="employee.days | hasPunchWarnings : 2 : punchDay.day"
                                                    (click)="showWarnings(employee, punchDay.day)"
                                                    click-stop-propagation
                                                    matTooltip="Visa varningar"
                                                    >warning
                                                </mat-icon>
                                            </ng-template>
                                            <mat-icon
                                                class="overtime-denied severe"
                                                *ngIf="punchDay.overtimeApproved === false"
                                                [matTooltip]="'Har nekad övertid denna dag' + (punchDay.overtimeAttestComment?.length > 0 ? ', kommentar:
                                                &quot;' + punchDay.overtimeAttestComment + '&quot;' : '')"
                                                >block</mat-icon
                                            >
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="employee.punchDayColumns[7]">
                                        <th mat-header-cell *matHeaderCellDef class="expand"></th>
                                        <td mat-cell *matCellDef="let punchDay" class="expand">
                                            <mat-icon *ngIf="punchDay != expandedPunchDay && punchDay.punches.length > 0">
                                                keyboard_arrow_down</mat-icon
                                            >
                                            <mat-icon *ngIf="punchDay == expandedPunchDay && punchDay.punches.length > 0">
                                                keyboard_arrow_up</mat-icon
                                            >
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="'punches_' + employee.index">
                                        <td mat-cell *matCellDef="let punchDay" [attr.colspan]="employee.punchDayColumns.length">
                                            <div
                                                class="punches-wrapper"
                                                [@toggle]="punchDay == expandedPunchDay ? 'expanded' : 'collapsed'"
                                                *ngIf="punchDay.punches.length > 0">
                                                <table class="punches" mat-table [dataSource]="punchDay.punches">
                                                    <ng-container [matColumnDef]="punchDay.punchColumns[0]">
                                                        <th mat-header-cell *matHeaderCellDef class="punch-type-icon"></th>
                                                        <td mat-cell *matCellDef="let punch" class="punch-type-icon">
                                                            <mat-icon *ngIf="punch.punchTypeId === 1" class="in">
                                                                arrow_forward</mat-icon
                                                            >
                                                            <mat-icon *ngIf="punch.punchTypeId === 2" class="out">
                                                                arrow_back</mat-icon
                                                            >
                                                        </td>
                                                    </ng-container>
                                                    <ng-container [matColumnDef]="punchDay.punchColumns[1]">
                                                        <th mat-header-cell *matHeaderCellDef class="punch-type-name">Typ</th>
                                                        <td mat-cell *matCellDef="let punch" class="punch-type-name">
                                                            <span *ngIf="punch.punchTypeId === 1; else punchOut">IN</span>
                                                            <ng-template #punchOut>
                                                                <span>UT</span>
                                                            </ng-template>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container [matColumnDef]="punchDay.punchColumns[2]">
                                                        <th mat-header-cell *matHeaderCellDef class="punch-time">Tid</th>
                                                        <td mat-cell *matCellDef="let punch" class="punch-time">
                                                            {{punch.punchTime | shortTime}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container [matColumnDef]="punchDay.punchColumns[3]">
                                                        <th mat-header-cell *matHeaderCellDef class="punch-cost-pool">
                                                            Kostnadsställe
                                                        </th>
                                                        <td mat-cell *matCellDef="let punch" class="punch-cost-pool">
                                                            {{punch.costPool ? punch.costPool.name : '-'}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row *matHeaderRowDef="punchDay.punchColumns"></tr>
                                                    <tr
                                                        mat-row
                                                        *matRowDef="let punch; columns: punchDay.punchColumns;"
                                                        [class.virtual]="punch.virtual"></tr>
                                                </table>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="employee.punchDayColumns"></tr>
                                    <tr
                                        mat-row
                                        *matRowDef="let punchDay; columns: employee.punchDayColumns;"
                                        class="punch-day-row"
                                        [class.no-punches]="punchDay.punches.length === 0"
                                        (click)="expandedPunchDay == punchDay ? expandedPunchDay = null : expandedPunchDay = punchDay"></tr>
                                    <tr
                                        mat-row
                                        *matRowDef="let punch; columns: ['punches_' + employee.index];"
                                        class="punches-row"></tr>
                                </table>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="employeeColumns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let employee; columns: employeeColumns;"
                        class="employee-row"
                        (click)="togglePunchDayRow(employee)"></tr>
                    <tr mat-row *matRowDef="let punchDay; columns: ['days']" class="punch-days-row"></tr>
                </table>
            </div>
            <div class="page-summary">
                <span class="employees-count"><strong>{{punchesPage.metaData.employeesCount}}</strong> anställda</span>
                |
                <span class="worked-minutes"
                    ><strong>{{punchesPage.metaData.totalWorkedMinutes | readableTimeSpan}}</strong></span
                >
                <span class="overtime-minutes"
                    >varav <strong>{{punchesPage.metaData.totalOvertimeMinutes | readableTimeSpan}}</strong> övertid</span
                >
                <span class="extra-work-minutes"
                    >och <strong>{{punchesPage.metaData.totalExtraWorkMinutes | readableTimeSpan}}</strong> mertid</span
                >
                |
                <span class="absence-minutes"
                    ><strong>{{punchesPage.metaData.totalAbsenceMinutes | readableTimeSpan}}</strong> frånvaro</span
                >
            </div>
            <mat-paginator
                *ngIf="punchesPage && punchesPage.totalItems > punchesPage.pageSize"
                [length]="punchesPage.totalItems"
                [pageSize]="punchesPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
        </div>
        <ng-template #noResults>
            <div class="col">
                <p class="no-results">Inga stämplingar att visa</p>
            </div>
        </ng-template>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
