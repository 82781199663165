<div class="container-fluid">
    <div class="row">
        <div class="col">
            <mat-card class="compact">
                <mat-card-content>
                    <div class="container-fluid settings-wrapper">
                        <div class="row" *ngFor="let setting of settings">
                            <div class="col description">{{setting.description}}</div>
                            <div class="col value">
                                <ng-container *ngIf="setting.dataType === 'int' || setting.dataType === 'decimal'">
                                    <mat-form-field class="no-subscript">
                                        <mat-label>Värde</mat-label>
                                        <input type="number" matInput [(ngModel)]="setting.settingValue" />
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="setting.dataType === 'date'">
                                    <mat-form-field class="date-picker" (click)="picker.open()">
                                        <input matInput [matDatepicker]="picker" [(ngModel)]="setting.settingValue" disabled />
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="setting.dataType === 'bool'">
                                    <mat-checkbox
                                        [checked]="setting.settingValue.toLowerCase() === 'True'"
                                        (change)="setCheckedValue($event, setting)">
                                    </mat-checkbox>
                                </ng-container>
                                <ng-container *ngIf="setting.dataType === 'string'">
                                    <mat-form-field class="no-subscript">
                                        <mat-label>Värde</mat-label>
                                        <input type="text" matInput [(ngModel)]="setting.settingValue" />
                                    </mat-form-field>
                                </ng-container>
                            </div>
                        </div>
                        <div class="loading-wrapper" *ngIf="loading">
                            <mat-spinner diameter="40"></mat-spinner>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="row save">
        <div class="col" align="end">
            <button mat-raised-button (click)="save()" color="primary">Spara</button>
        </div>
    </div>
</div>
