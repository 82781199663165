import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { registerLocaleData } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { environment } from '@env/environment';
import localeSE from '@angular/common/locales/se-SE';
import { SentryErrorHandler } from '@app/error-handling/sentry.error-handler';
import { SharedModule } from '@app/shared';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { PunchLoginComponent } from '@app/components/pages/employee/login/punch-login.component';
import { RegisterComponent } from '@app/components/pages/employee/register/register.component';
import { PunchComponent } from '@app/components/pages/employee/punch/punch.component';
import { EditEmployeeComponent } from '@app/components/pages/employee/edit/edit-employee.component';
import { LoginAdminComponent } from '@app/components/pages/admin/login/admin-login.component';
import { StartAdminComponent } from '@app/components/pages/admin/start/admin-start.component';
import { EmployeeCostPoolHandledByAdminComponent } from '@app/components/pages/admin/employee-cost-pool-handled-by/employee-cost-pool-handled-by.component';
import { PunchesCostPoolAdminComponent } from '@app/components/pages/admin/punches-cost-pool/punches-cost-pool.component';
import { AttestOvertimeAdminComponent } from '@app/components/pages/admin/attest-overtime/attest-overtime.component';
import { EmployeesListAdminComponent } from '@app/components/pages/admin/employees-list/employees-list.component';
import { EmployeeDetailsAdminComponent } from '@app/components/pages/admin/employee-details/employee-details.component';
import { PunchesListAdminComponent } from '@app/components/pages/admin/punches-list/punches-list.component';
import { PunchesEditAdminComponent } from '@app/components/pages/admin/punches-edit/punches-edit.component';
import { DrivingLogAdminComponent } from '@app/components/pages/admin/driving-log/driving-log.component';
import { SchedulesListAdminComponent } from '@app/components/pages/admin/schedules-list/schedules-list.component';
import { ScheduleEditAdminComponent } from '@app/components/pages/admin/schedule-edit/schedule-edit.component';
import { ScheduleNewAdminComponent } from '@app/components/pages/admin/schedule-new/schedule-new.component';
import { SchedulesConnectAdminComponent } from '@app/components/pages/admin/schedules-connect/schedules-connect.component';
import { TravelCompensationAdminComponent } from '@app/components/pages/admin/travel-compensation/travel-compensation.component';
import { CostPoolsListAdminComponent } from '@app/components/pages/admin/cost-pools-list/cost-pools-list.component';
import { CostPoolEditAdminComponent } from '@app/components/pages/admin/cost-pool-edit/cost-pool-edit.component';
import { CostPoolNewAdminComponent } from '@app/components/pages/admin/cost-pool-new/cost-pool-new.component';
import { GeneralSettingsAdminComponent } from '@app/components/pages/admin/general-settings/general-settings.component';
import { ReportTimePerCostPoolAdminComponent } from '@app/components/pages/admin/report-time-per-cost-pool/report-time-per-cost-pool.component';
import { AdminClaimSettingsAdminComponent } from '@app/components/pages/admin/admin-claim-settings/admin-claim-settings.component';
import { DrivingLogLoginComponent } from '@app/components/pages/driving-log/login/driving-log-login.component';
import { NewTripDrivingLogComponent } from '@app/components/pages/driving-log/new-trip/new-trip.component';
import { AllTripsDrivingLogComponent } from '@app/components/pages/driving-log/all-trips/all-trips.component';
import { ShowTripDrivingLogComponent } from '@app/components/pages/driving-log/show-trip/show-trip.component';
import { CarsDrivingLogComponent } from '@app/components/pages/driving-log/cars/cars.component';
import { NewCarDrivingLogComponent } from '@app/components/pages/driving-log/new-car/new-car.component';
import { EditCarDrivingLogComponent } from '@app/components/pages/driving-log/edit-car/edit-car.component';
import { EmployeeMenuComponent } from '@app/shared/menus/employee/employee-menu.component';
import { AdminMenuComponent } from '@app/shared/menus/admin/admin-menu.component';
import { MenuItemComponent } from '@app/shared/menus/menu-item/menu-item.component';
import { DrivingLogMenuComponent } from '@app/shared/menus/driving-log/driving-log-menu.component';
import { EmployeeFooterComponent } from '@app/shared/footers/employee/employee-footer.component';
import { AdminFooterComponent } from '@app/shared/footers/admin/admin-footer.component';
import { DrivingLogFooterComponent } from '@app/shared/footers/driving-log/driving-log-footer.component';
import { LoadingOverlayComponent } from '@app/shared/misc/loading-overlay/loading-overlay.component';
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';
import { ConfirmDialog } from '@app/shared/dialogs/confirm/confirm.dialog';
import { HelpDialog } from '@app/shared/dialogs/help/help.dialog';
import { PunchWarningActionDialog } from '@app/shared/dialogs/punch-warning-action/punch-warning-action.dialog';
import { OvertimeDialog } from '@app/shared/dialogs/overtime/overtime.dialog';
import { FixLastPunchDialog } from '@app/shared/dialogs/fix-last-punch/fix-last-punch.dialog';
import { AttestDenyOvertimeDialog } from '@app/shared/dialogs/attest-deny-overtime/attest-deny-overtime.dialog';
import { WorkPeriodEmployeesDialog } from '@app/shared/dialogs/work-period-employees/work-period-employees.dialog';
import { PunchWarningDialog } from '@app/shared/dialogs/punch-warning/punch-warning.dialog';
import { EmployeeScheduleDialog } from '@app/shared/dialogs/employee-schedule/employee-schedule.dialog';
import { EditPunchDialog } from '@app/shared/dialogs/edit-punch/edit-punch.dialog';
import { EditPunchMessagesDialog } from '@app/shared/dialogs/edit-punch-messages/edit-punch-messages.dialog';
import { EditPunchReplyMessageDialog } from '@app/shared/dialogs/edit-punch-reply-message/edit-punch-reply-message.dialog';
import { WorkTimePerCostPoolDialog } from '@app/shared/dialogs/work-time-per-cost-pool/work-time-per-cost-pool.dialog';
import { ScheduleConnectDialog } from '@app/shared/dialogs/schedule-connect/schedule-connect.dialog';
import { WhistleblowerInfoDialogComponent } from '@app/shared/dialogs/whistleblower-info-dialog/whistleblower-info-dialog.component';
import { EmployeeService } from '@app/services/employee.service';
import { CompanyService } from '@app/services/company.service';
import { EmploymentTypeService } from '@app/services/employment-type.service';
import { AuthService } from '@app/services/auth.service';
import { PunchService } from '@app/services/punch.service';
import { AbsenceService } from '@app/services/absence.service';
import { TravelCompensationService } from '@app/services/travel-compensation.service';
import { EmployeeContractService } from '@app/services/employee-contract.service';
import { CostPoolService } from '@app/services/cost-pool.service';
import { EmployeeMessageService } from '@app/services/employee-message.service';
import { CompanyDepartmentService } from './services/company-department.service';
import { PaymentTypeService } from './services/payment-type.service';
import { UnionContractService } from './services/union-contract.service';
import { ScheduleService } from './services/schedule.service';
import { VersionCheckService } from './services/version-check.service';
import { WorkPeriodService } from './services/work-period.service';
import { EmployeeCategoryService } from './services/employee-category.service';
import { CarService } from './services/car.service';
import { CarDrivingLogService } from './services/car-driving-log.service';
import { CarTypeService } from './services/car-type.service';
import { SettingService } from './services/settings.service';
import { ReportService } from './services/report.service';
import { MenuService } from './services/menu.service';
import { AdministratorService } from './services/administrator.service';
import { ClaimService } from './services/claim.service';
import { ErrorInterceptor } from '@app/interceptors/error.interceptor';
import { JwtInterceptor } from '@app/interceptors/jwt.interceptor';
import { ClickStopPropagationDirective } from '@app/directives/click-stop-propagation.directive';
import { DisableControlDirective } from '@app/directives/disable-control.directive';
import { MouseStopPropagationDirective } from '@app/directives/mouse-stop-propagation.directive';
import { EmployeeWorkPeriodsPartial } from '@app/components/partials/employee-work-periods/employee-work-periods.partial';
import { EmployeeRecentPunchesPartial } from '@app/components/partials/employee-recent-punches/employee-recent-punches.partial';
import { EmployeePunchSummaryPartial } from '@app/components/partials/employee-punch-summary/employee-punch-summary.partial';
import { ReportAbsencePartial } from '@app/components/partials/report-absence/report-absence.partial';
import { EmployeeAbsencePartial } from '@app/components/partials/employee-absence/employee-absence.partial';
import { ReportMilagePartial } from '@app/components/partials/report-milage/report-milage.partial';
import { EmployeeTravelCompensationsPartial } from '@app/components/partials/employee-travel-compensations/employee-travel-compensations.partial';
import { EmployeeContractsPartial } from '@app/components/partials/employee-contracts/employee-contracts.partial';
import { EmployeeDetailsPartial } from '@app/components/partials/employee-details/employee-details.partial';
import { EmployeeFormPartial } from '@app/components/partials/employee-form/employee-form.partial';
import { MessagesPartial } from '@app/components/partials/messages/messages.partial';
import { MessagesPanelPartial } from '@app/components/partials/messages/messages-panel/messages-panel.partial';
import { EmployeeCostPoolHandledByPartial } from '@app/components/partials/employee-cost-pool-handled-by/employee-cost-pool-handled-by.partial';
import { PunchesCostPoolPartial } from '@app/components/partials/punches-cost-pool/punches-cost-pool.partial';
import { AttestOvertimePartial } from '@app/components/partials/attest-overtime/attest-overtime.partial';
import { EmployeesListPartial } from '@app/components/partials/employees-list/employees-list.partial';
import { EmployeeSchedulePartial } from '@app/components/partials/employee-schedule/employee-schedule.partial';
import { EmployeeAddPunchPartial } from '@app/components/partials/employee-add-punch/employee-add-punch.partial';
import { EmployeePunchesPartial } from '@app/components/partials/employee-punches/employee-punches.partial';
import { ActiveWorkPeriodsPartial } from '@app/components/partials/active-work-periods/active-work-periods.partial';
import { PunchesListPartial } from '@app/components/partials/punches-list/punches-list.partial';
import { PunchesEditPartial } from '@app/components/partials/punches-edit/punches-edit.partial';
import { EmployeeAddAbsencePartial } from '@app/components/partials/employee-add-absence/employee-add-absence.partial';
import { DrivingLogTripPartial } from '@app/components/partials/driving-log-trip/driving-log-trip.partial';
import { DrivingLogTripHistoryPartial } from '@app/components/partials/driving-log-trip-history/driving-log-trip-history.partial';
import { DrivingLogShowTripPartial } from '@app/components/partials/driving-log-show-trip/driving-log-show-trip.partial';
import { DrivingLogCarsPartial } from '@app/components/partials/driving-log-cars/driving-log-cars.partial';
import { DrivingLogCarPartial } from '@app/components/partials/driving-log-car/driving-log-car.partial';
import { DrivingLogReportPartial } from '@app/components/partials/driving-log-report/driving-log-report.partial';
import { SchedulesListPartial } from '@app/components/partials/schedules-list/schedules-list.partial';
import { ScheduleFormPartial } from '@app/components/partials/schedule-form/schedule-form.partial';
import { SchedulesConnectPartial } from '@app/components/partials/schedules-connect/schedules-connect.partial';
import { TravelCompensationsListPartial } from '@app/components/partials/travel-compensations-list/travel-compensations-list.partial';
import { CostPoolsListPartial } from '@app/components/partials/cost-pools-list/cost-pools-list.partial';
import { CostPoolFormPartial } from '@app/components/partials/cost-pool-form/cost-pool-form.partial';
import { GeneralSettingsPartial } from '@app/components/partials/general-settings/general-settings.partial';
import { ReportTimePerCostPoolPartial } from '@app/components/partials/report-time-per-cost-pool/report-time-per-cost-pool.partial';
import { AdminClaimSettingsPartial } from '@app/components/partials/admin-claim-settings/admin-claim-settings.partial';
import { WorkPeriodNamePipe } from '@app/pipes/work-period-name.pipe';
import { ShortDatePipe } from '@app/pipes/short-date.pipe';
import { WorkPeriodStatusPipe } from '@app/pipes/work-period-status.pipe';
import { ArrayKeysPipe } from '@app/pipes/array-keys.pipe';
import { ReadableDatePipe } from '@app/pipes/readable-date.pipe';
import { ReadableDateTimePipe } from '@app/pipes/readable-date-time.pipe';
import { ShortTimePipe } from '@app/pipes/short-time.pipe';
import { DateTimePipe } from '@app/pipes/date-time.pipe';
import { EmptyReplacementPipe } from '@app/pipes/empty-replacement.pipe';
import { IsEmptyPipe } from '@app/pipes/is-empty.pipe';
import { VerboseDateTimePipe } from '@app/pipes/verbose-date-time.pipe';
import { PreviewPipe } from '@app/pipes/preview.pipe';
import { MessageClassesPipe } from '@app/pipes/message-classes.pipe';
import { PeriodWarningsSeverityClassPipe } from '@app/pipes/period-warnings-severity-class.pipe';
import { NextOfKinsPipe } from '@app/pipes/next-of-kins.pipe';
import { ValueFromObjectArrayPipe } from '@app/pipes/value-from-object-array.pipe';
import { ReadableTimeSpanPipe } from '@app/pipes/readable-time-span.pipe';
import { CompanyDepartmentFilterPipe } from '@app/pipes/company-department-filter.pipe';
import { ExtraShortDatePipe } from '@app/pipes/extra-short-date.pipe';
import { WeekOfYearPipe } from '@app/pipes/week-of-year.pipe';
import { ArrayContainsPipe } from '@app/pipes/array-contains.pipe';
import { HasPunchWarningsPipe } from '@app/pipes/has-punch-warnings.pipe';
import { OvertimeEmployeeNamePipe } from '@app/pipes/overtime-employee-name.pipe';
import { HasUnfixedPunchWarningsPipe } from '@app/pipes/has-unfixed-punch-warnings.pipe';
import { EmployeeOvertimeClassPipe } from '@app/pipes/employee-overtime-class.pipe';
import { DayOfWeekPipe } from '@app/pipes/day-of-week.pipe';
import { DayOfWeekShortPipe } from '@app/pipes/day-of-week-short.pipe';
import { AbsenceListPipe } from '@app/pipes/absence-list.pipe';
import { EditPunchPositionPipe } from '@app/pipes/edit-punch-position.pipe';
import { TightPunchPipe } from '@app/pipes/tight-punch.pipe';
import { EditPunchMessagesIgnoredPipe } from '@app/pipes/edit-punch-messages-ignored.pipe';
import { EditPunchMessageRepliesPipe } from '@app/pipes/edit-punch-message-replies.pipe';
import { HasMultipleCostPoolsPipe } from '@app/pipes/has-multiple-cost-pools.pipe';
import { ScheduleDaysPipe } from '@app/pipes/schedule-days.pipe';
import { ScheduleDayPeriodsPipe } from './pipes/schedule-day-periods.pipe';
import { EmployeeLoginPasswordPromptDialogComponent } from './shared/dialogs/employee-login-password-prompt-dialog/employee-login-password-prompt-dialog.component';
import { AdminAccountsAdminComponent } from './components/pages/admin/admin-accounts/admin-accounts.component';
import { AdminAccountsPartial } from './components/partials/admin-accounts/admin-accounts.partial';
import { AdminNewAdminComponent } from './components/pages/admin/admin-new/admin-new.component';
import { AdminFormPartial } from './components/partials/admin-form/admin-form.partial';
import { AdminResetPasswordPartial } from './components/partials/admin-reset-password/admin-reset-password.partial';
import { AdminResetPasswordComponent } from './components/pages/admin/admin-reset-password/admin-reset-password.component';
import { AdminEditAdminComponent } from './components/pages/admin/admin-edit/admin-edit.component';
import { EmployeeLoginResetPasswordDialogComponent } from './shared/dialogs/employee-login-reset-password-dialog/employee-login-reset-password-dialog.component';
import { EmployeeResetPasswordPartial } from './components/partials/employee-reset-password/employee-reset-password.partial';
import { EmployeeResetPasswordComponent } from './components/pages/employee/reset-password/employee-reset-password.component';
import { AdminPasswordExpiredDialogComponent } from './shared/dialogs/admin-password-expired-dialog/admin-password-expired-dialog.component';
import { ApplicationClientAccessFormComponent } from './components/partials/application-client-access-form/application-client-access-form.component';
import { ApplicationClientsListComponent } from './components/pages/admin/application-clients-list/application-clients-list.component';
import { ApplicationClientsListPartial } from './components/partials/application-clients-list/application-clients-list.partial';
import { MAT_FAB_DEFAULT_OPTIONS } from '@angular/material/button';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/slide-toggle';
import { AttestTimeComponent } from './components/partials/attest-time/attest-time.component';
import { AttestTimeAdminComponent } from './components/pages/admin/attest-time/attest-time.component';
import { AttestTimeOffsetPipe } from './pipes/attest-time-offset.pipe';
import { AttestTimeDetailsPipe } from './pipes/attest-time-details.pipe';
import { AttestTimeIssuesListPartial } from './components/partials/attest-time-issues-list/attest-time-issues-list.component';
import { AttestTimeIssuesListAdminComponent } from './components/pages/admin/attest-time-issues-list/attest-time-issues-list.component';
import { ReportWorkingEmployeesPartial } from './components/partials/report-working-employees/report-working-employees.partial';
import { ReportWorkingEmployeesAdminComponent } from './components/pages/admin/report-working-employees/report-working-employees.component';
import {StaffingCompanyService} from "@app/services/StaffingCompany.service";
import {ReportStaffingCompanyTimeComponent} from "@app/components/pages/admin/report-staffing-company-time/report-staffing-company-time.component";
import {ReportStaffingCompanyTimePartial} from "@app/components/partials/report-staffing-company-time/report-staffing-company-time.partial";

registerLocaleData(localeSE);

let prodOnlyProviders = [{ provide: ErrorHandler, useClass: SentryErrorHandler }];

if (!environment.production) {
    prodOnlyProviders = [];
    console.info('This is not prod environment, error handling service will not be registered');
}

@NgModule({
    declarations: [
        AppComponent,
        ClickStopPropagationDirective,
        DisableControlDirective,
        MouseStopPropagationDirective,
        AlertDialog,
        ConfirmDialog,
        HelpDialog,
        PunchWarningActionDialog,
        EmployeeScheduleDialog,
        OvertimeDialog,
        FixLastPunchDialog,
        AttestDenyOvertimeDialog,
        WorkPeriodEmployeesDialog,
        PunchWarningDialog,
        EditPunchDialog,
        EditPunchMessagesDialog,
        EditPunchReplyMessageDialog,
        WorkTimePerCostPoolDialog,
        ScheduleConnectDialog,
        EmployeeLoginPasswordPromptDialogComponent,
        EmployeeLoginResetPasswordDialogComponent,
        AdminPasswordExpiredDialogComponent,
        WhistleblowerInfoDialogComponent,
        EmployeeMenuComponent,
        AdminMenuComponent,
        DrivingLogMenuComponent,
        MenuItemComponent,
        EmployeeFooterComponent,
        AdminFooterComponent,
        DrivingLogFooterComponent,
        LoadingOverlayComponent,
        PunchLoginComponent,
        RegisterComponent,
        PunchComponent,
        EditEmployeeComponent,
        LoginAdminComponent,
        StartAdminComponent,
        EmployeeCostPoolHandledByAdminComponent,
        PunchesCostPoolAdminComponent,
        AttestOvertimeAdminComponent,
        EmployeesListAdminComponent,
        EmployeeDetailsAdminComponent,
        PunchesListAdminComponent,
        PunchesEditAdminComponent,
        DrivingLogLoginComponent,
        NewTripDrivingLogComponent,
        AllTripsDrivingLogComponent,
        ShowTripDrivingLogComponent,
        CarsDrivingLogComponent,
        NewCarDrivingLogComponent,
        EditCarDrivingLogComponent,
        DrivingLogAdminComponent,
        SchedulesListAdminComponent,
        ScheduleEditAdminComponent,
        ScheduleNewAdminComponent,
        SchedulesConnectAdminComponent,
        TravelCompensationAdminComponent,
        CostPoolsListAdminComponent,
        CostPoolEditAdminComponent,
        CostPoolNewAdminComponent,
        GeneralSettingsAdminComponent,
        ReportTimePerCostPoolAdminComponent,
        AdminClaimSettingsAdminComponent,
        AdminAccountsAdminComponent,
        AdminNewAdminComponent,
        AdminResetPasswordComponent,
        AdminEditAdminComponent,
        EmployeeResetPasswordComponent,
        EmployeeWorkPeriodsPartial,
        EmployeeRecentPunchesPartial,
        EmployeePunchSummaryPartial,
        ReportAbsencePartial,
        EmployeeAbsencePartial,
        ReportMilagePartial,
        EmployeeTravelCompensationsPartial,
        EmployeeContractsPartial,
        EmployeeDetailsPartial,
        EmployeeFormPartial,
        MessagesPartial,
        MessagesPanelPartial,
        EmployeeCostPoolHandledByPartial,
        PunchesCostPoolPartial,
        AttestOvertimePartial,
        EmployeesListPartial,
        EmployeeSchedulePartial,
        EmployeeAddPunchPartial,
        EmployeePunchesPartial,
        ActiveWorkPeriodsPartial,
        PunchesListPartial,
        PunchesEditPartial,
        EmployeeAddAbsencePartial,
        DrivingLogTripPartial,
        DrivingLogTripHistoryPartial,
        DrivingLogShowTripPartial,
        DrivingLogCarsPartial,
        DrivingLogCarPartial,
        DrivingLogReportPartial,
        SchedulesListPartial,
        ScheduleFormPartial,
        SchedulesConnectPartial,
        TravelCompensationsListPartial,
        CostPoolsListPartial,
        CostPoolFormPartial,
        GeneralSettingsPartial,
        ReportTimePerCostPoolPartial,
        AdminClaimSettingsPartial,
        AdminAccountsPartial,
        AdminFormPartial,
        AdminResetPasswordPartial,
        EmployeeResetPasswordPartial,
        WorkPeriodNamePipe,
        ShortDatePipe,
        WorkPeriodStatusPipe,
        ArrayKeysPipe,
        ReadableDatePipe,
        ShortTimePipe,
        DateTimePipe,
        EmptyReplacementPipe,
        IsEmptyPipe,
        VerboseDateTimePipe,
        PreviewPipe,
        MessageClassesPipe,
        PeriodWarningsSeverityClassPipe,
        NextOfKinsPipe,
        ReadableDateTimePipe,
        ValueFromObjectArrayPipe,
        ReadableTimeSpanPipe,
        CompanyDepartmentFilterPipe,
        ExtraShortDatePipe,
        WeekOfYearPipe,
        ArrayContainsPipe,
        HasPunchWarningsPipe,
        OvertimeEmployeeNamePipe,
        HasUnfixedPunchWarningsPipe,
        EmployeeOvertimeClassPipe,
        DayOfWeekPipe,
        DayOfWeekShortPipe,
        AbsenceListPipe,
        EditPunchPositionPipe,
        TightPunchPipe,
        EditPunchMessagesIgnoredPipe,
        EditPunchMessageRepliesPipe,
        HasMultipleCostPoolsPipe,
        ScheduleDaysPipe,
        ScheduleDayPeriodsPipe,
        AttestTimeOffsetPipe,
        ApplicationClientAccessFormComponent,
        ApplicationClientsListComponent,
        ApplicationClientsListPartial,
        AttestTimeComponent,
        AttestTimeAdminComponent,
        AttestTimeDetailsPipe,
        AttestTimeIssuesListPartial,
        AttestTimeIssuesListAdminComponent,
        ReportWorkingEmployeesPartial,
        ReportWorkingEmployeesAdminComponent,
        ReportStaffingCompanyTimeComponent,
        ReportStaffingCompanyTimePartial
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        SharedModule,
        AppRoutingModule,
        FormsModule,
        OverlayModule,
    ],
    providers: [
        ...prodOnlyProviders,
        { provide: MAT_DATE_LOCALE, useValue: 'sv-SE' },
        { provide: LOCALE_ID, useValue: 'sv-SE' },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: MAT_FAB_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
        { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
        { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
        { provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
        EmployeeService,
        CompanyService,
        EmploymentTypeService,
        AuthService,
        PunchService,
        AbsenceService,
        TravelCompensationService,
        EmployeeContractService,
        CostPoolService,
        EmployeeMessageService,
        CompanyDepartmentService,
        PaymentTypeService,
        UnionContractService,
        ScheduleService,
        VersionCheckService,
        WorkPeriodService,
        EmployeeCategoryService,
        CarService,
        CarDrivingLogService,
        CarTypeService,
        SettingService,
        ReportService,
        MenuService,
        AdministratorService,
        ClaimService,
        StaffingCompanyService
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
