<div class="filter">
    <div class="row">
        <div class="col">
            <mat-form-field (click)="fromDatePicker.open()">
                <mat-label>Från</mat-label>
                <input matInput [matDatepicker]="fromDatePicker" [(ngModel)]="from" disabled>
                <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #fromDatePicker [disabled]="false"></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field (click)="toDatePicker.open()">
                <mat-label>Till</mat-label>
                <input matInput [matDatepicker]="toDatePicker" [(ngModel)]="to" disabled>
                <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #toDatePicker [disabled]="false"></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col" align="end">
            <button mat-raised-button color="primary" (click)="getPunches(true)">Visa</button>
        </div>
    </div>
    <div class="row search-info" *ngIf="manuallySearched">
        <div class="col">
            <i>Obs! Maximalt 100 stämplingar visas</i>
        </div>
    </div>
</div>
<mat-accordion *ngIf="recentPunches">
    <mat-expansion-panel *ngFor="let key of recentPunches | arrayKeys">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{key | readableDate}}
            </mat-panel-title>
            <mat-panel-description>
                {{recentPunches[key].length}} {{recentPunches[key].length === 1 ? 'stämpling' : 'stämplingar'}}
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="table-wrapper">
            <table mat-table [dataSource]="recentPunches[key]">
                <ng-container matColumnDef="punchTypeId">
                    <th mat-header-cell *matHeaderCellDef class="icon"></th>
                    <td mat-cell *matCellDef="let punch" class="icon" [ngClass]="{'in': punch.punchTypeId === 1, 'out': punch.punchTypeId === 2}">
                        <mat-icon *ngIf="punch.punchTypeId === 1">arrow_forward</mat-icon>
                        <mat-icon *ngIf="punch.punchTypeId === 2">arrow_back</mat-icon>
                    </td>
                </ng-container>
                <ng-container matColumnDef="punchTypeName">
                    <th mat-header-cell *matHeaderCellDef class="name">Typ</th>
                    <td mat-cell *matCellDef="let punch" class="name">
                        <span *ngIf="punch.punchTypeId === 1">IN</span>
                        <span *ngIf="punch.punchTypeId === 2">UT</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="costPool">
                    <th mat-header-cell *matHeaderCellDef class="cost-pool">Avdelning</th>
                    <td mat-cell *matCellDef="let punch" class="cost-pool">
                        {{punch.costPool ? punch.costPool.name : '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="punchTime">
                    <th mat-header-cell *matHeaderCellDef class="punch-time">Tid</th>
                    <td mat-cell *matCellDef="let punch" class="punch-time">
                        {{punch.punchTime | shortTime}}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="punchColumns"></tr>
                <tr mat-row *matRowDef="let punch; columns: punchColumns;"></tr>
            </table>
        </div>
    </mat-expansion-panel>
</mat-accordion>

<div class="no-punches" *ngIf="(recentPunches | isEmpty) && !loading">
    Inga stämplingar att visa
</div>

<div class="loading-wrapper" *ngIf="loading">
    <mat-spinner diameter="40"></mat-spinner>
</div>
