import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Helpers } from '@app/helpers/helpers.class';
import { SelectionModel } from '@angular/cdk/collections';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { remove } from 'lodash-es';
import { EmployeeService } from '@app/services/employee.service';
import { CompanyService } from '@app/services/company.service';
import { EmploymentTypeService } from '@app/services/employment-type.service';
import { CompanyDepartmentService } from '@app/services/company-department.service';
import { ScheduleService } from '@app/services/schedule.service';
import { ScheduleConnectDialog } from '@app/shared/dialogs/schedule-connect/schedule-connect.dialog';
import { EmployeeScheduleDialog } from '@app/shared/dialogs/employee-schedule/employee-schedule.dialog';
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';
import { ConfirmDialog } from '@app/shared/dialogs/confirm/confirm.dialog';
import { ICompany } from '@app/interfaces/company.interface';
import { IEmploymentType } from '@app/interfaces/employment-type.interface';
import { ICompanyDepartment } from '@app/interfaces/company-department.interface';
import { IEmployeeSearchConnectSchedule } from '@app/interfaces/employee-search-connect-schedule.interface';
import { IEmployeeConnectSchedule } from '@app/interfaces/employee-connect-schedule.interface';
import { IPage } from '@app/interfaces/page.interface';
import { IEmployeeSchedule } from '@app/interfaces/employee-schedule.interface';

@Component({
    selector: 'app-schedules-connect',
    templateUrl: './schedules-connect.partial.html',
    styleUrls: ['./schedules-connect.partial.scss'],
    animations: [
        trigger('toggle', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class SchedulesConnectPartial implements OnInit {
    @Output() public filterChanged: EventEmitter<any> = new EventEmitter();
    @Input('query') public inputQuery: string;
    @Input('companyIds') public inputCompanyIds: string;
    @Input('employmentTypeIds') public inputEmploymentTypeIds: string;
    @Input('departmentIds') public inputDepartmentIds: string;
    @Input('registeredFrom') public inputRegisteredFrom: string;
    @Input('registeredTo') public inputRegisteredTo: string;
    @Input('active') public inputActive: number;
    @Input('missingSchedule') public inputMissingSchedule: number;
    @Input('page') public inputPage: number;

    public pageIndex: number;
    public loading: boolean = false;
    public filterQuery: string;
    public filterCompanyIds: number[];
    public filterEmploymentTypeIds: number[];
    public filterDepartmentIds: number[];
    public filterRegisteredFrom: string;
    public filterRegisteredTo: string;
    public filterActive: boolean;
    public filterMissingSchedule: boolean;
    public employeesPage: IPage<any>;
    public expandedEmployee: any;
    public visibleEmployeeIds: number[] = [];
    public employeeColumns: string[] = [
        'checkbox',
        'name',
        'company',
        'department',
        'employmentType',
        'activeSchedule',
        'expand',
    ];
    public companies: ICompany[];
    public employmentTypes: IEmploymentType[];
    public companyDepartments: ICompanyDepartment[];
    public selection: SelectionModel<IEmployeeConnectSchedule> = new SelectionModel<IEmployeeConnectSchedule>(true);
    public dataSource: MatTableDataSource<IEmployeeConnectSchedule>;

    constructor(
        private dialog: MatDialog,
        private employeeService: EmployeeService,
        private companyService: CompanyService,
        private employmentTypeService: EmploymentTypeService,
        private companyDepartmentService: CompanyDepartmentService,
        private scheduleService: ScheduleService
    ) {}

    public ngOnInit(): void {
        this.filterQuery = '';
        this.filterCompanyIds = [];
        this.filterEmploymentTypeIds = [];
        this.filterDepartmentIds = [];
        this.filterRegisteredFrom = '';
        this.filterRegisteredTo = '';
        this.filterActive = false;
        this.filterMissingSchedule = false;

        if (Helpers.isDefined(this.inputQuery)) {
            this.filterQuery = this.inputQuery;
        }

        if (Helpers.isDefined(this.inputCompanyIds)) {
            this.filterCompanyIds = this.inputCompanyIds.split(',').map(function (item) {
                return parseInt(item, 10);
            });
        }

        if (Helpers.isDefined(this.inputEmploymentTypeIds)) {
            this.filterEmploymentTypeIds = this.inputEmploymentTypeIds.split(',').map(function (item) {
                return parseInt(item, 10);
            });
        }

        if (Helpers.isDefined(this.inputDepartmentIds)) {
            this.filterDepartmentIds = this.inputDepartmentIds.split(',').map(function (item) {
                return parseInt(item, 10);
            });
        }

        if (Helpers.isDefined(this.inputRegisteredFrom)) {
            this.filterRegisteredFrom = this.inputRegisteredFrom;
        }

        if (Helpers.isDefined(this.inputRegisteredTo)) {
            this.filterRegisteredTo = this.inputRegisteredTo;
        }

        if (Helpers.isDefined(this.inputActive)) {
            this.filterActive = this.inputActive == 1;
        }

        if (Helpers.isDefined(this.inputMissingSchedule)) {
            this.filterMissingSchedule = this.inputMissingSchedule == 1;
        }

        if (Helpers.isDefined(this.inputPage)) {
            this.pageIndex = this.inputPage - 1;
        }

        this.getCompanies();
        this.getEmploymentTypes();
        this.getCompanyDepartments();
        this.getEmployeesPage(1);
    }

    public getCompanies() {
        this.companyService.getAll().subscribe({
            next: (data) => {
                this.companies = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get companies');
            },
        });
    }

    public getEmploymentTypes() {
        this.employmentTypeService.getAll().subscribe({
            next: (data) => {
                this.employmentTypes = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get employment types');
            },
        });
    }

    public getCompanyDepartments() {
        this.companyDepartmentService.getAll().subscribe({
            next: (data) => {
                this.companyDepartments = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get copmany departments');
            },
        });
    }

    public getEmployeesPage(page: number): void {
        let search: IEmployeeSearchConnectSchedule = {
            query: this.filterQuery,
            companyIds: this.filterCompanyIds,
            employmentTypeIds: this.filterEmploymentTypeIds,
            departmentIds: this.filterDepartmentIds,
            registeredFrom: Helpers.toShortDateString(this.filterRegisteredFrom),
            registeredTo: Helpers.toShortDateString(this.filterRegisteredTo),
            active: this.filterActive,
            missingSchedule: this.filterMissingSchedule,
            page: page,
        };

        this.selection.clear();

        this.loading = true;
        this.employeeService.getEmployeesForConnectSchedule(search).subscribe({
            next: (data) => {
                for (let i = 0; i < data.items.length; i++) {
                    data.items[i].index = i;
                    data.items[i].scheduleColumns = this.getScheduleColumns(i);
                }
                this.employeesPage = data;
                this.dataSource = new MatTableDataSource<IEmployeeConnectSchedule>(this.employeesPage.items);
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not get employees page');
            },
        });

        let filterSettings = {
            query: this.filterQuery,
            companyIds: this.filterCompanyIds,
            employmentTypeIds: this.filterEmploymentTypeIds,
            departmentIds: this.filterDepartmentIds,
            registeredFrom: Helpers.toShortDateString(this.filterRegisteredFrom),
            registeredTo: Helpers.toShortDateString(this.filterRegisteredTo),
            active: this.filterActive,
            missingSchedule: this.filterMissingSchedule,
            page: page,
        };

        this.filterChanged.emit(filterSettings);
    }

    public deleteEmployeeSchedule(scheduleId: number, employeeId: number, fromDate: string): void {
        const confirmHandle = this.dialog.open(ConfirmDialog, {
            width: '400px',
            data: {
                title: 'Är du säker?',
                message: 'Är du säker på att du vill ta bort schemat från den anställde?',
            },
        });

        confirmHandle.afterClosed().subscribe((doDelete: boolean) => {
            if (doDelete) {
                this.loading = true;
                let employeeSchedule: IEmployeeSchedule = {
                    scheduleId: scheduleId,
                    employeeId: employeeId,
                    fromDate: Helpers.toShortDateString(fromDate),
                };

                this.scheduleService.deleteEmployeeSchedule(employeeSchedule).subscribe({
                    next: (data) => {
                        this.getEmployeesPage(this.pageIndex + 1);
                    },
                    error: (error) => {
                        this.loading = false;
                        console.error(error);

                        let title = 'Fel uppstod';
                        let message = 'Något gick fel när schemat skulle tas bort';
                        let errors: string[];

                        if (error.status === 400 && error.error.errors) {
                            message = 'Följande gick fel när schemat skulle tas bort';
                            errors = error.error.errors;
                        } else {
                            Helpers.captureSentryError('Could not delete employee schedule');
                        }

                        this.dialog.open(AlertDialog, {
                            width: '400px',
                            data: {
                                title: title,
                                message: message,
                                list: errors,
                            },
                        });
                    },
                });
            }
        });
    }

    public showConnect(): void {
        let employeeIds: number[] = [];

        for (let employee of this.selection.selected) {
            employeeIds.push(employee.employeeId);
        }

        if (employeeIds.length === 0) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Inga valda',
                    message: 'Du har inte valt några anställa att koppla',
                },
            });
            return;
        }

        let handle = this.dialog.open(ScheduleConnectDialog, {
            width: '600px',
            data: {
                employeeIds,
            },
        });

        handle.afterClosed().subscribe((result: string) => {
            if (result === 'created') {
                this.getEmployeesPage(this.pageIndex + 1);
            }
        });
    }

    public showSchedule(scheduleId: number): void {
        this.dialog.open(EmployeeScheduleDialog, {
            width: '500px',
            data: {
                scheduleId: scheduleId,
            },
        });
    }

    public isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    public masterToggle(): void {
        this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
    }

    public toggleSchedulesRow(employee: any): void {
        if (!(employee.schedules && employee.schedules.length > 0)) {
            return;
        }
        if (this.expandedEmployee == employee) {
            this.expandedEmployee = null;
            setTimeout(
                (e) => {
                    remove(this.visibleEmployeeIds, (employeeId) => {
                        return employeeId === e.employeeId;
                    });
                },
                1,
                employee
            );
        } else {
            this.visibleEmployeeIds.push(employee.employeeId);
            setTimeout(
                (e) => {
                    this.expandedEmployee = e;
                },
                1,
                employee
            );
        }
    }

    public pageChanged(event: any): void {
        this.getEmployeesPage(event.pageIndex + 1);
        this.pageIndex = event.pageIndex;
    }

    public getScheduleColumns(i: number): any[] {
        return ['name_' + i, 'fromDate_' + i, 'length_' + i, 'deleteSchedule_' + i];
    }
}
