import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

// Interfaces
import { IWorkPeriod } from '@app/interfaces/work-period.interface';

@Pipe({name: 'workPeriodName'})
export class WorkPeriodNamePipe implements PipeTransform {
    transform(period: IWorkPeriod): string {
        return formatDate(period.periodStart, 'd/M', 'se-SE') + ' — ' +
            formatDate(period.periodEnd, 'd/M yyyy', 'se-SE');
    }
}
