import { Component, OnInit, EventEmitter, Input, Output, ViewChild, ElementRef, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fromEvent, Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Helpers } from '@app/helpers/helpers.class';

// Error handling
import * as Sentry from '@sentry/browser';

// Dialogs
import { EditPunchDialog } from '@app/shared/dialogs/edit-punch/edit-punch.dialog';
import { ConfirmDialog } from '@app/shared/dialogs/confirm/confirm.dialog';
import { EditPunchMessagesDialog } from '@app/shared/dialogs/edit-punch-messages/edit-punch-messages.dialog';

// Services
import { EmployeeService } from '@app/services/employee.service';
import { CompanyService } from '@app/services/company.service';
import { EmploymentTypeService } from '@app/services/employment-type.service';
import { CompanyDepartmentService } from '@app/services/company-department.service';
import { PunchService } from '@app/services/punch.service';
import { EmployeeMessageService } from '@app/services/employee-message.service';
import { AuthService } from '@app/services/auth.service';

// Interfaces
import { IEmployeeEditPunches } from '@app/interfaces/employee-edit-puches.interface';
import { ICompany } from '@app/interfaces/company.interface';
import { IEmploymentType } from '@app/interfaces/employment-type.interface';
import { ICompanyDepartment } from '@app/interfaces/company-department.interface';
import { IEditPunchesSearch } from '@app/interfaces/edit-punches-search.interface';
import { IEmployeeMessageEditPunch } from '@app/interfaces/employee-message-edit-punch.interface';
import { IPunchEdit } from '@app/interfaces/punch-edit.interface';

@Component({
    selector: 'app-punches-edit',
    templateUrl: './punches-edit.partial.html',
    styleUrls: ['./punches-edit.partial.scss'],
})
export class PunchesEditPartial implements OnInit {
    @Output() filterChanged: EventEmitter<any> = new EventEmitter();
    @Input('query') inputQuery: string;
    @Input('day') inputDay: string;
    @Input('companyIds') inputCompanyIds: string;
    @Input('employmentTypeIds') inputEmploymentTypeIds: string;
    @Input('departmentIds') inputDepartmentIds: string;
    @Input('hasMessages') inputHasMessages: number;
    @ViewChild('timelineLabel', { read: ElementRef }) timelineLabel: ElementRef<any>;
    @ViewChild('timelineHeader', { read: ElementRef }) timelineHeader: ElementRef<any>;

    employees: IEmployeeEditPunches[];
    loading: boolean = false;
    companies: ICompany[];
    employmentTypes: IEmploymentType[];
    companyDepartments: ICompanyDepartment[];
    filterQuery: string;
    filterDay: string;
    filterCompanyIds: number[];
    filterDepartmentIds: number[];
    filterEmploymentTypeIds: number[];
    filterHasMessages: boolean;
    lastHour: string;
    hours: number[];
    timelineLabelVisible: boolean = false;
    timelineLeftPosition: number = 0;
    pixelsPerMinute: number = 0;
    timelineLabelText: string = '';
    timelineLabelLeft: number = 0;
    timelineLabelTop: number = 0;
    timelineLengthHours: number = 0;
    timelineStartMinutes: number = 0;
    timelineStartTime: Date;
    isDraggingPunch: boolean = false;
    draggingPunchElement: any = null;
    dragPunchElementReleased: number = 0;
    lastLabelUpdate: number = 0;
    changedPunches: any[] = [];
    replyMessages: IEmployeeMessageEditPunch[] = [];
    mouseMoveSubscription: Subscription;
    hourInMilliseconds: number = 60 * 60 * 1000;
    createdPunches: number[] = [];
    dateClass: any;
    highlighCalendarDays: any[];

    constructor(
        private dialog: MatDialog,
        private employeeService: EmployeeService,
        private companyService: CompanyService,
        private employmentTypeService: EmploymentTypeService,
        private companyDepartmentService: CompanyDepartmentService,
        private employeeMessageService: EmployeeMessageService,
        private authService: AuthService,
        private punchService: PunchService
    ) {}

    ngOnInit(): void {
        this.filterQuery = '';
        this.filterDay = Helpers.toShortDateString(new Date());
        this.filterCompanyIds = [];
        this.filterEmploymentTypeIds = [];
        this.filterDepartmentIds = [];
        this.filterHasMessages = false;

        if (Helpers.isDefined(this.inputQuery)) {
            this.filterQuery = this.inputQuery;
        }

        if (Helpers.isDefined(this.inputDay)) {
            this.filterDay = this.inputDay;
        }

        if (Helpers.isDefined(this.inputCompanyIds)) {
            this.filterCompanyIds = this.inputCompanyIds.split(',').map(function (item) {
                return parseInt(item, 10);
            });
        }

        if (Helpers.isDefined(this.inputEmploymentTypeIds)) {
            this.filterEmploymentTypeIds = this.inputEmploymentTypeIds.split(',').map(function (item) {
                return parseInt(item, 10);
            });
        }

        if (Helpers.isDefined(this.inputDepartmentIds)) {
            this.filterDepartmentIds = this.inputDepartmentIds.split(',').map(function (item) {
                return parseInt(item, 10);
            });
        }

        if (Helpers.isDefined(this.inputHasMessages)) {
            this.filterHasMessages = this.inputHasMessages == 1;
        }

        this.getCompanies();
        this.getEmploymentTypes();
        this.getCompanyDepartments();
        this.getEmployees();
        this.highlightCalendarDays();
    }

    highlightCalendarDays(): void {
        let now = new Date();
        let toString = Helpers.toShortDateString(now);
        let from = now;
        from.setTime(from.getTime() - 90 * 24 * 60 * 60 * 1000);
        let fromString = Helpers.toShortDateString(from);

        this.employeeMessageService.getCountPerDay(this.authService.getEmployeeId(), fromString, toString).subscribe({
            next: (data) => {
                this.highlighCalendarDays = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get employees');
            },
        });

        this.dateClass = (d: Date) => {
            const dateString = Helpers.toShortDateString(d) + 'T00:00:00';
            for (let day of this.highlighCalendarDays) {
                if (day.day === dateString) {
                    if (day.unansweredCount > 0) {
                        return 'calendar-day-highlight-yellow';
                    } else if (day.answeredCount > 0) {
                        return 'calendar-day-highlight-green';
                    } else {
                        return undefined;
                    }
                }
            }
        };
    }

    getEmployees(): void {
        let search: IEditPunchesSearch = {
            query: this.filterQuery,
            day: Helpers.toShortDateString(this.filterDay),
            companyIds: this.filterCompanyIds,
            employmentTypeIds: this.filterEmploymentTypeIds,
            departmentIds: this.filterDepartmentIds,
            hasMessages: this.filterHasMessages,
        };
        this.loading = true;
        this.createdPunches = [];

        this.employeeService.getEditPunches(search).subscribe({
            next: (data) => {
                this.changedPunches = [];
                this.employees = data;
                this.generateHours();
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get employees');
            },
        });

        let filterSettings = {
            query: this.filterQuery,
            day: Helpers.toShortDateString(this.filterDay),
            companyIds: this.filterCompanyIds,
            employmentTypeIds: this.filterEmploymentTypeIds,
            departmentIds: this.filterDepartmentIds,
            hasMessages: this.filterHasMessages,
        };

        this.filterChanged.emit(filterSettings);
    }

    generateHours(): void {
        if (this.employees.length === 0) {
            return;
        }

        let minTime: Date = null,
            maxTime: Date = null;
        let now = new Date();
        this.hours = [];

        for (let employee of this.employees) {
            for (let punch of employee.punches) {
                let punchTime = new Date(punch.punchTime);

                if (minTime === null || punchTime < minTime) {
                    minTime = punchTime;
                }

                if (maxTime === null || punchTime > maxTime) {
                    maxTime = punchTime;
                }
            }
        }

        if (minTime === null) {
            minTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 8);
        }

        if (maxTime === null) {
            maxTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours());
        }

        let from = new Date(minTime.getFullYear(), minTime.getMonth(), minTime.getDate(), minTime.getHours());
        let to = new Date(maxTime.getFullYear(), maxTime.getMonth(), maxTime.getDate(), maxTime.getHours());

        if (from.getHours() > 0) {
            from.setTime(from.getTime() - this.hourInMilliseconds);
        }

        if (to.getHours() < 23) {
            to.setTime(to.getTime() + this.hourInMilliseconds);
        }

        this.timelineLengthHours = Math.abs(to.getTime() - from.getTime()) / this.hourInMilliseconds + 1;
        this.timelineStartMinutes = from.getHours() * 60;
        this.timelineStartTime = from;

        for (let i = 0; i < this.timelineLengthHours; i++) {
            let newTime = new Date(from.getTime() + i * this.hourInMilliseconds);
            this.hours.push(newTime.getHours());
        }

        if (this.hours[this.hours.length - 1] === 23) {
            this.lastHour = '24';
        } else {
            this.lastHour = Helpers.padLeft((this.hours[this.hours.length - 1] + 1).toString(), 2);
        }

        setTimeout(() => {
            this.calculatePixelsPerMinute();
        }, 100);
    }

    getCompanies() {
        this.companyService.getAll().subscribe({
            next: (data) => {
                this.companies = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get companies');
            },
        });
    }

    getEmploymentTypes() {
        this.employmentTypeService.getAll().subscribe({
            next: (data) => {
                this.employmentTypes = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get employment types');
            },
        });
    }

    getCompanyDepartments() {
        this.companyDepartmentService.getAll().subscribe({
            next: (data) => {
                this.companyDepartments = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get copmany departments');
            },
        });
    }

    getTimestamp(leftPosition: number): string {
        let minute = Math.round((leftPosition + 3 - this.timelineLeftPosition) / this.pixelsPerMinute);
        let minuteOfDay = this.timelineStartMinutes + minute;
        let hours = Math.floor(minuteOfDay / 60);

        if (hours > 23) {
            hours = 23;
        } /*else if (hours < 1) {
            hours = 1;
        }*/

        return Helpers.padLeft(hours.toString(), 2) + ':' + Helpers.padLeft((minuteOfDay % 60).toString(), 2);
    }

    calculatePixelsPerMinute(): void {
        let timelineWidth = this.timelineHeader.nativeElement.offsetWidth;
        this.timelineLeftPosition = this.timelineHeader.nativeElement.getBoundingClientRect().left;
        this.pixelsPerMinute = timelineWidth / (this.timelineLengthHours * 60);
    }

    showTimelineLabel(targetElement: any): void {
        this.timelineLabelVisible = true;
        let boundingRect = targetElement.getBoundingClientRect();
        let left = boundingRect.x,
            top = boundingRect.y;
        let labelWidth = 60;
        let height = Math.round(boundingRect.height);

        this.timelineLabelText = this.getTimestamp(left);
        this.timelineLabelLeft = left - labelWidth / 2;
        this.timelineLabelTop = top - height;
    }

    showInsertPunch(event: any, employee: IEmployeeEditPunches): void {
        let now = new Date().getTime();

        if (now - this.dragPunchElementReleased < 500) {
            return;
        }

        let left = event.clientX;
        let timestamp = this.getTimestamp(left);

        let handle = this.dialog.open(EditPunchDialog, {
            width: '400px',
            data: {
                punch: null,
                clickTimestamp: timestamp,
                employee: employee,
                date: this.filterDay,
            },
        });

        handle.afterClosed().subscribe((result) => {
            if (Helpers.isDefined(result)) {
                this.createdPunches.push(result.punch.punchId);
                this.punchChanged(result.action, result.punch, employee);
            }
        });
    }

    showEditPunch(punch: IPunchEdit, employee: IEmployeeEditPunches): void {
        let now = new Date().getTime();

        if (now - this.dragPunchElementReleased < 500) {
            return;
        }

        let handle = this.dialog.open(EditPunchDialog, {
            width: '400px',
            data: {
                punch: punch,
                clickTimestamp: null,
                employee: employee,
                date: this.filterDay,
            },
        });

        handle.afterClosed().subscribe((result) => {
            if (Helpers.isDefined(result)) {
                this.punchChanged(result.action, result.punch, employee);
            }
        });
    }

    mouseEnterPunch(punchElement: any): void {
        if (!this.isDraggingPunch) {
            this.showPunchTime(punchElement);
        }
    }

    mouseLeavePunch(): void {
        if (!this.isDraggingPunch) {
            this.timelineLabelVisible = false;
        }
    }

    showPunchTime(punchElement: any): void {
        this.showTimelineLabel(punchElement);
    }

    punchDragStarted(punchElem: any): void {
        this.draggingPunchElement = punchElem;
        this.isDraggingPunch = true;
        this.mouseMoveSubscription = this.mouseMoveObservable().subscribe((event) => {
            this.showTimelineLabel(this.draggingPunchElement);
        });
    }

    punchDragEnded(punch: IPunchEdit, employee: IEmployeeEditPunches): void {
        if (this.mouseMoveSubscription) {
            this.mouseMoveSubscription.unsubscribe();
        }

        this.dragPunchElementReleased = new Date().getTime();
        let boundingRect = this.draggingPunchElement.getBoundingClientRect();
        let timestamp = this.getTimestamp(boundingRect.x);

        punch.punchTime = Helpers.toShortDateString(punch.punchTime) + 'T' + timestamp + ':00';
        this.isDraggingPunch = false;

        this.punchChanged('UPDATE', punch, employee);
    }

    punchChanged(action: string, punch: IPunchEdit, employee: IEmployeeEditPunches): void {
        punch.edited = true;

        let isNew = false;

        for (let i = 0; i < this.changedPunches.length; i++) {
            if (this.changedPunches[i].punch.punchId === punch.punchId) {
                isNew = this.changedPunches[i].action === 'CREATE';
                this.changedPunches.splice(i, 1);
                break;
            }
        }

        if (action === 'DELETE') {
            for (let i = 0; i < employee.punches.length; i++) {
                if (employee.punches[i].punchId === punch.punchId) {
                    employee.punches.splice(i, 1);
                    break;
                }
            }

            if (this.createdPunches.indexOf(punch.punchId) === -1) {
                this.changedPunches.push({ action, punch });
            }
        } else {
            this.changedPunches.push({ action: isNew ? 'CREATE' : action, punch });
        }

        if (action === 'CREATE') {
            employee.punches.push(punch);
        }

        this.generateHours();
        this.reRenderPunches(employee);
    }

    updatePunches(): void {
        let handle = this.dialog.open(ConfirmDialog, {
            width: '400px',
            data: {
                title: 'Fortsätta?',
                message: 'Vill du spara dina förändringar?',
            },
        });

        handle.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.loading = true;

                this.punchService
                    .editUpdatePunches({ punches: this.changedPunches, replyMessages: this.replyMessages })
                    .subscribe({
                        next: (data) => {
                            this.getEmployees();
                        },
                        error: (error) => {
                            this.loading = false;
                            console.error(error);
                            Helpers.captureSentryError('Could not update edited punches');
                        },
                    });
            }
        });
    }

    reRenderPunches(employee: IEmployeeEditPunches): void {
        let punches = [...employee.punches];
        employee.punches = [];
        setTimeout(() => {
            employee.punches = [...punches];
        }, 1);
    }

    reRenderMessages(employee: IEmployeeEditPunches): void {
        let messages = [...employee.messages];
        employee.messages = [];
        setTimeout(() => {
            employee.messages = [...messages];
        }, 1);
    }

    showMessages(employee: IEmployeeEditPunches): void {
        let handle = this.dialog.open(EditPunchMessagesDialog, {
            width: '600px',
            data: {
                employee: employee,
                day: this.filterDay,
            },
        });

        handle.afterClosed().subscribe((result) => {
            this.replyMessages = [];

            for (let employee of this.employees) {
                for (let message of employee.messages) {
                    if (message.sendReply) {
                        this.replyMessages.push(message);
                    }
                }
            }

            this.reRenderMessages(employee);
        });
    }

    mouseMoveObservable(): Observable<any> {
        let event = fromEvent(document, 'mousemove');
        return event.pipe(debounceTime(30));
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.calculatePixelsPerMinute();
    }
}
