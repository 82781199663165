<main role="main">
    <div class="container-fluid punch-login-page-container" (click)="focusLogin()">
        <div class="row">
            <div class="col-md-5 col-lg-4 col-xl-3 working-now" [hidden]="!showActiveEmployees">
                <div *ngFor="let company of activeEmployees">
                    <span class="mat-headline-6">{{ company.companyName }}</span>
                    <mat-list>
                        <mat-list-item
                            *ngFor="let employee of company.employees"
                            [ngClass]="{ 'not-working': !employee.punchedIn }">
                            <mat-icon>perm_identity</mat-icon>
                            {{ employee.firstName }} {{ employee.lastName }} ({{ getPunchDate(employee.lastPunch) }})
                        </mat-list-item>
                    </mat-list>
                </div>
            </div>
            <div class="col main-content">
                <div class="row clock">
                    <div class="toggle-active-employees">
                        <button mat-fab (click)="toggleActiveEmployees()">
                            <mat-icon>account_circle</mat-icon>
                        </button>
                    </div>
                    <div class="register">
                        <button mat-fab routerLink="/register">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                    <div class="help">
                        <button mat-fab (click)="showHelp()">
                            <mat-icon>help</mat-icon>
                        </button>
                    </div>
                    <div class="whistleblower">
                        <button mat-fab (click)="showWhistleblowerInfo()">
                            <mat-icon>campaign</mat-icon>
                        </button>
                    </div>
                    <div class="col">
                        <h2 class="mat-headline-1">{{ currentTime }}</h2>
                        <h2 class="mat-headline-2">
                            {{ currentDay }} - v.
                            {{ currentDateTime | weekOfYear }}
                        </h2>
                    </div>
                </div>
                <div class="row login">
                    <div class="col">
                        <div *ngIf="!loading; else loggingIn">
                            <mat-form-field appearance="outline">
                                <input
                                    autofocus
                                    matInput
                                    type="password"
                                    placeholder="Kod"
                                    #loginCode
                                    (keydown.enter)="login(loginCode.value)"
                                    name="loginByCode" />
                            </mat-form-field>
                            <!--<div class="code-info">
                                <mat-icon>info</mat-icon>
                                <i>Alla koder är nu 4 siffror. Om du tidigare hade 3 siffror, lägg på en nolla i början</i>
                            </div>-->
                        </div>
                        <ng-template #loggingIn>
                            <mat-spinner></mat-spinner>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
