import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'alert-dialog',
    templateUrl: './alert.dialog.html',
    styleUrls: ['./alert.dialog.scss']
})

export class AlertDialog {
    title: string;
    message: string;
    list: string[]

    constructor(
        private dialogRef: MatDialogRef<AlertDialog>,
        @Inject(MAT_DIALOG_DATA) {
            title,
            message,
            list
        }: any) {

        this.title = title;
        this.message = message;
        this.list = list;
    }

    close(): void {
        this.dialogRef.close();
    }
}
