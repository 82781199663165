import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from "@angular/router";

// Services
import { AuthService } from '@app/services/auth.service';
import { IEmployeeShort } from '@app/interfaces/employee-short.interface';

@Component({
    selector: 'app-edit-employee-page',
    templateUrl: './edit-employee.component.html',
    styleUrls: ['./edit-employee.component.scss']
})

export class EditEmployeeComponent implements OnInit {
    employeeId: number;

    constructor(private authService: AuthService,
        public dialog: MatDialog,
        private router: Router) { }

    ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
    }

    saved(employee: IEmployeeShort): void {
        this.router.navigate(['/punch']);
    }
}
