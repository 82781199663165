import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Helpers } from '@app/helpers/helpers.class';

// Error handling
import * as Sentry from '@sentry/browser';

// Dialogs
import { ConfirmDialog } from '@app/shared/dialogs/confirm/confirm.dialog';
import { AlertDialog } from '../alert/alert.dialog';

// Services
import { EmployeeService } from '@app/services/employee.service';
import { CostPoolService } from '@app/services/cost-pool.service';

// Interfaces
import { IPunch } from '@app/interfaces/punch.interface';
import { IEmployeeShort } from '@app/interfaces/employee-short.interface';
import { ICostPool } from '@app/interfaces/cost-pool.interface';
import { IEmployeeEditPunches } from '@app/interfaces/employee-edit-puches.interface';

@Component({
    selector: 'edit-punch-dialog',
    templateUrl: './edit-punch.dialog.html',
    styleUrls: ['./edit-punch.dialog.scss'],
})
export class EditPunchDialog implements OnInit {
    employees: IEmployeeShort[];
    punch: IPunch;
    punchTime: string = '';
    overtime: boolean = false;
    overtimeOrderedByEmployeeId: number = null;
    punchTypeId: number = 1;
    costPoolId: number = null;
    costPools: ICostPool[] = [];
    date: string = null;
    employee: IEmployeeEditPunches;

    constructor(
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<EditPunchDialog>,
        private employeeService: EmployeeService,
        private costPoolService: CostPoolService,
        @Inject(MAT_DIALOG_DATA) { employee, punch, clickTimestamp, date }: any
    ) {
        this.punch = punch;
        this.date = date;
        this.employee = employee;

        if (Helpers.isDefined(this.punch)) {
            this.punchTime = Helpers.toShortTimeString(this.punch.punchTime);
            this.overtime = this.punch.overtime;
            this.overtimeOrderedByEmployeeId = this.punch.overtimeEmployeeId;
            this.punchTypeId = this.punch.punchTypeId;
            this.costPoolId = this.punch.poolId;
        } else {
            this.punchTime = clickTimestamp;
        }
    }

    ngOnInit(): void {
        for (let punch of this.employee.punches) {
            if (Helpers.isDefined(punch.poolId) && punch.poolId > 0) {
                this.costPoolId = punch.poolId;
                break;
            }
        }

        if (!Helpers.isDefined(this.costPoolId) || this.costPoolId === 0) {
            this.costPoolService.getSuggestedPoolForEmployee(this.employee.employeeId).subscribe({
                next: (data) => {
                    this.costPoolId = data.poolId;
                },
                error: (error) => {
                    console.error(error);
                    Helpers.captureSentryError('Could not get suggested cost pool');
                },
            });
        }

        this.getEmployees();
        this.getCostPools();
    }

    getEmployees(): void {
        this.employeeService.getCanOrderOvertime().subscribe({
            next: (data) => {
                this.employees = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get cost pools');
            },
        });
    }

    getCostPools(): void {
        this.costPoolService.getParents().subscribe({
            next: (data) => {
                this.costPools = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get cost pools');
            },
        });
    }

    close(action: string = null, punch: IPunch = null): void {
        if (Helpers.isDefined(action)) {
            this.dialogRef.close({
                action,
                punch,
            });
        } else {
            this.dialogRef.close();
        }
    }

    private getUniqueId(): number {
        return parseInt(new Date().getTime().toString().substr(5, 8), 10);
    }

    createPunch(): void {
        if (!this.validate()) {
            return;
        }

        this.punch = {
            employeeId: this.employee.employeeId,
            punchTypeId: this.punchTypeId,
            punchTime: Helpers.toShortDateString(this.date) + 'T' + this.punchTime + ':00',
            overtime: this.overtime,
            overtimeEmployeeId: this.overtimeOrderedByEmployeeId,
            poolId: this.costPoolId,
            punchId: this.getUniqueId(),
        };

        this.close('CREATE', this.punch);
    }

    updatePunch(): void {
        if (!this.validate()) {
            return;
        }

        this.punch.punchTime = Helpers.toShortDateString(this.punch.punchTime) + 'T' + this.punchTime + ':00';
        this.punch.overtime = this.overtime;
        this.punch.overtimeEmployeeId = this.overtimeOrderedByEmployeeId;
        this.punch.poolId = this.costPoolId;

        this.close('UPDATE', this.punch);
    }

    deletePunch(): void {
        let handle = this.dialog.open(ConfirmDialog, {
            width: '400px',
            data: {
                title: 'Fortsätta?',
                message: 'Är du säker på att du vill ta bort stämplingen?',
            },
        });

        handle.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.close('DELETE', this.punch);
            }
        });
    }

    validate(): boolean {
        if (!Helpers.shortTimeRegex.test(this.punchTime)) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Felaktig tid',
                    message: 'Tiden du angivit är inte giltig',
                },
            });
            return false;
        }

        if (this.overtime && (!Helpers.isDefined(this.overtimeOrderedByEmployeeId) || this.overtimeOrderedByEmployeeId === 0)) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Beordrad av',
                    message: 'Du måste välja vem som beordrat övertiden',
                },
            });
            return false;
        }

        if (!Helpers.isDefined(this.costPoolId) || this.costPoolId === 0) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Felaktigt kostnadsställe',
                    message: 'Du måste välja ett kostnadsställe',
                },
            });
            return false;
        }

        if (this.punchTypeId === 1) {
            this.overtime = false;
            this.overtimeOrderedByEmployeeId = null;
        }

        return true;
    }

    punchTypeUpdated(newPunchTypeId: number): void {
        if (newPunchTypeId === 1) {
            this.overtime = false;
            this.overtimeOrderedByEmployeeId = null;
        }
    }
}
