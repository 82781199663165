<div class="container-fluid">
    <div class="row">
        <div class="col employee-filter">
            <label>Hanteras av</label>
            <mat-radio-group [(ngModel)]="filterEmployeeId" (change)="getEmployeesPage(1)">
                <mat-radio-button [value]="employeeId">Mig</mat-radio-button>
                <mat-radio-button [value]="0">Visa alla</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="col status-filter">
            <label>Status</label>
            <mat-radio-group [(ngModel)]="filterStatus" (change)="getEmployeesPage(1)">
                <mat-radio-button [value]="1">Att göra</mat-radio-button>
                <mat-radio-button [value]="2">Färdiga</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="col day-filter" [hidden]="filterStatus !== 2">
            <label>Dag</label>
            <mat-form-field>
                <input matInput [matDatepicker]="picker" (dateChange)="getEmployeesPage(1)" [(ngModel)]="filterDay" disabled />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker [disabled]="filterStatus != 2"></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col query-filter">
            <label>Sök</label>
            <mat-form-field>
                <input matInput [(ngModel)]="filterQuery" autocomplete="off" placeholder="Fritextsök..." />
            </mat-form-field>
        </div>
        <div class="col button-filter">
            <button mat-raised-button (click)="getEmployeesPage(1)" color="primary">Sök</button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="divider-wrapper">
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col" *ngIf="employeesPage && employeesPage.totalItems; else noResults;">
            <mat-paginator
                *ngIf="employeesPage && employeesPage.totalItems > employeesPage.pageSize"
                [length]="employeesPage.totalItems"
                [pageSize]="employeesPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
            <div class="table-wrapper">
                <table mat-table *ngIf="employeesPage" [dataSource]="dataSource" multiTemplateDataRows>
                    <ng-container matColumnDef="checkbox">
                        <th mat-header-cell *matHeaderCellDef class="checkbox">
                            <mat-checkbox
                                (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                            <button mat-icon-button [matMenuTriggerFor]="costPoolsMenu">
                                <mat-icon>keyboard_arrow_down</mat-icon>
                            </button>
                            <mat-menu #costPoolsMenu="matMenu">
                                <label class="title">Koppla nedan valda till</label>
                                <button mat-menu-item *ngFor="let costPool of costPools" (click)="setCostPool(costPool)">
                                    <mat-icon>attach_money</mat-icon>
                                    <span> {{costPool.name}} </span>
                                </button>
                                <div class="spacer"></div>
                                <button mat-menu-item (click)="unknown()" class="unknown">
                                    <mat-icon>remove_circle</mat-icon>
                                    <span>Vet ej</span>
                                </button>
                            </mat-menu>
                        </th>
                        <td mat-cell *matCellDef="let employee" class="checkbox">
                            <mat-checkbox
                                click-stop-propagation
                                (change)="$event ? masterToggleEmployee(employee) : null"
                                [checked]="isAnySelectedEmployee(employee) && isAllSelectedEmployee(employee)"
                                [indeterminate]="isAnySelectedEmployee(employee) && !isAllSelectedEmployee(employee)">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="name">Namn</th>
                        <td mat-cell *matCellDef="let employee" class="name">{{employee.lastName}}, {{employee.firstName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="company">
                        <th mat-header-cell *matHeaderCellDef class="company">Bolag</th>
                        <td mat-cell *matCellDef="let employee" class="company">{{employee.companyName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="department">
                        <th mat-header-cell *matHeaderCellDef class="department">Avdelning</th>
                        <td mat-cell *matCellDef="let employee" class="department">
                            {{employee.departmentName | emptyReplacement}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="daysCount">
                        <th mat-header-cell *matHeaderCellDef class="punch-days-count">Dagar</th>
                        <td mat-cell *matCellDef="let employee" class="punch-days-count">
                            {{employee.uncategorizedPunches.length}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="expand">
                        <th mat-header-cell *matHeaderCellDef class="expand"></th>
                        <td mat-cell *matCellDef="let employee" class="expand">
                            <mat-icon *ngIf="employee != expandedEmployee">keyboard_arrow_down</mat-icon>
                            <mat-icon *ngIf="employee == expandedEmployee">keyboard_arrow_up</mat-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="uncategorizedPunches">
                        <td mat-cell *matCellDef="let employee" [attr.colspan]="employeeColumns.length">
                            <div class="punch-days-wrapper" [@toggle]="employee == expandedEmployee ? 'expanded' : 'collapsed'">
                                <table
                                    class="punch-days"
                                    mat-table
                                    [dataSource]="employee.uncategorizedPunches"
                                    multiTemplateDataRows
                                    *ngIf="visibleEmployeeIds.indexOf(employee.employeeId) > -1">
                                    <ng-container [matColumnDef]="employee.punchDayColumns[0]">
                                        <th mat-header-cell *matHeaderCellDef class="punch-day-checkbox"></th>
                                        <td mat-cell *matCellDef="let punchDay" class="punch-day-checkbox">
                                            <mat-checkbox
                                                click-stop-propagation
                                                (change)="$event ? masterTogglePunchDay(punchDay) : null"
                                                [checked]="isAnySelectedPunchDay(punchDay) && isAllSelectedPunchDay(punchDay)"
                                                [indeterminate]="isAnySelectedPunchDay(punchDay) && !isAllSelectedPunchDay(punchDay)">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="employee.punchDayColumns[1]">
                                        <th mat-header-cell *matHeaderCellDef class="punch-day">Dag</th>
                                        <td mat-cell *matCellDef="let punchDay" class="punch-day">
                                            {{punchDay.day | shortDate}}
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="employee.punchDayColumns[2]">
                                        <th mat-header-cell *matHeaderCellDef class="punch-day-spacer"></th>
                                        <td mat-cell *matCellDef="let punchDay" class="punch-day-spacer"></td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="employee.punchDayColumns[3]">
                                        <th mat-header-cell *matHeaderCellDef class="punch-day-total-time">Total tid</th>
                                        <td mat-cell *matCellDef="let punchDay" class="punch-day-total-time">
                                            {{punchDay.totalTime | readableTimeSpan}}
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="employee.punchDayColumns[4]">
                                        <th mat-header-cell *matHeaderCellDef class="punch-day-punches-count">Perioder</th>
                                        <td mat-cell *matCellDef="let punchDay" class="punch-day-punches-count">
                                            {{punchDay.punchPairs.length}} st
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="employee.punchDayColumns[5]">
                                        <th mat-header-cell *matHeaderCellDef class="expand"></th>
                                        <td mat-cell *matCellDef="let punchDay" class="expand">
                                            <mat-icon *ngIf="punchDay != expandedPunchDay">keyboard_arrow_down</mat-icon>
                                            <mat-icon *ngIf="punchDay == expandedPunchDay">keyboard_arrow_up</mat-icon>
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="'punchPairs_' + employee.index">
                                        <td mat-cell *matCellDef="let punchDay" [attr.colspan]="employee.punchDayColumns.length">
                                            <div
                                                class="punches-wrapper"
                                                [@toggle]="punchDay == expandedPunchDay ? 'expanded' : 'collapsed'">
                                                <table class="punches" mat-table [dataSource]="punchDay.punchPairs">
                                                    <ng-container [matColumnDef]="punchDay.punchColumns[0]">
                                                        <th mat-header-cell *matHeaderCellDef class="punch-checkbox"></th>
                                                        <td mat-cell *matCellDef="let punch" class="punch-checkbox">
                                                            <mat-checkbox
                                                                click-stop-propagation
                                                                (change)="$event ? selection.toggle(punch) : null"
                                                                [checked]="selection.isSelected(punch)">
                                                            </mat-checkbox>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container [matColumnDef]="punchDay.punchColumns[1]">
                                                        <th mat-header-cell *matHeaderCellDef class="punch-from">Från</th>
                                                        <td mat-cell *matCellDef="let punch" class="punch-from">
                                                            {{punch.in | shortTime}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container [matColumnDef]="punchDay.punchColumns[2]">
                                                        <th mat-header-cell *matHeaderCellDef class="punch-to">Till</th>
                                                        <td mat-cell *matCellDef="let punch" class="punch-to">
                                                            {{punch.out | shortTime}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container [matColumnDef]="punchDay.punchColumns[3]">
                                                        <th mat-header-cell *matHeaderCellDef class="punch-worked-time">
                                                            Arbetad tid
                                                        </th>
                                                        <td mat-cell *matCellDef="let punch" class="punch-worked-time">
                                                            {{punch.workedMinutes | readableTimeSpan}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container [matColumnDef]="punchDay.punchColumns[4]">
                                                        <th mat-header-cell *matHeaderCellDef class="punch-cost-pool">
                                                            Kostnadsställe
                                                        </th>
                                                        <td mat-cell *matCellDef="let punch" class="punch-cost-pool">
                                                            {{punch.costPool ? punch.costPool.name : '-'}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row *matHeaderRowDef="punchDay.punchColumns"></tr>
                                                    <tr mat-row *matRowDef="let punch; columns: punchDay.punchColumns;"></tr>
                                                </table>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="employee.punchDayColumns"></tr>
                                    <tr
                                        mat-row
                                        *matRowDef="let punchDay; columns: employee.punchDayColumns;"
                                        class="punch-day-row"
                                        (click)="expandedPunchDay == punchDay ? expandedPunchDay = null : expandedPunchDay = punchDay"></tr>
                                    <tr
                                        mat-row
                                        *matRowDef="let punch; columns: ['punchPairs_' + employee.index];"
                                        class="punches-row"></tr>
                                </table>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="employeeColumns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let employee; columns: employeeColumns;"
                        class="employee-row"
                        (click)="toggleEmployeeRow(employee)"></tr>
                    <tr mat-row *matRowDef="let punchDay; columns: ['uncategorizedPunches']" class="punch-days-row"></tr>
                </table>
            </div>
            <mat-paginator
                *ngIf="employeesPage && employeesPage.totalItems > employeesPage.pageSize"
                [length]="employeesPage.totalItems"
                [pageSize]="employeesPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
        </div>
        <ng-template #noResults>
            <div class="col">
                <p class="no-results">Inga stämplingar att koppla</p>
            </div>
        </ng-template>
    </div>

    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
