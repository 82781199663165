import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// Error handling
import * as Sentry from '@sentry/browser';

// Services
import { CarService } from '@app/services/car.service';
import { CompanyService } from '@app/services/company.service';
import { EmployeeService } from '@app/services/employee.service';

// Interfaces
import { IPage } from '@app/interfaces/page.interface';
import { ICompany } from '@app/interfaces/company.interface';
import { ICarExtended } from '@app/interfaces/car-extended.interface';
import { IEmployeeForSelect } from '@app/interfaces/employee-for-select.interface';
import { CarDrivingLogService } from '@app/services/car-driving-log.service';
import { ICarDrivingLogSearch } from '@app/interfaces/car-driving-log-search.interface';
import { Helpers } from '@app/helpers/helpers.class';

@Component({
    selector: 'app-driving-log-report',
    templateUrl: './driving-log-report.partial.html',
    styleUrls: ['./driving-log-report.partial.scss'],
})
export class DrivingLogReportPartial implements OnInit {
    @Output() filterChanged: EventEmitter<any> = new EventEmitter();
    @Input('companyIds') inputCompanyIds: string;
    @Input('carIds') inputCarIds: string;
    @Input('employeeIds') inputEmployeeIds: string;
    @Input('from') inputFrom: string;
    @Input('to') inputTo: string;
    @Input('page') inputPage: number;

    public pageIndex: number;
    public loading: boolean = false;
    public filterCompanyIds: number[];
    public filterCarIds: number[];
    public filterEmployeeIds: number[];
    public filterFrom: string;
    public filterTo: string;
    public drivingLogsPage: IPage<any>;
    public drivingLogsColumns: string[] = ['type', 'date', 'company', 'car', 'mileage', 'tripLength', 'employee', 'description'];
    public companies: ICompany[];
    public cars: ICarExtended[];
    public employees: IEmployeeForSelect[];

    constructor(
        private carService: CarService,
        private companyService: CompanyService,
        private employeeService: EmployeeService,
        private carDrivingLogService: CarDrivingLogService
    ) {}

    public ngOnInit(): void {
        this.filterCompanyIds = [];
        this.filterCarIds = [];
        this.filterEmployeeIds = [];
        this.filterFrom = Helpers.getCurrentMonthStart();
        this.filterTo = Helpers.getCurrentMonthEnd();

        if (Helpers.isDefined(this.inputCompanyIds)) {
            this.filterCompanyIds = this.inputCompanyIds.split(',').map(function (item) {
                return parseInt(item, 10);
            });
        }

        if (Helpers.isDefined(this.inputCarIds)) {
            this.filterCarIds = this.inputCarIds.split(',').map(function (item) {
                return parseInt(item, 10);
            });
        }

        if (Helpers.isDefined(this.inputEmployeeIds)) {
            this.filterEmployeeIds = this.inputEmployeeIds.split(',').map(function (item) {
                return parseInt(item, 10);
            });
        }

        if (Helpers.isDefined(this.inputFrom)) {
            this.filterFrom = this.inputFrom;
        }

        if (Helpers.isDefined(this.inputTo)) {
            this.filterTo = this.inputTo;
        }

        if (Helpers.isDefined(this.inputPage)) {
            this.pageIndex = this.inputPage - 1;
        }

        this.getCompanies();
        this.getCars();
        this.getDrivingLogEmployees();
        this.getDrivingLogsPage(1);
    }

    public getDrivingLogsPage(page: number): void {
        let search: ICarDrivingLogSearch = {
            companyIds: this.filterCompanyIds,
            carIds: this.filterCarIds,
            employeeIds: this.filterEmployeeIds,
            from: Helpers.toShortDateString(this.filterFrom),
            to: Helpers.toShortDateString(this.filterTo),
            page: page,
        };

        this.loading = true;
        this.carDrivingLogService.getReport(search).subscribe({
            next: (data) => {
                this.drivingLogsPage = data;
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get driving logs');
            },
        });

        let filterSettings = {
            companyIds: this.filterCompanyIds,
            carIds: this.filterCarIds,
            employeeIds: this.filterEmployeeIds,
            from: Helpers.toShortDateString(this.filterFrom),
            to: Helpers.toShortDateString(this.filterTo),
            page: page,
        };

        this.filterChanged.emit(filterSettings);
    }

    public getCompanies(): void {
        this.companyService.getAll().subscribe({
            next: (data) => {
                this.companies = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get companies');
            },
        });
    }

    public getCars(): void {
        this.carService.getAll().subscribe({
            next: (data) => {
                this.cars = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get companies');
            },
        });
    }

    public getDrivingLogEmployees(): void {
        this.employeeService.getDrivingLogEmployees().subscribe({
            next: (data) => {
                this.employees = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get driving log employees');
            },
        });
    }
}
