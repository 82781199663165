import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { IPunch } from '@app/interfaces/punch.interface';
import { Helpers } from '@app/helpers/helpers.class';

@Pipe({name: 'overtimeEmployeeName'})
export class OvertimeEmployeeNamePipe implements PipeTransform {
    transform(punches: IPunch[]): string {
        if(!Helpers.isDefined(punches)) {
            return '';
        }

        for(let i = 0; i < punches.length; i++) {
            if(punches[i].overtimeEmployee !== null) {
                return punches[i].overtimeEmployee.lastName + ', ' +
                    punches[i].overtimeEmployee.firstName;
            }
        }
    }
}
