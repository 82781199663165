<div class="container-fluid">
    <div class="row">
        <div class="col">
            <mat-card class="compact" *ngIf="tokenValid && passwordForm">
                <mat-card-title>Lösenord</mat-card-title>
                <mat-card-content>
                    <div class="container-fluid">
                        <form [formGroup]="passwordForm" autocomplete="off">
                            <div class="row password-rules">
                                <div class="col">
                                    <strong> Lösenordet måste uppfylla följande: </strong>
                                    <ul>
                                        <li>Vara minst 12 tecken långt</li>
                                        <li>Innehålla minst en stor bokstav</li>
                                        <li>Innehålla minst en liten bokstav</li>
                                        <li>Innehålla minst en siffra</li>
                                        <li>Innehålla minst ett specialtecken</li>
                                        <li>Inte vara ett lösenord du använt innan</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <mat-form-field>
                                        <mat-label>Lösenord</mat-label>
                                        <input
                                            matInput
                                            type="password"
                                            autocomplete="new-password"
                                            formControlName="password"
                                            name="resetPassword"
                                            required />
                                        <mat-error *ngIf="f.password.hasError('required')"> Obligatoriskt fält </mat-error>
                                        <mat-error *ngIf="f.password.hasError('pattern')">
                                            Lösenordet måste följa standarden
                                        </mat-error>
                                        <mat-error *ngIf="f.password.hasError('match')"> Lösenorden måste matcha </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field>
                                        <mat-label>Upprepa lösenord</mat-label>
                                        <input
                                            matInput
                                            type="password"
                                            autocomplete="new-password"
                                            formControlName="passwordAgain"
                                            name="resetPasswordAgain"
                                            required />
                                        <mat-error *ngIf="f.passwordAgain.hasError('required')"> Obligatoriskt fält </mat-error>
                                        <mat-error *ngIf="f.passwordAgain.hasError('pattern')">
                                            Lösenordet måste följa standarden
                                        </mat-error>
                                        <mat-error *ngIf="f.passwordAgain.hasError('match')"> Lösenorden måste matcha </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </form>
                    </div>
                </mat-card-content>
            </mat-card>
            <ng-container *ngIf="tokenValid === false">
                <div class="alert alert-warning">
                    <div class="alert-icon">
                        <mat-icon>warning</mat-icon>
                    </div>
                    <div class="alert-message">
                        Denna länk kan inte användas för att återställa lösenordet. Det kan bero på att den är felaktig eller att
                        för lång tid har gått sedan den genererades.
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="row buttons" *ngIf="tokenValid">
        <div class="col" align="end">
            <button mat-raised-button color="primary" (click)="save()">Uppdatera lösenord</button>
        </div>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
