<div class="container-fluid">
    <div class="row filters">
        <div class="col query-filter">
            <label>Sök</label>
            <mat-form-field>
                <input
                    matInput
                    [(ngModel)]="filterQuery"
                    autocomplete="off"
                    autocomplete="off"
                    placeholder="Fritextsök..."
                    (keydown.enter)="getEmployeesPage(1)" />
            </mat-form-field>
        </div>
        <div class="col company-filter">
            <label>Bolag</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filterCompanyIds" placeholder="Välj bolag" multiple>
                    <mat-option *ngFor="let company of companies" [value]="company.companyId">{{company.companyName}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row filters">
        <div class="col employment-type-filter">
            <label>Anställning</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filterEmploymentTypeIds" placeholder="Välj anställningstyp" multiple>
                    <mat-option *ngFor="let employmentType of employmentTypes" [value]="employmentType.employmentTypeId">
                        {{employmentType.employmentTypeName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col company-department-filter">
            <label>Avdelning</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filterDepartmentIds" placeholder="Välj avdelning" multiple>
                    <mat-option *ngFor="let department of companyDepartments" [value]="department.departmentId">
                        {{companies | valueFromObjectArray:'companyId':department.companyId:'companyShort'}} -
                        {{department.departmentName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col registered-filter">
            <label>Registrerades</label>
            <div class="date-range">
                <mat-form-field class="from" (click)="pickerFrom.open()">
                    <input matInput [matDatepicker]="pickerFrom" [(ngModel)]="filterRegisteredFrom" placeholder="Från" readonly />
                    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFrom></mat-datepicker>
                </mat-form-field>
                <span>-</span>
                <mat-form-field class="to" (click)="pickerTo.open()">
                    <input matInput [matDatepicker]="pickerTo" [(ngModel)]="filterRegisteredTo" placeholder="Till" readonly />
                    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                    <mat-datepicker #pickerTo></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="row filters">
        <div class="col col-3 company-department-filter">
            <label>Bemanning</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filterStaffingCompanyIds" placeholder="Välj bemanningsföretag" multiple>
                    <mat-option *ngFor="let staffingCompany of staffingCompanies" [value]="staffingCompany.staffingCompanyId">
                        {{companies | valueFromObjectArray:'companyId':staffingCompany.staffingCompanyId:'companyName'}} -
                        {{staffingCompany.companyName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col active-filter">
            <mat-checkbox [(ngModel)]="filterActive">Aktiva</mat-checkbox>
        </div>
        <div class="col button-filter">
            <button mat-raised-button (click)="exportEmployees()">
                <mat-icon>attach_file</mat-icon>
                Exportera till fil
            </button>
            <button mat-raised-button (click)="getEmployeesPage(1)" color="primary">Sök</button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="divider-wrapper">
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col" *ngIf="employeesPage && employeesPage.totalItems; else noResults;">
            <mat-paginator
                *ngIf="employeesPage && employeesPage.totalItems > employeesPage.pageSize"
                [length]="employeesPage.totalItems"
                [pageSize]="employeesPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
            <div class="table-wrapper">
                <table mat-table *ngIf="employeesPage" [dataSource]="employeesPage.items">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="name">Namn</th>
                        <td mat-cell *matCellDef="let employee" class="name">{{employee.lastName}}, {{employee.firstName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="company">
                        <th mat-header-cell *matHeaderCellDef class="company">Bolag</th>
                        <td mat-cell *matCellDef="let employee" class="company">{{employee.company.companyName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="department">
                        <th mat-header-cell *matHeaderCellDef class="department">Avdelning</th>
                        <td mat-cell *matCellDef="let employee" class="department">
                            {{employee.department ? employee.department.departmentName : '-'}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="employmentType">
                        <th mat-header-cell *matHeaderCellDef class="employment-type">Anställning</th>
                        <td mat-cell *matCellDef="let employee" class="employment-type">
                            {{employee.employmentType ? employee.employmentType.employmentTypeName : ''}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="staffingCompany">
                        <th mat-header-cell *matHeaderCellDef class="employment-type">Bemanningsföretag</th>
                        <td mat-cell *matCellDef="let employee" class="staffingcompany">
                            {{employee.staffingCompany ? employee.staffingCompany.companyName : ''}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="identityNumber">
                        <th mat-header-cell *matHeaderCellDef class="identity-number">Personnummer</th>
                        <td mat-cell *matCellDef="let employee" class="identity-number">{{employee.identityNumber}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="employeeColumns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let employee; columns: employeeColumns;"
                        [routerLink]="'/admin/employees/' + employee.employeeId"></tr>
                </table>
            </div>
            <mat-paginator
                *ngIf="employeesPage && employeesPage.totalItems > employeesPage.pageSize"
                [length]="employeesPage.totalItems"
                [pageSize]="employeesPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
        </div>
        <ng-template #noResults>
            <div class="col">
                <p class="no-results">Inga anställda att visa</p>
            </div>
        </ng-template>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
