import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'arrayKeys'})
export class ArrayKeysPipe implements PipeTransform {
    transform(array: any[]): string[] {
        if(typeof array === 'undefined' || array === null) {
            return [];
        }

        return Object.keys(array);
    }
}
