import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

// Services
import { AuthService } from '@app/services/auth.service';

// Dialogs
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';

@Component({
    selector: 'app-driving-log-login-page',
    templateUrl: './driving-log-login.component.html',
    styleUrls: ['./driving-log-login.component.scss'],
})
export class DrivingLogLoginComponent implements OnInit {
    submitted: boolean = false;
    loginForm: UntypedFormGroup;
    loading: boolean = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private authService: AuthService,
        public dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.loginForm = this.formBuilder.group({
            code: ['', Validators.required],
        });
    }

    get f() {
        return this.loginForm.controls;
    }

    login(): void {
        this.submitted = true;

        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;

        this.authService.drivingLogLogin(this.f.code.value).subscribe({
            next: (data) => {
                this.router.navigate(['/driving-log/trip']);
            },
            error: (error) => {
                if (error.status === 401) {
                    this.dialog.open(AlertDialog, {
                        width: '400px',
                        data: {
                            title: 'Felaktig kod',
                            message: 'Du har angett en felaktig kod',
                        },
                    });
                }
                this.loading = false;
                console.error(error);
            },
        });
    }
}
