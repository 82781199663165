import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl, UntypedFormControl } from '@angular/forms';
import { Helpers } from '@app/helpers/helpers.class';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

// Error handling
import * as Sentry from '@sentry/browser';

// Services
import { CostPoolService } from '@app/services/cost-pool.service';

// Dialogs
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';

// Interfaces
import { ICar } from '@app/interfaces/car.interface';
import { ICostPool } from '@app/interfaces/cost-pool.interface';
import { CompanyService } from '@app/services/company.service';
import { ICompany } from '@app/interfaces/company.interface';
import { IEmployeeForSelect } from '@app/interfaces/employee-for-select.interface';
import { map, Observable, startWith } from 'rxjs';
import { EmployeeService } from '@app/services/employee.service';
import { ICostPoolManager } from '@app/interfaces/cost-pool-manager.interface';
import { ConfirmDialog } from '@app/shared/dialogs/confirm/confirm.dialog';

@Component({
    selector: 'app-cost-pool-form',
    templateUrl: './cost-pool-form.partial.html',
    styleUrls: ['./cost-pool-form.partial.scss'],
})
export class CostPoolFormPartial implements OnInit {
    @Input() public poolId: number;

    public loading: boolean = false;
    public costPool: ICostPool;
    public costPoolForm: UntypedFormGroup;
    public newManager = new UntypedFormControl('', [Validators.required]);
    public parentCostPools: ICostPool[];
    protected companies: ICompany[];
    public filteredEmployees: Observable<IEmployeeForSelect[]>;
    public allEmployees: IEmployeeForSelect[];
    public managers: ICostPoolManager[] = [];

    constructor(
        private costPoolService: CostPoolService,
        private formBuilder: UntypedFormBuilder,
        private dialog: MatDialog,
        private router: Router,
        private companyService: CompanyService,
        private employeeService: EmployeeService
    ) {}

    public ngOnInit(): void {
        this.costPoolForm = this.formBuilder.group({
            code: [null, [Validators.required, Validators.pattern(Helpers.integerRegex)]],
            name: [null, Validators.required],
            visible: [false, Validators.required],
            project: [null, Validators.pattern(Helpers.integerRegex)],
            parentPool: [null, Validators.pattern(Helpers.integerRegex)],
            company: [null, Validators.required],
            showInPunchMenu: [false, Validators.required]
        });

        this.getCompanies();
        this.getParentCostPools();
        this.getCostPoolManagers();

        this.filteredEmployees = this.newManager.valueChanges.pipe(
            startWith(''),
            map((value) => (value !== null && value.length > 1 ? this.filter(value) : []))
        );

        if (Helpers.isDefined(this.poolId) && this.poolId > 0) {
            this.getCostPool();
        } else {
            this.resetForm();
        }

        this.getEmployees();
    }

    public get f() {
        return this.costPoolForm.controls;
    }

    public getEmployees(): void {
        this.employeeService.getAllForSelect().subscribe({
            next: (data) => {
                this.allEmployees = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get employees');
            },
        });
    }

    public filter(value: string): IEmployeeForSelect[] {
        const queries = value.toLowerCase().replace(/,/g, '').replace(/\(/g, '').replace(/\)/g, '').split(' ');
        return this.allEmployees.filter((e) => {
            let allMatch = true;
            for (let i = 0; i < queries.length; i++) {
                if (queries[i].length === 0) {
                    continue;
                }
                if ((e.firstName + e.lastName + e.companyShort).toLowerCase().indexOf(queries[i]) === -1) {
                    allMatch = false;
                    break;
                }
            }
            return allMatch;
        });
    }

    public getParentCostPools(): void {
        this.costPoolService.getParents().subscribe({
            next: (data) => {
                this.parentCostPools = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get parent cost pools');
            },
        });
    }

    public getCostPool(): void {
        this.loading = true;
        this.costPoolService.get(this.poolId).subscribe({
            next: (data) => {
                this.loading = false;
                this.costPool = data;
                this.resetForm();
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get cost pool');
            },
        });
    }

    private getCompanies(): void {
        this.companyService.getAll().subscribe({
            next: (companies) => {
                this.companies = companies;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not load companies');
            },
        });
    }

    public getCostPoolManagers(): void {
        this.costPoolService.getManagers(this.poolId).subscribe({
            next: (data) => {
                this.managers = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get cost pool managers');
            },
        });
    }

    public resetForm(): void {
        this.costPoolForm.reset();

        if (Helpers.isDefined(this.poolId) && this.poolId > 0) {
            this.f.name.setValue(this.costPool.name);
            this.f.code.setValue(this.costPool.code);
            this.f.visible.setValue(this.costPool.visible);
            this.f.project.setValue(this.costPool.project);
            this.f.parentPool.setValue(this.costPool.parentPoolId);
            this.f.showInPunchMenu.setValue(this.costPool.showInPunchMenu);

        } else {
            this.f.name.setValue(null);
            this.f.code.setValue(null);
            this.f.visible.setValue(false);
            this.f.project.setValue(null);
            this.f.parentPool.setValue(null);
            this.f.showInPunchMenu.setValue(false);
        }
    }

    public save(): void {
        if (!this.costPoolForm.valid) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Fel i formuläret',
                    message: 'Alla fält är inte korrekt ifyllda, korrigera felen och försök igen.',
                },
            });
            Helpers.setFormTouched(this.costPoolForm);
        } else {
            this.loading = true;
            this.costPool = {
                poolId: this.poolId > 0 ? this.poolId : 0,
                code: this.f.code.value,
                name: this.f.name.value,
                visible: this.f.visible.value,
                project: this.f.project.value,
                parentPoolId: this.f.parentPool.value,
                companyId: this.f.company.value,
                showInPunchMenu: this.f.showInPunchMenu.value
            };

            if (this.poolId > 0) {
                this.costPoolService.update(this.costPool).subscribe({
                    next: (data) => {
                        this.getCostPool();
                    },
                    error: (error) => {
                        console.error(error);
                        Helpers.captureSentryError('Could not update cost pool');
                        this.loading = false;
                        this.showError(error);
                    },
                });
            } else {
                this.costPoolService.create(this.costPool).subscribe({
                    next: (data) => {
                        this.router.navigate(['/admin/cost-pools/' + data.poolId]);
                    },
                    error: (error) => {
                        console.error(error);
                        Helpers.captureSentryError('Could not create cost pool');
                        this.loading = false;
                        this.showError(error);
                    },
                });
            }
        }
    }

    public showError(error: any): void {
        let title: string = 'Fel';
        let message: string = 'Något gick fel när kostnadsstället skulle sparas';
        let errors: string[];

        if (error.status === 400 && error.error.errors) {
            title = 'Felaktiga uppgifter';
            message = 'Följande är fel och måste korrigeras:';
            errors = error.error.errors;
        }

        this.dialog.open(AlertDialog, {
            width: '400px',
            data: {
                title: title,
                message: message,
                list: errors,
            },
        });
    }

    protected displaySelectedEmployee(employee?: IEmployeeForSelect): string | undefined {
        return employee ? employee.lastName + ', ' + employee.firstName + ' (' + employee.companyShort + ')' : undefined;
    }

    protected employeeSelected(event: any): void {
        const manager: ICostPoolManager = {
            employee: event.option.value,
            poolId: this.poolId,
            employeeId: event.option.value.employeeId,
            typeId: 1,
        };

        this.costPoolService.addManager(manager).subscribe({
            next: () => {
                this.managers.push(manager);
                this.newManager.reset();
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not add cost pool manager');
            },
        });
    }

    protected deleteManager(manager: ICostPoolManager) {
        this.dialog
            .open(ConfirmDialog, {
                width: '500px',
                data: {
                    title: 'Ta bort',
                    message: 'Är du säker på att du vill ta bort raden?',
                },
            })
            .afterClosed()
            .subscribe((result: boolean) => {
                if (!result) {
                    return;
                }

                if (!manager.id) {
                    this.managers.splice(this.managers.indexOf(manager), 1);
                    return;
                }

                this.costPoolService.deleteManager(manager.poolId, manager.id).subscribe({
                    next: () => {
                        this.managers.splice(this.managers.indexOf(manager), 1);
                    },
                    error: (error) => {
                        console.error(error);
                        Helpers.captureSentryError('Could not delete cost pool managers');
                    },
                });
            });
    }
}
