import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDenyOvertimeDialogResult } from '@app/interfaces/deny-overtime-dialog-result.interface';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
    selector: 'attest-deny-overtime-dialog',
    templateUrl: './attest-deny-overtime.dialog.html',
    styleUrls: ['./attest-deny-overtime.dialog.scss'],
})
export class AttestDenyOvertimeDialog {
    title: string;
    message: string;
    reasonFormControl = new UntypedFormControl('', [Validators.required]);

    constructor(private dialogRef: MatDialogRef<AttestDenyOvertimeDialog>, @Inject(MAT_DIALOG_DATA) { title, message }: any) {
        this.title = title;
        this.message = message;
    }

    close(deny: boolean): void {
        if (deny && !this.reasonFormControl.valid) {
            this.reasonFormControl.updateValueAndValidity();
            return;
        }

        let result: IDenyOvertimeDialogResult = {
            deny: deny,
            message: deny ? this.reasonFormControl.value : '',
        };

        this.dialogRef.close(result);
    }
}
