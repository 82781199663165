<h2 mat-dialog-title>{{title}}</h2>
<mat-dialog-content>
    <p [innerHtml]="message"></p>
    <mat-form-field>
        <mat-label>Beskrivning</mat-label>
        <textarea matInput [formControl]="reasonFormControl"></textarea>
        <mat-error *ngIf="reasonFormControl.hasError('required')">Du måste ange en anledning</mat-error>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close(false)">Avbryt</button>
    <button mat-raised-button (click)="close(true)" color="warn">Neka övertid</button>
</mat-dialog-actions>
