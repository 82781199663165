import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Helpers } from '@app/helpers/helpers.class';
import { IAdminSearchFilter } from '@app/interfaces/admin-search-filter.interface';
import { IAdministratorActive } from '@app/interfaces/administrator-active.interface';
import { IAdministrator } from '@app/interfaces/administrator.interface';
import { AdministratorService } from '@app/services/administrator.service';
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'app-admin-accounts',
    templateUrl: './admin-accounts.partial.html',
    styleUrls: ['./admin-accounts.partial.scss'],
})
export class AdminAccountsPartial implements OnInit {
    @Output() filterChanged: EventEmitter<any> = new EventEmitter();
    @Input('query') inputQuery: string;

    loading: boolean = false;
    filterQuery: string;
    admins: IAdministrator[];
    columns: string[] = ['userName', 'name', 'active'];
    canUpdateActive: boolean = false;

    constructor(private adminService: AdministratorService, private authService: AuthService) {
        this.canUpdateActive = this.authService.hasClaim('UpdateAdministrators');
    }

    public ngOnInit(): void {
        this.getAdminsPage(1);
    }

    getAdminsPage(page: number): void {
        let searchFilter: IAdminSearchFilter = {
            query: this.filterQuery,
        };

        this.loading = true;

        this.adminService.search(searchFilter).subscribe({
            next: (data) => {
                this.admins = data;
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not get admins page');
            },
        });

        let filterSettings = {
            query: this.filterQuery,
            page: page,
        };

        this.filterChanged.emit(filterSettings);
    }

    public setAdminActive(adminId: number, event: MatSlideToggleChange): void {
        let payload: IAdministratorActive = {
            adminId: adminId,
            active: event.checked,
        };

        this.adminService.setAdminActive(payload).subscribe({
            next: (data) => {},
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could toggle admin active');
            },
        });
    }
}
