import { Component, OnInit } from '@angular/core';

// Services
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'app-new-car-driving-log-page',
    templateUrl: './new-car.component.html',
    styleUrls: ['./new-car.component.scss']
})

export class NewCarDrivingLogComponent implements OnInit {
    public employeeId: number;

    constructor(private authService: AuthService) { }

    public ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
    }
}
