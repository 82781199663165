import { Pipe, PipeTransform } from '@angular/core';
import { Helpers } from '@app/helpers/helpers.class';

@Pipe({ name: 'hasUnfixedPunchWarnings' })
export class HasUnfixedPunchWarningsPipe implements PipeTransform {
    transform(punchDays: any[], day: any = null): boolean {
        if (typeof punchDays === 'undefined' || punchDays === null || punchDays.length === 0) {
            return false;
        }

        for (let i = 0; i < punchDays.length; i++) {
            for (let x = 0; x < punchDays[i].warnings.length; x++) {
                if (day === null || Helpers.toShortDateString(punchDays[i].day) === Helpers.toShortDateString(day)) {
                    if (punchDays[i].warnings[x].severity === 1 || !punchDays[i].warnings[x].ignored) {
                        return true;
                    }
                }
            }
        }

        return false;
    }
}
