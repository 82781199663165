import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Helpers } from "@app/helpers/helpers.class";
import { AuthService } from "@app/services/auth.service";

@Component({
    selector: "app-admin-accounts-admin-page",
    templateUrl: "./admin-accounts.component.html",
    styleUrls: ["./admin-accounts.component.scss"],
})
export class AdminAccountsAdminComponent implements OnInit {
    employeeId: number;
    query: string;
    page: number;

    constructor(private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private router: Router) {}

    ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);

        if (Helpers.isDefined(params['query'])) {
            this.query = params['query'];
        }

        if (Helpers.isDefined(params['page'])) {
            this.page = params['page'];
        }
    }

    updateQueryParameters(filter: any): void {
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);
        params['query'] = Helpers.isDefined(filter.query) && filter.query.length > 0 ?
            filter.query : null;
        params['page'] = filter.page;
        this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params });
    }
}
