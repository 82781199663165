<h2 mat-dialog-title>Redigera svar</h2>
<mat-dialog-content>
    <mat-form-field appearance="outline">
        <mat-label>Svar</mat-label>
        <textarea matInput [(ngModel)]="message.replyMessage"></textarea>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">OK</button>
</mat-dialog-actions>
