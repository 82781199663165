import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from "@angular/router";

// Services
import { AuthService } from '@app/services/auth.service';
import { Helpers } from '@app/helpers/helpers.class';

@Component({
    selector: 'app-punches-cost-pool-admin-page',
    templateUrl: './punches-cost-pool.component.html',
    styleUrls: ['./punches-cost-pool.component.scss']
})

export class PunchesCostPoolAdminComponent implements OnInit {
    employeeId: number;
    mode: number;
    status: number;
    day: string;
    query: string;
    page: number;

    constructor(private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private router: Router) { }

    ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);

        if(Helpers.isDefined(params['mode'])) {
            this.mode = parseInt(params['mode']);
        }

        if(Helpers.isDefined(params['status'])) {
            this.status = parseInt(params['status']);
        }

        if(Helpers.isDefined(params['day'])) {
            this.day = params['day'];
        }

        if(Helpers.isDefined(params['query'])) {
            this.query = params['query'];
        }

        if(Helpers.isDefined(params['page'])) {
            this.page = params['page'];
        }
    }

    updateQueryParameters(filter: any): void {
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);
        params['mode'] = filter.mode;
        params['status'] = filter.status;
        params['day'] = filter.day;
        params['query'] = Helpers.isDefined(filter.query) && filter.query.length > 0 ?
            filter.query : null;
        params['page'] = filter.page;
        this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params });
    }
}
