import { Component, OnInit, ViewChild } from '@angular/core';

// Services
import { AuthService } from '@app/services/auth.service';

// Partials
import { EmployeeAddPunchPartial } from '@app/components/partials/employee-add-punch/employee-add-punch.partial';
import { EmployeeAddAbsencePartial } from '@app/components/partials/employee-add-absence/employee-add-absence.partial';
import { EmployeePunchesPartial } from '@app/components/partials/employee-punches/employee-punches.partial';
import { EmployeeAbsencePartial } from '@app/components/partials/employee-absence/employee-absence.partial';

@Component({
    selector: 'app-start-admin-page',
    templateUrl: './admin-start.component.html',
    styleUrls: ['./admin-start.component.scss']
})

export class StartAdminComponent implements OnInit {
    @ViewChild('employeeAddPunchPartial', { static: true }) employeeAddPunchPartial: EmployeeAddPunchPartial;
    @ViewChild('employeeAddAbsencePartial', { static: true }) employeeAddAbsencePartial: EmployeeAddAbsencePartial;
    @ViewChild('employeePunchesPartial', { static: true }) employeePunchesPartial: EmployeePunchesPartial;
    @ViewChild('employeeAbsencePartial', { static: true }) employeeAbsencePartial: EmployeeAbsencePartial;

    public employeeId: number;
    public hasActiveWorkPeriodsClaim: boolean = false;
    public hasCreatePunchClaim: boolean = false;
    public hasCreateAbsenceClaim: boolean = false;
    public hasListPunchesClaim: boolean = false;
    public hasListAbsenceClaim: boolean = false;

    constructor(private authService: AuthService) { }

    ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
        this.hasActiveWorkPeriodsClaim = this.authService.hasClaim('ListActiveWorkPeriods');
        this.hasCreatePunchClaim = this.authService.hasClaim('CreateEmployeePunch');
        this.hasCreateAbsenceClaim = this.authService.hasClaim('CreateEmployeeAbsence');
        this.hasListPunchesClaim = this.authService.hasClaim('ListEmployeePunches');
        this.hasListAbsenceClaim = this.authService.hasClaim('ListEmployeeAbsence');
    }

    public employeeChanged(event: any): void {
        if (event.source !== 'EmployeeAddPunchPartial') {
            this.employeeAddPunchPartial.selectEmployee(event.employeeId);
        }

        if (event.source !== 'EmployeeAddAbsencePartial') {
            this.employeeAddAbsencePartial.selectEmployee(event.employeeId);
        }

        if (event.source !== 'EmployeePunchesPartial') {
            this.employeePunchesPartial.selectEmployee(event.employeeId);
        }

        if (event.source !== 'EmployeeAbsencePartial') {
            this.employeeAbsencePartial.selectEmployee(event.employeeId);
        }
    }
}
