import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'employeeOvertimeClass' })
export class EmployeeOvertimeClassPipe implements PipeTransform {
    transform(punchDays: any[]): string {
        if (typeof punchDays === 'undefined' || punchDays === null || punchDays.length === 0) {
            return '';
        }

        let hasApprovedOvertime = false;
        let hasNotApprovedOvertime = false;
        let hasNotAttestesOvertime = false;

        for (let i = 0; i < punchDays.length; i++) {
            if (punchDays[i].totalOvertimeMinutes > 0) {
                if (punchDays[i].overtimeApproved !== null && !punchDays[i].overtimeApproved) {
                    hasNotApprovedOvertime = true;
                    break;
                } else if (punchDays[i].overtimeApproved) {
                    hasApprovedOvertime = true;
                } else {
                    hasNotAttestesOvertime = true;
                }
            }
        }

        if (hasNotApprovedOvertime) {
            return 'has-not-approved';
        } else if (hasNotAttestesOvertime) {
            return 'has-not-attested';
        } else if (hasApprovedOvertime) {
            return 'all-approved';
        }

        return '';
    }
}
