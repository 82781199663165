import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment'
import { Observable } from 'rxjs';

// Interfaces
import { ISetting } from '@app/interfaces/settings.interface';

@Injectable({ providedIn: 'root' })
export class SettingService {

    constructor(private http: HttpClient) { }

    public getAll(): Observable<ISetting[]> {
        return this.http.get<ISetting[]>(environment.apiUrl + '/settings');
    }

    public updateAll(settings: ISetting[]): Observable<any> {
        return this.http.patch<any>(environment.apiUrl + '/settings', settings);
    }
}
