import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-admin-edit-admin-page',
    templateUrl: './admin-edit.component.html',
    styleUrls: ['./admin-edit.component.scss'],
})
export class AdminEditAdminComponent implements OnInit {
    public employeeId: number;
    public adminId: number;

    constructor(
        private authService: AuthService,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
        this.activatedRoute.params.subscribe((params) => {
            this.adminId = +params['id'];
        });
    }
}
