import { Pipe, PipeTransform } from '@angular/core';
import { Helpers } from '@app/helpers/helpers.class';
import { IEmployeeMessageEditPunch } from '@app/interfaces/employee-message-edit-punch.interface';

@Pipe({ name: 'editPunchMessageReplies' })
export class EditPunchMessageRepliesPipe implements PipeTransform {
    transform(messages: IEmployeeMessageEditPunch[]): number {
        if (!Helpers.isDefined(messages) || messages.length === 0) {
            return 0;
        }

        let results = 0;

        for (let message of messages) {
            if (message.sendReply) {
                results++;
            }
        }

        return results;
    }
}
