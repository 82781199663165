import { Component, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Helpers } from '@app/helpers/helpers.class';

@Component({
    selector: 'app-attest-time-split-dialog',
    templateUrl: './attest-time-split-dialog.component.html',
    styleUrls: ['./attest-time-split-dialog.component.scss'],
})
export class AttestTimeSplitDialogComponent {
    splitAtFormControl = new UntypedFormControl('', [Validators.required]);
    punchIn: Date;
    punchOut: Date;

    constructor(
        private dialogRef: MatDialogRef<AttestTimeSplitDialogComponent>,
        @Inject(MAT_DIALOG_DATA) { punchIn, punchOut }: any
    ) {
        this.punchIn = new Date(punchIn);
        this.punchOut = new Date(punchOut);
    }

    public close(cancelled: boolean): void {
        if (!cancelled) {
            if (!this.splitAtFormControl.valid) {
                this.splitAtFormControl.updateValueAndValidity();
                return;
            }

            const splitAtParts = this.splitAtFormControl.value.split(':');
            const splitAt = new Date(this.punchIn);
            splitAt.setHours(splitAtParts[0], splitAtParts[1], 0);

            console.log(splitAt);
            console.log(this.punchIn);
            console.log(this.punchOut);

            if (splitAt < this.punchIn || splitAt > this.punchOut) {
                this.splitAtFormControl.setErrors({
                    outOfBounds: true,
                });
                return;
            }

            this.dialogRef.close(Helpers.toDateTimeString(splitAt));
        } else {
            this.dialogRef.close(null);
        }
    }
}

