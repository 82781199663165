<header>
    <mat-toolbar>
        <button mat-button routerLink="/admin/start">
            <mat-icon class="spaced">access_time</mat-icon>
            <span>Stämpelklockan</span>
        </button>

        <mat-divider [vertical]="true"></mat-divider>

        <ng-container *ngFor="let item of menuItems; let i = index">
            <mat-divider *ngIf="i > 0" [vertical]="true"></mat-divider>
            <app-menu-item [menuItem]="item"></app-menu-item>
        </ng-container>

        <!--
        <button mat-button [matMenuTriggerFor]="employeesMenu">
            Personal
            <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <mat-menu #employeesMenu="matMenu">
            <button mat-menu-item routerLink="/admin/employees">
                <mat-icon>group</mat-icon>
                Personal
            </button>
            <button mat-menu-item routerLink="/admin/punches">
                <mat-icon>schedule</mat-icon>
                Stämplingar
            </button>
            <button mat-menu-item routerLink="/admin/edit-punches">
                <mat-icon>how_to_reg</mat-icon>
                Justera stämplingar
            </button>
            <button mat-menu-item routerLink="/admin/employees/attest-overtime">
                <mat-icon>alarm_on</mat-icon>
                Attestera övertid
                <span class="badge" *ngIf="attestOvertimeTodoCount > 0">
                    {{attestOvertimeTodoCount}}
                </span>
            </button>
        </mat-menu>

        <mat-divider [vertical]="true"></mat-divider>

        <button mat-button [matMenuTriggerFor]="carTripsMenu">
            Resor
            <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <mat-menu #carTripsMenu="matMenu">
            <button mat-menu-item routerLink="/admin/travel-compensations">
                <mat-icon>directions_car</mat-icon>
                Reseersättning
            </button>
            <button mat-menu-item routerLink="/admin/driving-log">
                <mat-icon>list_alt</mat-icon>
                Körjournal
            </button>
        </mat-menu>

        <mat-divider [vertical]="true"></mat-divider>

        <button mat-button [matMenuTriggerFor]="costPoolsMenu">
            Kostnadsställen
            <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <mat-menu #costPoolsMenu="matMenu">
            <button mat-menu-item routerLink="/admin/cost-pools">
                <mat-icon>attach_money</mat-icon>
                Kostnadsställen
            </button>
            <button mat-menu-item routerLink="/admin/cost-pools/new">
                <mat-icon>add</mat-icon>
                Nytt kostnadsställe
            </button>
            <button mat-menu-item routerLink="/admin/cost-pools/assign-employees">
                <mat-icon>group_add</mat-icon>
                Tilldela teamleaders
                <span class="badge" *ngIf="costPoolHandledByTodoCount > 0">
                    {{costPoolHandledByTodoCount}}
                </span>
            </button>
            <button mat-menu-item routerLink="/admin/cost-pools/categorize-punches">
                <mat-icon>playlist_add_check</mat-icon>
                Koppla tid
                <span class="badge" *ngIf="punchCostPoolTodoCount > 0">
                    {{punchCostPoolTodoCount}}
                </span>
            </button>
        </mat-menu>

        <mat-divider [vertical]="true"></mat-divider>

        <button mat-button [matMenuTriggerFor]="schedulesMenu">
            Scheman
            <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <mat-menu #schedulesMenu="matMenu">
            <button mat-menu-item routerLink="/admin/schedules">
                <mat-icon>calendar_today</mat-icon>
                Scheman
            </button>
            <button mat-menu-item routerLink="/admin/schedules/new">
                <mat-icon>add</mat-icon>
                Nytt schema
            </button>
            <button mat-menu-item routerLink="/admin/schedules/connect">
                <mat-icon>person_add</mat-icon>
                Koppla schema
            </button>
        </mat-menu>

        <mat-divider [vertical]="true"></mat-divider>

        <button mat-button [matMenuTriggerFor]="reportsMenu">
            Rapporter
            <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <mat-menu #reportsMenu="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="reportsMenuCostPools">
                <mat-icon>attach_money</mat-icon>
                Kostnadsställen
            </button>
        </mat-menu>
        <mat-menu #reportsMenuCostPools="matMenu">
            <button mat-menu-item routerLink="/admin/reports/cost-pools/time-per-cost-pool">Tid per kostnadsställe</button>
        </mat-menu>

        <mat-divider [vertical]="true"></mat-divider>

        <button mat-button routerLink="/admin/settings">
            Inställningar
        </button>-->

        <div class="spacer"></div>

        <!--<div class="search">
            <mat-form-field>
                <mat-label>Sök anställd...</mat-label>
                <input matInput autocomplete="off" />
            </mat-form-field>
        </div>-->

        <button mat-button *ngIf="authenticated" (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>
            Logga ut
        </button>
    </mat-toolbar>
</header>
