import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-attest-time-problem-dialog',
    templateUrl: './attest-time-problem-dialog.component.html',
    styleUrls: ['./attest-time-problem-dialog.component.scss'],
})
export class AttestTimeProblemDialogComponent {
    reasonFormControl = new UntypedFormControl('', [Validators.required]);

    constructor(private dialogRef: MatDialogRef<AttestTimeProblemDialogComponent>) {}

    close(cancelled: boolean): void {
        if (!cancelled && !this.reasonFormControl.valid) {
            this.reasonFormControl.updateValueAndValidity();
            return;
        }

        this.dialogRef.close(cancelled ? null : this.reasonFormControl.value);
    }
}

