<app-admin-menu></app-admin-menu>

<h1 class="mat-headline-3">Attesterad tid - ärenden</h1>

<div class="container">
    <div class="row">
        <div class="col">
            <mat-card class="compact issues">
                <mat-card-content>
                    <app-attest-time-issues-list
                        [query]="query"
                        [page]="page"
                        [solved]="solved"
                        [companyIds]="companyIds"
                        [employeeId]="issueEmployeeId"
                        [issueDate]="issueDate"
                        (filterChanged)="updateQueryParameters($event)"></app-attest-time-issues-list>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<app-messages #messagesPartial [employeeId]="employeeId"></app-messages>

<app-admin-footer></app-admin-footer>

