<div class="container-fluid">
    <div class="row">
        <div class="col">
            <div class="table-wrapper" *ngIf="cars && cars.length > 0; else noResults;">
                <table mat-table [dataSource]="cars">
                    <ng-container matColumnDef="registrationNumber">
                        <th mat-header-cell *matHeaderCellDef class="registration-number">Reg. nr</th>
                        <td mat-cell *matCellDef="let car" class="registration-number">{{car.registrationNumber}}</td>
                    </ng-container>
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef class="description">Beskrivning</th>
                        <td mat-cell *matCellDef="let car" class="description">{{car.description}}</td>
                    </ng-container>
                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef class="type">Typ</th>
                        <td mat-cell *matCellDef="let car" class="type">{{car.type.typeName}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="carColumns"></tr>
                    <tr mat-row *matRowDef="let car; columns: carColumns;" [routerLink]="'/driving-log/cars/' + car.carId">
                    </tr>
                </table>
            </div>
        </div>
        <ng-template #noResults>
            <div class="col">
                <p class="no-results">Inga bilar att visa</p>
            </div>
        </ng-template>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
