import { Pipe, PipeTransform } from '@angular/core';
import { IPunch } from '@app/interfaces/punch.interface';
import { IEmployeeEditPunches } from '@app/interfaces/employee-edit-puches.interface';
import { Helpers } from '@app/helpers/helpers.class';

@Pipe({ name: 'tightPunch' })
export class TightPunchPipe implements PipeTransform {
    transform(punch: IPunch, employee: IEmployeeEditPunches, currentIndex: number): boolean {
        if (currentIndex === 0 || employee.punches.length === 0) {
            return false;
        }

        let minutes = 5;
        let lastPunch = employee.punches[currentIndex - 1];
        let lastPunchTime = new Date(lastPunch.punchTime);
        let currentPunch = new Date(punch.punchTime);

        if (Math.abs(currentPunch.getTime() - lastPunchTime.getTime()) < (minutes * 60 * 1000)) {
            return true;
        }

        return false;
    }
}
