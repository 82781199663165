<div class="container-fluid">
    <ng-container *ngIf="trip">
        <div class="row">
            <div class="col car">
                <mat-icon>directions_car</mat-icon>
                <span><strong>{{trip.car.registrationNumber}}</strong> - {{trip.car.description}}</span>
            </div>
        </div>
        <div class="row" *ngIf="!trip.endDate">
            <div class="col start-date">
                <mat-icon>access_time</mat-icon>
                <span><strong>{{trip.startDate | readableDateTime}}</strong></span>
            </div>
        </div>
        <div class="row" *ngIf="trip.endDate">
            <div class="col dates">
                <mat-icon>access_time</mat-icon>
                <span>
                    <strong>{{trip.startDate | dateTime}}</strong>
                    <mat-icon>arrow_forward</mat-icon><br />
                    <strong class="end-date">{{trip.endDate | dateTime}}</strong>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col type">
                <ng-container *ngIf="trip.private; else workTrip">
                    <mat-icon>home</mat-icon>
                    <span><strong>Privat resa</strong></span>
                </ng-container>
                <ng-template #workTrip>
                    <mat-icon>business</mat-icon>
                    <span>
                        <strong>Tjänsteresa</strong>
                        <i> för </i>
                        <strong>{{trip.company.companyName}}</strong>
                    </span>
                </ng-template>
            </div>
        </div>
        <div class="row" *ngIf="!trip.endDate">
            <div class="col mileage-start">
                <mat-icon>money</mat-icon>
                <span>
                    <strong>{{trip.carKilometersStart}}</strong> km
                    <i>vid resans start</i>
                </span>
            </div>
        </div>
        <div class="row" *ngIf="trip.endDate">
            <div class="col mileage">
                <mat-icon>money</mat-icon>
                <span>
                    <strong>{{trip.carKilometersStart}}</strong> km
                    <mat-icon>arrow_forward</mat-icon>
                    <strong>{{trip.carKilometersEnd}}</strong> km (<i>{{trip.carKilometersEnd - trip.carKilometersStart}} km</i>)
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col description">
                <label class="block">Resans syfte</label>
                <span>{{trip.description}}</span>
            </div>
            <div class="col edit" *ngIf="!trip.endDate">
                <button mat-raised-button (click)="edit()">Redigera</button>
            </div>
        </div>
        <ng-container *ngIf="!trip.endDate">
            <div class="row divider">
                <div class="col">
                    <mat-divider></mat-divider>
                </div>
            </div>
            <div [formGroup]="endTripForm">
                <div class="row end-date">
                    <div class="col-6">
                        <mat-form-field class="end-date" (click)="endDate.open()">
                            <mat-label>Datum</mat-label>
                            <input matInput [matDatepicker]="endDate" formControlName="endDate" required />
                            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                            <mat-datepicker #endDate disabled="false"></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field>
                            <mat-label>Tidpunkt</mat-label>
                            <input type="time" autocomplete="off" matInput formControlName="endTime" required />
                        </mat-form-field>
                    </div>
                </div>
                <div class="row car-kilometers-end">
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Mätarställning resans slut</mat-label>
                            <input
                                type="tel"
                                autocomplete="off"
                                matInput
                                formControlName="mileage"
                                (keyup)="calculateTripLength()"
                                required />
                            <span matSuffix>km</span>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Resans längd:</label>&nbsp;
                        <span>{{tripLength === 0 ? '-' : tripLength + ' km'}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <button
                            mat-raised-button
                            color="primary"
                            [disabled]="tripLength === 0 || !endTripForm.valid"
                            (click)="end()">
                            Avsluta resa
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
