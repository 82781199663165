import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-employee-footer',
    templateUrl: './employee-footer.component.html',
    styleUrls: ['./employee-footer.component.scss']
})
export class EmployeeFooterComponent implements OnInit {
    constructor() { }

    ngOnInit() {

    }
}
