<h2 mat-dialog-title>Problem</h2>
<mat-dialog-content>
    <p>
        Beskriv vad problemet är i rutan nedan. Du behöver inte inkludera någon information om vilken person det gäller, det görs
        automatiskt.
    </p>
    <mat-form-field>
        <mat-label>Beskrivning</mat-label>
        <textarea matInput [formControl]="reasonFormControl"></textarea>
        <mat-error *ngIf="reasonFormControl.hasError('required')">Du måste ange en anledning</mat-error>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close(true)">Avbryt</button>
    <button mat-raised-button (click)="close(false)" color="primary">Skicka</button>
</mat-dialog-actions>
