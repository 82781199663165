<div class="container-fluid">
    <div class="row filters">
        <div class="col query-filter">
            <label>Sök</label>
            <mat-form-field>
                <input
                    matInput
                    [(ngModel)]="filter.query"
                    autocomplete="off"
                    (keydown.enter)="search(1)"
                    placeholder="Fritextsök..." />
            </mat-form-field>
        </div>
        <div class="col company-filter">
            <label>Bolag</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filter.companyIds" placeholder="(Alla)" multiple>
                    <mat-option *ngFor="let company of companies" [value]="company.companyId"
                        >{{ company.companyName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col solved-filter">
            <label>Åtgärdad</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filter.solved" placeholder="(Alla)">
                    <mat-option [value]="null">(Alla)</mat-option>
                    <mat-option [value]="true">Ja</mat-option>
                    <mat-option [value]="false">Nej</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row filters">
        <div class="col employee-filter">
            <label>Anställd</label>
            <mat-form-field>
                <input
                    type="text"
                    aria-label="Employee"
                    matInput
                    [formControl]="employeeFilterControl"
                    [matAutocomplete]="addEmployeeAutoComplete" />
                <mat-autocomplete
                    autoActiveFirstOption
                    #addEmployeeAutoComplete="matAutocomplete"
                    [displayWith]="displaySelectedEmployee"
                    (optionSelected)="setEmployeeFilter($event)">
                    <mat-option *ngFor="let employee of filteredEmployees | async" [value]="employee">
                        {{ employee.lastName }}, {{ employee.firstName }} ({{ employee.companyShort }})
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="col issue-date-filter">
            <label>Dag</label>
            <mat-form-field (click)="dayPicker.open()">
                <input matInput [matDatepicker]="dayPicker" [(ngModel)]="filter.issueDate" readonly />
                <mat-datepicker-toggle matSuffix [for]="dayPicker"></mat-datepicker-toggle>
                <mat-datepicker #dayPicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col button-filter">
            <button mat-raised-button (click)="search(1)" color="primary">Sök</button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="divider-wrapper">
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col" *ngIf="issuesPage && issuesPage.totalItems; else noResults">
            <mat-paginator
                *ngIf="issuesPage && issuesPage.totalItems > issuesPage.pageSize"
                [length]="issuesPage.totalItems"
                [pageSize]="issuesPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
            <div class="table-wrapper">
                <table mat-table *ngIf="issuesPage" [dataSource]="issuesPage.items">
                    <ng-container matColumnDef="issueId">
                        <th mat-header-cell *matHeaderCellDef class="issue-id">#</th>
                        <td mat-cell *matCellDef="let issue" class="issue-id">{{ issue.issueId }}</td>
                    </ng-container>
                    <ng-container matColumnDef="added">
                        <th mat-header-cell *matHeaderCellDef class="added">Skapad</th>
                        <td mat-cell *matCellDef="let issue" class="added">{{ issue.added | dateTime }}</td>
                    </ng-container>
                    <ng-container matColumnDef="issueDate">
                        <th mat-header-cell *matHeaderCellDef class="issue-date">Dag</th>
                        <td mat-cell *matCellDef="let issue" class="issue-date">{{ issue.issueDate | shortDate }}</td>
                    </ng-container>
                    <ng-container matColumnDef="employeeName">
                        <th mat-header-cell *matHeaderCellDef class="employee-name">Namn</th>
                        <td mat-cell *matCellDef="let issue" class="employee-name">{{ issue.employeeName }}</td>
                    </ng-container>
                    <ng-container matColumnDef="adminName">
                        <th mat-header-cell *matHeaderCellDef class="admin-name">Skapat av</th>
                        <td mat-cell *matCellDef="let issue" class="admin-name">{{ issue.addedByAdminName }}</td>
                    </ng-container>
                    <ng-container matColumnDef="message">
                        <th mat-header-cell *matHeaderCellDef class="message">Meddelande</th>
                        <td mat-cell *matCellDef="let issue" class="message">{{ issue.message }}</td>
                    </ng-container>
                    <ng-container matColumnDef="solved">
                        <th mat-header-cell *matHeaderCellDef class="solved">&nbsp;</th>
                        <td mat-cell *matCellDef="let issue" class="solved">
                            <mat-checkbox
                                [(ngModel)]="issue.isSolved"
                                (change)="toggleSolved(issue)"
                                [matTooltip]="issue.isSolved ? (issue.solved | dateTime) : null"
                                >Åtgärdad</mat-checkbox
                            >
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columns"></tr>
                    <tr mat-row *matRowDef="let client; columns: columns"></tr>
                </table>
            </div>
            <mat-paginator
                *ngIf="issuesPage && issuesPage.totalItems > issuesPage.pageSize"
                [length]="issuesPage.totalItems"
                [pageSize]="issuesPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
        </div>
        <ng-template #noResults>
            <div class="col">
                <p class="no-results">Inga ärenden att visa</p>
            </div>
        </ng-template>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>

