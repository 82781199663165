<div class="container-fluid">
    <div class="row filters">
        <div class="col query-filter">
            <label>Sök</label>
            <mat-form-field>
                <input
                    matInput
                    [(ngModel)]="filter.query"
                    autocomplete="off"
                    (keydown.enter)="search(1)"
                    placeholder="Fritextsök..." />
            </mat-form-field>
        </div>
        <div class="col authorized-filter">
            <label>Åtkomst</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filter.isAuthorized" placeholder="(Alla)">
                    <mat-option [value]="null">(Alla)</mat-option>
                    <mat-option [value]="true">Ja</mat-option>
                    <mat-option [value]="false">Nej</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col handled-filter">
            <label>Behandlade</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filter.isHandled" placeholder="(Alla)">
                    <mat-option [value]="null">(Alla)</mat-option>
                    <mat-option [value]="true">Ja</mat-option>
                    <mat-option [value]="false">Nej</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col button-filter">
            <button mat-raised-button (click)="search(1)" color="primary">Sök</button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="divider-wrapper">
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col" *ngIf="clientsPage && clientsPage.totalItems; else noResults;">
            <mat-paginator
                *ngIf="clientsPage && clientsPage.totalItems > clientsPage.pageSize"
                [length]="clientsPage.totalItems"
                [pageSize]="clientsPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
            <div class="table-wrapper">
                <table mat-table *ngIf="clientsPage" [dataSource]="clientsPage.items">
                    <ng-container matColumnDef="requestId">
                        <th mat-header-cell *matHeaderCellDef class="request-id">#</th>
                        <td mat-cell *matCellDef="let client" class="request-id">{{client.requestId}}</td>
                    </ng-container>
                    <ng-container matColumnDef="referenceNumber">
                        <th mat-header-cell *matHeaderCellDef class="reference-number">Ref.nr.</th>
                        <td mat-cell *matCellDef="let client" class="reference-number">{{client.referenceNumber}}</td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="name">Namn</th>
                        <td mat-cell *matCellDef="let client" class="name">{{client.employeeName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef class="description">Beskrivning</th>
                        <td mat-cell *matCellDef="let client" class="description">{{client.requestDescription}}</td>
                    </ng-container>
                    <ng-container matColumnDef="authorized">
                        <th mat-header-cell *matHeaderCellDef class="authorized">Godkänd</th>
                        <td
                            mat-cell
                            *matCellDef="let client"
                            class="authorized"
                            [class.is-authorized]="client.isAuthorized && client.handledDate !== null"
                            [class.is-not-authorized]="!client.isAuthorized && client.handledDate !== null">
                            <ng-container *ngIf="client.isAuthorized && client.handledDate !== null">Ja</ng-container>
                            <ng-container *ngIf="!client.isAuthorized && client.handledDate !== null">Nej</ng-container>
                            <ng-container *ngIf="client.handledDate === null">-</ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="handledDate">
                        <th mat-header-cell *matHeaderCellDef class="handled-date">Behandlad</th>
                        <td mat-cell *matCellDef="let client" class="handled-date">
                            <ng-container *ngIf="client.handledDate !== null; else notHandled">
                                {{client.handledDate | dateTime}} ({{client.handledByAdminName}})
                            </ng-container>
                            <ng-template #notHandled>-</ng-template>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="company">
                        <th mat-header-cell *matHeaderCellDef class="company">Bolag</th>
                        <td mat-cell *matCellDef="let client" class="company">
                            <mat-form-field class="no-subscript">
                                <mat-select
                                    [(ngModel)]="client.companyId"
                                    (selectionChange)="setClientCompany(client)"
                                    placeholder="(Inget valt)">
                                    <mat-option [value]="null">(Inget valt)</mat-option>
                                    <mat-option *ngFor="let company of companies" [value]="company.companyId">
                                        {{company.companyName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef class="actions">&nbsp;</th>
                        <td mat-cell *matCellDef="let client" class="actions">
                            <mat-icon
                                *ngIf="client.handledDate === null || !client.isAuthorized"
                                matTooltip="Godkänn"
                                class="approve"
                                (click)="authorizeAccessRequest(client, true)">
                                check
                            </mat-icon>
                            <mat-icon
                                *ngIf="client.handledDate === null || client.isAuthorized"
                                matTooltip="Neka"
                                class="deny"
                                (click)="authorizeAccessRequest(client, false)">
                                block
                            </mat-icon>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columns"></tr>
                    <tr mat-row *matRowDef="let client; columns: columns;"></tr>
                </table>
            </div>
            <mat-paginator
                *ngIf="clientsPage && clientsPage.totalItems > clientsPage.pageSize"
                [length]="clientsPage.totalItems"
                [pageSize]="clientsPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
        </div>
        <ng-template #noResults>
            <div class="col">
                <p class="no-results">Inga klienter att visa</p>
            </div>
        </ng-template>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
