import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'workPeriodStatus'})
export class WorkPeriodStatusPipe implements PipeTransform {
    transform(warnings: number): string {
        if(warnings > 0) {
            return 'Det finns ' + warnings + ' problem med perioden som måste lösas';
        } else {
            return 'Inga problem med perioden';
        }
    }
}
