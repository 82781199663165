import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-driving-log-footer',
    templateUrl: './driving-log-footer.component.html',
    styleUrls: ['./driving-log-footer.component.scss']
})
export class DrivingLogFooterComponent implements OnInit {
    constructor() { }

    ngOnInit() {

    }
}
