import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Helpers } from '@app/helpers/helpers.class';
import { MatDialog } from '@angular/material/dialog';
import { groupBy } from 'lodash-es';
import { ConfirmDialog } from '@app/shared/dialogs/confirm/confirm.dialog';
import { EmployeeService } from '@app/services/employee.service';
import { PunchService } from '@app/services/punch.service';
import { IEmployeeForSelect } from '@app/interfaces/employee-for-select.interface';
import { UntypedFormControl } from '@angular/forms';
import { IPunch } from '@app/interfaces/punch.interface';
import { Dictionary } from 'lodash';

@Component({
    selector: 'app-employee-punches',
    templateUrl: './employee-punches.partial.html',
    styleUrls: ['./employee-punches.partial.scss'],
})
export class EmployeePunchesPartial implements OnInit {
    @Input() public employeeId: number;
    @Output() public selectedEmployeeChanged = new EventEmitter<any>();

    public loading = false;
    public selectedEmployeeId: number;
    public employee: UntypedFormControl = new UntypedFormControl();
    public employees: IEmployeeForSelect[];
    public filteredEmployees: Observable<IEmployeeForSelect[]>;
    public externalEmployeeId: boolean;
    public punches: Dictionary<IPunch[]>;
    public from: string;
    public to: string;
    public punchColumns: string[] = ['punchTypeId', 'punchTypeName', 'punchTime', 'costPool', 'remove'];
    public hasSearched: boolean = false;

    constructor(private employeeService: EmployeeService, private punchService: PunchService, private dialog: MatDialog) {}

    public ngOnInit(): void {
        this.externalEmployeeId = Helpers.isDefined(this.employeeId) && this.employeeId > 0;

        if (this.externalEmployeeId) {
            this.selectedEmployeeId = this.employeeId;
        }

        this.filteredEmployees = this.employee.valueChanges.pipe(
            startWith(''),
            map((value) => (value !== null && value.length > 1 ? this.filter(value) : []))
        );

        this.from = Helpers.toShortDateString(new Date());
        this.to = Helpers.toShortDateString(new Date());
        this.getEmployees();
    }

    public selectEmployee(employeeId: number): void {
        if (!Helpers.isDefined(employeeId) || employeeId <= 0) {
            return;
        }

        if (!Helpers.isDefined(this.employees) || this.employees.length === 0) {
            return;
        }

        this.selectedEmployeeId = employeeId;
        this.hasSearched = false;

        for (let employee of this.employees) {
            if (employee.employeeId === this.selectedEmployeeId) {
                this.employee.setValue(employee);
                break;
            }
        }
    }

    public displaySelectedEmployee(employee?: IEmployeeForSelect): string | undefined {
        return employee ? employee.lastName + ', ' + employee.firstName + ' (' + employee.companyShort + ')' : undefined;
    }

    public filter(value: string): IEmployeeForSelect[] {
        const queries = value.toLowerCase().replace(/,/g, '').replace(/\(/g, '').replace(/\)/g, '').split(' ');
        return this.employees.filter((e) => {
            let allMatch = true;
            for (let i = 0; i < queries.length; i++) {
                if (queries[i].length === 0) {
                    continue;
                }
                if ((e.firstName + e.lastName + e.companyShort).toLowerCase().indexOf(queries[i]) === -1) {
                    allMatch = false;
                    break;
                }
            }
            return allMatch;
        });
    }

    public getEmployees(): void {
        this.employeeService.getAllForSelect().subscribe({
            next: (data) => {
                this.employees = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get employees');
            },
        });
    }

    public employeeSelected(event: any): void {
        this.selectedEmployeeId = event.option.value.employeeId;
        this.selectedEmployeeChanged.emit({
            employeeId: this.selectedEmployeeId,
            source: 'EmployeePunchesPartial',
        });
    }

    public getPunches(): void {
        if (!Helpers.isDefined(this.selectedEmployeeId) || this.selectedEmployeeId == 0) {
            return;
        }

        this.hasSearched = true;
        let from = Helpers.toShortDateString(this.from);
        let to = Helpers.toShortDateString(this.to);

        this.loading = true;
        this.punchService.getForEmployeeBetweenDates(this.selectedEmployeeId, from, to).subscribe({
            next: (data) => {
                if (data.length > 0) {
                    this.punches = groupBy(data, function (value: IPunch) {
                        return value.punchTime.substring(0, 10);
                    });
                } else {
                    this.punches = null;
                }
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not get punches');
            },
        });
    }

    public deletePunch(punch: IPunch): void {
        let handle = this.dialog.open(ConfirmDialog, {
            width: '400px',
            data: {
                title: 'Ta bort?',
                message: 'Är du säker på att du vill ta bort stämplingen?',
            },
        });

        handle.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.loading = true;
                this.punchService.deletePunch(punch.punchId).subscribe({
                    next: (data) => {
                        this.getPunches();
                    },
                    error: (error) => {
                        console.error(error);
                        this.loading = false;
                        Helpers.captureSentryError('Could not delete punch');
                    },
                });
            }
        });
    }
}
