import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';

@Pipe({ name: 'scheduleDays' })
export class ScheduleDaysPipe implements PipeTransform {
    transform(schedule: UntypedFormGroup): any[] {
        let days: UntypedFormArray = schedule.get('days') as UntypedFormArray;
        return days.controls;
    }
}
