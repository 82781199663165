<div class="container-fluid">
    <div class="row filters">
        <div class="col company-filter">
            <label>Bolag</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filterCompanyIds" placeholder="Välj bolag" multiple>
                    <mat-option *ngFor="let company of companies" [value]="company.companyId">
                        {{company.companyName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col punch-date-filter">
            <label>Stämpeldatum</label>
            <div class="date-range">
                <mat-form-field class="from" (click)="pickerFrom.open()">
                    <input matInput [matDatepicker]="pickerFrom" [(ngModel)]="filterPunchDateFrom" readonly />
                    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFrom></mat-datepicker>
                </mat-form-field>
                <span>-</span>
                <mat-form-field class="to" (click)="pickerTo.open()">
                    <input matInput [matDatepicker]="pickerTo" [(ngModel)]="filterPunchDateTo" readonly />
                    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                    <mat-datepicker #pickerTo></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="col button-filter">
            <button mat-raised-button (click)="getReport()" color="primary">Visa rapport</button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="divider-wrapper">
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col" *ngIf="report && report.items.length > 0; else noResults;">
            <div class="table-wrapper">
                <table mat-table [dataSource]="report.items">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="name">Namn</th>
                        <td mat-cell *matCellDef="let employee" class="name">{{employee.lastName}}, {{employee.firstName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="company">
                        <th mat-header-cell *matHeaderCellDef class="company">Bolag</th>
                        <td mat-cell *matCellDef="let employee" class="company">{{employee.companyName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="punchedInNow">
                        <th mat-header-cell *matHeaderCellDef class="punched-in-now">Instämplad</th>
                        <td mat-cell *matCellDef="let employee" class="punched-in-now">
                            {{employee.punchedInNow ? 'Ja' : 'Nej'}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="latestPunchTime">
                        <th mat-header-cell *matHeaderCellDef class="latest-punch-time">Senaste stämpling</th>
                        <td mat-cell *matCellDef="let employee" class="latest-punch-time">
                            {{employee.latestPunchTime | dateTime}}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let employee; columns: columns;"
                        [routerLink]="'/admin/employees/' + employee.employeeId"></tr>
                </table>
            </div>
        </div>
        <ng-template #noResults>
            <div class="col">
                <p class="no-results">Inga arbetande att visa</p>
            </div>
        </ng-template>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
