import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment'
import { Observable } from 'rxjs';

// Interfaces
import { ITravelCompensation } from '@app/interfaces/travel-compensation.interface';
import { ITravelCompensationSearch } from '@app/interfaces/travel-compensation-search.interface';
import { IPage } from '@app/interfaces/page.interface';

@Injectable({ providedIn: 'root' })
export class TravelCompensationService {

    constructor(private http: HttpClient) { }

    add(travelCompensation: ITravelCompensation): Observable<ITravelCompensation> {
        return this.http.post<ITravelCompensation>(environment.apiUrl + "/travel-compensations", travelCompensation);
    }

    getByEmployeeBetweenDates(from: string, to: string, employeeId: number): Observable<ITravelCompensation[]> {
        return this.http.get<ITravelCompensation[]>(environment.apiUrl + "/travel-compensations/" + employeeId, {
            params: new HttpParams()
                .set('from', from)
                .set('to', to)
        });
    }

    getTravelCompensations(search: ITravelCompensationSearch): Observable<IPage<any>> {
        return this.http.post<IPage<any>>(environment.apiUrl + '/travel-compensations/search', search);
    }

    setPaid(travelCompensation: ITravelCompensation): Observable<any> {
        return this.http.patch<any>(environment.apiUrl + "/travel-compensations/" + travelCompensation.travelId + "/paid-status",
            travelCompensation);
    }
}
