import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Helpers } from '@app/helpers/helpers.class';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AbsenceService } from '@app/services/absence.service';
import { EmployeeService } from '@app/services/employee.service';
import { ConfirmDialog } from '@app/shared/dialogs/confirm/confirm.dialog';
import { IEmployeeAbsence } from '@app/interfaces/employee-absence.interface';
import { IEmployeeForSelect } from '@app/interfaces/employee-for-select.interface';
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'app-employee-absence',
    templateUrl: './employee-absence.partial.html',
    styleUrls: ['./employee-absence.partial.scss'],
})
export class EmployeeAbsencePartial implements OnInit {
    @Input() public employeeId: number;
    @Input() public data: IEmployeeAbsence[];
    @Input() public useExternalData: boolean;
    @Output() public selectedEmployeeChanged = new EventEmitter<any>();

    public loading: boolean = false;
    public absence: IEmployeeAbsence[];
    public absenceColumns: string[] = ['time', 'type', 'comment'];
    public from: string;
    public to: string;
    public selectedEmployeeId: number;
    public employee: UntypedFormControl = new UntypedFormControl();
    public employees: IEmployeeForSelect[];
    public filteredEmployees: Observable<IEmployeeForSelect[]>;
    public externalEmployeeId: boolean;
    public hasSearched: boolean = false;

    constructor(
        private absenceService: AbsenceService,
        private employeeService: EmployeeService,
        private authService: AuthService,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        let now = new Date();
        this.to = Helpers.toShortDateString(now);
        now.setDate(now.getDate() - 30);
        this.from = Helpers.toShortDateString(now);

        this.externalEmployeeId = Helpers.isDefined(this.employeeId) && this.employeeId > 0;

        if (this.externalEmployeeId) {
            this.selectedEmployeeId = this.employeeId;
        }

        if (!this.useExternalData) {
            this.update();
        } else if (this.data) {
            this.absence = this.data;
        } else {
            this.loading = true;
        }

        this.filteredEmployees = this.employee.valueChanges.pipe(
            startWith(''),
            map((value) => (value !== null && value.length > 1 ? this.filter(value) : []))
        );

        if (this.authService.isAdminMode()) {
            this.absenceColumns.push('delete');
        }

        this.getEmployees();
    }

    public selectEmployee(employeeId: number): void {
        if (!Helpers.isDefined(employeeId) || employeeId <= 0) {
            return;
        }

        if (!Helpers.isDefined(this.employees) || this.employees.length === 0) {
            return;
        }

        this.selectedEmployeeId = employeeId;
        this.hasSearched = false;

        for (let employee of this.employees) {
            if (employee.employeeId === this.selectedEmployeeId) {
                this.employee.setValue(employee);
                break;
            }
        }
    }

    public getEmployees(): void {
        if (this.externalEmployeeId) {
            return;
        }

        this.employeeService.getAllForSelect().subscribe(
            (data) => {
                this.employees = data;
            },
            (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get employees');
            }
        );
    }

    public employeeSelected(event: any): void {
        this.selectedEmployeeId = event.option.value.employeeId;
        this.selectedEmployeeChanged.emit({
            employeeId: this.selectedEmployeeId,
            source: 'EmployeeAbsencePartial',
        });
    }

    protected displaySelectedEmployee(employee?: IEmployeeForSelect): string | undefined {
        return employee ? employee.lastName + ', ' + employee.firstName + ' (' + employee.companyShort + ')' : undefined;
    }

    public filter(value: string): IEmployeeForSelect[] {
        const queries = value.toLowerCase().replace(/,/g, '').replace(/\(/g, '').replace(/\)/g, '').split(' ');
        return this.employees.filter((e) => {
            let allMatch = true;
            for (let i = 0; i < queries.length; i++) {
                if (queries[i].length === 0) {
                    continue;
                }
                if ((e.firstName + e.lastName + e.companyShort).toLowerCase().indexOf(queries[i]) === -1) {
                    allMatch = false;
                    break;
                }
            }
            return allMatch;
        });
    }

    public externalDataLoaded(externalData: IEmployeeAbsence[]) {
        this.absence = externalData;
        this.loading = false;
    }

    public update(): void {
        this.getAbsence();
    }

    public getAbsence(): void {
        if (!Helpers.isDefined(this.selectedEmployeeId) || this.selectedEmployeeId <= 0) {
            return;
        }

        this.hasSearched = true;

        this.loading = true;
        this.absenceService
            .getByEmployeeBetweenDates(
                Helpers.toShortDateString(this.from),
                Helpers.toShortDateString(this.to),
                this.selectedEmployeeId
            )
            .subscribe({
                next: (data) => {
                    this.loading = false;
                    this.absence = data;
                },
                error: (error) => {
                    console.error(error);
                    this.loading = false;
                    Helpers.captureSentryError('Could not get absence');
                },
            });
    }

    public deleteAbsence(absence: IEmployeeAbsence): void {
        let handle = this.dialog.open(ConfirmDialog, {
            width: '400px',
            data: {
                title: 'Ta bort?',
                message: 'Är du säker på att du vill ta bort frånvaron?',
            },
        });

        handle.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.loading = true;
                this.absenceService.delete(absence.absenceId).subscribe({
                    next: (data) => {
                        this.getAbsence();
                    },
                    error: (error) => {
                        console.error(error);
                        this.loading = false;
                        Helpers.captureSentryError('Could not delete absence');
                    },
                });
            }
        });
    }
}
