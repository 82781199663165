import { Pipe, PipeTransform } from '@angular/core';
import { IWorkTime } from '@app/interfaces/work-time.interface';
import { Helpers } from '@app/helpers/helpers.class';

@Pipe({ name: 'absenceList' })
export class AbsenceListPipe implements PipeTransform {
    transform(workTime: IWorkTime[]): string {
        if (!Helpers.isDefined(workTime) || workTime.length === 0) {
            return '';
        }

        let result: string[] = [];

        for (let time of workTime) {
            if (time.timeType !== 'ABSENCE') {
                continue;
            }

            result.push(time.description + ': ' + Helpers.toReadableTimeSpan(time.totalMinutes));
        }

        return result.join('\n');
    }
}
