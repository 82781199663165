import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

// Error handling
import * as Sentry from '@sentry/browser';
import { Helpers } from '@app/helpers/helpers.class';

@Injectable({ providedIn: 'root' })
export class VersionCheckService {
    private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
    private handle: any = null;

    constructor(private http: HttpClient) {}

    initVersionCheck(frequency = 1000 * 60 * 10) {
        if (environment.production && this.handle === null) {
            console.info('Starting version check, frequency set to', frequency, 'ms');
            this.handle = setInterval(() => {
                this.checkVersion(environment.versionUrl);
            }, frequency);
        } else {
            console.info('Version check will not be run');
        }
    }

    public dispose() {
        if (typeof this.handle !== 'undefined' && this.handle !== null) {
            clearInterval(this.handle);
            this.handle = null;
            console.info('Version check interval cleared');
        }
    }

    private checkVersion(url) {
        this.http.get(url + '?t=' + new Date().getTime()).subscribe({
            next: (data: any) => {
                const hash = data.hash;
                const hashChanged = this.hasHashChanged(this.currentHash, hash);

                if (hashChanged) {
                    location.reload();
                }

                this.currentHash = hash;
            },
            error: (error) => {
                console.error(error, 'Could not get version');
                Helpers.captureSentryError('Could not reload page after deploy');
            },
        });
    }

    private hasHashChanged(currentHash, newHash) {
        if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
            return false;
        }

        return currentHash !== newHash;
    }
}
