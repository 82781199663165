import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

// Services
import { ScheduleService } from '@app/services/schedule.service';

// Error handling
import * as Sentry from '@sentry/browser';

// Interfaces
import { IScheduleWithDays } from '@app/interfaces/schedule-with-days.interface';
import { Helpers } from '@app/helpers/helpers.class';
import { AlertDialog } from '../alert/alert.dialog';
import { IEmployeeSchedule } from '@app/interfaces/employee-schedule.interface';

@Component({
    selector: 'schedule-connect-dialog',
    templateUrl: './schedule-connect.dialog.html',
    styleUrls: ['./schedule-connect.dialog.scss'],
})
export class ScheduleConnectDialog implements OnInit {
    public loading: boolean = false;
    public employeeIds: number[];
    public schedules: IScheduleWithDays[];
    public selectedScheduleId: number;
    public selectedStartDate: string;

    constructor(
        private scheduleService: ScheduleService,
        public dialog: MatDialog,
        private dialogRef: MatDialogRef<ScheduleConnectDialog>,
        @Inject(MAT_DIALOG_DATA) { employeeIds }: any
    ) {
        this.employeeIds = employeeIds;
    }

    public ngOnInit(): void {
        this.getSchedules();
    }

    public close(status: string): void {
        this.dialogRef.close(status);
    }

    public getSchedules(): void {
        this.loading = true;
        this.scheduleService.getAll().subscribe({
            next: (data) => {
                this.schedules = data;
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not get schedules');
            },
        });
    }

    public connectSchedules(): void {
        if (
            !Helpers.isDefined(this.selectedScheduleId) ||
            this.selectedScheduleId === 0 ||
            !Helpers.isDefined(this.selectedStartDate) ||
            this.selectedStartDate.length === 0 ||
            !Helpers.isDefined(this.employeeIds) ||
            this.employeeIds.length === 0
        ) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Fel i formuläret',
                    message: 'Du måste välja ett schema och ett start-datum',
                },
            });
            return;
        }

        let employeeSchedules: IEmployeeSchedule[] = [];

        for (let employeeId of this.employeeIds) {
            employeeSchedules.push({
                scheduleId: this.selectedScheduleId,
                employeeId: employeeId,
                fromDate: Helpers.toShortDateString(this.selectedStartDate),
            });
        }

        this.loading = true;
        this.scheduleService.connectEmployeeSchedules(employeeSchedules).subscribe({
            next: (data) => {
                this.close('created');
            },
            error: (error) => {
                console.error(error);
                this.loading = false;

                let title = 'Fel uppstod';
                let message = 'Något gick fel när schemat skulle kopplas';
                let errors: string[];

                if (error.status === 400 && error.error.errors) {
                    message = 'Följande gick fel när schemat skulle kopplas. Inga scheman har kopplats.';
                    errors = error.error.errors;
                } else {
                    Helpers.captureSentryError('Could not save employee schedule');
                }

                this.dialog.open(AlertDialog, {
                    width: '400px',
                    data: {
                        title: title,
                        message: message,
                        list: errors,
                    },
                });
            },
        });
    }
}
