<h2 mat-dialog-title>Visa varningar</h2>
<mat-dialog-content>
    <div class="wrapper" *ngIf="warnings">
        <div class="warning" [class.ignored]="warning.ignored" *ngFor="let warning of warnings">
            <mat-icon class="ignored" *ngIf="warning.ignored">check</mat-icon>
            <mat-icon class="message-sent" *ngIf="!warning.ignored && warning.messages.length > 0">mail_outline</mat-icon>
            <mat-icon class="warning-icon" [class.severe]="warning.severity === 1" *ngIf="!warning.ignored && warning.messages.length === 0">warning</mat-icon>
            <div class="title">{{warning.day | shortDate}} - {{warning.header}}</div>
            <div class="message" *ngIf="warning.header !== 'Ej definierat'; else notSpecified">
                {{warning.description}}
            </div>
            <ng-template #notSpecified>
                Ett ospecificerad fel, beror troligtvis på föregående eller efterkommande fel och försvinner när det löses.
            </ng-template>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">Stäng</button>
</mat-dialog-actions>
