import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Helpers } from '@app/helpers/helpers.class';
import { IAdministrator } from '@app/interfaces/administrator.interface';
import { IEmployeeForSelect } from '@app/interfaces/employee-for-select.interface';
import { AdministratorService } from '@app/services/administrator.service';
import { AuthService } from '@app/services/auth.service';
import { EmployeeService } from '@app/services/employee.service';
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';
import { ConfirmDialog } from '@app/shared/dialogs/confirm/confirm.dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-admin-form',
    templateUrl: './admin-form.partial.html',
    styleUrls: ['./admin-form.partial.scss'],
})
export class AdminFormPartial implements OnInit {
    @Input() adminId: number;

    admin: IAdministrator;
    employees: IEmployeeForSelect[];
    filteredEmployees: Observable<IEmployeeForSelect[]>;
    adminForm: UntypedFormGroup;
    loading: boolean;
    dreamMailPattern: RegExp = /^(.)+(@dreamlogistics\.se|@bring\.com)$/;

    constructor(
        private employeeService: EmployeeService,
        private adminService: AdministratorService,
        private authService: AuthService,
        private formBuilder: UntypedFormBuilder,
        private dialog: MatDialog,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.resetForm();

        this.filteredEmployees = this.f.employeeSearch.valueChanges.pipe(
            startWith(''),
            map((value) => (value !== null && value.length > 1 ? this.filter(value) : []))
        );

        this.getEmployees().subscribe(() => {
            if (this.adminId) {
                this.getAdmin();
            }
        });
    }

    public get f() {
        return this.adminForm.controls;
    }

    resetForm(): void {
        this.adminForm = this.formBuilder.group({
            userName: [null, [Validators.required, Validators.email, Validators.pattern(this.dreamMailPattern)]],
            employeeSearch: [null, Validators.required],
            employee: [null, Validators.required],
            active: [false, Validators.required],
        });
    }

    employeeSelected(event: MatAutocompleteSelectedEvent) {
        this.f.employee.setValue(event.option.value as IEmployeeForSelect);
    }

    displaySelectedEmployee(employee?: IEmployeeForSelect): string | undefined {
        return employee ? employee.lastName + ', ' + employee.firstName + ' (' + employee.companyShort + ')' : undefined;
    }

    getAdmin(): void {
        this.loading = true;
        this.adminService.get(this.adminId).subscribe({
            next: (data) => {
                this.admin = data;
                this.loading = false;

                let employee: IEmployeeForSelect;

                for (let item of this.employees) {
                    if (item.employeeId === this.admin.employeeId) {
                        employee = item;
                        break;
                    }
                }

                this.f.userName.setValue(this.admin.userName);
                this.f.employeeSearch.setValue(employee);
                this.f.employee.setValue(employee);
                this.f.active.setValue(this.admin.active);

                this.f.employeeSearch.disable();
                this.f.employee.disable();
                this.f.userName.disable();
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get admin');
                this.loading = false;
            },
        });
    }

    getEmployees(): Observable<boolean> {
        return new Observable((observer) => {
            this.employeeService.getAllForSelect().subscribe({
                next: (data) => {
                    this.employees = data;
                    observer.next(true);
                    observer.complete();
                },
                error: (error) => {
                    console.error(error);
                    Helpers.captureSentryError('Could not get employees');
                    observer.next(false);
                    observer.complete();
                },
            });
        });
    }

    filter(value: string): IEmployeeForSelect[] {
        const queries = value.toLowerCase().replace(/,/g, '').replace(/\(/g, '').replace(/\)/g, '').split(' ');
        return this.employees.filter((e) => {
            let allMatch = true;
            for (let i = 0; i < queries.length; i++) {
                if (queries[i].length === 0) {
                    continue;
                }
                if ((e.firstName + e.lastName + e.companyShort).toLowerCase().indexOf(queries[i]) === -1) {
                    allMatch = false;
                    break;
                }
            }
            return allMatch;
        });
    }

    resetPassword(): void {
        this.dialog
            .open(ConfirmDialog, {
                width: '500px',
                data: {
                    title: 'Återställ lösenord',
                    message:
                        'En länk kommer skickas till kontots ägare där lösenordet kan ändras. ' +
                        'Är du säker på att du vill återställa lösenordet?',
                },
            })
            .afterClosed()
            .subscribe((result: boolean) => {
                if (result) {
                    this.loading = true;
                    this.authService.requestResetAdministratorPassword(this.admin.userName).subscribe({
                        next: () => {
                            this.loading = false;
                            this.dialog.open(AlertDialog, {
                                width: '400px',
                                data: {
                                    title: 'Länk skickad',
                                    message: 'En länk har skickats till kontots ägare',
                                },
                            });
                        },
                        error: (error) => {
                            this.loading = false;
                            console.error(error);
                            Helpers.captureSentryError('Could not request reset password');
                            this.dialog.open(AlertDialog, {
                                width: '400px',
                                data: {
                                    title: 'Kunde inte skickas',
                                    message: 'Något gick fel när länken skulle skickas',
                                },
                            });
                        },
                    });
                }
            });
    }

    save(): void {
        if (!this.adminForm.valid) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Fel i formuläret',
                    message: 'Alla fält är inte korrekt ifyllda, korrigera felen och försök igen.',
                },
            });

            Helpers.setFormTouched(this.adminForm);

            return;
        }

        if (this.f.employee.hasError('required')) {
            this.f.employeeSearch.setErrors({
                required: true,
            });
            return;
        }

        this.loading = true;
        const employee = this.f.employee.value as IEmployeeForSelect;

        this.admin = {
            adminId: this.adminId ?? 0,
            userName: this.f.userName.value,
            firstName: null,
            lastName: null,
            employeeId: employee.employeeId,
            revalidationRequired: false,
            active: true,
        };

        if (this.adminId > 0) {
            this.admin.active = this.f.active.value;

            this.adminService.update(this.admin).subscribe({
                next: (data) => {
                    this.loading = false;
                },
                error: (error) => {
                    console.error(error);
                    Helpers.captureSentryError('Could not update admin');
                    this.loading = false;
                    this.showError(error);
                },
            });
        } else {
            this.adminService.create(this.admin).subscribe({
                next: (data) => {
                    this.router.navigate([`/admin/settings/accounts/${data.adminId}`]);
                },
                error: (error) => {
                    console.error(error);
                    Helpers.captureSentryError('Could not create admin');
                    this.loading = false;
                    this.showError(error);
                },
            });
        }
    }

    public showError(error: any): void {
        let title: string = 'Fel';
        let message: string = 'Något gick fel när administratören skulle sparas';
        let errors: string[];

        if (error.status === 400 && error.error.errors) {
            title = 'Felaktiga uppgifter';
            message = 'Följande är fel och måste korrigeras:';
            errors = error.error.errors;
        }

        this.dialog.open(AlertDialog, {
            width: '400px',
            data: {
                title: title,
                message: message,
                list: errors,
            },
        });
    }
}
