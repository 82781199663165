import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from "@angular/router";
import { Helpers } from '@app/helpers/helpers.class';

// Services
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'app-punches-edit-admin-page',
    templateUrl: './punches-edit.component.html',
    styleUrls: ['./punches-edit.component.scss']
})

export class PunchesEditAdminComponent implements OnInit {
    employeeId: number;
    query: string;
    day: string;
    companyIds: number[];
    employmentTypeIds: number[];
    departmentIds: number[];
    hasMessages: boolean;

    constructor(private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private router: Router) { }

    ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);

        if (Helpers.isDefined(params['query'])) {
            this.query = params['query'];
        }

        if (Helpers.isDefined(params['day'])) {
            this.day = params['day'];
        }

        if (Helpers.isDefined(params['companyIds'])) {
            this.companyIds = params['companyIds'];
        }

        if (Helpers.isDefined(params['employmentTypeIds'])) {
            this.employmentTypeIds = params['employmentTypeIds'];
        }

        if (Helpers.isDefined(params['departmentIds'])) {
            this.departmentIds = params['departmentIds'];
        }

        if (Helpers.isDefined(params['hasMessages'])) {
            this.hasMessages = params['hasMessages'];
        }
    }

    updateQueryParameters(filter: any): void {
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);
        params['query'] = filter.query.length > 0 ? filter.query : null;
        params['day'] = filter.day.length > 0 ? filter.day : null;
        params['companyIds'] = filter.companyIds.length > 0 ? filter.companyIds.join(',') : null;
        params['employmentTypeIds'] = filter.employmentTypeIds.length > 0 ? filter.employmentTypeIds.join(',') : null;
        params['departmentIds'] = filter.departmentIds.length > 0 ? filter.departmentIds.join(',') : null;
        params['hasMessages'] = filter.hasMessages ? 1 : 0;
        this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params });
    }
}
