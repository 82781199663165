import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'lodash-es';
import { ICompanyDepartment } from '@app/interfaces/company-department.interface';

@Pipe({ name: 'companyDepartmentFilter' })
export class CompanyDepartmentFilterPipe implements PipeTransform {
    transform(departments: ICompanyDepartment[], companyId: number): ICompanyDepartment[] {
        return filter(departments, function (department: ICompanyDepartment) {
            return department.companyId == companyId;
        });
    }
}
