<div class="container-fluid">
    <div class="row filters">
        <div class="col query-filter">
            <label>Sök</label>
            <mat-form-field>
                <input
                    matInput
                    [(ngModel)]="filterQuery"
                    autocomplete="off"
                    placeholder="Fritextsök..."
                    (keydown.enter)="getCostPoolsPage(1)" />
            </mat-form-field>
        </div>
        <div class="col active-filter">
            <label>Aktiva</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filterVisible" placeholder="(Alla)">
                    <mat-option [value]="null">(Alla)</mat-option>
                    <mat-option [value]="true">Ja</mat-option>
                    <mat-option [value]="false">Nej</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col button-filter">
            <button mat-raised-button (click)="getCostPoolsPage(1)" color="primary">Sök</button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="divider-wrapper">
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col" *ngIf="costPoolsPage && costPoolsPage.totalItems; else noResults;">
            <mat-paginator
                *ngIf="costPoolsPage && costPoolsPage.totalItems > costPoolsPage.pageSize"
                [length]="costPoolsPage.totalItems"
                [pageSize]="costPoolsPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
            <div class="table-wrapper">
                <table mat-table *ngIf="costPoolsPage" [dataSource]="costPoolsPage.items">
                    <ng-container matColumnDef="code">
                        <th mat-header-cell *matHeaderCellDef class="code">Kod</th>
                        <td mat-cell *matCellDef="let costPool" class="code" [routerLink]="'/admin/cost-pools/' + costPool.poolId">{{costPool.code}}</td>
                    </ng-container>
                    <ng-container matColumnDef="project">
                        <th mat-header-cell *matHeaderCellDef class="project">Projekt</th>
                        <td mat-cell *matCellDef="let costPool" class="project" [routerLink]="'/admin/cost-pools/' + costPool.poolId">{{costPool.project}}</td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="name">Namn</th>
                        <td mat-cell *matCellDef="let costPool" class="name" [routerLink]="'/admin/cost-pools/' + costPool.poolId">
                            <ng-container *ngIf="costPool.parentPoolId > 0">
                                <mat-icon>subdirectory_arrow_right</mat-icon>
                            </ng-container>
                            {{costPool.name}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="company">
                        <th mat-header-cell *matHeaderCellDef class="company">Bolag</th>
                        <td mat-cell *matCellDef="let costPool" class="company">
                            <mat-form-field class="no-subscript">
                                <mat-select
                                    [(ngModel)]="costPool.companyId"
                                    (selectionChange)="setPoolCompany(costPool)"
                                    placeholder="(Inget valt)">
                                    <mat-option [value]="null">(Inget valt)</mat-option>
                                    <mat-option *ngFor="let company of companies" [value]="company.companyId">
                                        {{company.companyName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="visible">
                        <th mat-header-cell *matHeaderCellDef class="visible">Aktiv</th>
                        <td mat-cell *matCellDef="let costPool" class="visible" click-stop-propagation>
                            <mat-checkbox [checked]="costPool.visible" (change)="setVisible($event, costPool)"></mat-checkbox>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="costPoolsColumns"></tr>
                    <tr mat-row *matRowDef="let costPool; columns: costPoolsColumns;"></tr>
                </table>
            </div>
            <mat-paginator
                *ngIf="costPoolsPage && costPoolsPage.totalItems > costPoolsPage.pageSize"
                [length]="costPoolsPage.totalItems"
                [pageSize]="costPoolsPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
        </div>
        <ng-template #noResults>
            <div class="col">
                <p class="no-results">Inga kostnadsställen att visa</p>
            </div>
        </ng-template>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
