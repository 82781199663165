import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Helpers } from '@app/helpers/helpers.class';
import { IAttestedTimeIssuesSearchFilter } from '@app/interfaces/attested-time-issues-search-filter.interface';
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'app-attest-time-issues-list-admin-page',
    templateUrl: './attest-time-issues-list.component.html',
    styleUrls: ['./attest-time-issues-list.component.scss'],
})
export class AttestTimeIssuesListAdminComponent implements OnInit {
    public employeeId: number;
    public query: string;
    public page: number;
    public solved: boolean | null;
    public companyIds: number[];
    public issueEmployeeId: number | null;
    public issueDate: Date | null;

    constructor(private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router) {}

    public ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);

        if (Helpers.isDefined(params['query'])) {
            this.query = params['query'];
        }

        if (Helpers.isDefined(params['page'])) {
            this.page = params['page'];
        }

        if (Helpers.isDefined(params['employeeId'])) {
            this.issueEmployeeId = params['employeeId'];
        }

        if (Helpers.isDefined(params['issueDate'])) {
            this.issueDate = params['issueDate'];
        }

        if (Helpers.isDefined(params['solved'])) {
            this.solved = params['solved'] === '1';
        }

        if (Helpers.isDefined(params['companyIds'])) {
            this.companyIds = params['companyIds'].split(',').map(function (item) {
                return parseInt(item, 10);
            });
        }
    }

    public updateQueryParameters(filter: IAttestedTimeIssuesSearchFilter): void {
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);
        params['solved'] = Helpers.isDefined(filter.solved) ? (filter.solved ? 1 : 0) : null;
        params['query'] = Helpers.isDefined(filter.query) && filter.query.length > 0 ? filter.query : null;
        params['page'] = filter.pageNumber;
        params['employeeId'] = filter.employeeId;
        params['issueDate'] = filter.issueDate;
        params['companyIds'] = filter.companyIds && filter.companyIds.length > 0 ? filter.companyIds.join(',') : null;
        this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params });
    }
}

