import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

// Services
import { AuthService } from '@app/services/auth.service';

// Enums
import { LoginTypeEnum } from '@app/enums/login-type.enum';

// Dialogs
import { HelpDialog } from '@app/shared/dialogs/help/help.dialog';

@Component({
    selector: 'app-employee-menu',
    templateUrl: './employee-menu.component.html',
    styleUrls: ['./employee-menu.component.scss']
})
export class EmployeeMenuComponent implements OnInit {
    authenticated: boolean;

    constructor(private router: Router,
        private authService: AuthService,
        public dialog: MatDialog) { }

    ngOnInit(): void {
        this.authenticated = this.authService.isAuthenticated(LoginTypeEnum.Employee);
    }

    showHelp(): void {
        const handle = this.dialog.open(HelpDialog,
            {
                width: '1000px'
            }
        );
    }

    logout(): void {
        this.authService.logoutEmployee();
        this.router.navigate(['/login']);
    }
}
