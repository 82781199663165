<div class="container-fluid">
    <div class="row">
        <div class="col employee-filter">
            <label>Hanteras av</label>
            <mat-radio-group [(ngModel)]="filterEmployeeId" (change)="getEmployeesPage(1)">
                <mat-radio-button [value]="employeeId">Mig</mat-radio-button>
                <mat-radio-button [value]="0">Visa alla</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="col status-filter">
            <label>Status</label>
            <mat-radio-group [(ngModel)]="filterStatus" (change)="getEmployeesPage(1)">
                <mat-radio-button [value]="1">Att göra</mat-radio-button>
                <mat-radio-button [value]="2">Färdiga</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="col day-filter" [hidden]="filterStatus !== 2">
            <label>Dag</label>
            <mat-form-field>
                <input matInput [matDatepicker]="picker" (dateChange)="getEmployeesPage(1)" [(ngModel)]="filterDay" disabled />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker [disabled]="filterStatus != 2"></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col query-filter">
            <label>Sök</label>
            <mat-form-field>
                <mat-label>Fritextsök...</mat-label>
                <input matInput [(ngModel)]="filterQuery" autocomplete="off" (keydown.enter)="getEmployeesPage(1)" />
            </mat-form-field>
        </div>
        <div class="col button-filter">
            <button mat-raised-button (click)="getEmployeesPage(1)" color="primary">Sök</button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="divider-wrapper">
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col" *ngIf="employeesPage && employeesPage.totalItems; else noResults;">
            <mat-paginator
                *ngIf="employeesPage && employeesPage.totalItems > employeesPage.pageSize"
                [length]="employeesPage.totalItems"
                [pageSize]="employeesPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
            <div class="table-wrapper">
                <table mat-table *ngIf="employeesPage" [dataSource]="dataSource" multiTemplateDataRows>
                    <ng-container matColumnDef="checkbox">
                        <th mat-header-cell *matHeaderCellDef class="checkbox">
                            <mat-checkbox
                                (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                            <button mat-icon-button [matMenuTriggerFor]="costPoolsMenu">
                                <mat-icon>keyboard_arrow_down</mat-icon>
                            </button>
                            <mat-menu #costPoolsMenu="matMenu">
                                <label class="title">Nedan valda är</label>
                                <button mat-menu-item (click)="overtimeAction(true)">
                                    <mat-icon>done</mat-icon>
                                    <span> Godkända </span>
                                </button>
                                <button mat-menu-item (click)="overtimeAction(false)">
                                    <mat-icon>block</mat-icon>
                                    <span> Ej godkända </span>
                                </button>
                            </mat-menu>
                        </th>
                        <td mat-cell *matCellDef="let employee" class="checkbox">
                            <mat-checkbox
                                click-stop-propagation
                                (change)="$event ? masterToggleEmployee(employee) : null"
                                [checked]="isAnySelectedEmployee(employee) && isAllSelectedEmployee(employee)"
                                [indeterminate]="isAnySelectedEmployee(employee) && !isAllSelectedEmployee(employee)">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="name">Namn</th>
                        <td mat-cell *matCellDef="let employee" class="name">{{employee.lastName}}, {{employee.firstName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="company">
                        <th mat-header-cell *matHeaderCellDef class="company">Bolag</th>
                        <td mat-cell *matCellDef="let employee" class="company">{{employee.companyName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="department">
                        <th mat-header-cell *matHeaderCellDef class="department">Avdelning</th>
                        <td mat-cell *matCellDef="let employee" class="department">
                            {{employee.departmentName | emptyReplacement}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="daysWithOvertime">
                        <th mat-header-cell *matHeaderCellDef class="days-with-overtime">Dagar</th>
                        <td mat-cell *matCellDef="let employee" class="days-with-overtime">{{employee.overtimeDays.length}}</td>
                    </ng-container>
                    <ng-container matColumnDef="expand">
                        <th mat-header-cell *matHeaderCellDef class="expand"></th>
                        <td mat-cell *matCellDef="let employee" class="expand">
                            <mat-icon *ngIf="employee != expandedEmployee">keyboard_arrow_down</mat-icon>
                            <mat-icon *ngIf="employee == expandedEmployee">keyboard_arrow_up</mat-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="overtimeDays">
                        <td mat-cell *matCellDef="let employee" [attr.colspan]="employeeColumns.length">
                            <div
                                class="overtime-days-wrapper"
                                [@toggle]="employee == expandedEmployee ? 'expanded' : 'collapsed'">
                                <table
                                    class="overtime-days"
                                    mat-table
                                    [dataSource]="employee.overtimeDays"
                                    *ngIf="visibleEmployeeIds.indexOf(employee.employeeId) > -1">
                                    <ng-container [matColumnDef]="employee.warningColumns[0]">
                                        <th mat-header-cell *matHeaderCellDef class="overtime-day-checkbox"></th>
                                        <td mat-cell *matCellDef="let overtimeDay" class="overtime-day-checkbox">
                                            <mat-checkbox
                                                click-stop-propagation
                                                (change)="$event ? selection.toggle(overtimeDay) : null"
                                                [checked]="selection.isSelected(overtimeDay)">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="employee.warningColumns[1]">
                                        <th mat-header-cell *matHeaderCellDef class="overtime-day-ordered-by">Beordrat av</th>
                                        <td mat-cell *matCellDef="let overtimeDay" class="overtime-day-ordered-by">
                                            {{overtimeDay.overtimeEmployee ? overtimeDay.overtimeEmployee.lastName + ', ' +
                                            overtimeDay.overtimeEmployee.firstName : ''}}
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="employee.warningColumns[2]">
                                        <th mat-header-cell *matHeaderCellDef class="overtime-day">Dag</th>
                                        <td mat-cell *matCellDef="let overtimeDay" class="overtime-day">
                                            {{overtimeDay.day | shortDate}}
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="employee.warningColumns[3]">
                                        <th mat-header-cell *matHeaderCellDef class="overtime-day-total-time">Totalt</th>
                                        <td mat-cell *matCellDef="let overtimeDay" class="overtime-day-total-time">
                                            {{overtimeDay.totalTime | readableTimeSpan}}
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="employee.warningColumns[4]">
                                        <th mat-header-cell *matHeaderCellDef class="overtime-day-scheduled-time">Schemalagd</th>
                                        <td mat-cell *matCellDef="let overtimeDay" class="overtime-day-scheduled-time">
                                            {{overtimeDay.scheduledTime | readableTimeSpan}}
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="employee.warningColumns[5]">
                                        <th mat-header-cell *matHeaderCellDef class="overtime-day-total-overtime">Övertid</th>
                                        <td mat-cell *matCellDef="let overtimeDay" class="overtime-day-total-overtime">
                                            {{overtimeDay.totalOvertime | readableTimeSpan}}
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="employee.warningColumns[6]">
                                        <th mat-header-cell *matHeaderCellDef class="overtime-day-status"></th>
                                        <td
                                            mat-cell
                                            *matCellDef="let overtimeDay"
                                            class="overtime-day-status"
                                            [ngClass]="{ 'approved': overtimeDay.overtimeApproved }">
                                            <mat-icon
                                                matTooltip="Godkänd övertid"
                                                *ngIf="overtimeDay.overtimeApproved !== null && overtimeDay.overtimeApproved">
                                                check
                                            </mat-icon>
                                            <mat-icon
                                                matTooltip="Ej godkänd övertid"
                                                *ngIf="overtimeDay.overtimeApproved !== null && !overtimeDay.overtimeApproved">
                                                block
                                            </mat-icon>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="employee.warningColumns"></tr>
                                    <tr
                                        mat-row
                                        *matRowDef="let overtimeDay; columns: employee.warningColumns;"
                                        class="overtime-day-row"
                                        (click)="expandedOvertimeDay == overtimeDay ? expandedOvertimeDay = null : expandedOvertimeDay = overtimeDay"></tr>
                                </table>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="employeeColumns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let employee; columns: employeeColumns;"
                        class="employee-row"
                        (click)="toggleEmployeeRow(employee)"></tr>
                    <tr mat-row *matRowDef="let overtimeDay; columns: ['overtimeDays']" class="overtime-days-row"></tr>
                </table>
            </div>
            <mat-paginator
                *ngIf="employeesPage && employeesPage.totalItems > employeesPage.pageSize"
                [length]="employeesPage.totalItems"
                [pageSize]="employeesPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
        </div>
        <ng-template #noResults>
            <div class="col">
                <p class="no-results">Ingen övertid att attestera</p>
            </div>
        </ng-template>
    </div>

    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
