<div class="wrapper" *ngIf="contracts && contracts.length > 0">
    <table mat-table [dataSource]="contracts">
        <ng-container matColumnDef="icon">
            <td mat-cell *matCellDef="let contract" class="icon"><mat-icon>description</mat-icon></td>
        </ng-container>
        <ng-container matColumnDef="date">
            <td mat-cell *matCellDef="let contract" class="date">{{contract.date | shortDate}}</td>
        </ng-container>
        <ng-container matColumnDef="download">
            <td mat-cell *matCellDef="let contract" class="download">
                <button mat-button (click)="downloadContract(contract)">
                    <mat-icon>get_app</mat-icon>
                    Ladda ner
                </button>
            </td>
        </ng-container>
        <tr mat-row *matRowDef="let contract; columns: contractColumns;"></tr>
    </table>
</div>

<div class="no-contracts" *ngIf="(contracts | isEmpty) && !loading">
    Inga avtal att visa
</div>

<div class="loading-wrapper" *ngIf="loading">
    <mat-spinner diameter="40"></mat-spinner>
</div>
