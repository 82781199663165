import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment'

// Interfaces
import { IEmployeeContract } from '@app/interfaces/employee-contract.interface';

@Injectable({ providedIn: 'root' })
export class EmployeeContractService {

    constructor(private http: HttpClient) { }

    getForEmployeeBetweenDates(from: string, to: string, employeeId: number): Observable<IEmployeeContract[]> {
        return this.http.get<IEmployeeContract[]>(environment.apiUrl + "/employee-contracts/" + employeeId, {
            params: new HttpParams()
                .set('from', from)
                .set('to', to)
        });
    }
}
