import { Component, OnInit, Input } from '@angular/core';
import { PunchService } from '@app/services/punch.service';
import { IEmployeePunchSummary } from '@app/interfaces/employee-punch-summary.interface';
import { Helpers } from '@app/helpers/helpers.class';

@Component({
    selector: 'app-employee-punch-summary',
    templateUrl: './employee-punch-summary.partial.html',
    styleUrls: ['./employee-punch-summary.partial.scss'],
})
export class EmployeePunchSummaryPartial implements OnInit {
    @Input() employeeId: number;
    @Input() data: IEmployeePunchSummary[];
    @Input() useExternalData: boolean;

    loading: boolean = false;
    punchSummary: IEmployeePunchSummary[];
    summaryColumns: string[] = ['label', 'humanReadable'];

    constructor(private punchService: PunchService) {}

    ngOnInit(): void {
        if (!this.useExternalData) {
            this.getPunchSummary();
        } else if (this.data) {
            this.punchSummary = this.data;
        } else {
            this.loading = true;
        }
    }

    externalDataLoaded(externalData: IEmployeePunchSummary[]) {
        this.punchSummary = externalData;
        this.loading = false;
    }

    getPunchSummary(): void {
        this.loading = true;
        this.punchService.getSummaryForEmployee(this.employeeId).subscribe({
            next: (data) => {
                this.punchSummary = data;
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not get punch summary');
            },
        });
    }
}
