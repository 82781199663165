import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IPunchWarning } from '@app/interfaces/punch-warning.interface';

@Component({
    selector: 'punch-warning-dialog',
    templateUrl: './punch-warning.dialog.html',
    styleUrls: ['./punch-warning.dialog.scss']
})

export class PunchWarningDialog implements OnInit {
    warnings: IPunchWarning[];

    ngOnInit() {

    }

    constructor(private dialogRef: MatDialogRef<PunchWarningDialog>,
        @Inject(MAT_DIALOG_DATA) {
            warnings
        }: any) {
        this.warnings = warnings;
    }

    close() {
        this.dialogRef.close();
    }
}
