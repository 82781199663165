import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver-es';
import { Helpers } from '@app/helpers/helpers.class';
import { EmployeeService } from '@app/services/employee.service';
import { CompanyService } from '@app/services/company.service';
import { EmploymentTypeService } from '@app/services/employment-type.service';
import { CompanyDepartmentService } from '@app/services/company-department.service';
import { IEmployeeSearch } from '@app/interfaces/employee-search.interface';
import { IPage } from '@app/interfaces/page.interface';
import { ICompany } from '@app/interfaces/company.interface';
import { IEmploymentType } from '@app/interfaces/employment-type.interface';
import { IEmployeeFullExtended } from '@app/interfaces/employee-full-extended.interface';
import { ICompanyDepartment } from '@app/interfaces/company-department.interface';
import {IStaffingCompany} from "@app/interfaces/StaffingCompany.interface";
import {StaffingCompanyService} from "@app/services/StaffingCompany.service";

@Component({
    selector: 'app-employees-list',
    templateUrl: './employees-list.partial.html',
    styleUrls: ['./employees-list.partial.scss'],
})
export class EmployeesListPartial implements OnInit {
    @Output() filterChanged: EventEmitter<any> = new EventEmitter();
    @Input('query') inputQuery: string;
    @Input('companyIds') inputCompanyIds: string;
    @Input('employmentTypeIds') inputEmploymentTypeIds: string;
    @Input('departmentIds') inputDepartmentIds: string;
    @Input('registeredFrom') inputRegisteredFrom: string;
    @Input('registeredTo') inputRegisteredTo: string;
    @Input('active') inputActive: number;
    @Input('page') inputPage: number;

    pageIndex: number;
    loading: boolean = false;
    filterQuery: string;
    filterCompanyIds: number[];
    filterEmploymentTypeIds: number[];
    filterDepartmentIds: number[];
    filterRegisteredFrom: string;
    filterRegisteredTo: string;
    filterActive: boolean;
    filterStaffingCompanyIds: number[];
    employeesPage: IPage<any>;
    employeeColumns: string[] = ['name', 'company', 'department', 'employmentType', 'staffingCompany','identityNumber'];
    companies: ICompany[];
    employmentTypes: IEmploymentType[];
    companyDepartments: ICompanyDepartment[];
    staffingCompanies: IStaffingCompany[];

    constructor(
        private dialog: MatDialog,
        private employeeService: EmployeeService,
        private companyService: CompanyService,
        private employmentTypeService: EmploymentTypeService,
        private companyDepartmentService: CompanyDepartmentService,
        private staffingCompanyService: StaffingCompanyService

    ) {}

    ngOnInit(): void {
        this.filterQuery = '';
        this.filterCompanyIds = [];
        this.filterEmploymentTypeIds = [];
        this.filterDepartmentIds = [];
        this.filterRegisteredFrom = '';
        this.filterRegisteredTo = '';
        this.filterActive = false;
        this.filterStaffingCompanyIds = [];

        if (Helpers.isDefined(this.inputQuery)) {
            this.filterQuery = this.inputQuery;
        }

        if (Helpers.isDefined(this.inputCompanyIds)) {
            this.filterCompanyIds = this.inputCompanyIds.split(',').map(function (item) {
                return parseInt(item, 10);
            });
        }

        if (Helpers.isDefined(this.inputEmploymentTypeIds)) {
            this.filterEmploymentTypeIds = this.inputEmploymentTypeIds.split(',').map(function (item) {
                return parseInt(item, 10);
            });
        }

        if (Helpers.isDefined(this.inputDepartmentIds)) {
            this.filterDepartmentIds = this.inputDepartmentIds.split(',').map(function (item) {
                return parseInt(item, 10);
            });
        }

        if (Helpers.isDefined(this.inputRegisteredFrom)) {
            this.filterRegisteredFrom = this.inputRegisteredFrom;
        }

        if (Helpers.isDefined(this.inputRegisteredTo)) {
            this.filterRegisteredTo = this.inputRegisteredTo;
        }

        if (Helpers.isDefined(this.inputActive)) {
            this.filterActive = this.inputActive == 1;
        }

        if (Helpers.isDefined(this.inputPage)) {
            this.pageIndex = this.inputPage - 1;
        }

        this.getCompanies();
        this.getEmploymentTypes();
        this.getCompanyDepartments();
        this.getEmployeesPage(1);
        this.getStaffingCompanies();
    }

    getEmployeesPage(page: number): void {
        let search: IEmployeeSearch = {
            query: this.filterQuery,
            companyIds: this.filterCompanyIds,
            employmentTypeIds: this.filterEmploymentTypeIds,
            departmentIds: this.filterDepartmentIds,
            registeredFrom: Helpers.toShortDateString(this.filterRegisteredFrom),
            registeredTo: Helpers.toShortDateString(this.filterRegisteredTo),
            staffingCompanyIds: this.filterStaffingCompanyIds,
            active: this.filterActive,
            page: page,
        };

        this.loading = true;
        this.employeeService.getEmployees(search).subscribe({
            next: (data) => {
                this.employeesPage = data;
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not get employees page');
            },
        });

        let filterSettings = {
            query: this.filterQuery,
            companyIds: this.filterCompanyIds,
            employmentTypeIds: this.filterEmploymentTypeIds,
            departmentIds: this.filterDepartmentIds,
            registeredFrom: Helpers.toShortDateString(this.filterRegisteredFrom),
            registeredTo: Helpers.toShortDateString(this.filterRegisteredTo),
            active: this.filterActive,
            page: page,
        };

        this.filterChanged.emit(filterSettings);
    }

    getCompanies() {
        this.companyService.getAll().subscribe({
            next: (data) => {
                this.companies = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get companies');
            },
        });
    }

    getEmploymentTypes() {
        this.employmentTypeService.getAll().subscribe({
            next: (data) => {
                this.employmentTypes = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get employment types');
            },
        });
    }

    getCompanyDepartments() {
        this.companyDepartmentService.getAll().subscribe({
            next: (data) => {
                this.companyDepartments = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get copmany departments');
            },
        });
    }

    getStaffingCompanies() {
        this.staffingCompanyService.getAll().subscribe({
            next: (data) => {
                this.staffingCompanies = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get staffing companies');
            },
        });
    }

    pageChanged(event: any): void {
        this.getEmployeesPage(event.pageIndex + 1);
        this.pageIndex = event.pageIndex;
    }

    exportEmployees() {
        if (!Helpers.isDefined(this.employeesPage) || this.employeesPage.items.length === 0) {
            return;
        }

        let xml = '',
            newLine = '\r\n',
            tab = '\t';
        let nextEmployeeIds = [];

        for (let company of this.companies) {
            nextEmployeeIds[company.companyId] = company.nextFreeExternalEmployeeId;
        }

        xml = '<?xml version="1.0" encoding="utf-8"?>' + newLine;
        xml +=
            '<paxml xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:noNamespaceSchemaLocation="http://www.paxml.se/2.0/paxml.xsd">' +
            newLine;
        xml += tab + '<header>' + newLine;
        xml += tab + tab + '<version>2.0</version>' + newLine;
        xml += tab + '</header>' + newLine;
        xml += tab + '<personal>' + newLine;

        for (let employee of this.employeesPage.items as IEmployeeFullExtended[]) {
            let externalEmployeeId = employee.fortnoxEmployeeId;

            if (externalEmployeeId == 0) {
                externalEmployeeId = nextEmployeeIds[employee.companyId];
                nextEmployeeIds[employee.companyId]++;
            }

            xml +=
                tab +
                tab +
                '<person anstid="' +
                externalEmployeeId +
                '" persnr="' +
                employee.identityNumber.replace('-', '') +
                '">' +
                newLine;
            xml += tab + tab + tab + '<fornamn>' + employee.firstName + '</fornamn>' + newLine;
            xml += tab + tab + tab + '<efternamn>' + employee.lastName + '</efternamn>' + newLine;
            xml += tab + tab + tab + '<postadress>' + employee.address + '</postadress>' + newLine;
            xml += tab + tab + tab + '<postnr>' + employee.zip + '</postnr>' + newLine;
            xml += tab + tab + tab + '<ort>' + employee.city + '</ort>' + newLine;
            xml += tab + tab + tab + '<land>Sverige</land>' + newLine;
            xml += tab + tab + tab + '<mobiltelefon>' + employee.mobile + '</mobiltelefon>' + newLine;
            xml += tab + tab + tab + '<hemtelefon>' + employee.phone + '</hemtelefon>' + newLine;
            xml += tab + tab + tab + '<eposthem>' + employee.email + '</eposthem>' + newLine;
            xml += tab + tab + tab + '<bankclearing>' + employee.clearingNumber + '</bankclearing>' + newLine;
            xml += tab + tab + tab + '<bankkonto>' + employee.bankAccount + '</bankkonto>' + newLine;
            xml += tab + tab + tab + '<anstdatum>' + employee.added.substr(0, 10) + '</anstdatum>' + newLine;
            xml += tab + tab + '</person>' + newLine;
        }

        xml += tab + '</personal>' + newLine;
        xml += '</paxml>';

        let blob = new Blob([xml], { type: 'text/plain;charset=utf-8' });
        saveAs(blob, 'personal_export_stampelklockan.xml');
    }
}
