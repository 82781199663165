import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Helpers } from '@app/helpers/helpers.class';
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'app-attest-time-admin-page',
    templateUrl: './attest-time.component.html',
    styleUrls: ['./attest-time.component.scss'],
})
export class AttestTimeAdminComponent implements OnInit {
    employeeId: number;
    day: string;
    page: number;

    constructor(private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);

        if (Helpers.isDefined(params['day'])) {
            this.day = params['day'];
        }

        if (Helpers.isDefined(params['page'])) {
            this.page = params['page'];
        }
    }

    updateQueryParameters(filter: any): void {
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);
        params['day'] = filter.day;
        params['page'] = filter.page;
        this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params });
    }
}

