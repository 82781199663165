import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Helpers } from '@app/helpers/helpers.class';
import { IApplicationClientSearchFilter } from '@app/interfaces/application-client-search-filter.interface';
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'app-application-clients-list-admin-page',
    templateUrl: './application-clients-list.component.html',
    styleUrls: ['./application-clients-list.component.scss'],
})
export class ApplicationClientsListComponent implements OnInit {
    public employeeId: number;
    public query: string;
    public page: number;
    public authorized: boolean | null;
    public handled: boolean | null;

    constructor(private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router) {}

    public ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);

        if (Helpers.isDefined(params['query'])) {
            this.query = params['query'];
        }

        if (Helpers.isDefined(params['page'])) {
            this.page = params['page'];
        }

        if (Helpers.isDefined(params['authorized'])) {
            this.authorized = params['authorized'];
        }

        if (Helpers.isDefined(params['handled'])) {
            this.authorized = params['handled'];
        }
    }

    public updateQueryParameters(filter: IApplicationClientSearchFilter): void {
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);
        params['authorized'] = Helpers.isDefined(filter.isAuthorized) ? (filter.isAuthorized ? 1 : 0) : null;
        params['handled'] = Helpers.isDefined(filter.isHandled) ? (filter.isHandled ? 1 : 0) : null;
        params['query'] = Helpers.isDefined(filter.query) && filter.query.length > 0 ? filter.query : null;
        params['page'] = filter.pageNumber;
        this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params });
    }
}

