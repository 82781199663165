import { Pipe, PipeTransform } from '@angular/core';
import { IPunch } from '@app/interfaces/punch.interface';

@Pipe({ name: 'editPunchPosition' })
export class EditPunchPositionPipe implements PipeTransform {
    transform(punch: IPunch, startTime: Date, pixelsPerMinute: number, elem: any): string {
        let transform = elem.style.transform.replace('translate3d(', '').replace(')', '')
            .replace('px', '').replace(' ', '').split(',');
        let transformLeft = 0;

        if(transform.length === 3) {
            transformLeft = parseInt(transform[0]);
        }

        let punchTime = new Date(punch.punchTime);
        let minutesDiff = Math.floor((punchTime.getTime() - startTime.getTime()) / (60 * 1000));
        return ((minutesDiff * pixelsPerMinute) - 3 - transformLeft).toString();
    }
}
