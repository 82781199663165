import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

// Interfaces
import { IAdministrator } from '@app/interfaces/administrator.interface';
import { IAdminSearchFilter } from '@app/interfaces/admin-search-filter.interface';
import { IAdministratorActive } from '@app/interfaces/administrator-active.interface';

@Injectable({ providedIn: 'root' })
export class AdministratorService {
    constructor(private http: HttpClient) {}

    get(adminId: number): Observable<IAdministrator> {
        return this.http.get<IAdministrator>(
            environment.apiUrl + `/administrators/${adminId}`
        );
    }

    getAll(): Observable<IAdministrator[]> {
        return this.http.get<IAdministrator[]>(
            environment.apiUrl + '/administrators'
        );
    }

    search(filter: IAdminSearchFilter): Observable<IAdministrator[]> {
        return this.http.post<IAdministrator[]>(
            environment.apiUrl + '/administrators/search',
            filter
        );
    }

    setAdminActive(payload: IAdministratorActive): Observable<any> {
        return this.http.patch<any>(
            environment.apiUrl + `/administrators/${payload.adminId}/active`,
            payload
        );
    }

    create(admin: IAdministrator): Observable<IAdministrator> {
        return this.http.post<any>(
            environment.apiUrl + `/administrators`,
            admin
        );
    }

    update(admin: IAdministrator): Observable<any> {
        return this.http.patch<any>(
            environment.apiUrl + `/administrators/${admin.adminId}`,
            admin
        );
    }
}
