<app-employee-menu></app-employee-menu>

<h1 class="mat-headline-3">Återställ lösenord</h1>

<div class="container">
    <div class="row">
        <div class="col">
            <app-employee-reset-password
                [token]="token"></app-employee-reset-password>
        </div>
    </div>
</div>
