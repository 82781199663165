<app-driving-log-menu></app-driving-log-menu>

<h1 class="mat-headline-3">Resa</h1>

<div class="container">
    <div class="row">
        <div class="col">
            <mat-card class="compact">
                <mat-card-content>
                    <app-driving-log-trip [employeeId]="employeeId"></app-driving-log-trip>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<app-driving-log-footer></app-driving-log-footer>
