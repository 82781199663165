<app-admin-menu></app-admin-menu>

<h1 class="mat-headline-3">Visa anställd</h1>

<div class="container">
    <app-employee-form [employeeId]="editEmployeeId"></app-employee-form>
</div>

<app-messages #messagesPartial [employeeId]="employeeId"></app-messages>

<app-admin-footer></app-admin-footer>

<div class="loading-wrapper" *ngIf="loading">
    <mat-spinner diameter="40"></mat-spinner>
</div>
