<h2 mat-dialog-title>Dela period</h2>
<mat-dialog-content>
    <p>Ange vilken tid perioden ska delas</p>
    <mat-form-field>
        <mat-label>Tidpunkt</mat-label>
        <input matInput type="time" autocomplete="off" [formControl]="splitAtFormControl" required />
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close(true)">Avbryt</button>
    <button mat-raised-button (click)="close(false)" color="primary">Dela</button>
</mat-dialog-actions>

