import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

// Interfaces
import { IPunch } from '@app/interfaces/punch.interface';
import { IEmployeePunchSummary } from '@app/interfaces/employee-punch-summary.interface';
import { IPrePunchCheck } from '@app/interfaces/pre-punch-check.interface';
import { IPunchCostPool } from '@app/interfaces/punch-cost-pool.interface';
import { IAttestOvertimeDay } from '@app/interfaces/attest-overtime-day.interface';
import { IAttestedOvertime } from '@app/interfaces/attested-overtime.interface';
import { IPunchSearch } from '@app/interfaces/punch-search.interface';
import { IPage } from '@app/interfaces/page.interface';
import { IPunchPair } from '@app/interfaces/punch-pair.interface';
import { IAttestedTime } from '@app/interfaces/attested-time.interface';
import { IAttestTimeApproveRequest } from '@app/interfaces/attest-time-approve-request.interface';
import { IAttestTimeProblemRequest } from '@app/interfaces/attest-time-problem-request.interface';
import { IAttestTimeMoveRequest } from '@app/interfaces/attest-time-move-request.interface';
import { IAttestTimeSplitRequest } from '@app/interfaces/attest-time-split-request.interface';
import { IAttestTimeApproveAllRequest } from '@app/interfaces/attest-time-approve-all.interface';
import { IAttestTimeUndoRequest } from '@app/interfaces/attest-time-undo-request.interface';
import { IAttestedTimeIssuesSearchFilter } from '@app/interfaces/attested-time-issues-search-filter.interface';
import { IAttestedTimeIssue } from '@app/interfaces/attested-time-issue.interface';
import { IAttestTimeProblemWithoutPeriodRequest } from '@app/interfaces/attest-time-problem-without-period-request.interface';

@Injectable({ providedIn: 'root' })
export class PunchService {
    constructor(private http: HttpClient) {}

    getRecentPunches(employeeId: number): Observable<IPunch[]> {
        return this.http.get<IPunch[]>(environment.apiUrl + '/punches/recent/' + employeeId);
    }

    getSummaryForEmployee(employeeId: number): Observable<IEmployeePunchSummary[]> {
        return this.http.get<IEmployeePunchSummary[]>(environment.apiUrl + '/punches/summary/' + employeeId);
    }

    getPrePunchCheck(employeeId: number): Observable<IPrePunchCheck> {
        return this.http.get<IPrePunchCheck>(environment.apiUrl + '/punches/pre-punch-check/' + employeeId);
    }

    create(punch: IPunch): Observable<IPunch> {
        return this.http.post<IPunch>(environment.apiUrl + '/punches', punch);
    }

    setIsCorrect(punch: IPunch): Observable<any> {
        return this.http.patch<any>(environment.apiUrl + '/punches/set-correct', punch);
    }

    setPunchCostPools(punches: IPunchCostPool[]): Observable<any> {
        return this.http.patch<any>(environment.apiUrl + '/punches/set-cost-pool', punches);
    }

    attestOvertime(days: IAttestOvertimeDay[], approved: boolean, message: string): Observable<IAttestedOvertime> {
        return this.http.post<any>(environment.apiUrl + '/punches/attest-overtime', { days, approved, message });
    }

    getForEmployeeBetweenDates(employeeId: number, from: string, to: string): Observable<IPunch[]> {
        return this.http.get<IPunch[]>(environment.apiUrl + '/punches/by-employee/' + employeeId, {
            params: new HttpParams().set('from', from).set('to', to),
        });
    }

    deletePunch(punchId: number): Observable<any> {
        return this.http.delete<any>(environment.apiUrl + '/punches/' + punchId);
    }

    getPunches(search: IPunchSearch): Observable<IPage<any>> {
        return this.http.post<IPage<any>>(environment.apiUrl + '/punches/search', search);
    }

    editUpdatePunches(request: any): Observable<any> {
        return this.http.patch<any>(environment.apiUrl + '/punches/edit-update', request);
    }

    attestTimeApproveAll(request: IAttestTimeApproveAllRequest): Observable<any> {
        return this.http.post<any>(environment.apiUrl + '/punches/attest-time/approve-all', request);
    }

    attestTimeApprove(request: IAttestTimeApproveRequest): Observable<IAttestedTime> {
        return this.http.post<IAttestedTime>(environment.apiUrl + '/punches/attest-time/approve', request);
    }

    attestTimeProblem(request: IAttestTimeProblemRequest): Observable<IAttestedTime> {
        return this.http.post<IAttestedTime>(environment.apiUrl + '/punches/attest-time/problem', request);
    }

    attestTimeProblemWithoutPeriod(request: IAttestTimeProblemWithoutPeriodRequest): Observable<IAttestedTimeIssue[]> {
        return this.http.post<IAttestedTimeIssue[]>(environment.apiUrl + '/punches/attest-time/problem-without-period', request);
    }

    attestTimeUndo(request: IAttestTimeUndoRequest): Observable<any> {
        return this.http.post<any>(environment.apiUrl + '/punches/attest-time/undo-action', request);
    }

    attestTimeMove(request: IAttestTimeMoveRequest): Observable<boolean> {
        return this.http.post<boolean>(environment.apiUrl + '/punches/attest-time/move', request);
    }

    attestTimeSplit(request: IAttestTimeSplitRequest): Observable<IPunchPair[]> {
        return this.http.post<IPunchPair[]>(environment.apiUrl + '/punches/attest-time/split', request);
    }

    getAttestTimeTodoDates(handledByEmployeeId: number): Observable<string[]> {
        return this.http.get<string[]>(environment.apiUrl + '/punches/attest-time/todo-dates', {
            params: new HttpParams().set('handledByEmployeeId', handledByEmployeeId),
        });
    }

    searchAttestedTimeIssues(request: IAttestedTimeIssuesSearchFilter): Observable<IPage<IAttestedTimeIssue>> {
        return this.http.post<IPage<IAttestedTimeIssue>>(environment.apiUrl + '/punches/attested-time-issues/search', request);
    }

    toggleAttestedTimeIssueSolved(issueId: number, solved: boolean): Observable<any> {
        return this.http.patch<any>(environment.apiUrl + '/punches/attested-time-issues/' + issueId + '/solved', {
            issueId,
            solved,
        });
    }
}
