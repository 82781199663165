import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from "@angular/router";
import { Helpers } from '@app/helpers/helpers.class';

// Services
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'app-report-staffing-company-time-page',
    templateUrl: './report-staffing-company-time.component.html',
    styleUrls: ['./report-staffing-company-time.component.scss']
})

export class ReportStaffingCompanyTimeComponent implements OnInit {
    staffingCompanyIds: number[];
    adminId: number;
    punchDateFrom: string;
    punchDateTo: string;
    page: number;

    constructor(private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private router: Router) { }

    ngOnInit(): void {
        this.adminId = this.authService.getAdminId();
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);

        if (Helpers.isDefined(params['staffingCompanyIds'])) {
            this.staffingCompanyIds = params['staffingCompanyIds'];
        }

        if (Helpers.isDefined(params['punchDateFrom'])) {
            this.punchDateFrom = params['punchDateFrom'];
        }

        if (Helpers.isDefined(params['punchDateTo'])) {
            this.punchDateTo = params['punchDateTo'];
        }

        if (Helpers.isDefined(params['page'])) {
            this.page = params['page'];
        }
    }

    updateQueryParameters(filter: any): void {
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);
        params['staffingCompanyIds'] = filter.staffingCompanyId > 0 ? filter.staffingCompanyId : null;
        params['punchDateFrom'] = filter.punchDateFrom.length > 0 ? filter.punchDateFrom : null;
        params['punchDateTo'] = filter.punchDateTo.length > 0 ? filter.punchDateTo : null;
        params['page'] = filter.page;
        this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params });
    }
}
