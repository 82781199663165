import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from "@angular/router";
import { Helpers } from '@app/helpers/helpers.class';

// Services
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'app-schedules-connect-admin-page',
    templateUrl: './schedules-connect.component.html',
    styleUrls: ['./schedules-connect.component.scss']
})

export class SchedulesConnectAdminComponent implements OnInit {
    public employeeId: number;
    public query: string;
    public companyIds: number[];
    public employmentTypeIds: number[];
    public departmentIds: number[];
    public registeredFrom: string;
    public registeredTo: string;
    public active: boolean;
    public missingSchedule: boolean;
    public page: number;

    constructor(private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private router: Router) { }

    ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);

        if (Helpers.isDefined(params['query'])) {
            this.query = params['query'];
        }

        if (Helpers.isDefined(params['companyIds'])) {
            this.companyIds = params['companyIds'];
        }

        if (Helpers.isDefined(params['employmentTypeIds'])) {
            this.employmentTypeIds = params['employmentTypeIds'];
        }

        if (Helpers.isDefined(params['departmentIds'])) {
            this.departmentIds = params['departmentIds'];
        }

        if (Helpers.isDefined(params['registeredFrom'])) {
            this.registeredFrom = params['registeredFrom'];
        }

        if (Helpers.isDefined(params['registeredTo'])) {
            this.registeredTo = params['registeredTo'];
        }

        if (Helpers.isDefined(params['active'])) {
            this.active = params['active'];
        }

        if (Helpers.isDefined(params['missingSchedule'])) {
            this.missingSchedule = params['missingSchedule'];
        }

        if (Helpers.isDefined(params['page'])) {
            this.page = params['page'];
        }
    }

    updateQueryParameters(filter: any): void {
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);
        params['active'] = filter.active ? 1 : 0;
        params['missingSchedule'] = filter.missingSchedule ? 1 : 0;
        params['companyIds'] = filter.companyIds.length > 0 ? filter.companyIds.join(',') : null;
        params['employmentTypeIds'] = filter.employmentTypeIds.length > 0 ? filter.employmentTypeIds.join(',') : null;
        params['departmentIds'] = filter.departmentIds.length > 0 ? filter.departmentIds.join(',') : null;
        params['registeredFrom'] = filter.registeredFrom.length > 0 ? filter.registeredFrom : null;
        params['registeredTo'] = filter.registeredTo.length > 0 ? filter.registeredTo : null;
        params['query'] = Helpers.isDefined(filter.query) && filter.query.length > 0 ?
            filter.query : null;
        params['page'] = filter.page;
        this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params });
    }
}
