<app-employee-menu></app-employee-menu>
<main role="main">
    <div class="container">
        <div class="row">
            <div class="col">
                <h1 class="mat-headline-3">Redigera uppgifter</h1>
            </div>
        </div>
        <app-employee-form [employeeId]="employeeId" cancelRoute="/punch" (saved)="saved($event)"></app-employee-form>
    </div>
</main>
<app-employee-footer></app-employee-footer>
