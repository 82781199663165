import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

// Interfaces
import { ICostPool } from '@app/interfaces/cost-pool.interface';
import { IPage } from '@app/interfaces/page.interface';
import { ICostPoolSearch } from '@app/interfaces/cost-pool-search.interface';
import { ICostPoolCompanyGroup } from '@app/interfaces/cost-pool-company-group.interface';
import { ICostPoolManager } from '@app/interfaces/cost-pool-manager.interface';

@Injectable({ providedIn: 'root' })
export class CostPoolService {
    constructor(private http: HttpClient) {}

    getParents(): Observable<ICostPool[]> {
        return this.http.get<ICostPool[]>(environment.apiUrl + '/cost-pools/parents');
    }

    getAllGroupedByCompany(): Observable<ICostPoolCompanyGroup[]> {
        return this.http.get<ICostPoolCompanyGroup[]>(environment.apiUrl + '/cost-pools/grouped-by-company');
    }

    getSuggestedPoolForEmployee(employeeId: number): Observable<ICostPool> {
        return this.http.get<ICostPool>(environment.apiUrl + '/cost-pools/suggested-for-employees/' + employeeId);
    }

    getCostPools(search: ICostPoolSearch): Observable<IPage<any>> {
        return this.http.post<IPage<any>>(environment.apiUrl + '/cost-pools/search', search);
    }

    setVisible(costPool: ICostPool): Observable<IPage<any>> {
        return this.http.patch<IPage<any>>(environment.apiUrl + '/cost-pools/' + costPool.poolId + '/visible', costPool);
    }

    get(poolId: number): Observable<ICostPool> {
        return this.http.get<ICostPool>(environment.apiUrl + '/cost-pools/' + poolId);
    }

    update(costPool: ICostPool): Observable<any> {
        return this.http.patch<any>(environment.apiUrl + '/cost-pools/' + costPool.poolId, costPool);
    }

    create(costPool: ICostPool): Observable<ICostPool> {
        return this.http.post<ICostPool>(environment.apiUrl + '/cost-pools', costPool);
    }

    setCompany(costPool: ICostPool): Observable<any> {
        return this.http.patch<any>(environment.apiUrl + '/cost-pools/' + costPool.poolId + '/company', costPool);
    }

    getManagers(poolId: number): Observable<ICostPoolManager[]> {
        return this.http.get<ICostPoolManager[]>(environment.apiUrl + '/cost-pools/' + poolId + '/managers');
    }

    deleteManager(poolId, id: number): Observable<any> {
        return this.http.delete<any>(environment.apiUrl + '/cost-pools/' + poolId + '/managers/' + id);
    }

    addManager(manager: ICostPoolManager): Observable<ICostPoolManager> {
        return this.http.post<ICostPoolManager>(environment.apiUrl + '/cost-pools/' + manager.poolId + '/managers', manager);
    }
}
