import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { Helpers } from '@app/helpers/helpers.class';
import { EmployeeService } from '@app/services/employee.service';
import { IWorkPeriod } from '@app/interfaces/work-period.interface';
import { IPunchWarning } from '@app/interfaces/punch-warning.interface';
import { IPunchWarningDialogResult } from '@app/interfaces/punch-warning-dialog-result.interface';
import { PunchWarningActionDialog } from '@app/shared/dialogs/punch-warning-action/punch-warning-action.dialog';
import { ConfirmDialog } from '@app/shared/dialogs/confirm/confirm.dialog';
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';
import { MessagesPartial } from '@app/components/partials/messages/messages.partial';
import { ReportAbsencePartial } from '@app/components/partials/report-absence/report-absence.partial';
import { IPreCompleteWorkPeriodCheck } from '@app/interfaces/pre-complete-work-period-check.interface';
import { IEmployeeCompletedWorkPeriod } from '@app/interfaces/employee-completed-work-period.interface';

@Component({
    selector: 'app-employee-work-periods',
    templateUrl: './employee-work-periods.partial.html',
    styleUrls: ['./employee-work-periods.partial.scss'],
    animations: [
        trigger('toggleWarnings', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class EmployeeWorkPeriodsPartial implements OnInit {
    @Input() employeeId: number;
    @Input() data: IWorkPeriod[];
    @Input() useExternalData: boolean;
    @Input() messagesPartial: MessagesPartial;
    @Input() reportAbsencePartial: ReportAbsencePartial;
    @Output() selectTab = new EventEmitter<number>();

    workPeriodColumns: string[] = ['periodStart', 'confirmDue', 'status', 'close', 'caret'];
    openWorkPeriods: IWorkPeriod[];
    expandedPeriod: IWorkPeriod;
    loading: boolean = false;

    constructor(public dialog: MatDialog, private employeeService: EmployeeService) {}

    ngOnInit(): void {
        if (!this.useExternalData) {
            this.getOpenWorkPeriods();
        } else if (this.data) {
            this.openWorkPeriods = this.data;
        } else {
            this.loading = true;
        }
    }

    externalDataLoaded(externalData: IWorkPeriod[]) {
        this.openWorkPeriods = null;
        setTimeout(
            (ed: any[]) => {
                let workPeriods: any[] = [];
                for (let i = 0; i < ed.length; i++) {
                    let period: any = Object.assign({}, ed[i]);
                    period.warningColumns = this.getWarningColumns(i);
                    workPeriods.push(period);
                }
                this.openWorkPeriods = workPeriods;
                this.loading = false;
            },
            1,
            externalData
        );
    }

    getOpenWorkPeriods(): void {
        this.loading = true;
        this.employeeService.getOpenWorkPeriods(this.employeeId).subscribe({
            next: (data) => {
                let workPeriods: any = data;
                for (let i = 0; i < workPeriods.length; i++) {
                    workPeriods[i].warningColumns = this.getWarningColumns(i);
                }
                this.openWorkPeriods = workPeriods;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get open work periods');
            },
            complete: () => {
                this.loading = false;
            },
        });
    }

    showWarningOptions(warning: IPunchWarning): void {
        const handle = this.dialog.open(PunchWarningActionDialog, {
            width: '500px',
            data: {
                warning: warning,
            },
        });

        handle.afterClosed().subscribe((result: IPunchWarningDialogResult) => {
            if (Helpers.isDefined(result)) {
                if (result.action == 1) {
                    // Ignorera
                    const confirmHandle = this.dialog.open(ConfirmDialog, {
                        width: '400px',
                        data: {
                            title: 'Är du säker?',
                            message: 'Är du säker på att du vill ignorera varningen?',
                        },
                    });

                    confirmHandle.afterClosed().subscribe((doIgnore: boolean) => {
                        if (doIgnore) {
                            this.loading = true;
                            this.employeeService.ignorePunchWarning(this.employeeId, warning.hashCode).subscribe({
                                next: (data) => {
                                    this.getOpenWorkPeriods();
                                },
                                error: (error) => {
                                    console.error(error);
                                    Helpers.captureSentryError('Could not ignore punch warning');
                                },
                            });
                        }
                    });
                } else if (result.action == 2) {
                    // Lägg in frånvaro
                    if (Helpers.isDefined(this.reportAbsencePartial)) {
                        this.reportAbsencePartial.setMode(1);
                        this.reportAbsencePartial.setDay(new Date(warning.day));
                        if (Helpers.isDefined(this.selectTab)) {
                            this.selectTab.emit(1);
                        }
                        setTimeout(() => {
                            this.reportAbsencePartial.focusFromTime();
                        }, 800);
                    }
                } else if (result.action == 3) {
                    // Skicka meddelande
                    if (Helpers.isDefined(this.messagesPartial)) {
                        this.messagesPartial.writePunchWarningMessage(
                            result.warning.hashCode,
                            'Hej,\nang. varningen "' +
                                warning.description +
                                '" den ' +
                                Helpers.toShortDateString(warning.day) +
                                ':\n'
                        );
                    }
                }
            }
        });
    }

    openConversation(event: any): void {
        event.stopPropagation();
        this.messagesPartial.showConversation(Helpers.economyContactEmployeeId);
    }

    completePeriodPreCheck(period: IWorkPeriod): void {
        this.loading = true;
        this.employeeService.getPreCompleteWorkPeriodCheck(this.employeeId, period).subscribe({
            next: (data: IPreCompleteWorkPeriodCheck) => {
                if (data.hasUnclosedPrecedingPeriods) {
                    this.loading = false;
                    this.dialog.open(AlertDialog, {
                        width: '400px',
                        data: {
                            title: 'Fel',
                            message:
                                'Du kan inte klarmarkera perioden eftersom det finns tidigare perioder som inte är klarmarkerade. ' +
                                'Du måste klarmarkera alla tidigare perioder först.',
                        },
                    });
                } else if (data.hasUnfixedWarnings) {
                    this.loading = false;
                    this.dialog.open(AlertDialog, {
                        width: '400px',
                        data: {
                            title: 'Fel',
                            message: 'Du kan inte klarmarkera perioden eftersom det finns varningar.',
                        },
                    });
                } else {
                    if (!data.isPunching) {
                        let handle = this.dialog.open(ConfirmDialog, {
                            width: '400px',
                            data: {
                                title: 'Glöm inte frånvaro',
                                message:
                                    'Glöm inte att du måste lägga in all frånvaro innan du klarmarkerar rapporten som exempelvis ' +
                                    'sjukfrånvaro, semester eller annan ledighet. Vill du klarmarkera rapporten nu?',
                            },
                        });

                        handle.afterClosed().subscribe((result: boolean) => {
                            if (result) {
                                this.completePeriod(period);
                            } else {
                                this.loading = false;
                            }
                        });
                    } else {
                        let handle = this.dialog.open(ConfirmDialog, {
                            width: '400px',
                            data: {
                                title: 'Fortsätt?',
                                message: 'Är du säker på att du vill klarmarkera rapporten?',
                            },
                        });

                        handle.afterClosed().subscribe((result: boolean) => {
                            if (result) {
                                this.completePeriod(period);
                            } else {
                                this.loading = false;
                            }
                        });
                    }
                }
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get complete period pre-check');
            },
        });
    }

    completePeriod(period: IWorkPeriod): void {
        this.employeeService.completeWorkPeriod(this.employeeId, period).subscribe({
            next: (data: IEmployeeCompletedWorkPeriod) => {
                this.getOpenWorkPeriods();
            },
            error: (error) => {
                this.loading = false;
                console.error(error);
                Helpers.captureSentryError('Could not complete period');
            },
        });
    }

    getWarningColumns(i: number): any[] {
        return ['severity_' + i, 'day_' + i, 'description_' + i, 'messageSent_' + i];
    }
}
