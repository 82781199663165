import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Helpers } from '@app/helpers/helpers.class';
import { Router } from '@angular/router';

// Error handling
import * as Sentry from '@sentry/browser';

// Enums
import { LoginTypeEnum } from '@app/enums/login-type.enum';
import { Observable } from 'rxjs';
import { IResetPassword } from '@app/interfaces/reset-password.interface';

@Injectable({ providedIn: 'root' })
export class AuthService {
    constructor(private http: HttpClient, private router: Router) {}

    employeeLogin(code: string, password: string) {
        return this.http
            .post<any>(environment.apiUrl + '/authenticate/employee', {
                code,
                password,
            })
            .pipe(
                map((user) => {
                    if (user && user.token) {
                        localStorage.setItem(
                            'currentUser',
                            JSON.stringify(user)
                        );
                        Sentry.configureScope((scope) => {
                            scope.setUser({ id: code });
                        });
                    }

                    return user;
                })
            );
    }

    requestResetEmployeePassword(code: string): Observable<any> {
        return this.http.patch<any>(
            environment.apiUrl +
                `/authenticate/employee/reset-password/request`,
            {
                code,
            }
        );
    }

    resetEmployeePassword(passwordReset: IResetPassword): Observable<any> {
        return this.http.patch<any>(
            environment.apiUrl + `/authenticate/employee/reset-password`,
            passwordReset
        );
    }

    isResetEmployeePasswordTokenValid(token: string): Observable<any> {
        return this.http.get<any>(
            environment.apiUrl +
                `/authenticate/employee/reset-password/token-valid/${token}`
        );
    }

    drivingLogLogin(code: string) {
        return this.http
            .post<any>(environment.apiUrl + '/authenticate/driving-log', {
                code,
            })
            .pipe(
                map((user) => {
                    if (user && user.token) {
                        localStorage.setItem(
                            'currentDrivingLogUser',
                            JSON.stringify(user)
                        );
                        Sentry.configureScope((scope) => {
                            scope.setUser({ id: code });
                        });
                    }

                    return user;
                })
            );
    }

    administratorLogin(userName: string, password: string) {
        return this.http
            .post<any>(environment.apiUrl + '/authenticate/administrator', {
                userName,
                password,
            })
            .pipe(
                map((user) => {
                    if (user && user.token) {
                        localStorage.setItem(
                            'currentAdmin',
                            JSON.stringify(user)
                        );
                    }
                    Sentry.configureScope((scope) => {
                        scope.setUser({ username: userName });
                    });

                    return user;
                })
            );
    }

    requestResetAdministratorPassword(username: string): Observable<any> {
        return this.http.patch<any>(
            environment.apiUrl +
                `/authenticate/administrator/reset-password/request`,
            {
                username: username,
            }
        );
    }

    resetAdministratorPassword(passwordReset: IResetPassword): Observable<any> {
        return this.http.patch<any>(
            environment.apiUrl + `/authenticate/administrator/reset-password`,
            passwordReset
        );
    }

    isResetAdministratorPasswordTokenValid(token: string): Observable<any> {
        return this.http.get<any>(
            environment.apiUrl +
                `/authenticate/administrator/reset-password/token-valid/${token}`
        );
    }

    logoutEmployee(): void {
        localStorage.removeItem('currentUser');
        Sentry.configureScope((scope) => {
            scope.setUser(null);
        });
    }

    logoutDrivingLog(): void {
        localStorage.removeItem('currentDrivingLogUser');
        Sentry.configureScope((scope) => {
            scope.setUser(null);
        });
    }

    logoutAdmin(): void {
        localStorage.removeItem('currentAdmin');
        Sentry.configureScope((scope) => {
            scope.setUser(null);
        });
    }

    isAuthenticated(type: LoginTypeEnum): boolean {
        if (
            localStorage.getItem('currentUser') === null &&
            localStorage.getItem('currentAdmin') === null &&
            localStorage.getItem('currentDrivingLogUser') === null
        ) {
            return false;
        }
        try {
            let tokenData = null;
            const time = Helpers.getTime();

            if (type === LoginTypeEnum.Employee) {
                tokenData = Helpers.decodeToken(
                    localStorage.getItem('currentUser')
                );
            } else if (type === LoginTypeEnum.Admin) {
                tokenData = Helpers.decodeToken(
                    localStorage.getItem('currentAdmin')
                );
            } else {
                tokenData = Helpers.decodeToken(
                    localStorage.getItem('currentDrivingLogUser')
                );
            }

            if (
                (type === LoginTypeEnum.Employee &&
                    !Helpers.isDefined(tokenData.accessLevel)) ||
                (type === LoginTypeEnum.DrivingLog &&
                    !Helpers.isDefined(tokenData.accessLevel)) ||
                (type === LoginTypeEnum.Admin && tokenData.accessLevel < 2)
            ) {
                return false;
            }

            return tokenData.exp > time;
        } catch {
            return false;
        }
    }

    getEmployeeId(): number {
        if (
            localStorage.getItem('currentUser') === null &&
            localStorage.getItem('currentAdmin') === null &&
            localStorage.getItem('currentDrivingLogUser') === null
        ) {
            return 0;
        }

        if (
            localStorage.getItem('currentAdmin') !== null &&
            this.router.url.indexOf('/admin/') > -1
        ) {
            return Helpers.decodeToken(localStorage.getItem('currentAdmin'))
                .employeeId;
        } else if (
            localStorage.getItem('currentDrivingLogUser') !== null &&
            this.router.url.indexOf('/driving-log/') > -1
        ) {
            return Helpers.decodeToken(
                localStorage.getItem('currentDrivingLogUser')
            ).employeeId;
        } else {
            return Helpers.decodeToken(localStorage.getItem('currentUser'))
                .employeeId;
        }
    }

    getAdminId(): number {
        if (localStorage.getItem('currentAdmin') === null) {
            return 0;
        }

        const tokenData = Helpers.decodeToken(
            localStorage.getItem('currentAdmin')
        );

        return tokenData.adminId;
    }

    getAdminUsername(): string {
        if (localStorage.getItem('currentAdmin') === null) {
            return null;
        }

        const tokenData = Helpers.decodeToken(
            localStorage.getItem('currentAdmin')
        );

        return tokenData.username;
    }

    hasClaim(claim: string): boolean {
        if (localStorage.getItem('currentAdmin') === null) {
            return false;
        }

        const tokenData = Helpers.decodeToken(
            localStorage.getItem('currentAdmin')
        );

        if (Helpers.isDefined(tokenData[claim])) {
            return true;
        }

        return false;
    }

    isEmployeeAuthenticatedWithPassword(): boolean {
        if (localStorage.getItem('currentUser') === null) {
            return false;
        }

        const tokenData = Helpers.decodeToken(
            localStorage.getItem('currentUser')
        );

        if (Helpers.isDefined(tokenData['withPassword'])) {
            return parseInt(tokenData['withPassword'], 10) === 1;
        }

        return false;
    }

    isAdminMode(): boolean {
        const adminId = this.getAdminId();

        if (adminId <= 0) {
            return false;
        }

        return this.router.url.indexOf('/admin/') > -1;
    }
}
