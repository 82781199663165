<app-admin-menu></app-admin-menu>

<h1 class="mat-headline-3">Attestera övertid</h1>

<div class="container">
    <div class="row">
        <div class="col">
            <mat-card class="compact attest-overtime">
                <mat-card-content>
                    <app-attest-overtime (filterChanged)="updateQueryParameters($event)"
                        [mode]="mode" [status]="status" [day]="day" [page]="page" [query]="query">
                    </app-attest-overtime>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<app-messages #messagesPartial [employeeId]="employeeId"></app-messages>

<app-admin-footer></app-admin-footer>
