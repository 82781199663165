<div class="container-fluid">
    <div class="row filters">
        <div class="col query-filter">
            <label>Sök</label>
            <mat-form-field>
                <input
                    matInput
                    [(ngModel)]="filterQuery"
                    autocomplete="off"
                    autocomplete="off"
                    placeholder="Fritextsök..."
                    (keydown.enter)="getEmployeesPage(1)" />
            </mat-form-field>
        </div>
        <div class="col company-filter">
            <label>Bolag</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filterCompanyIds" placeholder="Välj bolag" multiple>
                    <mat-option *ngFor="let company of companies" [value]="company.companyId">{{company.companyName}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row filters">
        <div class="col employment-type-filter">
            <label>Anställnig</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filterEmploymentTypeIds" placeholder="Välj anställningstyp" multiple>
                    <mat-option *ngFor="let employmentType of employmentTypes" [value]="employmentType.employmentTypeId">
                        {{employmentType.employmentTypeName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col company-department-filter">
            <label>Avdelning</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filterDepartmentIds" placeholder="Välj avdelning" multiple>
                    <mat-option *ngFor="let department of companyDepartments" [value]="department.departmentId">
                        {{companies | valueFromObjectArray:'companyId':department.companyId:'companyShort'}} -
                        {{department.departmentName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col registered-filter">
            <label>Registrerades</label>
            <div class="date-range">
                <mat-form-field class="from" (click)="pickerFrom.open()">
                    <input matInput [matDatepicker]="pickerFrom" [(ngModel)]="filterRegisteredFrom" placeholder="Från" readonly />
                    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFrom></mat-datepicker>
                </mat-form-field>
                <span>-</span>
                <mat-form-field class="to" (click)="pickerTo.open()">
                    <input matInput [matDatepicker]="pickerTo" [(ngModel)]="filterRegisteredTo" placeholder="Till" readonly />
                    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                    <mat-datepicker #pickerTo></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="row filters">
        <div class="col active-filter">
            <mat-checkbox [(ngModel)]="filterActive">Aktiva</mat-checkbox>
        </div>
        <div class="col missing-schedule-filter">
            <mat-checkbox [(ngModel)]="filterMissingSchedule">Saknar schema</mat-checkbox>
        </div>
        <div class="col button-filter">
            <button mat-raised-button (click)="getEmployeesPage(1)" color="primary">Sök</button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="divider-wrapper">
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col" *ngIf="employeesPage && employeesPage.totalItems; else noResults;">
            <div class="connect top">
                <button
                    mat-raised-button
                    (click)="showConnect()"
                    [disabled]="!(selection.selected && selection.selected.length > 0)">
                    <mat-icon>person_add</mat-icon>
                    Koppla
                </button>
            </div>
            <mat-paginator
                *ngIf="employeesPage && employeesPage.totalItems > employeesPage.pageSize"
                [length]="employeesPage.totalItems"
                [pageSize]="employeesPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
            <div class="table-wrapper">
                <table mat-table *ngIf="employeesPage" [dataSource]="dataSource" multiTemplateDataRows>
                    <ng-container matColumnDef="checkbox">
                        <th mat-header-cell *matHeaderCellDef class="checkbox">
                            <mat-checkbox
                                (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let employee" class="checkbox">
                            <mat-checkbox
                                (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(employee) : null"
                                [checked]="selection.isSelected(employee)">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="name">Namn</th>
                        <td mat-cell *matCellDef="let employee" class="name">{{employee.lastName}}, {{employee.firstName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="company">
                        <th mat-header-cell *matHeaderCellDef class="company">Bolag</th>
                        <td mat-cell *matCellDef="let employee" class="company">{{employee.company.companyShort}}</td>
                    </ng-container>
                    <ng-container matColumnDef="department">
                        <th mat-header-cell *matHeaderCellDef class="department">Avdelning</th>
                        <td mat-cell *matCellDef="let employee" class="department">
                            {{employee.department ? employee.department.departmentName : '-'}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="employmentType">
                        <th mat-header-cell *matHeaderCellDef class="employment-type">Anställning</th>
                        <td mat-cell *matCellDef="let employee" class="employment-type">
                            {{employee.employmentType ? employee.employmentType.employmentTypeName : ''}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="activeSchedule">
                        <th mat-header-cell *matHeaderCellDef class="active-schedule">Aktivt schema</th>
                        <td mat-cell *matCellDef="let employee" class="active-schedule">
                            <ng-container *ngIf="employee.activeSchedule; else noSchedule">
                                <span click-stop-propagation (click)="showSchedule(employee.activeSchedule.scheduleId)">
                                    {{employee.activeSchedule.name}}
                                </span>
                            </ng-container>
                            <ng-template #noSchedule> - </ng-template>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="expand">
                        <th mat-header-cell *matHeaderCellDef class="expand"></th>
                        <td mat-cell *matCellDef="let employee" class="expand">
                            <ng-container *ngIf="employee.schedules && employee.schedules.length > 0">
                                <mat-icon *ngIf="employee != expandedEmployee">keyboard_arrow_down</mat-icon>
                                <mat-icon *ngIf="employee == expandedEmployee">keyboard_arrow_up</mat-icon>
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="schedules">
                        <td mat-cell *matCellDef="let employee" [attr.colspan]="employeeColumns.length">
                            <div class="schedules-wrapper" [@toggle]="employee == expandedEmployee ? 'expanded' : 'collapsed'">
                                <table
                                    class="schedules"
                                    mat-table
                                    [dataSource]="employee.schedules"
                                    *ngIf="visibleEmployeeIds.indexOf(employee.employeeId) > -1">
                                    <ng-container [matColumnDef]="employee.scheduleColumns[0]">
                                        <th mat-header-cell *matHeaderCellDef class="name">Namn</th>
                                        <td mat-cell *matCellDef="let schedule" class="name">
                                            <span (click)="showSchedule(schedule.scheduleId)"> {{schedule.name}} </span>
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="employee.scheduleColumns[1]">
                                        <th mat-header-cell *matHeaderCellDef class="from-date">Från</th>
                                        <td mat-cell *matCellDef="let schedule" class="from-date">
                                            {{schedule.fromDate | shortDate}}
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="employee.scheduleColumns[2]">
                                        <th mat-header-cell *matHeaderCellDef class="length">Längd</th>
                                        <td mat-cell *matCellDef="let schedule" class="length">
                                            {{schedule.scheduleLength}} dagar
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="employee.scheduleColumns[3]">
                                        <th mat-header-cell *matHeaderCellDef class="delete-schedule"></th>
                                        <td mat-cell *matCellDef="let schedule" class="delete-schedule">
                                            <mat-icon
                                                matTooltip="Koppla bort schema"
                                                (click)="deleteEmployeeSchedule(schedule.scheduleId, employee.employeeId, schedule.fromDate)">
                                                delete_outline
                                            </mat-icon>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="employee.scheduleColumns"></tr>
                                    <tr mat-row *matRowDef="let schedule; columns: employee.scheduleColumns;"></tr>
                                </table>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="employeeColumns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let employee; columns: employeeColumns;"
                        (click)="toggleSchedulesRow(employee)"
                        [class.no-schedules]="!employee.schedules || employee.schedules.length === 0"></tr>
                    <tr mat-row *matRowDef="let schedule; columns: ['schedules']" class="schedules-row"></tr>
                </table>
            </div>
            <mat-paginator
                *ngIf="employeesPage && employeesPage.totalItems > employeesPage.pageSize"
                [length]="employeesPage.totalItems"
                [pageSize]="employeesPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
            <div class="connect bottom">
                <button
                    mat-raised-button
                    (click)="showConnect()"
                    [disabled]="!(selection.selected && selection.selected.length > 0)">
                    <mat-icon>person_add</mat-icon>
                    Koppla
                </button>
            </div>
        </div>
        <ng-template #noResults>
            <div class="col">
                <p class="no-results">Inga anställda att visa</p>
            </div>
        </ng-template>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
