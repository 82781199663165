<div class="container-fluid">
    <div class="row filters">
        <div class="col active-filter">
            <label>Administratör</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filterAdmin" placeholder="(Välje en)">
                    <mat-option [value]="null">(Välj en)</mat-option>
                    <mat-option *ngFor="let admin of administrators" [value]="admin.adminId">{{admin.lastName}},
                        {{admin.firstName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col button-filter">
            <button mat-raised-button (click)="getAdministratorClaims()" color="primary">Visa</button>
        </div>
    </div>
    <div class="row" *ngIf="allClaims && administratorClaims">
        <div class="col">
            <div class="divider-wrapper">
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="table-wrapper">
                <table mat-table *ngIf="allClaims && administratorClaims" [dataSource]="allClaims">
                    <ng-container matColumnDef="category">
                        <th mat-header-cell *matHeaderCellDef class="category">Kategori</th>
                        <td mat-cell *matCellDef="let claim" class="category">
                            {{claim.category.categoryName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="key">
                        <th mat-header-cell *matHeaderCellDef class="key">Namn</th>
                        <td mat-cell *matCellDef="let claim" class="key">
                            {{claim.claimKey}}</td>
                    </ng-container>
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef class="description">Beskrivning</th>
                        <td mat-cell *matCellDef="let claim" class="description">
                            {{claim.description}}</td>
                    </ng-container>
                    <ng-container matColumnDef="enabled">
                        <th mat-header-cell *matHeaderCellDef class="enabled"></th>
                        <td mat-cell *matCellDef="let claim" class="enabled">
                            <mat-slide-toggle (change)="setAdministratorClaim(claim, $event)"
                                [checked]="administratorClaims | arrayContains: claim.claimId"></mat-slide-toggle>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="claimColumns"></tr>
                    <tr mat-row *matRowDef="let claim; columns: claimColumns;">
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
