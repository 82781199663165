import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Helpers } from '@app/helpers/helpers.class';
import { MatDialog } from '@angular/material/dialog';
import { CostPoolService } from '@app/services/cost-pool.service';
import { IPage } from '@app/interfaces/page.interface';
import { ICostPoolSearch } from '@app/interfaces/cost-pool-search.interface';
import { ICostPool } from '@app/interfaces/cost-pool.interface';
import { CompanyService } from '@app/services/company.service';
import { ICompany } from '@app/interfaces/company.interface';

@Component({
    selector: 'app-cost-pools-list',
    templateUrl: './cost-pools-list.partial.html',
    styleUrls: ['./cost-pools-list.partial.scss'],
})
export class CostPoolsListPartial implements OnInit {
    @Output() public filterChanged: EventEmitter<any> = new EventEmitter();
    @Input('query') public inputQuery: string;
    @Input('visible') public inputVisible: number;
    @Input('page') public inputPage: number;

    public pageIndex: number;
    public loading: boolean = false;
    public filterQuery: string;
    public filterVisible: boolean;
    public costPoolsPage: IPage<any>;
    protected companies: ICompany[];
    public costPoolsColumns: string[] = ['code', 'project', 'name', 'company', 'visible'];

    constructor(private costPoolService: CostPoolService, private dialog: MatDialog, private companyService: CompanyService) {}

    public ngOnInit(): void {
        this.filterQuery = '';
        this.filterVisible = null;

        if (Helpers.isDefined(this.inputQuery)) {
            this.filterQuery = this.inputQuery;
        }

        if (Helpers.isDefined(this.inputVisible)) {
            this.filterVisible = this.inputVisible == 1;
        }

        if (Helpers.isDefined(this.inputPage)) {
            this.pageIndex = this.inputPage - 1;
        }

        this.loadCompanies();
        this.getCostPoolsPage(1);
    }

    public getCostPoolsPage(page: number): void {
        let search: ICostPoolSearch = {
            query: this.filterQuery,
            visible: this.filterVisible,
            page: page,
        };

        this.loading = true;
        this.costPoolService.getCostPools(search).subscribe({
            next: (data) => {
                this.costPoolsPage = data;
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not get cost pools page');
            },
        });

        let filterSettings = {
            query: this.filterQuery,
            visible: this.filterVisible,
            page: page,
        };

        this.filterChanged.emit(filterSettings);
    }

    public setVisible(event: any, costPool: ICostPool): void {
        costPool.visible = event.checked;
        this.loading = true;
        this.costPoolService.setVisible(costPool).subscribe({
            next: (data) => {
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not set cost pools visible');
            },
        });
    }

    private loadCompanies(): void {
        this.companyService.getAll().subscribe({
            next: (companies) => {
                this.companies = companies;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not load companies');
            },
        });
    }

    protected setPoolCompany(costPool: ICostPool): void {
        this.costPoolService.setCompany(costPool).subscribe({
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not set company');
            },
        });
    }
}
