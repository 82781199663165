import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

// Interfaces
import { IEmployeeBasic } from '@app/interfaces/employee-basic.interface';
import { ICompanyOpenWorkPeriod } from '@app/interfaces/company-open-work-period.interface';

@Component({
    selector: 'work-period-employees-dialog',
    templateUrl: './work-period-employees.dialog.html',
    styleUrls: ['./work-period-employees.dialog.scss']
})

export class WorkPeriodEmployeesDialog implements OnInit {

    company: ICompanyOpenWorkPeriod;
    employeeColumns: string[] = ['employeeName', 'status'];

    ngOnInit() {

    }

    constructor(private dialogRef: MatDialogRef<WorkPeriodEmployeesDialog>,
        @Inject(MAT_DIALOG_DATA) {
            company
        }: any) {
        this.company = company;
    }

    close() {
        this.dialogRef.close();
    }
}
