import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Helpers } from '@app/helpers/helpers.class';

// Error handling
import * as Sentry from '@sentry/browser';

// Services
import { ScheduleService } from '@app/services/schedule.service';

// Interfaces
import { IPage } from '@app/interfaces/page.interface';
import { IScheduleLength } from '@app/interfaces/schedule-length.interface';
import { IScheduleSearch } from '@app/interfaces/schedule-search.interface';

@Component({
    selector: 'app-schedules-list',
    templateUrl: './schedules-list.partial.html',
    styleUrls: ['./schedules-list.partial.scss'],
})
export class SchedulesListPartial implements OnInit {
    @Output() public filterChanged: EventEmitter<any> = new EventEmitter();
    @Input('query') inputQuery: string;
    @Input('lengths') inputLengths: string;
    @Input('page') inputPage: number;

    public pageIndex: number;
    public loading: boolean = false;
    public filterQuery: string;
    public filterLengths: number[];
    public schedulesPage: IPage<any>;
    public scheduleColumns: string[] = ['name', 'created', 'length', 'start', 'flex'];
    public scheduleLengths: IScheduleLength[];

    constructor(private dialog: MatDialog, private scheduleService: ScheduleService) {}

    public ngOnInit(): void {
        this.filterQuery = '';
        this.filterLengths = [];

        if (Helpers.isDefined(this.inputQuery)) {
            this.filterQuery = this.inputQuery;
        }

        if (Helpers.isDefined(this.inputLengths)) {
            this.filterLengths = this.inputLengths.split(',').map(function (item) {
                return parseInt(item, 10);
            });
        }

        if (Helpers.isDefined(this.inputPage)) {
            this.pageIndex = this.inputPage - 1;
        }

        this.getScheduleLengths();
        this.getSchedulesPage(1);
    }

    public getSchedulesPage(page: number): void {
        let search: IScheduleSearch = {
            query: this.filterQuery,
            lengths: this.filterLengths,
            page: page,
        };

        this.loading = true;
        this.scheduleService.getSchedules(search).subscribe({
            next: (data) => {
                this.schedulesPage = data;
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not get schedules page');
            },
        });

        let filterSettings = {
            query: this.filterQuery,
            lengths: this.filterLengths,
            page: page,
        };

        this.filterChanged.emit(filterSettings);
    }

    public getScheduleLengths(): void {
        this.scheduleService.getScheduleLengths().subscribe({
            next: (data) => {
                this.scheduleLengths = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get schedule lengths');
            },
        });
    }

    public pageChanged(event: any): void {
        this.getSchedulesPage(event.pageIndex + 1);
        this.pageIndex = event.pageIndex;
    }
}
