import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';

@Pipe({name: 'nextOfKins'})
export class NextOfKinsPipe implements PipeTransform {
    transform(employee: UntypedFormGroup): any[] {
        let kins: UntypedFormArray = employee.get('nextOfKins') as UntypedFormArray;
        return kins.controls;
    }
}
