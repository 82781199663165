<app-admin-menu></app-admin-menu>

<h1 class="mat-headline-3">Körjournal</h1>

<div class="container">
    <div class="row">
        <div class="col">
            <mat-card class="compact">
                <mat-card-content>
                    <app-driving-log-report (filterChanged)="updateQueryParameters($event)" [companyIds]="companyIds"
                        [carIds]="carIds" [employeeIds]="employeeIds" [from]="from" [to]="to" [page]="page">
                    </app-driving-log-report>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<app-messages #messagesPartial [employeeId]="employeeId"></app-messages>

<app-admin-footer></app-admin-footer>
