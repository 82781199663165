<mat-dialog-content>
    <h2>För dig som aldrig tidigare haft stämpelklocka</h2>
    <p>
        Om du aldrig tidigare haft stämpelklocka måste du registrera dig.
        Det gör du genom att klicka på den blå knappen längst upp till höger "Registrera".
        Väl där skriver du i alla uppgifter. Innan du klickar på "Registrera" ska du se vad
        det står för kod i fältet "Inloggningskod" i den gröna rutan. Detta är den kod du
        använder framöver i stämpelklockan. Efter att du skrivet ner koden eller vet att du
        kommer ihåg den klickar du på "Registrera".
    </p>
    <p>
        Du har nu ett anställningskonto och en kod som du ska använda i stämpelklockan. Välkommen!
    </p>
    <p>
        För att veta hur stämpelklockan fungerar kan du fortsätta läsa på punkt 2.
    </p>

    <h2>Så fungerar stämpelklockan</h2>
    <h3>Regler</h3>
    <p>
        Stämpelklockan används för att registrera din arbetstid. Vi har inte många regler men några få är:
    </p>
    <ul>
        <li>
            <strong>Instämpling</strong> sker när man är redo för arbete. Det betyder inte att man stämplar
            in när man kommer innanför dörrarna utan man stämplar in när man gjort sig klar, bytt om till
            arbetskläder, ätit sin frukost, rökt eller vad man nu ska göra innan arbetstid. Först efter detta
            stämplar man in.
        </li>
        <li>
            <strong>Utstämpling</strong>  sker när man slutar arbeta. Det betyder att man går från arbete
            till närmsta dator för att stämpla ut. Därefter byter man om, äter frukt, röker eller vad man
            nu vill göra EFTER arbetstid.
        </li>
        <li>
            <strong>Övertid</strong> väljs endast om man blivit beordrad övertid. Inte om man själv valt
            att arbeta övertid eller om arbetet dragit över några minuter.
        </li>
        <li>
            <strong>Vid lunch och raster</strong> stämplar vi ut. Det betyder att man går från arbete
            till närmsta dator för att stämpla ut. Efter lunch/rast stämplar man in innan arbetet påbörjas.
        </li>
    </ul>
    <h3>Var finns stämpelklockan?</h3>
    <p>
        Stämpelklockan finns tillgänglig på www.stampelklockan.nu men endast på företagets datorer som
        är anslutna till företagets nät. Det betyder att du måste stämpla in via någon dator på företaget.
    </p>
    <h3>Logga in/ut</h3>
    <p>
        För att kunna genomföra en stämpling, se tider eller skicka meddelande behöver du vara inloggad.
        Detta gör du genom att skriva i din kod i fältet och klicka på enter. Nu ska du vara inloggad.
        Om du är inaktiv i mer än 30 sekunder loggas du ut automatiskt. Annars är det viktigt att du själv
        loggar ut när du är klar med din stämpling. Detta gör du längst upp i det högra hörnet.
    </p>
    <h3>Min sida</h3>
    <p>
        När du loggat in kommer du till Min sida. Det är på Min sida som du gör alla stämplingarna, ser
        summering av tider, ändrar dina uppgifter och kan skicka meddelanden till Sara Erixon som är Ekonomichef
        och sköter lönerna och till Jonas Ahlgren som är VD. När du loggar in är det viktigt att du kontrollerar
        om du fått något meddelande.
    </p>
    <h3>Stämpla in</h3>
    <p>
        När du ska stämpla in loggar du in med din kod. Inne på Mina sidor klickar du sedan på den gröna knappen
        med texten "IN". När du gjort detta registreras din tid och knappen blir röd och nu med texten "UT".
        Kontrollera att din tid registrerades.
    </p>
    <h3>Stämpla ut</h3>
    <p>
        När du ska stämpla ut loggar du in med din kod. Inne på Mina sidor klickar du sedan på den röda knappen
        med texten "UT". När du gjort detta registreras din tid och knappen blir grön och nu med texten "IN".
    </p>
    <h3>Stämpla ut övertid</h3>
    <p>
        Om du arbetar mer än 8 timmar så kommer du vid utstämplingen få frågan om det var övertid. För att din tid
        utöver 8 timmar ska gälla som övertid ska du blivit beordrad detta. Vid utstämplingen väljer du vem som beordrade
        övertiden och denna person kommer då få verifiera detta. Är det inte beordrad övertid väljer du inget namn utan
        klickar att det inte var beordrad. När något av stegen ovan är avklarat registreras din tid och knappen blir grön
        och nu med texten "IN".
    </p>
    <h3>Stämplat fel/missat att stämpla</h3>
    <p>
        Om du stämplar fel eller missa att stämpla ska du skicka ett meddelande till Sara Erixon så får hon justera detta.
        Skriv tydligt vad som har hänt och vad som ska vara rätt.
    </p>
    <h3>Meddelande</h3>
    <p>
        Har du varit borta utan planerad ledighet exempelvis sjuk så måste du meddela detta till Sara. Använd därför
        meddelandefunktionen i Stämpelklockan för att göra det. Skriv vilka datum det gäller samt varför du var borta.
    </p>
    <h3>Meddelande efter frånvaro</h3>
    <p>
        Har du varit borta utan planerad ledighet exempelvis sjuk så måste du meddela detta till Sara. Använd därför
        meddelandefunktionen i Stämpelklockan för att göra det. Skriv vilka datum det gäller samt varför du var borta.
    </p>
    <h3>Frågor?</h3>
    <p>
        Har du frågor om Stämpelklockan ska du vända dig till din närmsta chef som får ta dem vidare.
    </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">OK</button>
</mat-dialog-actions>
