import { Component, OnInit, ElementRef, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Helpers } from '@app/helpers/helpers.class';
import { formatDate } from '@angular/common';

// Error handling
import * as Sentry from '@sentry/browser';

// Services
import { EmployeeService } from '@app/services/employee.service';
import { AuthService } from '@app/services/auth.service';
import { VersionCheckService } from '@app/services/version-check.service';

// Dialogs
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';
import { HelpDialog } from '@app/shared/dialogs/help/help.dialog';
import { EmployeeLoginPasswordPromptDialogComponent } from '@app/shared/dialogs/employee-login-password-prompt-dialog/employee-login-password-prompt-dialog.component';
import { WhistleblowerInfoDialogComponent } from '@app/shared/dialogs/whistleblower-info-dialog/whistleblower-info-dialog.component';

@Component({
    selector: 'app-punch-login-page',
    templateUrl: './punch-login.component.html',
    styleUrls: ['./punch-login.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PunchLoginComponent implements OnInit, OnDestroy {
    @ViewChild('loginCode') loginInput: ElementRef;

    loading: boolean = false;
    showActiveEmployees: boolean = false;
    activeEmployees: any[] = [];
    activeEmployeesIntervalHandle: any;
    currentTime: string = '';
    currentDay: string = '';
    currentDateTime: Date;

    constructor(
        private employeeService: EmployeeService,
        private authService: AuthService,
        public dialog: MatDialog,
        private router: Router,
        private versionCheckService: VersionCheckService
    ) {}

    ngOnInit(): void {
        this.updateClock();
        setInterval(() => {
            this.updateClock();
        }, 500);

        this.versionCheckService.initVersionCheck();
    }

    ngOnDestroy(): void {
        this.versionCheckService.dispose();
    }

    toggleActiveEmployees(): void {
        this.showActiveEmployees = !this.showActiveEmployees;

        if (typeof this.activeEmployeesIntervalHandle !== 'undefined') {
            clearInterval(this.activeEmployeesIntervalHandle);
        }

        if (this.showActiveEmployees) {
            this.getActiveEmployees();
            this.activeEmployeesIntervalHandle = setInterval(() => {
                this.getActiveEmployees();
            }, 60000);
        }
    }

    getActiveEmployees(): void {
        this.employeeService.getActiveToday().subscribe((data) => {
            this.activeEmployees = data;
        });
    }

    login(code: string, password: string = null): void {
        this.loading = true;
        this.authService.employeeLogin(code, password).subscribe({
            next: (data) => {
                this.router.navigate(['/punch']);
            },
            error: (error) => {
                if (error.status === 401 && error.error === 'PASSWORD_REQUIRED') {
                    const handle = this.dialog.open(EmployeeLoginPasswordPromptDialogComponent, {
                        width: '400px',
                    });

                    handle.afterClosed().subscribe((result) => {
                        if (!result) {
                            this.loading = false;
                            setTimeout(() => {
                                this.focusLogin();
                            }, 100);
                        } else {
                            this.login(code, result);
                        }
                    });
                } else {
                    let title = '',
                        message = '';
                    if (error.status === 401) {
                        title = 'Inloggning misslyckades';
                        message = 'Du har angett en felaktig kod eller får inte logga in från denna plats. Försök igen.';
                    } else {
                        console.error(error);
                        title = 'Oops!';
                        message = 'Något gick fel när du skulle loggas in, försök igen.';
                        Helpers.captureSentryError('Failed to login');
                    }

                    const handle = this.dialog.open(AlertDialog, {
                        width: '400px',
                        data: {
                            title: title,
                            message: message,
                        },
                    });

                    handle.afterClosed().subscribe((val) => {
                        this.loading = false;

                        setTimeout(() => {
                            this.focusLogin();
                        }, 100);
                    });
                }
            },
        });
    }

    focusLogin(): void {
        if (Helpers.isDefined(this.loginInput)) {
            this.loginInput.nativeElement.focus();
        }
    }

    getPunchDate(dateTime: string) {
        var now = new Date();

        if (formatDate(now, 'd/M', 'se-SE') == formatDate(dateTime, 'd/M', 'se-SE')) {
            return formatDate(dateTime, 'HH:mm', 'se-SE');
        }

        return formatDate(dateTime, 'd/M HH:mm', 'se-SE');
    }

    updateClock(): void {
        this.currentDateTime = new Date();
        this.currentDateTime.setSeconds(this.currentDateTime.getSeconds() - 10);
        this.currentDay =
            Helpers.getDayName(this.currentDateTime.getDay()) +
            ' den ' +
            this.currentDateTime.getDate().toString() +
            ' ' +
            Helpers.getMonthName(this.currentDateTime.getMonth());
        this.currentTime = formatDate(this.currentDateTime, 'HH:mm:ss', 'se-SE');
    }

    showHelp(): void {
        const handle = this.dialog.open(HelpDialog, {
            width: '1000px',
        });

        handle.afterClosed().subscribe((val) => {
            setTimeout(() => {
                this.focusLogin();
            }, 100);
        });
    }

    showWhistleblowerInfo(): void {
        const handle = this.dialog.open(WhistleblowerInfoDialogComponent, {
            width: '1000px',
        });

        handle.afterClosed().subscribe((val) => {
            setTimeout(() => {
                this.focusLogin();
            }, 100);
        });
    }
}
