<mat-dialog-content>
    <h2>Visselblåsning</h2>
    <h3>Varför visselblåsa?</h3>
    <p>
        På Shelfless strävar vi efter att ha en öppen och transparent arbetsplats, där missförhållanden inte ska förekomma. Genom
        att erhålla er möjligheten att visselblåsa upprätthåller vi det tillsammans. Vi använder Visslans visselblåsarkanal som
        säkerställer din anonymitet och hög säkerhet och som du når genom att klicka här:
        <a href="https://shelfless.visslan-report.se/" target="_blank">https://shelfless.visslan-report.se/</a>.
    </p>
    <h3>Vem kan rapportera?</h3>
    <p>
        Du kan visselblåsa om du är arbetstagare, volontär, praktikant, verksam aktieägare, person som annars står till förfogande
        för arbete under vår kontroll och ledning eller ingår i vår förvaltnings-, ledings- eller tillsynsorgan.
    </p>
    <h3>Vad kan rapporteras?</h3>
    <p>
        Vi uppmanar dig att visselblåsa vid misstanke om ett missförhållande inom företaget med allmänintresse av att det kommer
        fram. Är du osäker uppmuntrar vi dig till att läsa mer i vår visselblåsarpolicy. Du behöver inte bevis för din misstanke,
        men alla rapporteringar måste lämnas i god tro. Vi undanber dig också att rapportera problem som handlar om missnöjen inom
        arbetsplatsen eller dylikt, och refererar dig i stället till gällande rutiner för sådana frågor.
    </p>
    <h3>Hur hanteras rapporteringen?</h3>
    <p>
        Rapporteringen hanteras konfidentiellt. Samtliga personuppgifter utan relevans kommer att raderas, och ärendet sparas
        endast så länge det är nödvändigt.
    </p>
    <h3>Har du fler frågor?</h3>
    <p>
        Om du vill veta mer om Shelfless hantering av visselblåsningsärenden kan du läsa den fullständiga visselblåsarpolicyn här:
        <a href="https://stampelklockanprodsa.blob.core.windows.net/documents/visselblåsarpolicy.pdf" target="_blank"
            >Visselblåsarpolicy.pdf</a
        >. En förenklad variant finns att tillgå här:
        <a href="https://stampelklockanprodsa.blob.core.windows.net/documents/förenklad-visselblåsarpolicy.pdf" target="_blank">
            Förenklad visselblåsarpolicy.pdf
        </a>
    </p>
    <img class="flowchart" src="/assets/images/flowchart-whistleblower.png" alt="Flödesschema" />
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">Stäng</button>
</mat-dialog-actions>
