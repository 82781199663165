import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { remove, some } from 'lodash-es';
import { Helpers } from '@app/helpers/helpers.class';
import { EmployeeService } from '@app/services/employee.service';
import { CostPoolService } from '@app/services/cost-pool.service';
import { PunchService } from '@app/services/punch.service';
import { AuthService } from '@app/services/auth.service';
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';
import { ConfirmDialog } from '@app/shared/dialogs/confirm/confirm.dialog';
import { IPage } from '@app/interfaces/page.interface';
import { IEmployeePunchCostPool } from '@app/interfaces/employee-punch-cost-pool.interface';
import { IPunchPair } from '@app/interfaces/punch-pair.interface';
import { IPunchDayPair } from '@app/interfaces/punch-day-pair.interface';
import { ICostPool } from '@app/interfaces/cost-pool.interface';
import { IPunchCostPool } from '@app/interfaces/punch-cost-pool.interface';
import { IEmployeeCostPoolHandledBy } from '@app/interfaces/employee-cost-pool-handled-by.interface';

@Component({
    selector: 'app-punches-cost-pool',
    templateUrl: './punches-cost-pool.partial.html',
    styleUrls: ['./punches-cost-pool.partial.scss'],
    animations: [
        trigger('toggle', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class PunchesCostPoolPartial implements OnInit {
    @Output() filterChanged: EventEmitter<any> = new EventEmitter();
    @Input('mode') inputMode: number;
    @Input('status') inputStatus: number;
    @Input('day') inputDay: string;
    @Input('query') inputQuery: string;
    @Input('page') inputPage: number;

    employeeId: number;
    loading: boolean = false;
    pageIndex: number;
    employeesPage: IPage<any>;
    employeeColumns: string[] = ['checkbox', 'name', 'company', 'department', 'daysCount', 'expand'];
    filterEmployeeId: number;
    filterStatus: number;
    filterDay: string;
    filterQuery: string;
    expandedEmployee: any;
    expandedPunchDay: any;
    selection: SelectionModel<IPunchPair> = new SelectionModel<IPunchPair>(true);
    dataSource: MatTableDataSource<IEmployeePunchCostPool>;
    costPools: ICostPool[];
    visibleEmployeeIds: number[] = [];

    constructor(
        private dialog: MatDialog,
        private employeeService: EmployeeService,
        private costPoolService: CostPoolService,
        private punchService: PunchService,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
        this.filterEmployeeId = this.employeeId;
        this.filterStatus = 1;
        this.filterQuery = '';
        this.filterDay = Helpers.toShortDateString(new Date());

        if (Helpers.isDefined(this.inputMode)) {
            this.filterEmployeeId = this.inputMode == 0 ? 0 : this.employeeId;
        }

        if (Helpers.isDefined(this.inputStatus)) {
            this.filterStatus = this.inputStatus;
        }

        if (Helpers.isDefined(this.inputDay)) {
            this.filterDay = this.inputDay;
        }

        if (Helpers.isDefined(this.inputQuery)) {
            this.filterQuery = this.inputQuery;
        }

        if (Helpers.isDefined(this.inputPage)) {
            this.pageIndex = this.inputPage - 1;
        }

        this.getCostPools();
        this.getEmployeesPage(1);
    }

    pageChanged(event: any): void {
        this.getEmployeesPage(event.pageIndex + 1);
        this.pageIndex = event.pageIndex;
    }

    toggleEmployeeRow(employee: any): void {
        if (this.expandedEmployee == employee) {
            this.expandedEmployee = null;
            this.expandedPunchDay = null;
            setTimeout(
                (e) => {
                    remove(this.visibleEmployeeIds, (employeeId) => {
                        return employeeId === e.employeeId;
                    });
                },
                1,
                employee
            );
        } else {
            this.visibleEmployeeIds.push(employee.employeeId);
            setTimeout(
                (e) => {
                    this.expandedEmployee = e;
                },
                1,
                employee
            );
        }
    }

    isAllSelectedEmployee(employee: IEmployeePunchCostPool): boolean {
        let numSelected = 0;
        let numRows = 0;

        for (let punchDay of employee.uncategorizedPunches) {
            for (let punch of punchDay.punchPairs) {
                numRows++;
                if (this.selection.isSelected(punch)) {
                    numSelected++;
                }
            }
        }

        return numSelected === numRows;
    }

    isAnySelectedEmployee(employee: IEmployeePunchCostPool): boolean {
        for (let punchDay of employee.uncategorizedPunches) {
            for (let punch of punchDay.punchPairs) {
                if (this.selection.isSelected(punch)) {
                    return true;
                }
            }
        }
    }

    masterToggleEmployee(employee: IEmployeePunchCostPool): void {
        if (this.isAllSelectedEmployee(employee)) {
            for (let punchDay of employee.uncategorizedPunches) {
                for (let punch of punchDay.punchPairs) {
                    this.selection.deselect(punch);
                }
            }
        } else {
            for (let punchDay of employee.uncategorizedPunches) {
                for (let punch of punchDay.punchPairs) {
                    this.selection.select(punch);
                }
            }
        }
    }

    isAllSelectedPunchDay(punchDay: IPunchDayPair): boolean {
        let numSelected = 0;
        let numRows = 0;

        for (let punch of punchDay.punchPairs) {
            numRows++;
            if (this.selection.isSelected(punch)) {
                numSelected++;
            }
        }

        return numSelected === numRows;
    }

    isAnySelectedPunchDay(punchDay: IPunchDayPair): boolean {
        for (let punch of punchDay.punchPairs) {
            if (this.selection.isSelected(punch)) {
                return true;
            }
        }
    }

    masterTogglePunchDay(punchDay: IPunchDayPair): void {
        if (this.isAllSelectedPunchDay(punchDay)) {
            for (let punch of punchDay.punchPairs) {
                this.selection.deselect(punch);
            }
        } else {
            for (let punch of punchDay.punchPairs) {
                this.selection.select(punch);
            }
        }
    }

    isAllSelected() {
        let numSelected = 0;
        let numRows = 0;

        for (let employee of this.dataSource.data) {
            for (let punchDay of employee.uncategorizedPunches) {
                for (let punch of punchDay.punchPairs) {
                    numRows++;
                    if (this.selection.isSelected(punch)) {
                        numSelected++;
                    }
                }
            }
        }

        return numSelected === numRows;
    }

    masterToggle() {
        if (this.isAllSelected()) {
            for (let employee of this.dataSource.data) {
                for (let punchDay of employee.uncategorizedPunches) {
                    for (let punch of punchDay.punchPairs) {
                        this.selection.deselect(punch);
                    }
                }
            }
        } else {
            for (let employee of this.dataSource.data) {
                for (let punchDay of employee.uncategorizedPunches) {
                    for (let punch of punchDay.punchPairs) {
                        this.selection.select(punch);
                    }
                }
            }
        }
    }

    getEmployeesPage(page: number): void {
        this.loading = true;
        let day = Helpers.toShortDateString(this.filterDay);
        this.employeeService
            .getPunchCostPoolEmployees(this.filterEmployeeId, this.filterStatus, day, this.filterQuery, page)
            .subscribe({
                next: (data) => {
                    for (let i = 0; i < data.items.length; i++) {
                        data.items[i].index = i;
                        data.items[i].punchDayColumns = this.getPunchDayColumns(i);
                        for (let x = 0; x < data.items[i].uncategorizedPunches.length; x++) {
                            data.items[i].uncategorizedPunches[x].index = x;
                            data.items[i].uncategorizedPunches[x].punchColumns = this.getPunchColumns(i, x);
                        }
                    }
                    console.log(data);
                    this.employeesPage = data;
                    this.dataSource = new MatTableDataSource<IEmployeePunchCostPool>(this.employeesPage.items);
                    this.selection.clear();
                    this.loading = false;
                },
                error: (error) => {
                    this.loading = false;
                    console.error(error);
                    Helpers.captureSentryError('Could not get employees page');
                },
            });

        let filterSettings = {
            mode: this.filterEmployeeId == 0 ? 0 : 1,
            status: this.filterStatus,
            day: day,
            query: this.filterQuery,
            page: page,
        };

        this.filterChanged.emit(filterSettings);
    }

    getCostPools(): void {
        this.costPoolService.getParents().subscribe({
            next: (data) => {
                this.costPools = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get cost pools');
            },
        });
    }

    unknown(): void {
        if (this.selection.isEmpty()) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Inga valda',
                    message: 'Du har inte valt några anställda',
                },
            });
            return;
        }

        let handle = this.dialog.open(ConfirmDialog, {
            width: '400px',
            data: {
                title: 'Fortsätta?',
                message:
                    'De anställda du valt stämplingar för nedan kommer att kopplas bort från dig, ' +
                    'är du säker på att du vill fortsätta?',
            },
        });

        handle.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.loading = true;
                let employees: IEmployeeCostPoolHandledBy[] = [];

                for (let punchPair of this.selection.selected) {
                    let exists = some(employees, function (value: IEmployeeCostPoolHandledBy) {
                        return value.employeeId == punchPair.employeeId;
                    });

                    if (!exists) {
                        employees.push({
                            employeeId: punchPair.employeeId,
                            handledByEmployeeId: 0,
                        });
                    }
                }

                this.employeeService.setCostPoolHandledBy(employees).subscribe({
                    next: (data) => {
                        this.getEmployeesPage(1);
                    },
                    error: (error) => {
                        this.loading = false;
                        console.error(error);
                        Helpers.captureSentryError('Could not set cost pool handled by');
                    },
                });
            }
        });
    }

    setCostPool(costPool: ICostPool): void {
        console.log(this.selection.selected);
        if (this.selection.isEmpty()) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Inga valda',
                    message: 'Du har inte valt några stämplingar att koppla',
                },
            });
            return;
        }

        let message =
            'Är du säker på att du vill koppla nedan valda (' +
            this.selection.selected.length +
            ') perioder till "' +
            costPool.name +
            '"?';

        let containsDone = some(this.selection.selected, function (value: IPunchPair) {
            return Helpers.isDefined(value.costPool);
        });

        if (containsDone) {
            message =
                '<strong>OBS!</strong> Dessa stämplingar är redan kopplade till ett kostnadsställe, om du väljer att fortsätta kommer detta ' +
                'att skrivas över med det du valt.<br /><br />' +
                message;
        }

        let handle = this.dialog.open(ConfirmDialog, {
            width: '400px',
            data: {
                title: 'Fortsätta?',
                message: message,
            },
        });

        handle.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.loading = true;
                let punches: IPunchCostPool[] = [];

                for (let punchPair of this.selection.selected) {
                    punches.push({
                        punchId: punchPair.inPunchId,
                        poolId: costPool.poolId,
                    });
                    punches.push({
                        punchId: punchPair.outPunchId,
                        poolId: costPool.poolId,
                    });
                }

                this.punchService.setPunchCostPools(punches).subscribe({
                    next: (data) => {
                        this.getEmployeesPage(1);
                    },
                    error: (error) => {
                        this.loading = false;
                        console.error(error);
                        Helpers.captureSentryError('Could not set punch cost pools');
                    },
                });
            }
        });
    }

    getPunchDayColumns(i: number): any[] {
        return ['checkbox_' + i, 'day_' + i, 'spacer_' + i, 'totalTime_' + i, 'punchesCount_' + i, 'expand_' + i];
    }

    getPunchColumns(i: number, x: number): any[] {
        return [
            'checkbox_' + i + '_' + x,
            'from_' + i + '_' + x,
            'to_' + i + '_' + x,
            'workedTime_' + i + '_' + x,
            'costPool_' + i + '_' + x,
        ];
    }
}
