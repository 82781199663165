import { Component, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Helpers } from '@app/helpers/helpers.class';
import { AuthService } from '@app/services/auth.service';
import { AlertDialog } from '../alert/alert.dialog';

@Component({
    selector: 'app-employee-login-reset-password-dialog',
    templateUrl: './employee-login-reset-password-dialog.component.html',
    styleUrls: ['./employee-login-reset-password-dialog.component.scss'],
})
export class EmployeeLoginResetPasswordDialogComponent {
    code: string;
    loading: boolean = false;
    passwordExpired: boolean = false;

    constructor(
        private dialogRef: MatDialogRef<EmployeeLoginResetPasswordDialogComponent>,
        private authService: AuthService,
        private dialog: MatDialog,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) { passwordExpired }: any
    ) {
        this.passwordExpired = passwordExpired;

        if (this.passwordExpired) {
            this.dialogRef.disableClose = true;
        }
    }

    close(): void {
        this.dialogRef.close();
    }

    reset(): void {
        if (this.code.length < 4) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Felaktig kod',
                    message: 'Koden du angivit är inte giltig',
                },
            });
            return;
        }

        this.loading = true;
        this.authService.requestResetEmployeePassword(this.code).subscribe({
            next: () => {
                this.loading = false;
                this.dialog
                    .open(AlertDialog, {
                        width: '400px',
                        data: {
                            title: 'Återställning påbörjad',
                            message:
                                'Återställningsprocessen är påbörjad, ett mail har skickats ' +
                                'till den e-postadress du registrerat dig med',
                        },
                    })
                    .afterClosed()
                    .subscribe(() => {
                        this.close();

                        if (this.passwordExpired) {
                            this.authService.logoutEmployee();
                            this.router.navigate([`/login`]);
                        }
                    });
            },
            error: (error) => {
                this.loading = false;
                console.error(error);
                Helpers.captureSentryError('Could not request reset password');

                let title: string = 'Fel';
                let message: string = 'Något gick fel när lösenordet skulle återställas';
                let errors: string[];

                if (error.status === 400 && error.error.errors) {
                    message = 'Följande fel uppstod:';
                    errors = error.error.errors;
                }

                this.dialog.open(AlertDialog, {
                    width: '400px',
                    data: {
                        title: title,
                        message: message,
                        list: errors,
                    },
                });
            },
        });
    }

    logout(): void {
        this.authService.logoutEmployee();
        this.router.navigate([`/login`]);
        this.close();
    }
}
