import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IApplicationClientAccessRequest } from '@app/interfaces/application-client-access-request.interface';
import { IApplicationClientAuthorizedResponse } from '@app/interfaces/application-client-authorized-response.interface';
import { IApplicationClientSearchFilter } from '@app/interfaces/application-client-search-filter.interface';
import { IApplicationClient } from '@app/interfaces/application-client.interface';
import { IPage } from '@app/interfaces/page.interface';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Injectable({ providedIn: 'root' })
export class ApplicationClientService {
    constructor(private http: HttpClient, private router: Router) {}

    public getClientId(): string {
        let clientId = localStorage.getItem('clientId');

        if (!clientId || !clientId.length) {
            clientId = uuidv4();
            localStorage.setItem('clientId', clientId);
        }

        return clientId;
    }

    /**
     * This method validates a uuid from local storage with the server
     * to determine if the client should have access. This is not meant as
     * a bulletproof way to block clients but rather make it difficult to
     * access the app from places we don't want to.
     */
    public isClientAuthorized(): Observable<IApplicationClientAuthorizedResponse> {
        const clientId = this.getClientId();
        const requestedUrl = this.router.url;

        return this.http.get<IApplicationClientAuthorizedResponse>(
            environment.apiUrl + `/application-clients/client-authorized`,
            {
                params: new HttpParams().set('clientId', clientId).set('requestedUrl', requestedUrl),
            }
        );
    }

    public createAccessRequest(request: IApplicationClientAccessRequest): Observable<IApplicationClientAccessRequest> {
        return this.http.post<IApplicationClientAccessRequest>(
            environment.apiUrl + `/application-clients/access-requests`,
            request
        );
    }

    public searchClients(filter: IApplicationClientSearchFilter): Observable<IPage<IApplicationClient>> {
        return this.http.post<IPage<IApplicationClient>>(environment.apiUrl + `/application-clients/search`, filter);
    }

    public authorizeAccessRequest(requestId: number, authorized: boolean): Observable<IApplicationClient> {
        return this.http.patch<IApplicationClient>(
            environment.apiUrl + `/application-clients/${requestId}/authorize`,
            {},
            {
                params: new HttpParams().set('authorized', authorized),
            }
        );
    }

    public setClientCompany(requestId: number, companyId: number): Observable<never> {
        return this.http.patch<never>(
            environment.apiUrl + `/application-clients/${requestId}/company`,
            {},
            {
                params: new HttpParams().set('companyId', companyId),
            }
        );
    }
}
