<div class="container-fluid filter">
    <div class="row" *ngIf="!externalEmployeeId">
        <div class="col">
            <mat-form-field class="example-full-width">
                <mat-label>Anställd</mat-label>
                <input
                    type="text"
                    aria-label="Employee"
                    matInput
                    [formControl]="employee"
                    [matAutocomplete]="employeeAutoComplete" />
                <mat-autocomplete
                    autoActiveFirstOption
                    #employeeAutoComplete="matAutocomplete"
                    [displayWith]="displaySelectedEmployee"
                    (optionSelected)="employeeSelected($event)">
                    <mat-option *ngFor="let employee of filteredEmployees | async" [value]="employee">
                        {{employee.lastName}}, {{employee.firstName}} ({{employee.companyShort}})
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field (click)="fromDatePicker.open()">
                <mat-label>Från</mat-label>
                <input matInput [matDatepicker]="fromDatePicker" [(ngModel)]="from" readonly />
                <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #fromDatePicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col" (click)="toDatePicker.open()">
            <mat-form-field>
                <mat-label>Till</mat-label>
                <input matInput [matDatepicker]="toDatePicker" [(ngModel)]="to" readonly />
                <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #toDatePicker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col" align="end">
            <button mat-raised-button color="primary" (click)="getAbsence()">Visa</button>
        </div>
    </div>
    <div class="row divider">
        <div class="col">
            <mat-divider></mat-divider>
        </div>
    </div>
</div>
<div class="wrapper" *ngIf="absence && absence.length > 0 && (hasSearched || useExternalData)">
    <table mat-table [dataSource]="absence">
        <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef class="time">Tid</th>
            <td mat-cell *matCellDef="let absenceRow" class="time">
                {{absenceRow.fromDate | shortDate}}, {{absenceRow.fromDate | shortTime}} - {{absenceRow.toDate | shortTime}}
            </td>
        </ng-container>
        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef class="type">Typ</th>
            <td mat-cell *matCellDef="let absenceRow" class="type">{{absenceRow.type.absenceName}}</td>
        </ng-container>
        <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef class="comment">Kommentar</th>
            <td mat-cell *matCellDef="let absenceRow" class="comment">{{absenceRow.comment}}</td>
        </ng-container>
        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef class="delete">&nbsp;</th>
            <td mat-cell *matCellDef="let absenceRow" class="delete">
                <mat-icon (click)="deleteAbsence(absenceRow)">delete_outline</mat-icon>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="absenceColumns"></tr>
        <tr mat-row *matRowDef="let absenceRow; columns: absenceColumns;"></tr>
    </table>
</div>

<div class="no-absence" *ngIf="(absence | isEmpty) && !loading  && (hasSearched || useExternalData)">Ingen frånvaro att visa</div>

<div class="loading-wrapper" *ngIf="loading">
    <mat-spinner diameter="40"></mat-spinner>
</div>
