<ng-container *ngIf="menuItem.subItems.length === 0">
    <button mat-button *ngIf="!isSubItem" [routerLink]="menuItem.route">
        <mat-icon *ngIf="menuItem.iconBefore && menuItem.iconBefore.length > 0">{{menuItem.iconBefore}}</mat-icon>
        {{menuItem.text}}
        <mat-icon *ngIf="menuItem.iconAfter && menuItem.iconAfter.length > 0">{{menuItem.iconAfter}}</mat-icon>
    </button>
    <button mat-menu-item *ngIf="isSubItem" [routerLink]="menuItem.route">
        <mat-icon *ngIf="menuItem.iconBefore && menuItem.iconBefore.length > 0">{{menuItem.iconBefore}}</mat-icon>
        {{menuItem.text}}
        <mat-icon *ngIf="menuItem.iconAfter && menuItem.iconAfter.length > 0">{{menuItem.iconAfter}}</mat-icon>
    </button>
</ng-container>
<ng-container *ngIf="menuItem.subItems.length > 0">
    <ng-container *ngIf="!isSubItem">
        <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon *ngIf="menuItem.iconBefore && menuItem.iconBefore.length > 0">{{menuItem.iconBefore}}</mat-icon>
            {{menuItem.text}}
            <mat-icon *ngIf="menuItem.iconAfter && menuItem.iconAfter.length > 0">{{menuItem.iconAfter}}</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <app-menu-item *ngFor="let subItem of menuItem.subItems" [menuItem]="subItem" [isSubItem]="true"></app-menu-item>
        </mat-menu>
    </ng-container>
    <ng-container *ngIf="isSubItem">
        <button mat-menu-item [matMenuTriggerFor]="menu">
            <mat-icon *ngIf="menuItem.iconBefore && menuItem.iconBefore.length > 0">{{menuItem.iconBefore}}</mat-icon>
            {{menuItem.text}}
            <mat-icon *ngIf="menuItem.iconAfter && menuItem.iconAfter.length > 0">{{menuItem.iconAfter}}</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <app-menu-item *ngFor="let subItem of menuItem.subItems" [menuItem]="subItem" [isSubItem]="true"></app-menu-item>
        </mat-menu>
    </ng-container>
</ng-container>
