import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray, AbstractControl, ValidatorFn } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { find } from 'lodash-es';
import { Observable } from 'rxjs';
import { Helpers } from '@app/helpers/helpers.class';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/browser';
import { EmployeeService } from '@app/services/employee.service';
import { CompanyService } from '@app/services/company.service';
import { EmploymentTypeService } from '@app/services/employment-type.service';
import { CompanyDepartmentService } from '@app/services/company-department.service';
import { AuthService } from '@app/services/auth.service';
import { PaymentTypeService } from '@app/services/payment-type.service';
import { UnionContractService } from '@app/services/union-contract.service';
import { EmployeeCategoryService } from '@app/services/employee-category.service';
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';
import { ICompanyDepartment } from '@app/interfaces/company-department.interface';
import { EmployeeAbsencePartial } from '@app/components/partials/employee-absence/employee-absence.partial';
import { EmployeeTravelCompensationsPartial } from '@app/components/partials/employee-travel-compensations/employee-travel-compensations.partial';
import { ICompany } from '@app/interfaces/company.interface';
import { IEmployeeShort } from '@app/interfaces/employee-short.interface';
import { IEmploymentType } from '@app/interfaces/employment-type.interface';
import { INextOfKin } from '@app/interfaces/next-of-kin.interface';
import { IEmployeeFullExtended } from '@app/interfaces/employee-full-extended.interface';
import { IPaymentType } from '@app/interfaces/payment-type.interface';
import { IUnionContract } from '@app/interfaces/union-contract.interface';
import { IEmployeeCategory } from '@app/interfaces/employee-category.interface';
import { ConfirmDialog } from '@app/shared/dialogs/confirm/confirm.dialog';
import {IStaffingCompany} from "@app/interfaces/StaffingCompany.interface";
import {StaffingCompanyService} from "@app/services/StaffingCompany.service";

@Component({
    selector: 'app-employee-form',
    templateUrl: './employee-form.partial.html',
    styleUrls: ['./employee-form.partial.scss'],
})
export class EmployeeFormPartial implements OnInit {
    @Input() employeeId: number;
    @Input('companyId') inputCompanyId: number;
    @Input('employmentTypeId') inputEmploymentTypeId: number;
    @Input() cancelRoute: string;
    @Output() saved = new EventEmitter<IEmployeeShort>();

    @ViewChild('absencePartial', { static: true })
    absencePartial: EmployeeAbsencePartial;
    @ViewChild('travelCompensationsPartial', { static: true })
    travelCompensationsPartial: EmployeeTravelCompensationsPartial;

    adminMode: boolean;
    employee: IEmployeeFullExtended;
    companies: ICompany[];
    departments: ICompanyDepartment[];
    employmentTypes: IEmploymentType[];
    employeeCategories: IEmployeeCategory[];
    paymentTypes: IPaymentType[];
    unionContracts: IUnionContract[];
    staffingCompanies: IStaffingCompany[];
    employeeForm: UntypedFormGroup;
    nextOfKins: UntypedFormArray;
    login: string = '-';
    loading: boolean = false;
    hasListPunchesClaim: boolean = false;
    hasListEmploymentContractsClaim: boolean = false;
    hasViewActiveScheduleClaim: boolean = false;
    hasListAbsenceClaim: boolean = false;
    hasListTravelCompensationsClaim: boolean = false;
    hasViewOpenWorkPeriodClaim: boolean = false;

    constructor(
        private employeeService: EmployeeService,
        private companyService: CompanyService,
        private employmentTypeService: EmploymentTypeService,
        public dialog: MatDialog,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private authService: AuthService,
        private departmentService: CompanyDepartmentService,
        private paymentTypeService: PaymentTypeService,
        private unionContractService: UnionContractService,
        private employeeCategoryService: EmployeeCategoryService,
        private staffingCompanyService: StaffingCompanyService
    ) {}

    ngOnInit(): void {
        this.hasListPunchesClaim = this.authService.hasClaim('ListEmployeePunches');
        this.hasListEmploymentContractsClaim = this.authService.hasClaim('ListEmployeeContracts');
        this.hasViewActiveScheduleClaim = this.authService.hasClaim('ViewEmployeeActiveSchedule');
        this.hasListAbsenceClaim = this.authService.hasClaim('ListEmployeeAbsence');
        this.hasListTravelCompensationsClaim = this.authService.hasClaim('ListEmployeeTravelCompensation');
        this.hasViewOpenWorkPeriodClaim = this.authService.hasClaim('ListActiveWorkPeriods');

        Sentry.withScope((scope) => {
            scope.setTag('file', '/components/partials/employee-form.partial.ts');
        });

        this.getCompanies();
        this.getEmploymentTypes();
        this.getEmployeeCategories();
        this.getDepartments();
        this.getPaymentTypes();
        this.getUnionContracts();
        this.getStaffingCompanies();

        this.employeeForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            identityNumber: ['', [Validators.required, this.isValidIdentityNumber()]],
            email: ['', [Validators.required, Validators.email]],
            workEmail: ['', [Validators.required, Validators.email]],
            mobile: ['', Validators.required],
            phone: '',
            address: ['', Validators.required],
            zip: ['', Validators.required],
            city: ['', Validators.required],
            bankName: '',
            clearingNumber: '',
            bankAccount: '',
            login: ['', Validators.required],
            companyId: [this.inputCompanyId, [Validators.pattern(Helpers.integerRegex), Validators.required]],
            departmentId: [0, [Validators.pattern(Helpers.integerRegex), Validators.required]],
            employmentTypeId: [this.inputEmploymentTypeId, [Validators.pattern(Helpers.integerRegex), Validators.required]],
            paymentTypeId: [1, [Validators.pattern(Helpers.integerRegex), Validators.required]],
            categoryId: [1, [Validators.pattern(Helpers.integerRegex), Validators.required]],
            fortnoxEmployeeId: [0, [Validators.pattern(Helpers.integerRegex), Validators.required]],
            hoursPerDay: [8, [Validators.pattern(Helpers.decimalRegex), Validators.required]],
            daysPerWeek: [5, [Validators.pattern(Helpers.decimalRegex), Validators.required]],
            hourlyWage: [0, [Validators.pattern(Helpers.decimalRegex), Validators.required]],
            unionContractId: [0, [Validators.pattern(Helpers.integerRegex), Validators.required]],
            title: '',
            periodsStart: '',
            reportMilage: false,
            reportCostPool: false,
            employmentContract: false,
            inactive: false,
            isPunching: true,
            punchWarningsAndPeriodsActive: false,
            canOrderOvertime: false,
            hasUnsocialHoursPayment: true,
            allowRemoteAccess: false,
            staffingCompanyId: [0, [Validators.pattern(Helpers.integerRegex), Validators.required]],
            nextOfKins: this.formBuilder.array([this.newKin()]),
        });

        if (this.employeeId && this.employeeId > 0 && this.router.url.indexOf('/admin/') > -1) {
            this.loading = true;
            this.getFullExtendedDetails();
            this.adminMode = true;
        } else if (this.employeeId && this.employeeId > 0) {
            this.loading = true;
            this.getShortDetails();
            this.adminMode = false;
            this.f.login.disable();
        }
    }

    get f() {
        return this.employeeForm.controls;
    }

    isValidIdentityNumber(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            return !Helpers.isValidIdentityNumber(control.value) ? { invalidIdentityNumber: { value: control.value } } : null;
        };
    }

    getShortDetails(): void {
        this.employeeService.getShortDetails(this.employeeId).subscribe({
            next: (data) => {
                this.loading = false;
                this.employee = data as IEmployeeFullExtended;
                this.resetForm();
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get short employee details');
            },
        });
    }

    getFullExtendedDetails(): void {
        this.employeeService.getFullExtendedDetails(this.employeeId).subscribe({
            next: (data) => {
                this.loading = false;
                this.employee = data;
                this.resetForm();
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get full extended employee details');
            },
        });
    }

    resetForm(): void {
        if (!this.employee || this.employeeId === 0) {
            this.employeeForm.reset();
            this.employeeForm.get('companyId').setValue(this.inputCompanyId);
            this.employeeForm.get('employmentTypeId').setValue(this.inputEmploymentTypeId);
            this.employeeForm.get('nextOfKins').setValue(this.formBuilder.array([this.newKin()]));
        } else {
            this.employeeForm.reset();
            this.employeeForm.get('firstName').setValue(this.employee.firstName);
            this.employeeForm.get('lastName').setValue(this.employee.lastName);
            this.employeeForm.get('identityNumber').setValue(this.employee.identityNumber);
            this.employeeForm.get('email').setValue(this.employee.email);
            this.employeeForm.get('workEmail').setValue(this.employee.workEmail);
            this.employeeForm.get('mobile').setValue(this.employee.mobile);
            this.employeeForm.get('phone').setValue(this.employee.phone);
            this.employeeForm.get('address').setValue(this.employee.address);
            this.employeeForm.get('zip').setValue(this.employee.zip);
            this.employeeForm.get('city').setValue(this.employee.city);
            this.employeeForm.get('bankName').setValue(this.employee.bankName);
            this.employeeForm.get('clearingNumber').setValue(this.employee.clearingNumber);
            this.employeeForm.get('bankAccount').setValue(this.employee.bankAccount);
            this.employeeForm.get('login').setValue(this.employee.login);
            this.employeeForm.get('companyId').setValue(this.employee.companyId);
            this.employeeForm.get('employmentTypeId').setValue(this.employee.employmentTypeId);
            this.employeeForm.get('departmentId').setValue(this.employee.departmentId);
            this.employeeForm.get('paymentTypeId').setValue(this.employee.paymentTypeId);
            this.employeeForm.get('categoryId').setValue(this.employee.categoryId);
            this.employeeForm.get('fortnoxEmployeeId').setValue(this.employee.fortnoxEmployeeId);
            this.employeeForm.get('hoursPerDay').setValue(this.employee.hoursPerDay);
            this.employeeForm.get('daysPerWeek').setValue(this.employee.daysPerWeek);
            this.employeeForm.get('hourlyWage').setValue(this.employee.hourlyWage);
            this.employeeForm
                .get('unionContractId')
                .setValue(this.employee.unionContract != null ? this.employee.unionContract.contractId : 0);
            this.employeeForm.get('title').setValue(this.employee.title);
            this.employeeForm.get('periodsStart').setValue(this.employee.periodsStart);
            this.employeeForm.get('reportMilage').setValue(this.employee.reportMilage);
            this.employeeForm.get('reportCostPool').setValue(this.employee.reportCostPool);
            this.employeeForm.get('employmentContract').setValue(this.employee.employmentContract);
            this.employeeForm.get('inactive').setValue(this.employee.inactive);
            this.employeeForm.get('isPunching').setValue(this.employee.isPunching);
            this.employeeForm.get('punchWarningsAndPeriodsActive').setValue(this.employee.punchWarningsAndPeriodsActive);
            this.employeeForm.get('canOrderOvertime').setValue(this.employee.canOrderOvertime);
            this.employeeForm.get('hasUnsocialHoursPayment').setValue(this.employee.hasUnsocialHoursPayment);
            this.employeeForm.get('allowRemoteAccess').setValue(this.employee.allowRemoteAccess);
            this.employeeForm.get('staffingCompanyId').setValue(this.employee.staffingCompany === null ? null : this.employee.staffingCompany.staffingCompanyId);
            this.nextOfKins = this.employeeForm.get('nextOfKins') as UntypedFormArray;

            if (this.f.paymentTypeId.value !== 1) {
                this.f.hourlyWage.disable();
            }

            if (!this.adminMode) {
                this.f.paymentTypeId.clearValidators();
                this.f.categoryId.clearValidators();
                this.f.fortnoxEmployeeId.clearValidators();
                this.f.hoursPerDay.clearValidators();
                this.f.daysPerWeek.clearValidators();
                this.f.hourlyWage.clearValidators();
                this.f.paymentTypeId.updateValueAndValidity();
                this.f.categoryId.updateValueAndValidity();
                this.f.fortnoxEmployeeId.updateValueAndValidity();
                this.f.hoursPerDay.updateValueAndValidity();
                this.f.daysPerWeek.updateValueAndValidity();
                this.f.hourlyWage.updateValueAndValidity();
            }

            while (this.nextOfKins.length > 0) {
                this.nextOfKins.removeAt(0);
            }

            if (this.employee.nextOfKins.length > 0) {
                for (let i = 0; i < this.employee.nextOfKins.length; i++) {
                    let kin = this.employee.nextOfKins[i];
                    this.nextOfKins.push(
                        this.formBuilder.group({
                            firstName: [kin.firstName, Validators.required],
                            lastName: [kin.lastName, Validators.required],
                            address: kin.address,
                            zip: kin.zip,
                            city: kin.city,
                            mobile: kin.mobile,
                            phone: kin.phone,
                            comment: kin.comment,
                        })
                    );
                }
            } else {
                this.addKin();
            }

            this.updateLoginCode();
        }
    }

    newKin(): UntypedFormGroup {
        return this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            address: '',
            zip: '',
            city: '',
            mobile: '',
            phone: '',
            comment: '',
        });
    }

    addKin(): void {
        this.nextOfKins = this.employeeForm.get('nextOfKins') as UntypedFormArray;
        this.nextOfKins.push(this.newKin());
    }

    removeKin(i): void {
        this.nextOfKins = this.employeeForm.get('nextOfKins') as UntypedFormArray;
        this.nextOfKins.removeAt(i);
    }

    getCompanies(): void {
        this.companyService.getAll().subscribe({
            next: (data) => {
                this.companies = data;
                let found = false;

                for (let company of this.companies) {
                    if (company.companyId === this.inputCompanyId) {
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    this.inputCompanyId = 1;
                }
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get companies');
            },
        });
    }

    getDepartments(): void {
        this.departmentService.getAll().subscribe({
            next: (data) => {
                this.departments = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get departments');
            },
        });
    }

    getEmploymentTypes(): void {
        this.employmentTypeService.getAll().subscribe({
            next: (data) => {
                this.employmentTypes = data;
                let found = false;

                for (let employmentType of this.employmentTypes) {
                    if (employmentType.employmentTypeId === this.inputEmploymentTypeId) {
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    this.inputEmploymentTypeId = 4;
                }
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get employment types');
            },
        });
    }

    getEmployeeCategories(): void {
        this.employeeCategoryService.getAll().subscribe({
            next: (data) => {
                this.employeeCategories = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get employee categories');
            },
        });
    }

    getPaymentTypes(): void {
        this.paymentTypeService.getAll().subscribe({
            next: (data) => {
                this.paymentTypes = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get payment types');
            },
        });
    }

    getUnionContracts(): void {
        this.unionContractService.getAll().subscribe({
            next: (data) => {
                this.unionContracts = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get union contracts');
            },
        });
    }

    getStaffingCompanies(): void {
        this.staffingCompanyService.getAll().subscribe({
            next: (data) => {
                this.staffingCompanies = data;
                this.staffingCompanies.push( {
                    isActive: false,
                    orgNumber: "",
                    staffingCompanyId: 0, companyName: 'Inget valt'
                })
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get staffing companies');
            },
        });
    }

    updateLoginCode(): void {
        this.getLoginCode().subscribe();
    }

    getLoginCode(): Observable<any> {
        return new Observable((observer) => {
            if (this.employeeId > 0) {
                this.login = this.employee.login;
                this.employeeForm.get('login').setValue(this.employee.login);
                observer.next();
                observer.complete();
            } else {
                if (
                    this.employeeForm.get('identityNumber').valid &&
                    (!this.adminMode || Helpers.isValidIdentityNumber(this.f.identityNumber.value))
                ) {
                    this.employeeService.getLoginCode(this.employeeForm.get('identityNumber').value).subscribe({
                        next: (data) => {
                            this.login = data.login;
                            this.employeeForm.get('login').setValue(data.login);
                        },
                        error: (error) => {
                            console.error(error);
                            Helpers.captureSentryError('Could not get login code');
                        },
                        complete: () => {
                            observer.next();
                            observer.complete();
                        },
                    });
                } else {
                    observer.next();
                    observer.complete();
                }
            }
        });
    }

    save(): void {
        if (!this.employeeForm.valid || (!this.adminMode && !Helpers.isValidIdentityNumber(this.f.identityNumber.value))) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Fel i formuläret',
                    message: 'Alla fält är inte korrekt ifyllda, korrigera felen och försök igen.',
                },
            });
            for (let i in this.employeeForm.controls) {
                if (!this.employeeForm.controls[i].valid) {
                    console.log(i, this.employeeForm.controls[i].valid);
                    console.log(i, this.employeeForm.controls[i].value);
                }
            }
            Helpers.setFormTouched(this.employeeForm);
        } else {
            this.loading = true;

            let nextOfKins: INextOfKin[] = [];
            this.nextOfKins = this.employeeForm.get('nextOfKins') as UntypedFormArray;
            let selectedUnionContract = null;
            let that = this;

            for (let control of this.nextOfKins.controls) {
                nextOfKins.push({
                    firstName: control.get('firstName').value,
                    lastName: control.get('lastName').value,
                    address: control.get('address').value,
                    zip: control.get('zip').value,
                    city: control.get('city').value,
                    mobile: control.get('mobile').value,
                    phone: control.get('phone').value,
                    comment: control.get('comment').value,
                });
            }

            if (this.f.unionContractId.value != null && this.f.unionContractId.value > 0) {
                selectedUnionContract = find(this.unionContracts, function (c: IUnionContract) {
                    return c.contractId == that.f.unionContractId.value;
                });
            }

            this.employee = {
                employeeId: this.employeeId > 0 ? this.employeeId : 0,
                firstName: this.employeeForm.get('firstName').value,
                lastName: this.employeeForm.get('lastName').value,
                identityNumber: this.employeeForm.get('identityNumber').value,
                email: this.employeeForm.get('email').value,
                workEmail: this.employeeForm.get('workEmail').value,
                mobile: this.employeeForm.get('mobile').value,
                phone: this.employeeForm.get('phone').value,
                address: this.employeeForm.get('address').value,
                zip: this.employeeForm.get('zip').value,
                city: this.employeeForm.get('city').value,
                bankName: this.employeeForm.get('bankName').value,
                clearingNumber: this.employeeForm.get('clearingNumber').value,
                bankAccount: this.employeeForm.get('bankAccount').value,
                login: this.employeeForm.get('login').value,
                companyId: this.employeeForm.get('companyId').value,
                employmentTypeId: this.employeeForm.get('employmentTypeId').value,
                departmentId: this.employeeForm.get('departmentId').value,
                paymentTypeId: this.employeeForm.get('paymentTypeId').value,
                fortnoxEmployeeId: this.employeeForm.get('fortnoxEmployeeId').value,
                hoursPerDay: this.employeeForm.get('hoursPerDay').value,
                daysPerWeek: this.employeeForm.get('daysPerWeek').value,
                hourlyWage: this.employeeForm.get('hourlyWage').value,
                unionContract: selectedUnionContract,
                title: this.employeeForm.get('title').value,
                periodsStart: Helpers.toShortDateString(this.employeeForm.get('periodsStart').value),
                reportMilage: this.employeeForm.get('reportMilage').value,
                reportCostPool: this.employeeForm.get('reportCostPool').value,
                employmentContract: this.employeeForm.get('employmentContract').value,
                inactive: this.employeeForm.get('inactive').value,
                isPunching: this.employeeForm.get('isPunching').value,
                punchWarningsAndPeriodsActive: this.employeeForm.get('punchWarningsAndPeriodsActive').value,
                canOrderOvertime: this.employeeForm.get('canOrderOvertime').value,
                hasUnsocialHoursPayment: this.employeeForm.get('hasUnsocialHoursPayment').value,
                allowRemoteAccess: this.employeeForm.get('allowRemoteAccess').value,
                categoryId: this.employeeForm.get('categoryId').value,
                nextOfKins: nextOfKins,
                staffingCompany: null
            };

            if(this.employeeForm.get('staffingCompanyId').value !== 0) {
                this.employee.staffingCompany = this.staffingCompanies.find(x => x.staffingCompanyId === this.employeeForm.get('staffingCompanyId').value)
            }

            this.getLoginCode().subscribe(() => {
                if (this.employeeId > 0) {
                    if (this.adminMode) {
                        this.employeeService.updateByAdmin(this.employee).subscribe({
                            next: (data) => {
                                this.loading = false;
                                this.resetForm();

                                if (this.saved) {
                                    this.saved.emit(this.employee);
                                }
                            },
                            error: (error) => {
                                console.error(error);
                                this.loading = false;
                                this.showError(error);
                                Helpers.captureSentryError('Could not get login code (2)');
                            },
                        });
                    } else {
                        this.employeeService.update(this.employee).subscribe({
                            next: (data) => {
                                this.loading = false;
                                this.resetForm();

                                if (this.saved) {
                                    this.saved.emit(this.employee);
                                }
                            },
                            error: (error) => {
                                console.error(error);
                                this.loading = false;
                                this.showError(error);
                                Helpers.captureSentryError('Could not update employee');
                            },
                        });
                    }
                } else {
                    this.employeeService.create(this.employee).subscribe({
                        next: (data) => {
                            this.loading = false;
                            this.resetForm();

                            if (this.saved) {
                                this.saved.emit(this.employee);
                            }
                        },
                        error: (error) => {
                            console.error(error);
                            this.loading = false;
                            this.showError(error);
                            Helpers.captureSentryError('Could not create employee');
                        },
                    });
                }
            });
        }
    }

    cancel(): void {
        this.router.navigate([this.cancelRoute]);
    }

    showError(error: any): void {
        let title: string = 'Fel';
        let message: string = 'Något gick fel när dina uppgifter skulle sparas';
        let errors: string[];

        if (error.status === 400 && error.error.errors) {
            title = 'Felaktiga uppgifter';
            message = 'Följande är fel och måste korrigeras:';
            errors = error.error.errors;
        }

        this.dialog.open(AlertDialog, {
            width: '400px',
            data: {
                title: title,
                message: message,
                list: errors,
            },
        });
    }

    paymentTypeChanged(): void {
        if (this.f.paymentTypeId.value !== 1) {
            this.f.hourlyWage.disable();
        } else {
            this.f.hourlyWage.enable();
        }
    }

    sendToDreamPack(): void {
        this.loading = true;
        this.employeeService.sendToDreamPack(this.employeeId).subscribe({
            next: (data) => {
                this.loading = false;
                this.dialog.open(AlertDialog, {
                    width: '400px',
                    data: {
                        title: 'Lyckades',
                        message: 'Uppgifter om den anställde skickades till DreamPack',
                    },
                });
            },
            error: (error) => {
                this.loading = false;
                this.dialog.open(AlertDialog, {
                    width: '400px',
                    data: {
                        title: 'Misslyckades',
                        message: 'Kunde inte skicka den anställde till DreamPack',
                    },
                });
                console.error(error);
                Helpers.captureSentryError('Could not send to DreamPack');
            },
        });
    }

    resetPassword(): void {
        this.loading = true;
        this.dialog
            .open(ConfirmDialog, {
                width: '500px',
                data: {
                    title: 'Återställ lösenord',
                    message: 'Är du säker på att du vill återställa lösenordet?',
                },
            })
            .afterClosed()
            .subscribe((result: boolean) => {
                if (result) {
                    this.authService.requestResetEmployeePassword(this.employee.login).subscribe({
                        next: () => {
                            this.loading = false;
                            this.dialog.open(AlertDialog, {
                                width: '400px',
                                data: {
                                    title: 'Återställning påbörjad',
                                    message: 'Återställningsprocessen är påbörjad, ett mail har skickats.',
                                },
                            });
                        },
                        error: (error) => {
                            this.loading = false;
                            console.error(error);
                            Helpers.captureSentryError('Could not request reset password');

                            let title: string = 'Fel';
                            let message: string = 'Något gick fel när lösenordet skulle återställas';
                            let errors: string[];

                            if (error.status === 400 && error.error.errors) {
                                message = 'Följande fel uppstod:';
                                errors = error.error.errors;
                            }

                            this.dialog.open(AlertDialog, {
                                width: '400px',
                                data: {
                                    title: title,
                                    message: message,
                                    list: errors,
                                },
                            });
                        },
                    });
                }
            });
    }


}
