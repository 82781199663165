import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment'

// Interfaces
import { IPaymentType } from '@app/interfaces/payment-type.interface';

@Injectable({ providedIn: 'root' })
export class PaymentTypeService {

    constructor(private http: HttpClient) { }

    getAll(): Observable<IPaymentType[]> {
        return this.http.get<IPaymentType[]>(environment.apiUrl + "/payment-types");
    }
}
