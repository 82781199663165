<app-admin-menu></app-admin-menu>

<h1 class="mat-headline-3">Arbetad tid bemanningspersonal</h1>

<div class="container">
    <div class="row">
        <div class="col">
            <mat-card class="compact">
                <mat-card-content>
                    <app-report-staffing-company-time (filterChanged)="updateQueryParameters($event)"
                        [staffingCompanyIds]="staffingCompanyIds" [punchDateFrom]="punchDateFrom"
                        [punchDateTo]="punchDateTo">
                    </app-report-staffing-company-time>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<app-admin-footer></app-admin-footer>
