import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { IEmployeePunchList } from '@app/interfaces/employee-punch-list.interface';

@Pipe({ name: 'hasMultipleCostPools' })
export class HasMultipleCostPoolsPipe implements PipeTransform {
    transform(employee: IEmployeePunchList): boolean {
        let seen = [];

        for (let day of employee.days) {
            for (let time of day.workTime) {
                if (seen.indexOf(time.costPoolCode) > -1) {
                    continue;
                }

                if (seen.length > 0) {
                    return true;
                }

                seen.push(time.costPoolCode);
            }
        }
    }
}
