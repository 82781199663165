import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-whistleblower-info-dialog',
    templateUrl: './whistleblower-info-dialog.component.html',
    styleUrls: ['./whistleblower-info-dialog.component.scss'],
})
export class WhistleblowerInfoDialogComponent implements OnInit {
    constructor(private dialogRef: MatDialogRef<WhistleblowerInfoDialogComponent>) {}

    ngOnInit(): void {}

    close() {
        this.dialogRef.close();
    }
}

