import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({name: 'shortTime'})
export class ShortTimePipe implements PipeTransform {
    transform(dateTime: string): string {
        if (dateTime == null || typeof dateTime === 'undefined') {
            return '';
        }

        if (typeof dateTime === 'string' && dateTime.toString().length === 0) {
            return '';
        }

        if (dateTime.toString().length === 8 && dateTime.toString().indexOf(':') > -1) {
            return dateTime.toString().substr(0, 5);
        }

        if (dateTime.toString().length === 5 && dateTime.toString().indexOf(':') > -1) {
            return dateTime;
        }

        return formatDate(dateTime, 'HH:mm', 'se-SE');
    }
}
