import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Helpers } from '@app/helpers/helpers.class';
import { IApplicationClientAccessRequest } from '@app/interfaces/application-client-access-request.interface';
import { IApplicationClientAuthorizedResponse } from '@app/interfaces/application-client-authorized-response.interface';
import { ApplicationClientService } from '@app/services/application-client.service';
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';
import { WhistleblowerInfoDialogComponent } from '@app/shared/dialogs/whistleblower-info-dialog/whistleblower-info-dialog.component';
import { forkJoin, Observable } from 'rxjs';

@Component({
    selector: 'app-application-client-access-form',
    templateUrl: './application-client-access-form.component.html',
    styleUrls: ['./application-client-access-form.component.scss'],
})
export class ApplicationClientAccessFormComponent implements OnInit {
    protected accessForm: UntypedFormGroup;
    protected loading: boolean = false;
    protected clientAuthorizedResponse: IApplicationClientAuthorizedResponse | null = null;

    constructor(
        private formBuilder: UntypedFormBuilder,
        public dialog: MatDialog,
        private applicationClientService: ApplicationClientService
    ) {}

    ngOnInit(): void {
        forkJoin({
            loadClientAuthorized: this.loadClientAuthorized(),
        }).subscribe(() => {
            this.resetForm();
        });
    }

    protected resetForm(): void {
        this.accessForm = this.formBuilder.group({
            loginCode: ['', Validators.required],
            password: ['', Validators.required],
            description: ['', Validators.required],
        });
    }

    protected sendAccessRequest(): void {
        if (this.clientAuthorizedResponse.hasPendingRequest) {
            return;
        }

        const clientId = this.applicationClientService.getClientId();

        if (!clientId || !clientId.length) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Inget klient-ID',
                    message: 'Du har inte fått något klient-ID. Kontakta HR för hjälp.',
                },
            });
            return;
        }

        if (!this.accessForm.valid) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Fel i formuläret',
                    message: 'Alla fält är inte korrekt ifyllda, korrigera felen och försök igen.',
                },
            });
            Helpers.setFormTouched(this.accessForm);
            return;
        }

        const request: IApplicationClientAccessRequest = {
            loginCode: this.accessForm.get('loginCode').value,
            password: this.accessForm.get('password').value,
            description: this.accessForm.get('description').value,
            clientId: clientId,
        };

        this.loading = true;
        this.applicationClientService.createAccessRequest(request).subscribe({
            next: (data) => {
                this.loading = false;
                this.loadClientAuthorized().subscribe(() => {
                    this.resetForm();
                });
            },
            error: (error) => {
                this.loading = false;
                let title = 'Fel',
                    message = 'Något gick fel när din förfrågan skulle skickas. Försök igen, eller kontakta HR.';

                if (error.status === 401) {
                    title = 'Inloggning misslyckades';
                    message = 'Du har angett en felaktig kod eller får inte logga in från denna plats. Försök igen.';
                } else {
                    console.error(error);
                    Helpers.captureSentryError('Could not send access request');
                }

                this.dialog.open(AlertDialog, {
                    width: '400px',
                    data: {
                        title: title,
                        message: message,
                    },
                });
            },
        });
    }

    private loadClientAuthorized(): Observable<boolean> {
        this.loading = true;
        return new Observable((observer) => {
            this.applicationClientService.isClientAuthorized().subscribe({
                next: (data) => {
                    this.clientAuthorizedResponse = data;
                    observer.next(true);
                    observer.complete();
                    this.loading = false;
                },
                error: (error) => {
                    console.error(error);
                    observer.next(false);
                    observer.complete();
                    this.loading = false;
                },
            });
        });
    }

    protected showWhistleblowerInfo(): void {
        this.dialog.open(WhistleblowerInfoDialogComponent, {
            width: '1000px',
        });
    }
}
