import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Helpers } from '@app/helpers/helpers.class';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

// Error handling
import * as Sentry from '@sentry/browser';

// Services
import { CarService } from '@app/services/car.service';
import { CarTypeService } from '@app/services/car-type.service';

// Dialogs
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';

// Interfaces
import { ICarType } from '@app/interfaces/car-type.interface';
import { ICarExtended } from '@app/interfaces/car-extended.interface';
import { ICar } from '@app/interfaces/car.interface';

@Component({
    selector: 'app-driving-log-car',
    templateUrl: './driving-log-car.partial.html',
    styleUrls: ['./driving-log-car.partial.scss'],
})
export class DrivingLogCarPartial implements OnInit {
    @Input() public employeeId: number;
    @Input() public carId: number;

    public loading: boolean = false;
    public car: ICarExtended;
    public carForm: UntypedFormGroup;
    public carTypes: ICarType[];

    constructor(
        private carService: CarService,
        private carTypeService: CarTypeService,
        private formBuilder: UntypedFormBuilder,
        private dialog: MatDialog,
        private router: Router
    ) {}

    public ngOnInit(): void {
        this.carForm = this.formBuilder.group({
            registrationNumber: [null, Validators.required],
            description: [null, Validators.required],
            type: [null, Validators.pattern(Helpers.integerRegex)],
        });

        this.getCarTypes();

        if (Helpers.isDefined(this.carId)) {
            this.getCar();
        }
    }

    public get f() {
        return this.carForm.controls;
    }

    public resetCarForm(): void {
        this.carForm.reset();
        this.f.registrationNumber.setValue(null);
        this.f.description.setValue(null);
        this.f.type.setValue(null);
    }

    public getCarTypes(): void {
        this.carTypeService.getAll().subscribe({
            next: (data) => {
                this.carTypes = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get car types');
            },
        });
    }

    public getCar(): void {
        this.loading = true;
        this.carService.get(this.carId).subscribe({
            next: (data) => {
                this.car = data;
                this.loading = false;

                this.resetCarForm();
                this.f.registrationNumber.setValue(this.car.registrationNumber);
                this.f.description.setValue(this.car.description);
                this.f.type.setValue(this.car.typeId);
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get car');
                this.loading = false;
            },
        });
    }

    public validateCarForm(): boolean {
        if (!this.carForm.valid) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Fel i formuläret',
                    message: 'Alla fält är inte korrekt ifyllda, korrigera felen och försök igen.',
                },
            });
            Helpers.setFormTouched(this.carForm);
            return false;
        }

        return true;
    }

    public create(): void {
        if (!this.validateCarForm()) {
            return;
        }

        let car: ICar = {
            registrationNumber: this.f.registrationNumber.value,
            description: this.f.description.value,
            typeId: this.f.type.value,
        };

        this.loading = true;

        this.carService.create(car).subscribe({
            next: (data) => {
                this.router.navigate(['/driving-log/cars']);
            },
            error: (error) => {
                console.error(error);

                let title = 'Fel uppstod';
                let message =
                    'Något gick fel när bilen skulle skapas, kontrollera ' + 'att allt är korrekt ifyllt och försök igen';
                let errors: string[];

                if (error.status === 400 && error.error.errors) {
                    message = 'Följande fel hittades';
                    errors = error.error.errors;
                } else {
                    Helpers.captureSentryError('Could not create car');
                }

                this.dialog.open(AlertDialog, {
                    width: '400px',
                    data: {
                        title: title,
                        message: message,
                        list: errors,
                    },
                });

                this.loading = false;
            },
        });
    }

    public update(): void {
        if (!this.validateCarForm()) {
            return;
        }

        let car: ICar = {
            registrationNumber: this.f.registrationNumber.value,
            description: this.f.description.value,
            typeId: this.f.type.value,
            carId: this.car.carId,
        };

        this.loading = true;

        this.carService.update(car).subscribe({
            next: (data) => {
                this.router.navigate(['/driving-log/cars']);
            },
            error: (error) => {
                console.error(error);

                let title = 'Fel uppstod';
                let message =
                    'Något gick fel när bilen skulle uppdateras, kontrollera ' + 'att allt är korrekt ifyllt och försök igen';
                let errors: string[];

                if (error.status === 400 && error.error.errors) {
                    message = 'Följande fel hittades';
                    errors = error.error.errors;
                } else {
                    Helpers.captureSentryError('Could not update car');
                }

                this.dialog.open(AlertDialog, {
                    width: '400px',
                    data: {
                        title: title,
                        message: message,
                        list: errors,
                    },
                });

                this.loading = false;
            },
        });
    }

    public cancel(): void {
        this.router.navigate(['/driving-log/cars']);
    }
}
