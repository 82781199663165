import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Helpers } from '@app/helpers/helpers.class';
import { IResetPassword } from '@app/interfaces/reset-password.interface';
import { AuthService } from '@app/services/auth.service';
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';

@Component({
    selector: 'app-admin-reset-password',
    templateUrl: './admin-reset-password.partial.html',
    styleUrls: ['./admin-reset-password.partial.scss'],
})
export class AdminResetPasswordPartial implements OnInit {
    @Input() token: string;

    tokenValid: boolean;
    passwordForm: UntypedFormGroup;
    loading: boolean = false;
    pattern: RegExp = /(?=.{12,})(?=.*?[^\w\s])(?=.*?[0-9])(?=.*?[A-Z]).*?[a-z].*/;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private dialog: MatDialog,
        private authService: AuthService,
        private router: Router
    ) {}

    ngOnInit(): void {
        if (!this.token) {
            this.tokenValid = false;
        } else {
            this.validateToken();
        }
    }

    public get f() {
        return this.passwordForm.controls;
    }

    resetForm(): void {
        this.passwordForm = this.formBuilder.group({
            password: [null, [Validators.required, Validators.pattern(this.pattern)]],
            passwordAgain: [null, [Validators.required, Validators.pattern(this.pattern)]],
        });
    }

    validateToken(): void {
        if (!this.token) {
            return;
        }

        this.loading = true;
        this.authService.isResetAdministratorPasswordTokenValid(this.token).subscribe({
            next: (data) => {
                this.tokenValid = data;

                if (this.tokenValid) {
                    this.resetForm();
                }

                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not check token');
                this.loading = false;
            },
        });
    }

    save(): void {
        if (!this.passwordForm.valid) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Fel i formuläret',
                    message: 'Alla fält är inte korrekt ifyllda, korrigera felen och försök igen.',
                },
            });

            Helpers.setFormTouched(this.passwordForm);

            return;
        }

        if (this.f.password.value !== this.f.passwordAgain.value) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Fel i formuläret',
                    message: 'Lösenorden matchar inte varandra',
                },
            });

            return;
        }

        const payload: IResetPassword = {
            token: this.token,
            password: this.f.password.value,
            passwordAgain: this.f.passwordAgain.value,
        };

        this.loading = true;

        this.authService.resetAdministratorPassword(payload).subscribe({
            next: (data) => {
                this.authService.logoutAdmin();
                this.loading = false;

                this.dialog
                    .open(AlertDialog, {
                        width: '400px',
                        data: {
                            title: 'Återställt',
                            message:
                                'Lösenordet har ändrats till det du angav. Du kommer nu skickas till inloggningssidan för att logga in.',
                        },
                    })
                    .afterClosed()
                    .subscribe(() => {
                        this.router.navigate([`/admin/login`]);
                    });
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not reset admin password');
                this.loading = false;
                this.showError(error);
            },
        });
    }

    public showError(error: any): void {
        let title: string = 'Fel';
        let message: string = 'Något gick fel när lösenordet skulle sparas';
        let errors: string[];

        if (error.status === 400 && error.error.errors) {
            title = 'Felaktiga uppgifter';
            message = 'Följande är fel och måste korrigeras:';
            errors = error.error.errors;
        }

        this.dialog.open(AlertDialog, {
            width: '400px',
            data: {
                title: title,
                message: message,
                list: errors,
            },
        });
    }
}
