<table mat-table [dataSource]="punchSummary">
    <ng-container matColumnDef="label">
        <th mat-header-cell *matHeaderCellDef class="label">Period</th>
        <td mat-cell *matCellDef="let row" class="label">{{row.label}}</td>
    </ng-container>
    <ng-container matColumnDef="humanReadable">
        <th mat-header-cell *matHeaderCellDef class="time">Tid</th>
        <td mat-cell *matCellDef="let row" class="time" [ngClass]="{'no-data': row.totalMinutes === 0}">
            {{row.totalMinutes === 0 ? 'Ingen tid att visa' : row.humanReadable}}
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="summaryColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: summaryColumns;"></tr>
</table>
<div class="loading-wrapper" *ngIf="loading">
    <mat-spinner diameter="40"></mat-spinner>
</div>
