import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'emptyReplacement'})
export class EmptyReplacementPipe implements PipeTransform {
    transform(input: string, replacement: string = '-'): string {
        if(typeof input === 'undefined' || input === null ||
            input.length === 0) {
            return replacement;
        }

        return input;
    }
}
