<app-admin-menu></app-admin-menu>

<h1 class="mat-headline-3">Koppla schema</h1>

<div class="container">
    <div class="row">
        <div class="col">
            <mat-card class="compact employees-list">
                <mat-card-content>
                    <app-schedules-connect (filterChanged)="updateQueryParameters($event)" [query]="query" [companyIds]="companyIds"
                        [employmentTypeIds]="employmentTypeIds" [departmentIds]="departmentIds" [registeredFrom]="registeredFrom"
                        [registeredTo]="registeredTo" [active]="active" [missingSchedule]="missingSchedule" [page]="page">
                    </app-schedules-connect>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<app-messages #messagesPartial [employeeId]="employeeId"></app-messages>

<app-admin-footer></app-admin-footer>
