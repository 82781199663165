<div class="container-fluid">
    <div class="row filters">
        <div class="col query-filter">
            <label>Sök</label>
            <mat-form-field>
                <input
                    matInput
                    [(ngModel)]="filterQuery"
                    autocomplete="off"
                    autocomplete="off"
                    placeholder="Fritextsök..."
                    (keydown.enter)="getSchedulesPage(1)" />
            </mat-form-field>
        </div>
        <div class="col length-filter">
            <label>Längd</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filterLengths" placeholder="Välj längd" multiple>
                    <mat-option *ngFor="let scheduleLength of scheduleLengths" [value]="scheduleLength.length"
                        >{{scheduleLength.length}} dagar</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col button-filter">
            <button mat-raised-button (click)="getSchedulesPage(1)" color="primary">Sök</button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="divider-wrapper">
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col" *ngIf="schedulesPage && schedulesPage.totalItems; else noResults;">
            <mat-paginator
                *ngIf="schedulesPage && schedulesPage.totalItems > schedulesPage.pageSize"
                [length]="schedulesPage.totalItems"
                [pageSize]="schedulesPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
            <div class="table-wrapper">
                <table mat-table *ngIf="schedulesPage" [dataSource]="schedulesPage.items">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="name">Namn</th>
                        <td mat-cell *matCellDef="let schedule" class="name">{{schedule.name}}</td>
                    </ng-container>
                    <ng-container matColumnDef="created">
                        <th mat-header-cell *matHeaderCellDef class="created">Skapat</th>
                        <td mat-cell *matCellDef="let schedule" class="created">{{schedule.created | shortDate}}</td>
                    </ng-container>
                    <ng-container matColumnDef="length">
                        <th mat-header-cell *matHeaderCellDef class="length">Längd</th>
                        <td mat-cell *matCellDef="let schedule" class="length">{{schedule.scheduleLength}} dagar</td>
                    </ng-container>
                    <ng-container matColumnDef="start">
                        <th mat-header-cell *matHeaderCellDef class="start">Startdatum</th>
                        <td mat-cell *matCellDef="let schedule" class="start">{{schedule.startDate | shortDate}}</td>
                    </ng-container>
                    <ng-container matColumnDef="flex">
                        <th mat-header-cell *matHeaderCellDef class="flex">Tillåter flex</th>
                        <td mat-cell *matCellDef="let schedule" class="flex">{{schedule.allowFlex ? 'Ja' : 'Nej'}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="scheduleColumns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let schedule; columns: scheduleColumns;"
                        [routerLink]="'/admin/schedules/' + schedule.scheduleId"></tr>
                </table>
            </div>
            <mat-paginator
                *ngIf="schedulesPage && schedulesPage.totalItems > schedulesPage.pageSize"
                [length]="schedulesPage.totalItems"
                [pageSize]="schedulesPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="pageIndex">
            </mat-paginator>
        </div>
        <ng-template #noResults>
            <div class="col">
                <p class="no-results">Inga scheman att visa</p>
            </div>
        </ng-template>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
