import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'attestTimeOffset' })
export class AttestTimeOffsetPipe implements PipeTransform {
    transform(date: Date, timelineStart: Date, timelineLengthMinutes: number): string {
        date = new Date(date);
        const cleanedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0);
        const minutes = Math.floor((cleanedDate.getTime() - timelineStart.getTime()) / 1000 / 60);
        return (minutes / timelineLengthMinutes) * 100 + '%';
    }
}
