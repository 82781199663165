<div class="wrapper">
    <button mat-icon-button class="close" (click)="close()">
        <mat-icon aria-label="Stäng">close</mat-icon>
    </button>
    <h2>Meddelanden</h2>
    <div class="conversations" *ngIf="conversationsPage && (activeConversation | isEmpty) && !loading">
        <div class="write-new" [formGroup]="sendMessageForm">
            <mat-form-field *ngIf="!adminMode">
                <mat-label>Välj mottagare</mat-label>
                <mat-select [(value)]="selectedRecipient">
                    <mat-option [value]="recipient.employeeId" *ngFor="let recipient of recipients">
                        {{recipient.firstName}} {{recipient.lastName}} ({{recipient.title}})
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="adminMode">
                <mat-label>Välj mottagare</mat-label>
                <input
                    type="text"
                    aria-label="Recipient"
                    matInput
                    formControlName="recipient"
                    [matAutocomplete]="recipientAutoComplete" />
                <mat-autocomplete
                    autoActiveFirstOption
                    #recipientAutoComplete="matAutocomplete"
                    [displayWith]="displaySelectedRecipient"
                    (optionSelected)="recipientSelected($event)">
                    <mat-option *ngFor="let recipient of filteredRecipients | async" [value]="recipient">
                        {{recipient.lastName}}, {{recipient.firstName}} ({{recipient.companyShort}})
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Skriv ett nytt meddelande</mat-label>
                <textarea matInput #newMessageTextarea formControlName="message"></textarea>
            </mat-form-field>
            <div class="button-wrapper">
                <button mat-raised-button color="primary" (click)="sendMessage()">Skicka</button>
            </div>
        </div>
        <div class="search-conversation" *ngIf="adminMode">
            <mat-form-field>
                <mat-label>Sök konversation...</mat-label>
                <input type="text" matInput [(ngModel)]="filterQuery" (keyup.enter)="getConversationPage(1)" autocomplete="off" />
            </mat-form-field>
        </div>
        <div class="paginator" *ngIf="conversationsPage.totalItems > conversationsPage.pageSize">
            <mat-paginator
                [length]="conversationsPage.totalItems"
                [pageSize]="conversationsPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="conversationsPageIndex">
            </mat-paginator>
        </div>
        <div
            class="conversation"
            *ngFor="let conversation of conversationsPage.items"
            (click)="showConversation(conversation)"
            [class.unread]="conversation.unreadMessagesCount > 0">
            <div class="icon" [class.working-now]="conversation.workingNow">
                <mat-icon>account_circle</mat-icon>
            </div>
            <div class="with">
                <span class="name"> {{conversation.withEmployee.firstName}} {{conversation.withEmployee.lastName}} </span>
                <span class="preview">
                    <span
                        >{{conversation.lastMessage.fromEmployeeId == employeeId ? 'Jag:' : conversation.withEmployee.firstName +
                        ':'}}</span
                    >
                    {{conversation.lastMessage.message | preview:35 }}
                </span>
            </div>
            <div class="at">{{conversation.lastMessage.sendDate | verboseDateTime}}</div>
            <div class="new-messages" *ngIf="conversation.unreadMessagesCount > 0">{{conversation.unreadMessagesCount}}</div>
        </div>
        <div class="paginator" *ngIf="conversationsPage.totalItems > conversationsPage.pageSize">
            <mat-paginator
                [length]="conversationsPage.totalItems"
                [pageSize]="conversationsPage.pageSize"
                (page)="pageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="conversationsPageIndex">
            </mat-paginator>
        </div>
    </div>

    <div class="active-conversation" *ngIf="!(messagePage | isEmpty) && !(activeConversation | isEmpty) && !loading">
        <div class="back">
            <button mat-icon-button (click)="backToConversations()">
                <mat-icon aria-label="Tillbaka">keyboard_arrow_left</mat-icon>
            </button>
        </div>
        <div class="write-new" [formGroup]="sendMessageForm">
            <mat-form-field appearance="outline">
                <mat-label>{{'Skriv ett nytt meddelande till ' + activeConversation.withEmployee.firstName}}</mat-label>
                <textarea
                    matInput
                    formControlName="message"></textarea>
            </mat-form-field>
            <div class="button-wrapper">
                <button mat-raised-button color="primary" (click)="sendMessage()">Skicka</button>
            </div>
        </div>
        <h3>Konversation med {{activeConversation.withEmployee.firstName}}</h3>
        <div class="paginator" *ngIf="messagePage.totalItems > messagePage.pageSize">
            <mat-paginator
                [length]="messagePage.totalItems"
                [pageSize]="messagePage.pageSize"
                (page)="messagesPageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="messagePageIndex">
            </mat-paginator>
        </div>
        <div class="messages">
            <div
                [class]="messagePage.items | messageClasses: employeeId: i"
                *ngFor="let message of messagePage.items; let i = index">
                <div class="message-body">
                    <div class="from">
                        <mat-icon>account_circle</mat-icon>
                        <span *ngIf="message.fromEmployeeId == employeeId; else notFromMe">Jag</span>
                        <ng-template #notFromMe>
                            <span> {{message.fromEmployee.firstName}} </span>
                        </ng-template>
                    </div>
                    <div class="message-text">{{message.message}}</div>
                    <div class="at">{{message.sendDate | verboseDateTime}}</div>
                </div>
            </div>
        </div>
        <div class="paginator" *ngIf="messagePage.totalItems > messagePage.pageSize">
            <mat-paginator
                [length]="messagePage.totalItems"
                [pageSize]="messagePage.pageSize"
                (page)="messagesPageChanged($event)"
                [hidePageSize]="true"
                [pageIndex]="messagePageIndex">
            </mat-paginator>
        </div>
    </div>

    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
