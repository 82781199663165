<app-admin-menu></app-admin-menu>

<h1 class="mat-headline-3">Justera stämplingar</h1>

<mat-card class="wrapper">
    <mat-card-content>
        <app-punches-edit (filterChanged)="updateQueryParameters($event)" [query]="query"
        [day]="day" [companyIds]="companyIds" [employmentTypeIds]="employmentTypeIds"
        [departmentIds]="departmentIds" [hasMessages]="hasMessages"></app-punches-edit>
    </mat-card-content>
</mat-card>

<app-messages #messagesPartial [employeeId]="employeeId"></app-messages>

<app-admin-footer></app-admin-footer>
