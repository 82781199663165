import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({ name: 'shortDate' })
export class ShortDatePipe implements PipeTransform {
    transform(dateTime: string): string {
        if (dateTime == null || typeof dateTime === 'undefined') {
            return '';
        }

        return formatDate(dateTime, 'yyyy-MM-dd', 'se-SE');
    }
}
