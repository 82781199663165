import { Pipe, PipeTransform } from '@angular/core';
import { some } from 'lodash-es';

@Pipe({ name: 'arrayContains' })
export class ArrayContainsPipe implements PipeTransform {
    transform(array: any[], id: string, value: any = null): boolean {
        if (typeof array === 'undefined' || array === null || array.length === 0) {
            return false;
        }
        if (value == null) {
            const match = array.indexOf(id) > -1;
            return match;
        } else {
            let keys = {};
            keys[id] = value;
            return some(array, keys);
        }
    }
}
