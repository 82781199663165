import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { Helpers } from '@app/helpers/helpers.class';

@Component({
    selector: 'app-driving-log-admin-page',
    templateUrl: './driving-log.component.html',
    styleUrls: ['./driving-log.component.scss']
})

export class DrivingLogAdminComponent implements OnInit {
    public employeeId: number;
    public companyIds: number[];
    public carIds: number[];
    public employeeIds: number[];
    public from: string;
    public to: string;
    public page: number;

    constructor(private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private router: Router) { }

    public ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);

        if (Helpers.isDefined(params['companyIds'])) {
            this.companyIds = params['companyIds'];
        }

        if (Helpers.isDefined(params['carIds'])) {
            this.carIds = params['carIds'];
        }

        if (Helpers.isDefined(params['employeeIds'])) {
            this.employeeIds = params['employeeIds'];
        }

        if (Helpers.isDefined(params['from'])) {
            this.from = params['from'];
        }

        if (Helpers.isDefined(params['to'])) {
            this.to = params['to'];
        }

        if (Helpers.isDefined(params['page'])) {
            this.page = params['page'];
        }
    }

    public updateQueryParameters(filter: any): void {
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);
        params['companyIds'] = filter.companyIds.length > 0 ? filter.companyIds.join(',') : null;
        params['carIds'] = filter.carIds.length > 0 ? filter.carIds.join(',') : null;
        params['employeeIds'] = filter.employeeIds.length > 0 ? filter.employeeIds.join(',') : null;
        params['from'] = filter.from.length > 0 ? filter.from : null;
        params['to'] = filter.to.length > 0 ? filter.to : null;
        params['page'] = filter.page;
        this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params });
    }
}
