import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Helpers } from '@app/helpers/helpers.class';
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'app-cost-pools-list-admin-page',
    templateUrl: './cost-pools-list.component.html',
    styleUrls: ['./cost-pools-list.component.scss'],
})
export class CostPoolsListAdminComponent implements OnInit {
    public employeeId: number;
    public query: string;
    public visible: boolean;
    public page: number;

    constructor(private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router) {}

    public ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);

        if (Helpers.isDefined(params['query'])) {
            this.query = params['query'];
        }

        if (Helpers.isDefined(params['visible'])) {
            this.visible = params['visible'];
        }

        if (Helpers.isDefined(params['page'])) {
            this.page = params['page'];
        }
    }

    public updateQueryParameters(filter: any): void {
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);
        params['visible'] = Helpers.isDefined(filter.visible) ? (filter.visible ? 1 : 0) : null;
        params['query'] = Helpers.isDefined(filter.query) && filter.query.length > 0 ? filter.query : null;
        params['page'] = filter.page;
        this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params });
    }
}
