import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

// Dialogs
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';

// Services
import { AuthService } from '@app/services/auth.service';
import { AdminPasswordExpiredDialogComponent } from '@app/shared/dialogs/admin-password-expired-dialog/admin-password-expired-dialog.component';
import { EmployeeLoginResetPasswordDialogComponent } from '@app/shared/dialogs/employee-login-reset-password-dialog/employee-login-reset-password-dialog.component';

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
    public claimMissingDialogOpen: boolean = false;
    public passwordExpiredDialogOpen: boolean = false;

    constructor(
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error) => {
                if (error.status === 401) {
                    if (this.router.url.indexOf('/admin/') > -1) {
                        this.authService.logoutAdmin();
                        if (this.router.url !== '/admin/login') {
                            this.router.navigate(['/admin/login']);
                        }
                    } else if (this.router.url !== '/login') {
                        this.authService.logoutEmployee();
                        this.router.navigate(['/login']);
                    }
                } else if (error.status === 403) {
                    if (this.router.url.indexOf('/admin/') > -1) {
                        this.adminForbidden(error);
                    } else {
                        this.employeeForbidden(error);
                    }
                }

                return throwError(error);
            })
        );
    }

    employeeForbidden(error: any): void {
        if (error.statusText.toLowerCase() === 'password expired') {
            if (!this.passwordExpiredDialogOpen) {
                this.passwordExpiredDialogOpen = true;
                this.dialog
                    .open(EmployeeLoginResetPasswordDialogComponent, {
                        width: '400px',
                        backdropClass: 'white',
                        data: {
                            passwordExpired: true,
                        },
                    })
                    .afterClosed()
                    .subscribe(() => {
                        this.passwordExpiredDialogOpen = false;
                    });
            }
        }
    }

    adminForbidden(error: any): void {
        if (error.statusText.toLowerCase() === 'password expired') {
            if (!this.passwordExpiredDialogOpen) {
                this.passwordExpiredDialogOpen = true;
                this.dialog
                    .open(AdminPasswordExpiredDialogComponent, {
                        width: '400px',
                        backdropClass: 'white',
                    })
                    .afterClosed()
                    .subscribe(() => {
                        this.passwordExpiredDialogOpen = false;
                    });
            }
        } else if (!this.claimMissingDialogOpen) {
            this.claimMissingDialogOpen = true;
            let handle = this.dialog
                .open(AlertDialog, {
                    width: '400px',
                    data: {
                        title: 'Rättigheter saknas',
                        message:
                            'Du saknar rättigheter för att visa denna sida eller att utföra denna åtgärd. Kontakta IT om du borde ha dessa rättigeheter.',
                    },
                })
                .afterClosed()
                .subscribe(() => {
                    this.claimMissingDialogOpen = false;
                });
        }
    }
}
