import { Pipe, PipeTransform } from '@angular/core';
import { Helpers } from '@app/helpers/helpers.class';
import { IEmployeeEditPunches } from '@app/interfaces/employee-edit-puches.interface';

@Pipe({ name: 'editPunchMessagesIgnored' })
export class EditPunchMessagesIgnoredPipe implements PipeTransform {
    transform(employee: IEmployeeEditPunches): boolean {
        if (!Helpers.isDefined(employee) || !Helpers.isDefined(employee.messages) ||
            employee.messages.length === 0) {
            return false;
        }

        for (let message of employee.messages) {
            if (!message.ignoreInEditPunches && !message.replied) {
                return false;
            }
        }

        return true;
    }
}
