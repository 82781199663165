import { Component, OnInit } from '@angular/core';

// Services
import { AuthService } from '@app/services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-schedule-edit-admin-page',
    templateUrl: './schedule-edit.component.html',
    styleUrls: ['./schedule-edit.component.scss']
})

export class ScheduleEditAdminComponent implements OnInit {
    public employeeId: number;
    public scheduleId: number;

    constructor(private authService: AuthService,
        private activatedRoute: ActivatedRoute) { }

    ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
        this.activatedRoute.params.subscribe(params => {
            this.scheduleId = +params['id'];
        });
    }
}
