import { Component, OnInit, Input } from '@angular/core';

// Interfaces
import { IMenuItem } from '@app/interfaces/menu-item.interface';

@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss']
})

export class MenuItemComponent implements OnInit {
    @Input() public menuItem: IMenuItem;
    @Input() public isSubItem: boolean;

    constructor() { }

    ngOnInit(): void {

    }
}
