import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Helpers } from '@app/helpers/helpers.class';

// Services
import { EmployeeService } from '@app/services/employee.service';
import { PunchService } from '@app/services/punch.service';

// Interfaces
import { IPunch } from '@app/interfaces/punch.interface';
import { IEmployeeShort } from '@app/interfaces/employee-short.interface';

@Component({
    selector: 'fix-last-punch-dialog',
    templateUrl: './fix-last-punch.dialog.html',
    styleUrls: ['./fix-last-punch.dialog.scss'],
})
export class FixLastPunchDialog implements OnInit {
    lastPunch: IPunch;
    hours: string[] = [];
    minutes: string[] = [];
    selectedHour: string;
    selectedMinute: string;
    earliestPossible: string;
    earliestHour: string;
    earliestMinute: string;
    overtimeEmployeeId: number;
    overtimeEmployees: IEmployeeShort[];
    isOvertime: boolean = false;
    missingOvertimeEmployee: boolean = false;

    constructor(
        private employeeService: EmployeeService,
        private punchService: PunchService,
        private dialogRef: MatDialogRef<FixLastPunchDialog>,
        @Inject(MAT_DIALOG_DATA) { lastPunch }: any
    ) {
        this.lastPunch = lastPunch;
    }

    ngOnInit(): void {
        let punchTime = new Date(this.lastPunch.punchTime);
        let startHour = punchTime.getHours();
        let startMinute = punchTime.getMinutes() + 1;
        this.selectedHour = startHour < 10 ? '0' + startHour.toString() : startHour.toString();
        this.earliestHour = this.selectedHour;
        this.selectedMinute = startMinute < 10 ? '0' + startMinute.toString() : startMinute.toString();
        this.earliestMinute = this.selectedMinute;
        this.earliestPossible = this.selectedHour + ':' + this.selectedMinute;

        for (let i = startHour; i <= 23; i++) {
            this.hours.push(i < 10 ? '0' + i.toString() : i.toString());
        }

        for (let i = 0; i <= 59; i++) {
            this.minutes.push(i < 10 ? '0' + i.toString() : i.toString());
        }

        this.getOvertimeEmployees();
    }

    getOvertimeEmployees(): void {
        this.employeeService.getCanOrderOvertime().subscribe({
            next: (data) => {
                this.overtimeEmployees = data;
            },
            error: (error) => {
                console.error(error);
            },
        });
    }

    noChanges(): void {
        this.punchService.setIsCorrect(this.lastPunch).subscribe({
            next: (data) => {
                this.dialogRef.close(false);
            },
            error: (error) => {
                console.error(error);
            },
        });
    }

    selectedHourChanged(): void {
        if (this.selectedHour == this.earliestHour && this.selectedMinute < this.earliestMinute) {
            this.selectedMinute = this.earliestMinute;
        }
    }

    addPunch(): void {
        this.missingOvertimeEmployee = false;

        let punchTime = Helpers.toShortDateString(this.lastPunch.punchTime) + ' ' + this.selectedHour + ':' + this.selectedMinute;

        if (this.isOvertime && (!Helpers.isDefined(this.overtimeEmployeeId) || this.overtimeEmployeeId == 0)) {
            this.missingOvertimeEmployee = true;
            return;
        }

        let punch: IPunch = {
            employeeId: this.lastPunch.employeeId,
            punchTypeId: 2,
            punchTime: punchTime,
            overtime: this.isOvertime,
            overtimeEmployeeId: this.overtimeEmployeeId,
            mayForgot: true,
            poolId: this.lastPunch.poolId,
        };

        this.punchService.create(punch).subscribe({
            next: (data) => {
                this.dialogRef.close(true);
            },
            error: (error) => {
                console.error(error);
            },
        });
    }
}
