import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({name: 'readableDate'})
export class ReadableDatePipe implements PipeTransform {
    transform(dateTime: string): string {
        let now = new Date();
        let yesterday = new Date();

        yesterday.setDate(yesterday.getDate() - 1);

        if(formatDate(dateTime, 'yyyy-MM-dd', 'se-SE') ==
            formatDate(now, 'yyyy-MM-dd', 'se-SE')) {
            return 'Idag';
        } else if(formatDate(dateTime, 'yyyy-MM-dd', 'se-SE') ==
            formatDate(yesterday, 'yyyy-MM-dd', 'se-SE')) {
            return 'Igår';
        } else {
            return formatDate(dateTime, 'yyyy-MM-dd', 'se-SE');
        }
    }
}
