import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Helpers } from '@app/helpers/helpers.class';
import { ICostPoolCompanyGroup } from '@app/interfaces/cost-pool-company-group.interface';
import { ICostPool } from '@app/interfaces/cost-pool.interface';
import { CostPoolService } from '@app/services/cost-pool.service';

@Component({
    selector: 'app-attest-time-move-dialog',
    templateUrl: './attest-time-move-dialog.component.html',
    styleUrls: ['./attest-time-move-dialog.component.scss'],
})
export class AttestTimeMoveDialogComponent {
    costPoolFormControl = new UntypedFormControl('', [Validators.required]);
    groups: ICostPoolCompanyGroup[];
    noCostPool: ICostPool = {
        poolId: 0,
        code: null,
        name: null,
        visible: null,
        project: null,
        parentPoolId: null,
        companyId: null,
        showInPunchMenu: false,
    };

    constructor(private dialogRef: MatDialogRef<AttestTimeMoveDialogComponent>, private costPoolService: CostPoolService) {
        this.getCostPoolsPage();
    }

    public close(cancelled: boolean): void {
        if (!cancelled && !this.costPoolFormControl.valid) {
            this.costPoolFormControl.updateValueAndValidity();
            return;
        }

        this.dialogRef.close(cancelled ? null : this.costPoolFormControl.value);
    }

    public getCostPoolsPage(): void {
        this.costPoolService.getAllGroupedByCompany().subscribe({
            next: (data) => {
                this.groups = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get cost pools');
            },
        });
    }
}
