import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment'

// Interfaces
import { ICompany } from '@app/interfaces/company.interface';

@Injectable({ providedIn: 'root' })
export class CompanyService {

    constructor(private http: HttpClient) { }

    getAll(): Observable<ICompany[]> {
        return this.http.get<ICompany[]>(environment.apiUrl + "/companies");
    }

    getAllActive(): Observable<ICompany[]> {
        return this.http.get<ICompany[]>(environment.apiUrl + "/companies/active");
    }
}
