import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

// Interfaces
import { IPage } from '@app/interfaces/page.interface';
import { IEmployeeMessage } from '@app/interfaces/employee-message.interface';
import { IMessageRecipient } from '@app/interfaces/message-recipient.interface';
import { IEmployeeMessageNewCount } from '@app/interfaces/employee-message-new-count.interface';
import { Helpers } from '@app/helpers/helpers.class';

@Injectable({ providedIn: 'root' })
export class EmployeeMessageService {

    constructor(private http: HttpClient) { }

    getConversations(employeeId: number, query: string, page: number): Observable<IPage<any>> {
        let params = new HttpParams()
            .set('page', page.toString());

        if (Helpers.isDefined(query) && query.length > 0) {
            params = params.set('query', query);
        }

        return this.http.get<IPage<any>>(environment.apiUrl + '/employee-messages/' + employeeId + '/conversations', {
            params: params
        });
    }

    getConversation(employeeId: number, withEmployeeId: number, page: number): Observable<IPage<any>> {
        return this.http.get<IPage<any>>(environment.apiUrl + '/employee-messages/' + employeeId + '/conversations/' + withEmployeeId, {
            params: new HttpParams()
                .set('page', page.toString())
        });
    }

    sendMessage(message: IEmployeeMessage): Observable<IEmployeeMessage> {
        return this.http.post<IEmployeeMessage>(environment.apiUrl + '/employee-messages', message);
    }

    getRecipients(employeeId: number): Observable<IMessageRecipient[]> {
        return this.http.get<IMessageRecipient[]>(environment.apiUrl + '/employee-messages/' + employeeId + '/recipients');
    }

    getNewMessagesCount(employeeId: number): Observable<IEmployeeMessageNewCount> {
        return this.http.get<IEmployeeMessageNewCount>(environment.apiUrl + '/employee-messages/' + employeeId + '/new-count');
    }

    setConversationRead(employeeId: number, withEmployeeId: number): Observable<any> {
        return this.http.patch<any>(environment.apiUrl + '/employee-messages/' + employeeId + '/conversations/' + withEmployeeId + '/read', {});
    }

    ignoreMessage(messageId: number): Observable<any> {
        return this.http.patch<any>(environment.apiUrl + '/employee-messages/' + messageId + '/ignore', {});
    }

    unignoreMessage(messageId: number): Observable<any> {
        return this.http.patch<any>(environment.apiUrl + '/employee-messages/' + messageId + '/unignore', {});
    }

    setMessageDisplayAtDate(message: IEmployeeMessage): Observable<any> {
        return this.http.patch<any>(environment.apiUrl + '/employee-messages/set-display-at-date', message);
    }

    getCountPerDay(toEmployeeId: number, from: string, to: string): Observable<any[]> {
        return this.http.get<any[]>(environment.apiUrl + '/employee-messages/' + toEmployeeId + '/count-per-day', {
            params: new HttpParams()
                .set('from', from)
                .set('to', to)
        });
    }
}
