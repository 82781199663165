import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

// Interfaces
import { ICarDrivingLog } from '@app/interfaces/car-driving-log.interface';
import { ICarDrivingLogExtended } from '@app/interfaces/car-driving-log-extended.interface';
import { ICarDrivingLogSuggestedData } from '@app/interfaces/car-driving-log-suggested-data.interface';
import { IPage } from '@app/interfaces/page.interface';
import { ICarDrivingLogSearch } from '@app/interfaces/car-driving-log-search.interface';

@Injectable({ providedIn: 'root' })
export class CarDrivingLogService {
    constructor(private http: HttpClient) {}

    startNewTrip(log: ICarDrivingLog): Observable<ICarDrivingLog> {
        return this.http.post<ICarDrivingLog>(environment.apiUrl + '/car-driving-log/new-trip', log);
    }

    endTrip(log: ICarDrivingLog): Observable<any> {
        return this.http.patch<any>(environment.apiUrl + '/car-driving-log/end-trip/' + log.logId, log);
    }

    update(log: ICarDrivingLog): Observable<any> {
        return this.http.patch<any>(environment.apiUrl + '/car-driving-log/' + log.logId, log);
    }

    getActiveTripByEmployee(employeeId: number): Observable<ICarDrivingLogExtended> {
        return this.http.get<ICarDrivingLogExtended>(environment.apiUrl + '/car-driving-log/active-trips/' + employeeId);
    }

    get(logId: number): Observable<ICarDrivingLogExtended> {
        return this.http.get<ICarDrivingLogExtended>(environment.apiUrl + '/car-driving-log/' + logId);
    }

    getSuggestedData(employeeId: number): Observable<ICarDrivingLogSuggestedData> {
        return this.http.get<ICarDrivingLogSuggestedData>(environment.apiUrl + '/car-driving-log/suggested-data/' + employeeId);
    }

    getByEmployee(employeeId: number, page: number): Observable<IPage<any>> {
        return this.http.get<IPage<any>>(environment.apiUrl + '/car-driving-log/by-employee/' + employeeId, {
            params: new HttpParams().set('page', page.toString()),
        });
    }

    getReport(search: ICarDrivingLogSearch): Observable<IPage<any>> {
        return this.http.post<IPage<any>>(environment.apiUrl + '/car-driving-log/search', search);
    }
}
