<app-driving-log-menu></app-driving-log-menu>

<h1 class="mat-headline-3">Tidigare resa</h1>

<div class="container">
    <div class="row">
        <div class="col">
            <button mat-button class="back" routerLink="/driving-log/trip-history">
                <mat-icon>chevron_left</mat-icon>
                Tillbaka
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-card class="compact">
                <mat-card-content>
                    <app-driving-log-show-trip [logId]="logId"></app-driving-log-show-trip>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<app-driving-log-footer></app-driving-log-footer>
