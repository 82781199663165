import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// Services
import { EmployeeService } from '@app/services/employee.service';

// Interfaces
import { IEmployeeShort } from '@app/interfaces/employee-short.interface';
import { IOvertimeDialogResult } from '@app/interfaces/overtime-dialog-result.interface';

@Component({
    selector: 'overtime-dialog',
    templateUrl: './overtime.dialog.html',
    styleUrls: ['./overtime.dialog.scss'],
})
export class OvertimeDialog {
    employees: IEmployeeShort[];
    employee: IEmployeeShort;
    loading: boolean;

    constructor(private dialogRef: MatDialogRef<OvertimeDialog>, private employeeService: EmployeeService) {
        this.getEmployees();
    }

    getEmployees(): void {
        this.loading = true;
        this.employeeService.getCanOrderOvertime().subscribe({
            next: (data) => {
                this.employees = data;
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
            },
        });
    }

    close(isOvertime: boolean): void {
        if (isOvertime && (this.employee === null || typeof this.employee === 'undefined')) {
            return;
        }

        let result: IOvertimeDialogResult = {
            isOvertime: isOvertime,
            overtimeEmployeeId: isOvertime ? this.employee.employeeId : null,
        };

        this.dialogRef.close(result);
    }
}
