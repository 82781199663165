import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Helpers } from '@app/helpers/helpers.class';
import { IApplicationClientSearchFilter } from '@app/interfaces/application-client-search-filter.interface';
import { IApplicationClient } from '@app/interfaces/application-client.interface';
import { ICompany } from '@app/interfaces/company.interface';
import { IPage } from '@app/interfaces/page.interface';
import { ApplicationClientService } from '@app/services/application-client.service';
import { CompanyService } from '@app/services/company.service';

@Component({
    selector: 'app-application-clients-list',
    templateUrl: './application-clients-list.partial.html',
    styleUrls: ['./application-clients-list.partial.scss'],
})
export class ApplicationClientsListPartial implements OnInit {
    @Output() public filterChanged: EventEmitter<any> = new EventEmitter();
    @Input('query') public inputQuery: string;
    @Input('page') public inputPage: number;
    @Input('authorized') public inputAuthorized: boolean | null;
    @Input('handled') public inputHandled: boolean | null;

    protected filter: IApplicationClientSearchFilter;
    protected loading: boolean = false;
    protected clientsPage: IPage<IApplicationClient>;
    protected companies: ICompany[];
    protected columns: string[] = [
        'requestId',
        'referenceNumber',
        'name',
        'description',
        'authorized',
        'handledDate',
        'company',
        'actions',
    ];

    constructor(private applicationClientService: ApplicationClientService, private companyService: CompanyService) {}

    ngOnInit(): void {
        this.resetFilter();
        this.loadCompanies();
        this.search(1);
    }

    protected resetFilter(): void {
        this.filter = {
            query: this.inputQuery,
            pageNumber: this.inputPage,
            isAuthorized: this.inputAuthorized,
            isHandled: this.inputHandled,
        };
    }

    protected search(page: number): void {
        this.filter.pageNumber = page;

        this.loading = true;
        this.applicationClientService.searchClients(this.filter).subscribe({
            next: (data) => {
                this.clientsPage = data;
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not get cost pools page');
            },
        });

        this.filterChanged.emit(this.filter);
    }

    protected authorizeAccessRequest(client: IApplicationClient, authorized: boolean): void {
        this.loading = true;
        this.applicationClientService.authorizeAccessRequest(client.requestId, authorized).subscribe({
            next: (result) => {
                const clientsPage = [...this.clientsPage.items];

                for (let i = 0; i < clientsPage.length; i++) {
                    const item = clientsPage[i];

                    if (item.requestId !== client.requestId) {
                        continue;
                    }

                    clientsPage[i] = result;
                }

                this.clientsPage.items = clientsPage;
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not authorize access request');
            },
        });
    }

    private loadCompanies(): void {
        this.companyService.getAll().subscribe({
            next: (companies) => {
                this.companies = companies;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not load companies');
            },
        });
    }

    protected setClientCompany(client: IApplicationClient): void {
        this.applicationClientService.setClientCompany(client.requestId, client.companyId).subscribe({
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not set company');
            },
        });
    }
}
