import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Helpers } from '@app/helpers/helpers.class';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';

// Interfaces
import { ICostPool } from '@app/interfaces/cost-pool.interface';
import { IEmployeeShortExtended } from '@app/interfaces/employee-short-extended.interface';
import { IPunch } from '@app/interfaces/punch.interface';

// Services
import { AuthService } from '@app/services/auth.service';
import { EmployeeService } from '@app/services/employee.service';
import { CostPoolService } from '@app/services/cost-pool.service';
import { PunchService } from '@app/services/punch.service';

// Partials
import { EmployeeWorkPeriodsPartial } from '@app/components/partials/employee-work-periods/employee-work-periods.partial';
import { EmployeePunchSummaryPartial } from '@app/components/partials/employee-punch-summary/employee-punch-summary.partial';
import { EmployeeRecentPunchesPartial } from '@app/components/partials/employee-recent-punches/employee-recent-punches.partial';
import { EmployeeAbsencePartial } from '@app/components/partials/employee-absence/employee-absence.partial';
import { EmployeeTravelCompensationsPartial } from '@app/components/partials/employee-travel-compensations/employee-travel-compensations.partial';
import { EmployeeDetailsPartial } from '@app/components/partials/employee-details/employee-details.partial';
import { EmployeeContractsPartial } from '@app/components/partials/employee-contracts/employee-contracts.partial';

// Dialogs
import { OvertimeDialog } from '@app/shared/dialogs/overtime/overtime.dialog';
import { FixLastPunchDialog } from '@app/shared/dialogs/fix-last-punch/fix-last-punch.dialog';
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';
import { EmployeeLoginPasswordPromptDialogComponent } from '@app/shared/dialogs/employee-login-password-prompt-dialog/employee-login-password-prompt-dialog.component';
import { ConfirmDialog } from '@app/shared/dialogs/confirm/confirm.dialog';

@Component({
    selector: 'app-punch-page',
    templateUrl: './punch.component.html',
    styleUrls: ['./punch.component.scss'],
})
export class PunchComponent implements OnInit {
    @ViewChild('workPeriodsPartial', { static: true })
    workPeriodsPartial: EmployeeWorkPeriodsPartial;
    @ViewChild('punchSummaryPartial', { static: true })
    punchSummaryPartial: EmployeePunchSummaryPartial;
    @ViewChild('recentPunchesPartial', { static: true })
    recentPunchesPartial: EmployeeRecentPunchesPartial;
    @ViewChild('absencePartial', { static: true })
    absencePartial: EmployeeAbsencePartial;
    @ViewChild('travelCompensationsPartial', { static: true })
    travelCompensationsPartial: EmployeeTravelCompensationsPartial;
    @ViewChild('detailsPartial', { static: true })
    detailsPartial: EmployeeDetailsPartial;
    @ViewChild('contractsPartial', { static: true })
    contractsPartial: EmployeeContractsPartial;

    employeeId: number;
    employee: IEmployeeShortExtended;
    costPools: ICostPool[];
    selectedPool: ICostPool;
    mustSelectCostPool: boolean = true;
    currentTime: string = '';
    currentDay: string = '';
    loading: boolean = true;
    punchedIn: boolean = false;
    selectedTabIndex: number = 0;
    showBankDetailsWarning: boolean = false;
    showSoonDuePeriodWarning: boolean = false;
    showPastDuePeriodWarning: boolean = false;
    inactivityCounter: number = 0;
    inactivityPaused: boolean = false;
    intervalHandle: any;
    authenticatedWithPassword: boolean = false;

    constructor(
        private authService: AuthService,
        private employeeService: EmployeeService,
        private costPoolService: CostPoolService,
        private punchService: PunchService,
        private dialog: MatDialog,
        private router: Router
    ) {
        this.authenticatedWithPassword = this.authService.isEmployeeAuthenticatedWithPassword();
    }

    ngOnInit(): void {
        this.updateClock();
        this.intervalHandle = setInterval(() => {
            this.updateClock();

            if (!this.inactivityPaused && (!this.dialog.openDialogs || this.dialog.openDialogs.length === 0)) {
                this.inactivityCounter++;
            }

            if (this.inactivityCounter > 60) {
                clearInterval(this.intervalHandle);
                this.inactivityCounter = 0;
                this.authService.logoutEmployee();
                this.router.navigate(['/login']);
            }
        }, 500);

        this.employeeId = this.authService.getEmployeeId();
        this.getEmployee();
        this.getCostPools();
    }

    ngOnDestroy() {
        clearInterval(this.intervalHandle);
    }

    @HostListener('document:mousemove', ['$event'])
    onMouseMove(e) {
        this.resetInactivityCounter();
    }

    @HostListener('document:keyup', ['$event'])
    onKeyUp(e) {
        this.resetInactivityCounter();
    }

    selectTab(index: number): void {
        this.selectedTabIndex = index;
    }

    punch(): void {
        let punchTypeId: number = this.punchedIn ? 2 : 1;

        if (punchTypeId === 1 && (this.selectedPool === null || typeof this.selectedPool === 'undefined')) {
            return;
        }

        this.loading = true;

        this.punchService.getPrePunchCheck(this.employeeId).subscribe({
            next: (data) => {
                if (data.expectedPunchTypeId !== punchTypeId) {
                    // Ska inte hända
                    window.location.reload();
                    return;
                }

                if (data.tightPunch) {
                    this.loading = false;
                    this.dialog.open(AlertDialog, {
                        width: '400px',
                        data: {
                            title: 'Vänta',
                            message: 'Du har precis stämplat och måste vänta en liten stund innan du kan stämpla igen',
                        },
                    });
                    return;
                }

                this.loading = false;

                if (data.askForOvertime) {
                    const handle = this.dialog.open(OvertimeDialog, {
                        width: '500px',
                        disableClose: true,
                    });

                    handle.afterClosed().subscribe((val) => {
                        this.loading = true;
                        this.createPunch(punchTypeId, val.isOvertime, val.overtimeEmployeeId);
                    });
                } else {
                    this.loading = true;
                    this.createPunch(punchTypeId, data.overtimeReported, data.overtimeEmployeeId);
                }
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not get punch pre-check');
            },
        });
    }

    createPunch(punchTypeId: number, isOvertime: boolean, overtimeEmployeeId: number): void {
        let punch: IPunch = {
            employeeId: this.employeeId,
            punchTypeId: punchTypeId,
            overtime: isOvertime,
            overtimeEmployeeId: overtimeEmployeeId,
            mayForgot: true,
            poolId: this.selectedPool.poolId,
        };

        this.punchService.create(punch).subscribe({
            next: (data) => {
                this.getEmployee();
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not create punch');
            },
        });
    }

    preselectCostPool(): void {
        if (!this.employee || !this.costPools) {
            return;
        }

        if (this.employee.preselectedPoolId !== null && this.employee.preselectedPoolId > 0) {
            this.selectedPool = this.getCostPool(this.employee.preselectedPoolId);
        } else if (this.employee.lastPunch !== null && this.employee.lastPunch.poolId !== null) {
            this.selectedPool = this.getCostPool(this.employee.lastPunch.poolId);
        } else if (this.employee.lastPunch !== null && this.punchedIn) {
            this.selectedPool = this.getCostPool(0);
        } else if (this.employee.department !== null && this.employee.department.poolId !== null) {
            this.selectedPool = this.getCostPool(this.employee.department.poolId);
        }
    }

    getCostPool(poolId: number): ICostPool {
        if (!this.costPools) {
            return null;
        }

        for (let i = 0; i < this.costPools.length; i++) {
            if (this.costPools[i].poolId === poolId) {
                return this.costPools[i];
            }
        }
    }

    getEmployee(): void {
        this.loading = true;
        this.employeeService.getShortExtendedDetails(this.employeeId).subscribe({
            next: (data) => {
                this.employee = data;
                this.workPeriodsPartial.externalDataLoaded(data.openWorkPeriods);
                this.punchSummaryPartial.externalDataLoaded(data.punchSummary);
                this.recentPunchesPartial.externalDataLoaded(data.recentPunches);
                this.absencePartial.externalDataLoaded(data.recentAbsence);
                this.travelCompensationsPartial.externalDataLoaded(data.recentTravelCompensations);
                this.detailsPartial.externalDataLoaded(data);
                this.contractsPartial.externalDataLoaded(data.recentContracts);
                this.mustSelectCostPool = this.employee.preselectedPoolId === null || this.employee.preselectedPoolId === 0;
                this.punchedIn = this.employee.lastPunch !== null && this.employee.lastPunch.punchTypeId === 1;
                this.preselectCostPool();
                this.loading = false;

                /*if ((!Helpers.isDefined(this.employee.bankAccount) || this.employee.bankAccount.length === 0) ||
                    (!Helpers.isDefined(this.employee.bankName) || this.employee.bankName.length === 0) ||
                    (!Helpers.isDefined(this.employee.clearingNumber) || this.employee.clearingNumber.length === 0)) {
                    this.showBankDetailsWarning = true;
                }*/

                let now = new Date();

                if (this.employee.openWorkPeriods.length > 0) {
                    for (let period of this.employee.openWorkPeriods) {
                        let periodEnd = new Date(period.periodEnd);
                        let confirmDue = new Date(period.confirmDue);

                        if (periodEnd < now && confirmDue > now) {
                            this.showSoonDuePeriodWarning = true;
                            continue;
                        }

                        if (periodEnd < now && confirmDue < now) {
                            this.showPastDuePeriodWarning = true;
                            continue;
                        }
                    }
                }

                if (this.employee.showFixPunchDialog) {
                    const handle = this.dialog.open(FixLastPunchDialog, {
                        width: '500px',
                        disableClose: true,
                        data: {
                            lastPunch: this.employee.lastPunch,
                        },
                    });

                    handle.afterClosed().subscribe((result: boolean) => {
                        if (result) {
                            this.getEmployee();
                        }
                    });
                }
            },
            error: (error) => {
                this.loading = false;
                console.error(error);
                Helpers.captureSentryError('Not get employee data');
            },
        });
    }

    getCostPools(): void {
        this.costPoolService.getParents().subscribe({
            next: (data) => {
                this.costPools = [];

                this.costPools.push({
                    poolId: 0,
                    code: '',
                    name: '(Vet inte)',
                    succession: 0,
                    visible: true,
                    project: 0,
                    parentPoolId: null,
                    companyId: null,
                    showInPunchMenu: false
                });

                for (let costPool of data) {
                    if (costPool.poolId !== 25) {
                        this.costPools.push(costPool);
                    }
                }

                this.preselectCostPool();
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get cost pools');
            },
        });
    }

    updateClock(): void {
        const now = new Date();
        now.setSeconds(now.getSeconds() - 10);
        this.currentDay =
            Helpers.getDayName(now.getDay()) + ' den ' + now.getDate().toString() + ' ' + Helpers.getMonthName(now.getMonth());
        this.currentTime = formatDate(now, 'HH:mm:ss', 'se-SE');
    }

    resetInactivityCounter() {
        this.inactivityCounter = 0;
    }

    messagesOpened() {
        this.inactivityPaused = true;
    }

    messagesClosed() {
        this.inactivityPaused = false;
    }

    showLoginWithPassword(): void {
        this.dialog
            .open(EmployeeLoginPasswordPromptDialogComponent, {
                width: '400px',
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.loginWithPassword(result);
                }
            });
    }

    loginWithPassword(password: string): void {
        this.loading = true;
        this.authService.employeeLogin(this.employee.login, password).subscribe({
            next: () => {
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['/punch']));
                this.loading = false;
            },
            error: () => {
                this.dialog.open(AlertDialog, {
                    width: '400px',
                    data: {
                        title: 'Misslyckades',
                        message: 'Du kunde inte loggas in med lösenord. Du kan ha angett fel lösenord.',
                    },
                });
                this.loading = false;
            },
        });
    }

    absenceAdded(): void {
        if (this.authenticatedWithPassword) {
            this.absencePartial.update();
        }
    }

    travelCompensationAdded(): void {
        if (this.authenticatedWithPassword) {
            this.travelCompensationsPartial.update();
        }
    }

    costPoolChanged(): void {
        if (!this.punchedIn) {
            return;
        }

        let handle = this.dialog.open(ConfirmDialog, {
            width: '500px',
            data: {
                title: 'Byta avdelning',
                message: 'Vill du byta avdelning till ' + this.selectedPool.name + '?',
            },
        });

        handle.afterClosed().subscribe((result) => {
            if (!result) {
                for (let costPool of this.costPools) {
                    if (costPool.poolId == this.employee.lastPunch.costPool.poolId) {
                        this.selectedPool = costPool;
                        break;
                    }
                }
                return;
            }

            this.loading = true;
            this.createPunch(3, false, null);
        });
    }
}
