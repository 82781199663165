import { Component, OnInit } from '@angular/core';

// Services
import { AuthService } from '@app/services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-cars-driving-log-page',
    templateUrl: './cars.component.html',
    styleUrls: ['./cars.component.scss']
})

export class CarsDrivingLogComponent implements OnInit {
    public employeeId: number;

    constructor(private authService: AuthService,
        private activatedRoute: ActivatedRoute) { }

    public ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
    }
}
