import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'employee-schedule-dialog',
    templateUrl: './employee-schedule.dialog.html',
    styleUrls: ['./employee-schedule.dialog.scss']
})

export class EmployeeScheduleDialog implements OnInit {
    public employeeId: number;
    public atDate: string;
    public scheduleId: number;

    constructor(
        private dialogRef: MatDialogRef<EmployeeScheduleDialog>,
        @Inject(MAT_DIALOG_DATA) {
            employeeId,
            atDate,
            scheduleId
        }: any) {
        this.employeeId = employeeId;
        this.atDate = atDate;
        this.scheduleId = scheduleId;
    }

    public ngOnInit() {

    }

    public close(): void {
        this.dialogRef.close();
    }
}
