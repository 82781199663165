<h2 mat-dialog-title>Glömt att stämpla ut?</h2>
<mat-dialog-content>
    <p>
        Det verkar som att du glömde stämpla ut sist du jobbade. Din senaste instämpling var
        <strong>{{(lastPunch.punchTime | readableDateTime).toLowerCase()}}</strong>, men ingen utstämpling hittades efter detta.
        Nedan kan du lägga in en utstämpling.
    </p>
    <label>Tid för utstämpling</label>
    <div>
        {{lastPunch.punchTime | shortDate}}
        <mat-form-field class="hour">
            <mat-select [(value)]="selectedHour" (selectionChange)="selectedHourChanged()">
                <mat-option *ngFor="let hour of hours; let i = index" [value]="hour">{{hour}}</mat-option>
            </mat-select>
        </mat-form-field>
        :
        <mat-form-field class="minute">
            <mat-select [(value)]="selectedMinute">
                <mat-option
                    *ngFor="let minute of minutes; let i = index"
                    [value]="minute"
                    [disabled]="selectedHour == earliestHour && earliestMinute > minute">
                    {{minute}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <span class="earliest-possible">(Tidigaste möjliga är {{earliestPossible}})</span>
    </div>
    <div>
        <label>Övertid</label>
        <mat-checkbox [(ngModel)]="isOvertime">Jag jobbade övertid</mat-checkbox>
        <mat-form-field class="overtime-employee">
            <mat-label>Övertid beordrat av</mat-label>
            <mat-select [(value)]="overtimeEmployeeId">
                <mat-option *ngFor="let employee of overtimeEmployees" [value]="employee.employeeId">
                    {{employee.firstName}} {{employee.lastName}} {{employee.title && employee.title.length > 0 ? '(' +
                    employee.title + ')' : ''}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <p class="error" *ngIf="missingOvertimeEmployee">Du måste välja vem som beordrade övertiden</p>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="noChanges()">Justera inte, allt är korrekt</button>
    <button mat-raised-button color="primary" (click)="addPunch()">Justera stämplingar</button>
</mat-dialog-actions>
