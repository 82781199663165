import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { Helpers } from '@app/helpers/helpers.class';

// Error handling
import * as Sentry from '@sentry/browser';

// Services
import { ScheduleService } from '@app/services/schedule.service';

// Dialogs
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';

// Interfaces
import { IScheduleLength } from '@app/interfaces/schedule-length.interface';
import { IScheduleWithDays } from '@app/interfaces/schedule-with-days.interface';
import { IScheduleDay } from '@app/interfaces/schedule-day.interface';
import { Router } from '@angular/router';
import { IScheduleDayPeriod } from '@app/interfaces/schedule-day-period.interface';

@Component({
    selector: 'app-schedule-form',
    templateUrl: './schedule-form.partial.html',
    styleUrls: ['./schedule-form.partial.scss'],
})
export class ScheduleFormPartial implements OnInit {
    @Input() scheduleId: number;

    public loading: boolean = false;
    public schedule: IScheduleWithDays;
    public scheduleLengths: IScheduleLength[];
    public scheduleForm: UntypedFormGroup;
    public scheduleDays: UntypedFormArray;
    public breakLengths: number[] = [];
    public dateFilter: any;

    constructor(
        private dialog: MatDialog,
        private scheduleService: ScheduleService,
        private formBuilder: UntypedFormBuilder,
        private router: Router
    ) {}

    public ngOnInit(): void {
        this.scheduleForm = this.formBuilder.group({
            name: ['', Validators.required],
            scheduleLength: [7, [Validators.pattern(Helpers.integerRegex), Validators.required]],
            startDate: ['', Validators.required],
            allowFlex: [false, Validators.required],
            days: this.formBuilder.array([this.newDay()]),
        });

        this.loading = true;

        if (Helpers.isDefined(this.scheduleId) && this.scheduleId > 0) {
            this.getSchedule();
        } else {
            this.resetForm();
        }

        this.getScheduleLengths();
        this.generateBreakLengths();

        this.dateFilter = (date: Date) => {
            return date && date.getDay() === 1;
        };
    }

    get f() {
        return this.scheduleForm.controls;
    }

    public resetForm(): void {
        this.scheduleForm.reset();

        if (Helpers.isDefined(this.scheduleId) && this.scheduleId > 0) {
            this.f.name.setValue(this.schedule.name);
            this.f.scheduleLength.setValue(this.schedule.scheduleLength);
            this.f.startDate.setValue(Helpers.toShortDateString(this.schedule.startDate));
            this.f.allowFlex.setValue(this.schedule.allowFlex);
        } else {
            this.f.name.setValue('');
            this.f.scheduleLength.setValue(7);
            this.f.startDate.setValue(Helpers.getCurrentWeekStart());
            this.f.allowFlex.setValue(false);
        }

        this.getScheduleDays();
    }

    public newDay(): UntypedFormGroup {
        return this.formBuilder.group({
            dayOfWeek: [null, [Validators.pattern(Helpers.integerRegex), Validators.required]],
            dayRow: [null, [Validators.pattern(Helpers.integerRegex), Validators.required]],
            dayName: [null, Validators.required],
            totalBreakLength: [null, [Validators.pattern(Helpers.integerRegex), Validators.required]],
            active: [false, Validators.required],
            periods: this.formBuilder.array([]),
        });
    }

    public newDayPeriod(): UntypedFormGroup {
        return this.formBuilder.group({
            fromHour: [null, [Validators.pattern(Helpers.shortTimeRegex), Validators.required]],
            toHour: [null, [Validators.pattern(Helpers.shortTimeRegex), Validators.required]],
        });
    }

    public getScheduleLengths(): void {
        this.scheduleService.getScheduleLengths().subscribe({
            next: (data) => {
                this.scheduleLengths = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get schedule lengths');
            },
        });
    }

    public getSchedule(): void {
        this.scheduleService.getScheduleWithDays(this.scheduleId).subscribe({
            next: (data) => {
                this.schedule = data;

                if (this.schedule.scheduleId === 0) {
                    this.router.navigate(['/admin/schedules']);
                } else {
                    this.resetForm();
                }
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get schedule');
            },
        });
    }

    public generateBreakLengths(): void {
        this.breakLengths = [];
        for (let i = 0; i <= 180; i += 5) {
            this.breakLengths.push(i);
        }
    }

    public getScheduleDays(): void {
        let startDate = Helpers.toShortDateString(this.f.startDate.value);
        let tempDays: IScheduleDay[] = [];
        this.scheduleDays = this.scheduleForm.get('days') as UntypedFormArray;
        this.loading = true;

        while (this.scheduleDays.length > 0) {
            this.scheduleDays.removeAt(0);
        }

        if (Helpers.isDefined(this.schedule)) {
            for (let day of this.schedule.scheduleDays) {
                tempDays.push(day);
            }
        }

        this.scheduleService.getScheduleDaysByLengthAndStartDate(this.f.scheduleLength.value, startDate).subscribe({
            next: (data) => {
                for (let day of data) {
                    let found = false;
                    if (Helpers.isDefined(this.schedule)) {
                        for (let existingDay of this.schedule.scheduleDays) {
                            if (day.dayRow === existingDay.dayRow) {
                                found = true;
                                break;
                            }
                        }
                    }

                    if (!found) {
                        tempDays.push({
                            dayOfWeek: day.dayOfWeek,
                            dayRow: day.dayRow,
                            dayName: day.dayName,
                            totalBreakLength: 60,
                            active: false,
                            periods: [],
                        });
                    }
                }

                tempDays = tempDays.sort((first, second) => {
                    return first.dayRow - second.dayRow;
                });

                for (let day of tempDays) {
                    let formDay = this.newDay();
                    let periods = formDay.get('periods') as UntypedFormArray;

                    formDay.get('dayOfWeek').setValue(day.dayOfWeek);
                    formDay.get('dayRow').setValue(day.dayRow);
                    formDay.get('dayName').setValue(day.dayName);
                    formDay.get('totalBreakLength').setValue(day.totalBreakLength);
                    formDay.get('active').setValue(day.active);

                    if (!day.active) {
                        this.scheduleDayActiveChange(formDay);
                    }

                    if (day.periods.length > 0) {
                        for (let period of day.periods) {
                            let formDayPeriod = this.newDayPeriod();
                            formDayPeriod.get('fromHour').setValue(Helpers.toShortTimeString(period.fromHour));
                            formDayPeriod.get('toHour').setValue(Helpers.toShortTimeString(period.toHour));
                            periods.push(formDayPeriod);
                        }
                    } else {
                        periods.push(this.newDayPeriod());
                    }

                    this.scheduleDayActiveChange(formDay);
                    this.scheduleDays.push(formDay);
                }
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get schedule days');
                this.loading = false;
            },
        });
    }

    public save(): void {
        for (let dayControl of this.scheduleDays.controls) {
            let periodControls = dayControl.get('periods') as UntypedFormArray;

            for (let periodControl of periodControls.controls) {
                periodControl.get('fromHour').setErrors(null);
                periodControl.get('toHour').setErrors(null);
            }
        }

        if (!this.scheduleForm.valid) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Fel i formuläret',
                    message: 'Alla fält är inte korrekt ifyllda, korrigera felen och försök igen.',
                },
            });
            Helpers.setFormTouched(this.scheduleForm);
        } else {
            this.loading = true;

            let scheduleDays: IScheduleDay[] = [];
            this.scheduleDays = this.scheduleForm.get('days') as UntypedFormArray;
            let todayDate = Helpers.toShortDateString(new Date());

            for (let dayControl of this.scheduleDays.controls) {
                if (dayControl.get('active').value) {
                    let periods: IScheduleDayPeriod[] = [];
                    let periodControls = dayControl.get('periods') as UntypedFormArray;

                    for (let periodControl of periodControls.controls) {
                        let fromHour = periodControl.get('fromHour').value;
                        let toHour = periodControl.get('toHour').value;

                        for (let period of periods) {
                            let existingPeriodFromHourDate = new Date(todayDate + ' ' + period.fromHour);
                            let existingPeriodToHour = new Date(todayDate + ' ' + period.toHour);
                            let fromHourDate = new Date(todayDate + ' ' + fromHour);
                            let toHourDate = new Date(todayDate + ' ' + toHour);

                            if (existingPeriodFromHourDate < toHourDate && fromHourDate < existingPeriodToHour) {
                                periodControl.get('fromHour').setErrors({ dateOverlap: true });
                                periodControl.get('toHour').setErrors({ dateOverlap: true });
                                this.dialog.open(AlertDialog, {
                                    width: '400px',
                                    data: {
                                        title: 'Överlappande perioder',
                                        message: 'Det finns perioder som överlappar varandra, se rödmarkerade fält',
                                    },
                                });
                                this.loading = false;
                                return;
                            }
                        }

                        periods.push({
                            fromHour: periodControl.get('fromHour').value,
                            toHour: periodControl.get('toHour').value,
                        });
                    }

                    scheduleDays.push({
                        dayName: dayControl.get('dayName').value,
                        dayOfWeek: dayControl.get('dayOfWeek').value,
                        totalBreakLength: dayControl.get('totalBreakLength').value,
                        dayRow: dayControl.get('dayRow').value,
                        periods: periods,
                    });
                }
            }

            this.schedule = {
                scheduleId: this.scheduleId > 0 ? this.scheduleId : 0,
                scheduleLength: this.f.scheduleLength.value,
                startDate: Helpers.toShortDateString(this.f.startDate.value),
                allowFlex: this.f.allowFlex.value,
                name: this.f.name.value,
                scheduleDays: scheduleDays,
            };

            if (this.scheduleId > 0) {
                this.scheduleService.update(this.schedule).subscribe({
                    next: (data) => {
                        this.getSchedule();
                    },
                    error: (error) => {
                        console.error(error);
                        Helpers.captureSentryError('Could not update schedule');
                        this.loading = false;
                        this.showError(error);
                    },
                });
            } else {
                this.scheduleService.create(this.schedule).subscribe({
                    next: (data) => {
                        this.router.navigate(['/admin/schedules/' + data.scheduleId]);
                    },
                    error: (error) => {
                        console.error(error);
                        Helpers.captureSentryError('Could not create schedule');
                        this.loading = false;
                        this.showError(error);
                    },
                });
            }
        }
    }

    public showError(error: any): void {
        let title: string = 'Fel';
        let message: string = 'Något gick fel när schemat skulle sparas';
        let errors: string[];

        if (error.status === 400 && error.error.errors) {
            title = 'Felaktiga uppgifter';
            message = 'Följande är fel och måste korrigeras:';
            errors = error.error.errors;
        }

        this.dialog.open(AlertDialog, {
            width: '400px',
            data: {
                title: title,
                message: message,
                list: errors,
            },
        });
    }

    public scheduleDayActiveChange(scheduleDay: UntypedFormGroup): void {
        let periods = scheduleDay.get('periods') as UntypedFormArray;

        if (!scheduleDay.get('active').value) {
            scheduleDay.get('totalBreakLength').disable();
            for (let period of periods.controls) {
                period.get('fromHour').disable();
                period.get('toHour').disable();
            }
        } else {
            scheduleDay.get('totalBreakLength').enable();

            for (let period of periods.controls) {
                period.get('fromHour').enable();
                period.get('toHour').enable();
            }
        }
    }

    public addPeriod(scheduleDay: UntypedFormGroup): void {
        let periods = scheduleDay.get('periods') as UntypedFormArray;
        periods.push(this.newDayPeriod());
    }

    public removePeriod(scheduleDay: UntypedFormGroup, index: number): void {
        let periods = scheduleDay.get('periods') as UntypedFormArray;
        periods.removeAt(index);
    }
}
