import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { ApplicationClientService } from './services/application-client.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    claimMissingDialogOpen: boolean = false;
    isClientAuthorized: boolean | null = null;

    constructor(private applicationClientService: ApplicationClientService, private router: Router) {}

    ngOnInit(): void {
        this.checkClientAuthorized();
        this.router.events
            .pipe(filter((e: any): e is NavigationEnd => e instanceof NavigationEnd))
            .subscribe(() => this.checkClientAuthorized());
    }

    onActivate(event: any) {
        window.scrollTo(0, 0);
    }

    checkClientAuthorized(): void {
        this.applicationClientService.isClientAuthorized().subscribe((response) => {
            this.isClientAuthorized = response.isAuthorized;
        });
    }
}
