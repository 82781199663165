import { Component, OnInit, Input } from '@angular/core';

// Error handling
import * as Sentry from '@sentry/browser';

// Services
import { EmployeeService } from '@app/services/employee.service';

// Interfaces
import { IEmployeeShort } from '@app/interfaces/employee-short.interface';
import { Helpers } from '@app/helpers/helpers.class';

@Component({
    selector: 'app-employee-details',
    templateUrl: './employee-details.partial.html',
    styleUrls: ['./employee-details.partial.scss'],
})
export class EmployeeDetailsPartial implements OnInit {
    @Input() employeeId: number;
    @Input() data: IEmployeeShort;
    @Input() useExternalData: boolean;

    loading: boolean = false;
    employee: IEmployeeShort;

    constructor(private employeeService: EmployeeService) {}

    ngOnInit(): void {
        if (!this.useExternalData) {
            this.getDetails();
        } else if (this.data) {
            this.employee = this.data;
        } else {
            this.loading = true;
        }
    }

    externalDataLoaded(externalData: IEmployeeShort) {
        this.employee = externalData;
        this.loading = false;
    }

    getDetails(): void {
        this.loading = true;
        this.employeeService.getShortDetails(this.employeeId).subscribe({
            next: (data) => {
                this.loading = false;
                this.employee = data;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not get employee details');
            },
        });
    }
}
