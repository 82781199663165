<app-admin-menu></app-admin-menu>

<h1 class="mat-headline-3">Stämpelklockan</h1>

<div class="container">
    <div class="row">
        <div class="col">
            <div class="row margin" [ngClass]="{'locked': !hasCreatePunchClaim}">
                <div class="col">
                    <mat-card class="compact">
                        <mat-card-title>
                            Lägg in stämplingar
                        </mat-card-title>
                        <mat-card-content>
                            <div class="container-fluid">
                                <app-employee-add-punch #employeeAddPunchPartial
                                    (selectedEmployeeChanged)="employeeChanged($event)"></app-employee-add-punch>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <div class="row margin" [ngClass]="{'locked': !hasCreateAbsenceClaim}">
                <div class="col">
                    <mat-card class="compact">
                        <mat-card-title>
                            Lägg in frånvaro
                        </mat-card-title>
                        <mat-card-content>
                            <div class="container-fluid">
                                <app-employee-add-absence #employeeAddAbsencePartial
                                    (selectedEmployeeChanged)="employeeChanged($event)"></app-employee-add-absence>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <div class="row margin" *ngIf="hasActiveWorkPeriodsClaim">
                <div class="col">
                    <mat-card class="compact">
                        <mat-card-title>
                            Aktiva löneperioder
                        </mat-card-title>
                        <mat-card-content>
                            <app-active-work-periods></app-active-work-periods>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="row margin" [ngClass]="{'locked': !hasListPunchesClaim}">
                <div class="col">
                    <mat-card class="compact">
                        <mat-card-title>
                            Visa stämplingar
                        </mat-card-title>
                        <mat-card-content>
                            <app-employee-punches #employeePunchesPartial
                                (selectedEmployeeChanged)="employeeChanged($event)"></app-employee-punches>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <div class="row margin" [ngClass]="{'locked': !hasListAbsenceClaim}">
                <div class="col">
                    <mat-card class="compact">
                        <mat-card-title>
                            Visa frånvaro
                        </mat-card-title>
                        <mat-card-content>
                            <app-employee-absence #employeeAbsencePartial
                                (selectedEmployeeChanged)="employeeChanged($event)"></app-employee-absence>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>

<app-messages #messagesPartial [employeeId]="employeeId"></app-messages>

<app-admin-footer></app-admin-footer>
