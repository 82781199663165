import { Pipe, PipeTransform } from '@angular/core';
import { Helpers } from '@app/helpers/helpers.class';

@Pipe({name: 'preview'})
export class PreviewPipe implements PipeTransform {
    transform(input: string, length: number = 10, replaceNewLines: boolean = true): string {
        if(!Helpers.isDefined(input) || input.length < length) {
            return input;
        }

        if(replaceNewLines) {
            input = Helpers.replaceNewLines(input);
        }

        return input.substr(0, length) + '...';
    }
}
