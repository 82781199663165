import { Pipe, PipeTransform } from '@angular/core';
import { Helpers } from '@app/helpers/helpers.class';

@Pipe({ name: 'hasPunchWarnings' })
export class HasPunchWarningsPipe implements PipeTransform {
    transform(punchDays: any[], severity: number = null, day: any = null): boolean {
        if (typeof punchDays === 'undefined' || punchDays === null || punchDays.length === 0) {
            return false;
        }

        let warningsFound = false;

        for (let i = 0; i < punchDays.length; i++) {
            if (punchDays[i].warnings.length > 0) {
                warningsFound = true;
                break;
            }
        }

        if (!warningsFound) {
            return;
        }

        if (day === null && severity === null) {
            return true;
        }

        for (let i = 0; i < punchDays.length; i++) {
            for (let x = 0; x < punchDays[i].warnings.length; x++) {
                if (day === null) {
                    if (punchDays[i].warnings[x].severity === severity) {
                        return true;
                    }
                } else {
                    if (
                        punchDays[i].warnings[x].severity === severity &&
                        Helpers.toShortDateString(punchDays[i].day) === Helpers.toShortDateString(day)
                    ) {
                        return true;
                    }
                }
            }
        }

        return false;
    }
}
