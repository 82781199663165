<h2 mat-dialog-title>Byt lösenord</h2>
<mat-dialog-content>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <p>
                    <ng-container *ngIf="!mailSent; else mailSentContent">
                        Ditt lösenord är för gammalt och måste bytas innan du
                        kan fortsätta. Klicka på knappen för att påbörja bytet.
                    </ng-container>
                    <ng-template #mailSentContent>
                        En länk har skickats till den e-post du är registrerad
                        med. Följ instruktionerna för att byta ditt lösenord.
                    </ng-template>
                </p>
            </div>
        </div>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button
        mat-raised-button
        color="primary"
        (click)="resetPassword()"
        [disabled]="mailSent">
        Skicka återställningsmail
    </button>
</mat-dialog-actions>
