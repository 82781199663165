import { Pipe, PipeTransform } from '@angular/core';

// Interfaces
import { IEmployeeMessage } from '@app/interfaces/employee-message.interface';

@Pipe({name: 'messageClasses'})
export class MessageClassesPipe implements PipeTransform {
    transform(messages: IEmployeeMessage[], employeeId: number, index: number): string {
        let classes: string[] = ['message'];
        let message = messages[index];

        if(message.fromEmployeeId === employeeId) {
            classes.push('from-me');
        } else {
            classes.push('to-me');
        }

        if((messages.length - 2) >= index &&
            messages[index + 1].fromEmployeeId === message.fromEmployeeId) {
            classes.push('from-streak');
        }

        if(!message.messageRead) {
            classes.push('unread');
        }

        return classes.join(' ');
    }
}
