import { Component, OnInit } from '@angular/core';

// Error handling
import * as Sentry from '@sentry/browser';

// Services
import { SettingService } from '@app/services/settings.service';
import { AdministratorService } from '@app/services/administrator.service';
import { ClaimService } from '@app/services/claim.service';

// Interfaces
import { IAdministrator } from '@app/interfaces/administrator.interface';
import { IClaim } from '@app/interfaces/claim.interface';
import { Helpers } from '@app/helpers/helpers.class';
import { IAdministratorClaim } from '@app/interfaces/administrator-claim.interface';

@Component({
    selector: 'app-admin-claim-settings',
    templateUrl: './admin-claim-settings.partial.html',
    styleUrls: ['./admin-claim-settings.partial.scss'],
})
export class AdminClaimSettingsPartial implements OnInit {
    public filterAdmin: number;
    public administrators: IAdministrator[];
    public allClaims: IClaim[];
    public administratorClaims: number[];
    public claimColumns: string[] = ['category', 'key', 'description', 'enabled'];
    public loading: boolean = false;

    constructor(
        private settingsService: SettingService,
        private administratorService: AdministratorService,
        private claimService: ClaimService
    ) {}

    public ngOnInit(): void {
        this.getAllAdministrators();
        this.getAllClaims();
    }

    public getAllAdministrators(): void {
        this.administratorService.getAll().subscribe({
            next: (data) => {
                this.administrators = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get administrators');
            },
        });
    }

    public getAllClaims(): void {
        this.loading = true;
        this.claimService.getAll().subscribe({
            next: (data) => {
                this.allClaims = data;
                this.loading = false;
            },
            error: (error) => {
                this.loading = false;
                console.error(error);
                Helpers.captureSentryError('Could not get all claims');
            },
        });
    }

    public getAdministratorClaims(): void {
        if (!Helpers.isDefined(this.filterAdmin) || this.filterAdmin === 0) {
            return;
        }
        this.loading = true;
        let claims: number[] = [];
        this.administratorClaims = null;
        this.claimService.getByAdministrator(this.filterAdmin).subscribe({
            next: (data) => {
                for (let claim of data) {
                    claims.push(claim.claimId);
                }
                this.administratorClaims = claims;
                console.log(this.administratorClaims);
                this.loading = false;
            },
            error: (error) => {
                this.loading = false;
                console.error(error);
                Helpers.captureSentryError('Could not get administrator claims');
            },
        });
    }

    public setAdministratorClaim(claim: IClaim, checkbox: any): void {
        let adminClaim: IAdministratorClaim = {
            adminId: this.filterAdmin,
            claimId: claim.claimId,
        };

        if (checkbox.checked) {
            this.claimService.grantAdministratorClaim(adminClaim).subscribe({
                next: (data) => {},
                error: (error) => {
                    console.error(error);
                    Helpers.captureSentryError('Could not grant administrator claim');
                },
            });
        } else {
            this.claimService.denyAdministratorClaim(adminClaim).subscribe({
                next: (data) => {},
                error: (error) => {
                    console.error(error);
                    Helpers.captureSentryError('Could not deny administrator claim');
                },
            });
        }
    }
}
